import {Formik, ErrorMessage} from 'formik';
import * as Yup from "yup";
import React, {useState, useEffect, useRef} from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import * as yup from "yup";
import BookDrivingSlot from "../page/Meeting/component/calendar/BookDrivingSlot";
import { InlineWidget } from 'react-calendly';
import CalendlyWidget from "../page/Meeting/component/CalendlyWidget";

const MeetingAddEdit = ({show, setShowAction, getAllMeetings, getActivity,user}) => {

    const handleClose = () => setShowAction(false);
    const [defaultValue, setDefaultValue] = useState('');
    const [currentPromoteur, setCurrentPromoteur] = useState('');
    const [optPromoteurs, setOptPromoteurs] = useState([]);

    const initialValues = {
        meetingType:{value: 1, label: 'Call'},
        subject: '',
        date: '',
        startTime: '',
        endTime: '',
        promoteur: defaultValue
    };

    useEffect(() => {
        getAllPromoteurs();
    }, []);
    useEffect(() => {

    }, [defaultValue]);

    const getAllPromoteurs = () => {
        axios.get(`/admin/promoteurs`)
            .then(function (response) {

                const promoteurs = response.data;
                setOptPromoteurs(promoteurs);
                setDefaultValue(promoteurs[0])
                promoteurs && promoteurs[0] ? setCurrentPromoteur(promoteurs[0].value) : null
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    function onSubmit(values, actions) {

        const fields = {...values};



            createMeeting(fields);


        actions.setSubmitting(false);

        getActivity()
    }

    const formatDate = (inputDate) => {
// Divise la chaîne en parties (jour, mois, année, heure, minute)
        const parts = inputDate.split(/[\s-:]+/);
// Crée un objet Date en utilisant les parties extraites
// Les mois dans JavaScript sont 0-indexés, donc nous devons soustraire 1 du mois
        const dateObject = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);

        // Utilisez la méthode toISOString() pour formater la date en format ISO
        const isoDate = dateObject.toISOString();

        return isoDate;
    };

    function createMeeting(fields) {

        // Créer un objet représentant les détails de l'événement à ajouter
        const eventDetails = {
            extendedProperties: {
                private: {
                    user: user.id,
                }},
            summary: fields.subject,

            // description: 'Description de l\'événement',
            start: {
                dateTime: formatDate(fields.startTime), // Date et heure de début de l'événement
                timeZone: 'Europe/Paris' // Fuseau horaire de l'événement
            },
            end: {
                dateTime: formatDate(fields.endTime), // Date et heure de fin de l'événement
                timeZone: 'Europe/Paris' // Fuseau horaire de l'événement
            }
        };

        axios.post('http://www.t1.viteimmo.com:3000/apo/calendar/insert/events', eventDetails)
            .then(function (response) {

                setShowAction(false);
                getAllMeetings();
                Swal.fire({
                    icon: 'success',
                    title: 'Le rendez-vous a été ajouté avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {
                console.log(error)
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            });
    }

    const MeetingForm = ({
                             handleSubmit,
                             handleChange,
                             values,
                             errors,
                             isValid,
                             isSubmitting,
                             touched,
                             setFieldValue,
                             handleBlur
                         }) => {

        return (
                <div className={"contact-wrapper-form"}>
                    <CalendlyWidget/>
            </div>
        )
    };

    const validationSchema = Yup.object().shape({
        date: yup.string()
            .required("Une heure de réunion est requise"),
        subject: yup.string()
            .required("Un sujet est requis"),
    });


    const [contacts, setContacts] = useState([])
    // const offcanvasStyle = {
    //     width: '700px', // Ajustez cette valeur selon vos besoins
    // };

    return (

        <Offcanvas className={'offcanvas-size-sm'} id={"offcanvas-contact"} show={show} onHide={handleClose}
                   placement="end" >
            <Offcanvas.Header closeButton className={"border-bottom"}>
                <Offcanvas.Title className={'py-2 ps-4'}>Planifiez un rendez-vous</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {

                        console.log(errors)


                        return (

                            <Form noValidate onSubmit={handleSubmit}>


                                <MeetingForm
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    values={values}
                                    errors={errors}
                                    isValid={isValid}
                                    isSubmitting={isSubmitting}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}

                                />


                            </Form>)
                    }}
                </Formik>
            </Offcanvas.Body>
        </Offcanvas>

    );
}

export default MeetingAddEdit;