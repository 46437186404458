import React, { useContext, useEffect } from "react";
import {FormContext} from "../OptionAddEdit";

function OptionStepper() {
    const { activeStepIndex } = useContext(FormContext);
    useEffect(() => {
        const stepperItems = document.querySelectorAll(".stepper-item");
        stepperItems.forEach((step, i) => {
            if (i <= activeStepIndex) {
                step.classList.add("bg-orange-500", "text-white");
            } else {
                step.classList.remove("bg-orange-500", "text-white");
            }
        });
    }, [activeStepIndex]);
    return (

        <div className="steps orange clearfix">
            <ul>
                <li key="0" className="stepper-item current nav-item"><a className="active nav-link"><span
                    className="number-orange">1.</span> Prospect</a></li>
                <li key="1" className="stepper-item nav-item"><a className="nav-link"><span className="number-orange">2.</span> <span>Lots</span></a>
                </li>
                <li key="2" className="stepper-item nav-item"><a className="done nav-link"><span className="number-orange">3.</span> Récapitulatif</a></li>

                <li key="3" className="stepper-item nav-item"><a className="done nav-link"><span className="number-orange">4.</span> Validation</a>
                </li>
            </ul>
        </div>
    );
}

export default OptionStepper;