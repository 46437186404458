import React, {useState, useEffect, useRef} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../component/Layout"
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {Col, Row} from "react-bootstrap";
import {Icon} from '@iconify/react';
import Sidebar from "./component/Sidebar";
import {GetMail} from "./component/GetMail";
import {loadDynamicContent} from "./component/loadDynamicContent";


function MailView({user}) {

    const [email, setEmail] = useState('');
    const [mail, setMail] = useState('');
    const [name, setName] = useState('');
    const [attachments, setAttachments] = useState([]);
    let navigate = useNavigate();
    const [id, setId] = useState(useParams().id)
    const [dynamicContent, setDynamicContent] = useState('');
    const [modalShow, setModalShow] = React.useState(false);
    const [mailSuggestions, setMailSuggestions] = useState([{}, {}]);

    useEffect(() => {

        email ? loadDynamicContent(email,setDynamicContent) : null;

    }, [email]);

    useEffect(() => {

    }, [dynamicContent]);
    useEffect(() => {

    }, [attachments]);

    function getSender(text) {
        // Utilisez une expression régulière pour extraire le nom et l'e-mail
        const pattern = /(.+?) <(.+)>/;
        const match = text.match(pattern);

        if (match) {
            // Le groupe 1 correspond au nom et le groupe 2 correspond à l'e-mail
            setName(match[1]);
            setMail(match[2]);
        }
    }

    useEffect(() => {

        GetMail(id, setEmail, setName,setMail);
    }, []);


    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1">
                                <div className={'navbar-header'}>
                                    <h1>Messagerie</h1>
                                </div>
                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/courriel/liste`)
                                                  }}>Liste des courriels</span>
                                        </li>
                                        <li className="breadcrumb-item active ">Lire le courriel</li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'content'}>

                {email ?
                    <Row>
                        <Col xs={3}>
                            <Sidebar mailSuggestions={mailSuggestions} modalShow={modalShow}
                                     setModalShow={setModalShow}/>
                        </Col>
                        <Col xs={8}>
                            <Card>
                                <Card.Body>

                                    <div className="d-flex mb-4">
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-14 mt-1">{name}</h5>
                                            <small className="text-muted">{mail}</small>
                                        </div>
                                    </div>
                                    <div>
                                        {dynamicContent.includes('<pre>') ? (
                                            <pre>{dynamicContent}</pre>

                                        ) : (
                                            <div dangerouslySetInnerHTML={{__html: dynamicContent}}/>
                                        )}
                                        <Row>
                                            {attachments.length > 0 ? attachments.map((attachment, index) => {
                                                return (
                                                    <Col xs={6} xl={2} className={'mt-2'}>
                                                        <div className={'wrp-card-rct'}>
                                                            <a href={attachment.url} download={attachment.fileName}>
                                                                <div className={'card-rct'}>

                                                                    <Icon width={'50px'} icon="bi:filetype-docx"/>
                                                                </div>
                                                                <p className={'text-center card-rct-title'}>{attachment.fileName}</p>


                                                            </a></div>
                                                    </Col>
                                                )
                                            }) : null}   </Row>
                                    </div>
                                    <Button variant="secondary" className={"mt-5"} onClick={() => setModalShow(true)}>
                                        Répondre
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    : null
                }


            </section>

        </Layout>
    );
}

export default MailView;