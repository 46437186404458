import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MaterialTable from "@material-table/core";
import {lotLinkValue} from "../../../component/LotLinkValue";
import {pxVenteValue} from "../../../component/PxVenteValue";
import axios from "axios";
import Swal from "sweetalert2";
import FilterLot from "../../Lot/component/FilterLot";
import ScrollWrapper from "../../../utils/ScrollWrapper";
import {handleLotSelectChange} from "../../../component/handleLotSelectChange";
import Pagination from "../../../component/Pagination";
import TablePaginationActions from "../../../component/TablePaginationActions";
import TablePaginationNullActions from "../../../component/TablePaginationNullActions";
import LogApiList from "../../Log/LogApiList";

const LotsTab = ({user, programmeId, setActive, setSelectedRows, isFullSize}) => {


    const tableRef = React.useRef();
    const [lotList, setLotList] = useState([])
    const [isBuyerHidden, setIsBuyerHidden] = useState(true);
    const [filter, setFilter] = useState(false)
    const [filteredData, setFilteredData] = useState([])
    const [programId, setProgramId] = useState('all')
    const [filtered, setFiltered] = useState(false)
    const [actionsTab, setActionsTab] = useState([])
    const getLevelValueForRow = {

        1: '-',
        2: 'RDC', // just for example, remove it if you don't need
        3: 1,
        4: 2,
        5: 3,
        6: 4,
        7: 5, // just for example, remove it if you don't need
        8: -1,
        9: -2,
        10: -3,
        11: -4,
        12: -5, // just for example, remove it if you don't need
        13: 6,
        14: 7,
        15: 8,
        16: 9,
        17: '10 et +',
    };

    const [initialValues, setInitialValues] = useState({

        typeBien: [],
        minPrice: '',
        maxPrice: '',
        minSurface: '',
        maxSurface: '',
        programme: 'all',
    });

    // Variables d'état pour la pagination
    const [dataCount, setDataCount] = useState(0); // Les données de l'API ou de la source
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
        fetchLots();

    }, [initialValues, page, rowsPerPage]); // Exécuter à chaque changement de page ou de nombre de lignes


    useEffect(() => {

    }, [filteredData]);

    useEffect(() => {

        setFilteredData(programId === 'all' ? lotList : lotList.filter(dt => dt.programId === programId))

    }, [programId])

    function fetchLots(fields) {

        var url = programmeId ? '/admin/filter/lots?programId=' + programmeId : '/admin/filter/lots'
        axios.get(url, {
            params: {
                filterData: fields,
                page: page + 1,  // Certaines APIs commencent la pagination à 1
                limit: rowsPerPage,
            }, page: page + 1,  // Certaines APIs commencent la pagination à 1
            limit: rowsPerPage,
        })
            .then(function (response) {

                setDataCount(response.data.meta.total_items)
                setFilteredData(response.data.items);


            });
    }

    const getStatusValueForRow = {

        1: <span className="label label-success">libre</span>,
        2: <span className="label label-warning">optionné</span>, // just for example, remove it if you don't need
        3: <span className="label label-danger">réservé</span>,
        4: <span className="label label-default">signé</span>,
        5: <span className="label label-default">livré</span>
    };

    useEffect(() => {

        user.role === 'ROLE_ACQUEREUR' ? setIsBuyerHidden(true) : setIsBuyerHidden(false)

    }, []);

    return (

        <div className="container-fluid">

            <Row>
                <Col xs={12}>

                    <FilterLot programmeId={programmeId} user={user} setLotList={setFilteredData} fetchLots={fetchLots}
                               lotList={filteredData} initialValues={initialValues}
                               setInitialValues={setInitialValues} setFiltered={setFiltered}/>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <ScrollWrapper dependency={page} path='lot_list' isFullSize={isFullSize}>
                        <MaterialTable
                            title={null} // Ne pas afficher le titre
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} of {count}',
                                    labelRowsSelect: 'lignes'
                                },
                                toolbar: {
                                    nRowsSelected: '{0} lot(s) sélectionnée(s)',
                                    searchPlaceholder: 'Chercher'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Aucun lot à afficher',
                                    filterRow: {
                                        filterTooltip: 'Filter'
                                    }
                                }
                            }}

                            options={{
                                toolbar: false,
                                paging: false,
                                search: false,
                                pageSize: rowsPerPage,
                                toolbarButtonAlignment: "left",
                                filtering: filter,
                                searchFieldAlignment: 'left',
                                selection: user.role === 'ROLE_PROSPECT' || user.role === 'ROLE_ACQUEREUR' || user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ? true : false,
                                selectionProps: rowData => ({
                                    disabled: !rowData.isSelectable, // Désactiver la sélection si isSelectable est faux
                                }),
                            }}
                            onSelectionChange={(rows, row) => handleLotSelectChange(rows, row, setSelectedRows, null, setFilteredData, filteredData, setActive)}
                            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                            columns={[
                                {
                                    title: 'N° Lot', field: 'nrLot', filtering: false,
                                    cellStyle: {className: 'my-custom-class'},
                                    width: "10%", render: rowData => lotLinkValue(rowData, user.role),

                                },
                                {
                                    title: 'N° Copro', field: 'nrCopro', filtering: false

                                },
                                {
                                    title: 'Etat', field: 'status',
                                    filtering: false,
                                    width: "5%",
                                    align: "center",
                                    render: rowData => getStatusValueForRow[rowData.status],

                                },
                                {
                                    title: 'Prix de vente TTC',
                                    field: 'salePrice',
                                    align: "right",
                                    filtering: true,
                                    render: rowData => pxVenteValue(rowData, user.role),
                                },

                                {
                                    title: 'Type',
                                    field: 'type',
                                    filtering: false,
                                },

                                {
                                    title: 'Surf. H',
                                    field: 'livingArea',
                                    filtering: false,
                                    align: "right",
                                },
                                {
                                    title: 'Surf. T',
                                    field: 'landArea',
                                    filtering: false,
                                    align: "right",
                                },

                                {
                                    title: 'Niveau',
                                    field: 'level',
                                    align: "center",
                                    filtering: false,
                                    width: "5%",
                                    render: rowData => getLevelValueForRow[rowData.level],
                                },
                                {
                                    title: "Libellé",
                                    field: 'label',
                                    type: 'date',
                                    filtering: false
                                },
                                {
                                    title: "Plan 3D",
                                    field: 'url3DPlan',
                                    filtering: false,
                                    editable:true
                                },

                            ]}

                            tableRef={tableRef}
                            data={filteredData}
                            actions={actionsTab}
                        />

                    </ScrollWrapper>
                    {filteredData.length > 0 && (
                        <Pagination
                            dataCount={dataCount}  // Total des données
                            page={page}  // Page actuelle
                            rowsPerPage={rowsPerPage}  // Nombre de lignes par page
                            onPageChange={(newPage) => setPage(newPage)}  // Changer la page
                            onRowsPerPageChange={(newRowsPerPage) => {

                                setRowsPerPage(newRowsPerPage);
                                setPage(0);  // Réinitialiser à la première page quand le nombre de lignes change
                            }}
                            ActionsComponent={TablePaginationActions}  // Composant personnalisé de pagination
                            TablePaginationNullActions={TablePaginationNullActions}  // Composant sans actions
                        />
                    )}

                </Col>
            </Row>
        </div>


    )
}

export default LotsTab;