import Calendar from 'react-calendar';
import React, {useState, useEffect, useRef} from 'react';
import 'react-calendar/dist/Calendar.css';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import Swal from "sweetalert2";
import {convertDate} from "../../../../component/convertDate";


// Fonction pour ajouter un zéro à gauche si la valeur est inférieure à 10
const formatWithLeadingZero = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
};

const BookDrivingSlot = props => {

    const [bookingDate, setBookingDate] = useState(new Date());
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [dateString, setDateString] = useState(null);
    const [elementSelectionne, setElementSelectionne] = useState(null);
    const [selectStartTime, setSelectStartTime] = useState('08:00');
    const [slots, setSlots] = useState( [
        {startTime: '08:00', range: "08:00 - 08:30", enabled: true},
        {startTime: '08:30', range: "08:30 - 09:00", enabled: true},
        {startTime: '09:00', range: "09:00 - 09:30", enabled: true},
        {startTime: '09:30', range: "09:30 - 10:00", enabled: true},
        {startTime: '10:00', range: "10:00 - 10:30", enabled: true},
        {startTime: '10:30', range: "10:30 - 11:00", enabled: true},
        {startTime: '11:00', range: "11:00 - 11:30", enabled: true},
        {startTime: '11:30', range: "11:30 - 12:00", enabled: true},
        {startTime: '14:00', range: "14:00 - 14:30", enabled: true},
        {startTime: '14:30', range: "14:30 - 15:00", enabled: true},
        {startTime: '15:00', range: "15:00 - 15:30", enabled: true},
        {startTime: '15:30', range: "15:30 - 16:00", enabled: true},
        {startTime: '16:00', range: "16:00 - 16:30", enabled: true},
        {startTime: '16:30', range: "16:30 - 17:00", enabled: true},
    ]);

    const [slotsPerson, setSlotsPerson] = useState( [
        {startTime: '08:00', range: "08:00 - 10:00", enabled: true},
        {startTime: '10:00', range: "10:00 - 12:00", enabled: true},
        {startTime: '14:00', range: "14:00 - 16:00", enabled: true},
        {startTime: '16:00', range: "16:00 - 18:00", enabled: true},

    ]);

    useEffect(() => {

    }, [slots]);

    useEffect(() => {

        props.show === true ? getEventsList(new Date()) : ''
    }, [props.show]);

    useEffect(() => {
        dateString?getEventsList(dateString):null;
    }, [props.meetingType]);

    // Exemple de fonction pour récupérer l'objet correspondant à une heure donnée
    const handleSelectSlot = (time, index) => {
        setSelectStartTime(time.startTime)
        if (time.enabled) {
            setElementSelectionne(index);
            let [heures, minutes] = time.startTime.split(':');
            bookingDate.setHours(heures);
            bookingDate.setMinutes(minutes);
            props.setFieldValue('startTime', formatDateCustom(bookingDate))
            let maDate = new Date(bookingDate);
            // Obtenez les minutes actuelles
            let minutesActuelles = maDate.getMinutes();
            // Ajoutez 30 minutes
            maDate.setMinutes(minutesActuelles + 30);
            props.setFieldValue('endTime', formatDateCustom(maDate))
            props.setFieldValue('date', formatDateCustom(bookingDate))
        }
    };

    function hoursWithZero(startObject) {
        if (startObject.getHours() < 10) {
            // Ajoutez un zéro devant les heures
            var startHoursWithZero = startObject.getHours().toString().padStart(2, '0');
        } else {
            var startHoursWithZero = startObject.getHours();
        }

        const start = String(startHoursWithZero + ':' + formatWithLeadingZero(startObject.getMinutes()));

        return start;
    }

    // Fonction pour mettre à jour les créneaux
    const updateSlots = (appointments) => {


       const slotsList= props.meetingType.value===1?slots:slotsPerson;

        const updatedSlots = slotsList.map(slot => {
            const slotStartTime = new Date(`2024-02-08 ${slot.startTime}`);
            const slotEndTime = new Date(slotStartTime);
            slotEndTime.setMinutes(slotEndTime.getMinutes() + 30);

            // Vérifier si un rendez-vous se trouve dans le créneau
            const hasAppointment = appointments.some(appointment => {
                const appointmentStart = new Date(appointment.start);
                const appointmentEnd = new Date(appointment.end);

                // Extraire uniquement les heures et les minutes
                const slotHour = slotStartTime.getHours();
                const slotMinute = slotStartTime.getMinutes();
                const appointmentStartHour = appointmentStart.getHours();
                const appointmentStartMinute = appointmentStart.getMinutes();
                const appointmentEndHour = appointmentEnd.getHours();
                const appointmentEndMinute = appointmentEnd.getMinutes();

                // Comparer les heures et les minutes
                // Comparer les heures et les minutes des rendez-vous avec le créneau
                return (
                    // Si l'heure de début du rendez-vous est avant l'heure de fin du créneau
                    // ET
                    // Si l'heure de fin du rendez-vous est après l'heure de début du créneau
                    (appointmentStartHour < slotEndTime.getHours() ||
                        (appointmentStartHour === slotEndTime.getHours() &&
                            appointmentStartMinute < slotEndTime.getMinutes())) &&
                    (appointmentEndHour > slotHour ||
                        (appointmentEndHour === slotHour &&
                            appointmentEndMinute > slotMinute))
                );
            });

            // Mettre à jour le statut du créneau
            return { ...slot, enabled: !hasAppointment };
        });

        setSlots(updatedSlots);
    };

    const getEventsList = (selectedDate) => {
        setDateString(selectedDate);
        var dateString = selectedDate.toISOString();

        var url = `http://www.t1.viteimmo.com:3000/apo/calendar/list/events/${dateString}`;

        axios.get(url)
            .then(response => {
                // La réponse de l'API est stockée dans response.data
                var eventsList = response.data;
                eventsList && updateSlots(eventsList);
            })
            .catch(error => {
                console.log(error)
                if (error.response.status = '401') {

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            });
    }

    // Créez une fonction de formatage de date personnalisée
    function formatDateCustom(date) {

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }


    const onDateChange = selectedDate => {

        setSelectedTimeSlot(null);
        props.setFieldValue('date', formatDateCustom(new Date(selectedDate)))
        setBookingDate(selectedDate);
        const date = convertDate(selectedDate);
        let [heures, minutes] = selectStartTime.split(':');
        selectedDate.setHours(heures);
        selectedDate.setMinutes(minutes);
// Obtention du décalage entre l'heure locale (Paris) et UTC
        var offset = selectedDate.getTimezoneOffset();
// Ajouter le décalage pour obtenir l'heure de Paris
        selectedDate.setMinutes(selectedDate.getMinutes() - offset);
// Convertir en chaîne au format ISO 8601
        getEventsList(selectedDate);
    };

    const buttonWidth = "150px";

    return (
        <div className="k-my-8">
            <Row>
                <div className={'d-flex mb-3'}>
                    <div>
                        <Calendar value={bookingDate} onChange={onDateChange}/>
                    </div>

                    <div className={'columnWithScrollbar'}>
                        <p className={'mb-3'}><small className={'fw-bolder'}>Choisissez l'heure du rendez-vous</small></p>
                        <div id={'list-slot'}>
                            {slots.map((time, index) => {

                                return (
                                    <div
                                        key={index}
                                        style={{width: buttonWidth}}
                                        className={`mb-2 d-block border text-center p-2 ${time.enabled ? '' : 'disabled'} ${index === elementSelectionne ? 'active' : ''}`}
                                        onClick={e => handleSelectSlot(time, index)}
                                    >
                                        <small>{time.range}</small>
                                    </div>
                                );
                            })}</div>
                    </div>
                </div>
            </Row>

            {bookingDate && selectedTimeSlot ? (
                <div>
                    Selected slot: {bookingDate.toDateString()} at {selectedTimeSlot}
                </div>
            ) : null}

        </div>
    );
};

export default BookDrivingSlot;