import {useDropzone} from "react-dropzone";
import React from 'react';
import {formatDate} from "@fullcalendar/core";

//month{numeric,long}
export function formatShortDate(date) {

    // Obtenez la date actuelle
    const dateActuelle = new Date();

    const monthNames = [
        'jan', 'fév', 'mar', 'avr', 'mai', 'juin',
        'juil', 'août', 'sep', 'oct', 'nov', 'déc'
    ];

    if (
        date.getDate() === dateActuelle.getDate() &&
        date.getMonth() === dateActuelle.getMonth() &&
        date.getFullYear() === dateActuelle.getFullYear()
    )
    {
        // Si le message est du jour en cours, affichez l'heure
        const heure = date.getHours();
        const minutes = date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes();
        return `${heure}:${minutes}`;
    }
else{
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const monthName = monthNames[monthIndex];

        return `${day} ${monthName}`;
    }


}