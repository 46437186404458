import React, {useState, useEffect} from 'react';
import Layout from "../../component/Layout";
import Container from 'react-bootstrap/Container';
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import parse from 'html-react-parser';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Dropdown from 'react-bootstrap/Dropdown';




function LogView({history, match}) {

    const [log, setLog] = useState()
    const [id, setId] = useState(useParams().id)
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);



    const fetchLogData = async () => {
        try {
            const response = await axios.get(`/admin/logs/api/${id}`);
            var data = response.data;
            setLog(data);

            setIsLoading(true); // Définir isLoading sur false lorsque les données sont chargées
        } catch (error) {
            setIsLoading(true);
            Swal.fire({
                icon: 'error',
                title: 'Une erreur s\'est produite!',
                showConfirmButton: false,
                timer: 1500
            })

        }
    };


    const getHTTPMethodValueForRow = {

        1: <span className="label label-success">POST</span>,
        2: <span className="label label-primary">GET</span>, // just for example, remove it if you don't need
        3: <span className="label label-warning">PATCH</span>,
        4: <span className="label label-danger">DELETE</span>,
    };

    const getChannelValueForRow = {

        1: <span className="label label-default">programme</span>,
        2: <span className="label label-default">lot</span>, // just for example, remove it if you don't need
        3: <span className="label label-default">media</span>,
        4: <span className="label label-default">contact</span>,
        5: <span className="label label-default">allotement</span>,
        6: <span className="label label-default">adf</span>, // just for example, remove it if you don't need
        7: <span className="label label-default">adfparametre</span>,
        8: <span className="label label-default">adfMedia</span>,
        9: <span className="label label-default">reservation</span>,
        10: <span className="label label-default">tma</span>, // just for example, remove it if you don't need
        11: <span className="label label-default">contactcomptes</span>,
        12: <span className="label label-default">options</span>,
        13: <span className="label label-default">environnement</span>,
        14: <span className="label label-default">adfecheanciers</span>,
        15: <span className="label label-default">comptes</span>,
        16: <span className="label label-default">webhook</span>,
        17: <span className="label label-default">reservationlot</span>,


    };



    useEffect(() => {

        fetchLogData();


    }, [])





    return isLoading?(

        <Layout>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4 ">
                            <div className="navbar navbar-default" role="navigation">
                                <div className="navbar-header">
                                    <a href="#"><h1>
                                     API
                                    </h1></a></div>

                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span></li>
                                        <li className={"link-primary"} onClick={() => {
                                            navigate('/admin/log/api/liste')
                                        }}
                                        >
                                            <span className={"px-1 cursor-pointer"}>Liste des logs</span>
                                        </li>
                                        <li>
                                            {/*<span className={"ps-1"}>{lot.label ? lot.label : lot.nrLot}</span>*/}
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <section className="content">
        <Container fluid>
            <Row>
                <Col lg="6">
                    <Card>
                        <Card.Body className={'px-4'}>
                            <h5 className={"pb-4 border-bottom mb-4"}>Détails</h5>
                            <div className={"info-container mb-4"}>
                                <Table responsive="xl" borderless>
                                    <tbody>
                                    <tr>
                                        <td className={"font-weight-bold"}>Date</td>
                                        <td>{log.date}</td>
                                    </tr>
                                    <tr>
                                        <td className={"font-weight-bold"}>Message</td>
                                    </tr>
                                    <tr>
                                        <td className={"font-weight-bold"}>Promoteur</td>
                                        <td>{log.promoteur}</td>
                                    </tr>
                                    <tr>
                                        <td className={"font-weight-bold"}>Méthode</td>
                                        <td>{getHTTPMethodValueForRow[log.httpMethod]}</td>
                                    </tr>
                                    <tr>
                                        <td className={"font-weight-bold"}>Code</td>
                                        <td>{log.level}</td>
                                    </tr>
                                    <tr>

                                        <td className={"font-weight-bold"}>Canal</td>
                                        <td>{getChannelValueForRow[log.channel]}</td>

                                    </tr>
                                    <tr className={'mt-2'}>

                                        <td className={"font-weight-bold"}>Contenu</td>
                                        <div className={'code-format mt-2'}>
                                            <div className={'list-group-left yellow-text'}>
                                                {log.responseContent?parse(log.responseContent):'204'}
                                            </div>
                                            </div>

                                    </tr>

                                    </tbody>
                                </Table>


                            </div>

                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="6">
                    <Card>
                        <Card.Body>
                            <h5 className={"pb-4 border-bottom mb-4"}>Requête</h5>
                            <div className={'code-format'}>
                                {parse(log.extra)}</div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        </section>
        </Layout>
    ):null

}

export default LogView;