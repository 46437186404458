import React, {useState, useEffect} from 'react';
import Layout from "../component/Layout";
import Container from 'react-bootstrap/Container';
import {Card, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Icon} from "@iconify/react/dist/iconify";
import {useLocation} from "react-router-dom";


function Confirmation() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    //Utiliser pour préselectionner une option dans le menu déroulant des roles
    const role = params.get('role');

    const renderTitleByRole = (role) => {
        switch (role) {
            case 'ROLE_INVESTISSEUR':
            case 'ROLE_PARTENAIRE':
            case 'ROLE_VENDEUR':
            case 'ROLE_CONSOMMATEUR':
                return (
                    'Message envoyé'
                );

            case 'ROLE_COLLEAGUE':
                return (
                    'Votre mot de passe a été défini avec succès !'
                );

            default:
                return (
                  'Vérifier votre boîte mail'
                );
        }
    };

    const renderContentByRole = (role) => {
        switch (role) {
            case 'ROLE_INVESTISSEUR':
            case 'ROLE_PARTENAIRE':
            case 'ROLE_VENDEUR':
            case 'ROLE_CONSOMMATEUR':
                return (
                    <div><p>
                        Nous vous remercions d'avoir pris le temps de remplir le formulaire. Nous vous
                        confirmons que nous l'avons transmis à notre équipe.
                    </p>

                        <p>
                            Nous nous engageons à vous répondre dans les plus brefs délais.
                        </p>
                        <p>
                            Merci pour votre confiance.
                        </p></div>
                );

            case 'ROLE_COLLEAGUE':
                return (
                    <div>

                        <p>
                            Vous pouvez maintenant vous connecter avec vos identifiants pour accéder à votre espace personnel.
                        </p>
                        <Button href={'/login'} className={'mt-5 text-white'}>Se connecter</Button>
                    </div>
                );

            default:
                return (
                    <div>
                        <p>
                            Un email de vérification a été envoyé à votre adresse email. Veuillez
                            vérifier votre boîte de réception
                            et votre dossier spam afin de la valider.
                        </p>

                        <p>
                            Nous vous remercions de votre inscription à notre plateforme. Nous
                            souhaitons vous informer que votre inscription est soumise à une
                            vérification par notre équipe de modération.
                        </p>
                        <p>
                            Nous vous informerons dès que votre compte sera activé et prêt à être
                            utilisé. Si vous avez des questions, n'hésitez pas à nous contacter.
                        </p>
                        <p>
                            Nous vous remercions pour votre compréhension et votre coopération.
                        </p>

                        <Button href={'/login'} className={'mt-5 text-white'}>Se connecter</Button>
                    </div>
                );
        }
    };


    return (
        <Layout>
            <Container className={'mb-5 text-center'}>
                <Row>

                    <Col xs={7} className={'mx-auto auth-form'}>
                        <Card className={'my-5'}>

                            <Icon width={'100px'} height={'100px'} className={'mx-auto mt-5'} icon="tdesign:rocket"/>
                            <Card.Title className={'mt-5'}>
                                <h5>{renderTitleByRole(role)}</h5>
                            </Card.Title>
                            <Card.Body className={'px-4'}>
                                {renderContentByRole(role)}

                            </Card.Body>
                        </Card></Col>
                </Row>
            </Container>

        </Layout>
    );
}

export default Confirmation;