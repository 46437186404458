import React from 'react';
import {useNavigate} from "react-router-dom";


export function GetLinkValueForRow(rowData,entity) {
    var label;
    var entityId;
    let navigate = useNavigate();

    if(entity==='programme'){
        label=rowData.programme;
        entityId=rowData.programId;

    }
    else if(entity==='lot'){
        label=rowData.lot;
        entityId=rowData.lotId;
    }
    else if(entity==='media'){
        label=rowData.id;
        entityId=rowData.id;
    }
    else if(entity==='contacts'){

        label=rowData.contact;
        entityId=rowData.contactId;
    }


    var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
        navigate(`/admin/${entity}/${entityId}/voir`)
    }}>{label}</span>;

    return elem;
}

