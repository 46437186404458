import {useDropzone} from "react-dropzone";
import React from 'react';
import {formatDate} from "@fullcalendar/core";
import axios from "axios";
import Swal from "sweetalert2";

export async function fetchIsContractAvailable(id,tag) {

    try {
        const response = await axios.get(`/admin/is-contract-available/${id}`,{
            params: {
                tag: tag,
            },
        });

        return response;
    } catch (error) {

        if (error.response && error.response.status === 404) {
            // L'élément n'a pas été trouvé, traiter l'indisponibilité
            console.log('Le modèle de contrat n\'existe pas');
        } else {
            // Autres erreurs, traiter en conséquence
            console.log('Erreur de requête', error);
        }

        throw error;
    }}




