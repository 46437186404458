import React, {useState, useEffect, useRef} from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SimpleSlider from "../../../component/SimpleSlider";
import ListGroup from "react-bootstrap/ListGroup";
import {switchValue} from "../../../component/SwitchValue";
import {Icon} from '@iconify/react';
import Button from "react-bootstrap/Button";
import WorkSituationAddEdit from "./WorkSituationAddEdit";
import axios from "axios";
import Swal from "sweetalert2";
import {Editor} from '@tinymce/tinymce-react';
import HorizontalNonLinearStepper from "./HorizontalNonLinearStepper";
import Form from "react-bootstrap/Form";

const ProgramHome = ({
                         program,
                         adminRole,
                         user,
                         workSituationsList,
                         getAllWorkSituations,
                         medias,
                         description,
                         setDescription,
                         progress,
                         setProgress,
                         setPlan3D,
                         plan3D,
                         videoUrl,
                         setVideoUrl
                     }) => {

    const [isBuyerHidden, setIsBuyerHidden] = useState(true);
    const [show, setShow] = useState(false);

    const [idWorkSituation, setWorkSituation] = useState(null);
    const [isAddMode, setIsAddMode] = useState(false);
    const editorRef = useRef(null);

    useEffect(() => {

    }, [medias]);
    const handleShow = id => {

        id ? setWorkSituation(id) : setIsAddMode(true);
        setShow(true);
    };
    const handleVideoUrlChange = (e) => {
        setVideoUrl(e.target.value)
    }

    const handlePlan3DChange = (e) => {
        setPlan3D(e.target.value)
    }
    useEffect(() => {

    }, [idWorkSituation]);

    const handleDelete = id => {
        deleteWorkSituation(id)
    };


    function deleteWorkSituation(id) {

        Swal.fire({
            icon: 'warning',
            title: "Avertissement",
            text: "Confirmer la suppression de l'état d'avancement?",
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            dangerMode: true,
        })
            .then(result => {

                if (result.isConfirmed) {


                    axios.delete(`/admin/worksituations/${id}`)
                        .then(res => {

                            getAllWorkSituations();

                            var message = "L'état d'avancement a bien été supprimé"

                            Swal.fire({
                                title: "Fait!",
                                text: message,
                                icon: "success",
                                timer: 2000,
                                button: false
                            })
                        })
                        .catch(function (error) {

                                if (error.response.status === 401) {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'Vous n\'êtes pas autorisé à supprimer cet état d\'avancement',
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                }
                            }
                        );

                } else {

                    Swal.fire({
                        icon: 'info',
                        title: "Information",
                        text: 'Votre état d\'avancement est maintenu',
                        showConfirmButton: true,
                        showCancelButton: true,
                        cancelButtonText: 'Annuler',
                        dangerMode: true,
                    })

                }
            })
    }

    useEffect(() => {

        adminRole === 'ROLE_ACQUEREUR' ? setIsBuyerHidden(true) : setIsBuyerHidden(false)

    }, []);

    return (

        <div>

            <Row>
                <Col lg="4">

                    {program && program.medias && program.medias.slider1 && program.medias.slider1.length > 0 ?
                        <SimpleSlider medias={program.medias}/> : null}
                    {workSituationsList.length > 0 && user.role === 'ROLE_ACQUEREUR' || user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ?
                        <Card className={'mt-3'}>
                            <Card.Body>
                                <Card.Title
                                    className={'d-flex justify-content-between pb-3 border-bottom-blue-grey border-bottom-lighten-5 wrapper-card-title'}>
                                    <h2 className={'pt-3'}>Avancement du chantier</h2>


                                    {user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ?
                                        <Button id="add-work-situation" onClick={() => {
                                            handleShow(null)
                                        }}> <Icon style={{color: "white"}} icon="humbleicons:plus"/> </Button> : null}


                                </Card.Title>

                                {workSituationsList.length > 0 ? <ul className={"timeline mt-4"}>

                                        <div>
                                            {workSituationsList.map((workSituation, index) => {
                                                    const point = `timeline-point timeline-point-primary bg-info`;
                                                    return (

                                                        <li className={"timeline-item timeline-item-transparent"} key={index}>
                                                            <span className={point}></span>
                                                            <div className={'timeline-event'}>
                                                                <div className={'timeline-header mb-1'}>
                                                                    <h6>{workSituation.label}</h6>
                                                                    <small className="text-muted">{workSituation.date}</small>
                                                                    {user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ?
                                                                        <div>
                                                                            <Icon style={{color: "black"}} icon="cil:pencil"
                                                                                  className={'me-3'}
                                                                                  onClick={() => {
                                                                                      handleShow(workSituation.id)
                                                                                  }}/>
                                                                            <Icon width={'18px'} height={'18px'}
                                                                                  style={{color: "black"}}
                                                                                  icon="material-symbols-light:delete-outline"
                                                                                  onClick={() => {
                                                                                      handleDelete(workSituation.id)
                                                                                  }}/>
                                                                        </div>
                                                                        : null}


                                                                </div>
                                                                <p className={"text-muted"}>{workSituation.description}</p>

                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </div>

                                    </ul> :
                                    user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ?
                                        <Row className={'text-center'}>
                                            <Icon style={{color: "#8c8c8d"}} width={'100px'} id={"icon-park-outline"}
                                                  icon="icon-park-outline:writing-fluently"/>
                                            <h3 className={'fw-bold mt-4'}>Ajouter un état d'avancement</h3>
                                            <p className={'col-10 mx-auto mb-4'}>Informer l'ensemble de vos contacts des
                                                avancées de chantier.</p>
                                            <Row className={'text-center'}>
                                                <div className={'col-3 mx-auto mb-3'}>
                                                    <Button onClick={() => {
                                                        handleShow(null)
                                                    }}>Ajouter</Button></div>
                                            </Row>
                                        </Row> : null
                                }

                            </Card.Body>
                            <WorkSituationAddEdit show={show} setShow={setShow} setIsAddMode={setIsAddMode}
                                                  isAddMode={isAddMode} idWorkSituation={idWorkSituation}
                                                  getAllWorkSituations={getAllWorkSituations}/>
                        </Card> : null}
                </Col>
                <Col lg="8">
                    {user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' && user.codeClient === '00003' || user.codeClient === '57006' || user.codeClient === '00000' ?
                        <Card>
                            <Card.Body>
                                <HorizontalNonLinearStepper progress={progress} setProgress={setProgress}/>
                            </Card.Body>

                        </Card> : null}
                    <Card>
                        <Card.Body>
                            <h2 className={'pt-3'}>Présentation</h2>
                            <p>{program.descriptif ?
                                <div dangerouslySetInnerHTML={{__html: program.descriptif}}/> : 'non renseignée'}</p>
                        </Card.Body>

                    </Card>
                    {user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' && user.codeClient === '00003' || user.codeClient === '57006' ?
                        <Card>
                            <Card.Body>
                                <h2 className={'pt-3'}>Description</h2>
                                <Editor
                                    apiKey='b4gtx3qcm9yj0zi2xvm7ysp9l3xhba3vh9b5gy4f5l1qi5ka'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    value={description}
                                    onEditorChange={(newValue, editor) => {
                                        // setMessage(newValue);
                                        setDescription(newValue)
                                        // setText(editor.getContent({format: 'text'}));
                                    }}
                                    init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                            'lists', // Active le support des listes
                                            'advlist', // Ajoute des styles avancés pour les listes
                                            'customplugin',
                                            'videoplugin'
                                        ],
                                        toolbar: 'undo redo | h1 h2 h3 | bullist ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help | custombutton videoplugin',
                                        extended_valid_elements: 'iframe[src|frameborder|style|scrolling|class|width|height|name|align]',
                                        content_style: '',
                                        valid_elements: '*[style|class],-span', // Vous pouvez personnaliser selon vos besoins
                                        setup: (editor) => {
                                            console.log('Editor setup called');
                                            // Enregistrer un plugin personnalisé
                                            // Enregistrement du plugin personnalisé
                                            tinymce.PluginManager.add('customplugin', function (editor) {
                                                // Ajout d'un bouton personnalisé
                                                editor.ui.registry.addButton('custombutton', {
                                                    text: 'Insérer un Iframe',
                                                    onAction: () => {
                                                        // Ouvrir une fenêtre modale pour saisir le lien
                                                        editor.windowManager.open({
                                                            title: 'Insérer un Iframe',
                                                            body: {
                                                                type: 'panel',
                                                                items: [
                                                                    {
                                                                        type: 'input',
                                                                        name: 'link',
                                                                        label: 'URL',
                                                                        placeholder: 'Entrez une url',
                                                                    },
                                                                ],
                                                            },
                                                            buttons: [
                                                                {
                                                                    text: 'Annuler',
                                                                    type: 'cancel',
                                                                },
                                                                {
                                                                    text: 'Insérer',
                                                                    type: 'submit',
                                                                    primary: true,
                                                                },
                                                            ],
                                                            onSubmit: (api) => {
                                                                const data = api.getData(); // Récupère les données du formulaire
                                                                const link = data.link;

                                                                if (link) {
                                                                    // Insère un lien dans l'éditeur
                                                                    editor.insertContent(` <h3>Visite virtuelle du programme</h3>    <div class="rh-property-virtual-tour-inner-wrap">   <iframe
                            src="${link}"
                            width="100%"
                            height="430"
                            frameborder="0"
                            allowfullscreen>
                    </iframe></div>`);
                                                                }

                                                                api.close(); // Ferme la fenêtre modale
                                                            },
                                                        });
                                                    },
                                                });
                                            });
                                            tinymce.PluginManager.add('videoplugin', function (editor) {
                                                // Ajout d'un bouton personnalisé
                                                editor.ui.registry.addButton('videoplugin', {
                                                    text: 'Insérer une vidéo',
                                                    onAction: () => {
                                                        // Ouvrir une fenêtre modale pour saisir le lien
                                                        editor.windowManager.open({
                                                            title: 'Insérer une vidéo',
                                                            body: {
                                                                type: 'panel',
                                                                items: [
                                                                    {
                                                                        type: 'input',
                                                                        name: 'link',
                                                                        label: 'URL',
                                                                        placeholder: 'Entrez une url',
                                                                    },
                                                                ],
                                                            },
                                                            buttons: [
                                                                {
                                                                    text: 'Annuler',
                                                                    type: 'cancel',
                                                                },
                                                                {
                                                                    text: 'Insérer',
                                                                    type: 'submit',
                                                                    primary: true,
                                                                },
                                                            ],
                                                            onSubmit: (api) => {
                                                                const data = api.getData(); // Récupère les données du formulaire
                                                                const link = data.link;

                                                                if (link) {
                                                                    // Insère un lien dans l'éditeur
                                                                    editor.insertContent(` <h3>Vidéo du programme</h3>    <div class="rh-property-virtual-tour-inner-wrap">         <iframe
                    width="100%"
                    height="430"
                    src="${link}"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
            </iframe></div>`);
                                                                }

                                                                api.close(); // Ferme la fenêtre modale
                                                            },
                                                        });
                                                    },
                                                });
                                            });
                                        },
                                    }}
                                />
                            </Card.Body>

                        </Card> : null}


                    <Card>
                        <Card.Body>
                            <h2 className={'pt-3'}>Caractéristiques</h2>
                            <Row>
                                <Col lg="6">
                                    <ListGroup id='program-details' className={"borderless"} as="ul">
                                        <ListGroup.Item as="li"
                                                        className={"d-flex justify-content-between lh-condensed"}>
                                            <div className={"text-muted"}>Adresse</div>
                                            <span>{program.address ? program.address : 'non renseigné'}</span></ListGroup.Item>
                                        <ListGroup.Item as="li"
                                                        className={"d-flex justify-content-between lh-condensed"}>
                                            <div className={"text-muted"}>Code postal</div>
                                            <span>{program.zipCode}</span></ListGroup.Item>
                                        <ListGroup.Item as="li"
                                                        className={"d-flex justify-content-between lh-condensed"}>
                                            <div className={"text-muted"}>Ville</div>
                                            <span>{program.city}</span></ListGroup.Item>
                                        <ListGroup.Item as="li"
                                                        className={"d-flex justify-content-between lh-condensed"}>
                                            <div className={"text-muted"}>Latitude</div>
                                            <span>{program.lat}</span></ListGroup.Item>
                                        <ListGroup.Item as="li"
                                                        className={"d-flex justify-content-between lh-condensed"}>
                                            <div className={"text-muted"}>Longitude</div>
                                            <span>{program.long}</span></ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col lg="6">
                                    <ListGroup className={"borderless"} as="ul" id={"program-features"}>
                                        {/*<ListGroup.Item as="li"*/}
                                        {/*                className={"d-flex justify-content-between lh-condensed"}>*/}
                                        {/*    <div className={"text-muted"}>Loi Pinel</div>*/}
                                        {/*    <span>{switchValue(program.loiPinel)}</span></ListGroup.Item>*/}
                                        <ListGroup.Item as="li"
                                                        className={"d-flex justify-content-between lh-condensed"}>
                                            <div className={"text-muted"}>Loi Girardin</div>
                                            <span>{switchValue(program.loiGirardin)}</span></ListGroup.Item>
                                        <ListGroup.Item as="li"
                                                        className={"d-flex justify-content-between lh-condensed"}>
                                            <div className={"text-muted"}>Prêt à taux zéro</div>
                                            <span>{switchValue(program.PTZ)}</span></ListGroup.Item>

                                        {adminRole != 'ROLE_FOURNISSEUR' ? <ListGroup.Item as="li"
                                                                                           className={"d-flex justify-content-between lh-condensed"}>
                                            <div className={"text-muted"}>Prix min</div>
                                            <span>{program.priceFrom}</span></ListGroup.Item> : null}
                                        {adminRole != 'ROLE_FOURNISSEUR' ? <ListGroup.Item as="li"
                                                                                           className={"d-flex justify-content-between lh-condensed"}>
                                            <div className={"text-muted"}>Prix max</div>
                                            <span>{program.priceTo}</span></ListGroup.Item> : null}

                                        <ListGroup.Item as="li"
                                                        className={"d-flex justify-content-between lh-condensed mb-2"}>
                                            <div className={"text-muted"}>Plan 3D</div>
                                            <span> <Form.Control
                                                name={`plan3D`}
                                                type="text"
                                                // disabled={isDisabled}
                                                onChange={handlePlan3DChange}
                                                value={plan3D}
                                            /></span>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li"
                                                        className={"d-flex justify-content-between lh-condensed"}>
                                            <div className={"text-muted"}>Vidéo</div>
                                            <span> <Form.Control
                                                name={`videoUrl`}
                                                type="text"
                                                // disabled={isDisabled}
                                                onChange={handleVideoUrlChange}
                                                value={videoUrl}
                                            /></span>
                                        </ListGroup.Item>

                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Body>

                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <h2 className={'pt-3 pb-2'}>Documents</h2>
                            {medias.length > 0 && medias
                                .filter((media) => media.contentType === 'application/pdf').map((media, index) => {
                                    return (
                                        <div className={'mb-3 d-flex align-items-center pb-3'} key={index}>

                                            <div className={'rounded-circle bg-primary-subtle me-2'}><Icon
                                                width={'30px'} style={{color: "#556EE6"}}
                                                icon="bxs:file-doc"/></div>
                                            <div><a href={media.mediaPublicUrl} target="_blank"
                                                    className="text-body nav-link-pdf">{media.name}</a><br/><small>Size
                                                : {media.size}</small></div>
                                        </div>
                                    )
                                })}

                        </Card.Body>

                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="4">

                </Col>
            </Row>

        </div>


    )
}

export default ProgramHome;