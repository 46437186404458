import React from 'react';

import { convertToFloat, numberWithCommas } from '../../../utils/formatters';
import {addTotalToState} from '../../../utils/tableUtils'


export default function handleLotRowUpdate({
                                               newData,
                                               oldData,
                                               lotList,
                                               setLotList,
                                               setFieldValue=null,
                                           }) {
    return new Promise((resolve) => {
        setTimeout(() => {
            console.log(lotList)


            const dataUpdate = [...lotList];
            const index = oldData.tableData.index;
            console.log(newData)
            console.log(oldData)
            if (newData.salePrice != oldData.salePrice) {
                console.log('hh')
                if (newData.mntDG === oldData.mntDG) {
                    const multiply = newData.pctDG * 0.01;
                    const newMntDG = convertToFloat(newData.salePrice) * multiply;
                    newData.mntDG = numberWithCommas(newMntDG.toFixed(2));
                }

                let salePrice = convertToFloat(newData.salePrice);
                salePrice = numberWithCommas(salePrice.toFixed(2));
                newData.salePrice = salePrice;
            }

            if (newData.mntDG != oldData.mntDG) {
                const mntDG = convertToFloat(newData.mntDG);
                newData.mntDG = numberWithCommas(mntDG.toFixed(2));
            }

            dataUpdate[index] = newData;
            const valuesUpdate = dataUpdate.map(({ tableData, ...rest }) => rest);

            setFieldValue ?setFieldValue('lots', valuesUpdate):null;
            setLotList([...dataUpdate]);

            resolve();
        }, 1000);
    });
}
