import {FormContext} from "../OptionAddEdit";
import Buyer from "../Steps/Buyer";
import Step2 from "../Steps/Step2";
import React, { useContext,useEffect } from "react";
import Step3 from "../Steps/Step3";
import Validation from "../Steps/Validation";

function OptionStep({user}) {

    const { activeStepIndex } = useContext(FormContext);
    let stepContent;

    switch (activeStepIndex) {
        case 0:
            stepContent = <Buyer user={user}/>;
            break;
        case 1:
            stepContent = <Step2 user={user}/>;
            break;
        case 2:
            stepContent = <Step3 user={user}/>;
            break;
        case 3:
            stepContent = <Validation />;
            break;
        default:
            break;
    }

    return stepContent;
}

export default OptionStep;