import axios from "axios";
import React, {useState, useEffect, useRef} from 'react';


export function getSender(text) {
    // Utilisez une expression régulière pour extraire le nom et l'e-mail
    var pattern = /(.+?) <(.+)>/;
    var match = text.match(pattern);
    // const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    //
    if (match) {
        // Le groupe 1 correspond au nom et le groupe 2 correspond à l'e-mail
        var name=match[1];
        var email=match[2];

    }
    return [name,email]
}