import React, {useState, useEffect} from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import MaterialTable from "@material-table/core";
import Chart from "react-apexcharts";
import {ComposableMap, Geographies, Geography, ZoomableGroup, Marker} from "react-simple-maps";
import {Icon} from '@iconify/react';
import {useNavigate} from "react-router-dom";
import TimelineTab from "../component/TimelineTab";
import Card from "react-bootstrap/Card";
import {Col} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import ReservationTab from "../page/Reservation/component/ReservationTab";
import DashboardReservationTab from "../component/DashboardReservationTab";

function Dashboard({user}) {

    let navigate = useNavigate();
    const [scaleFactor, setScaleFactor] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [nbrOptions, setNbrOptions] = useState([]);
    const [optionsByMonth, setOptionsByMonth] = useState([]);
    const [textLotCard, setTextLotCard] = useState();
    const [activityList, setActivityList] = useState([]);
    const [buyerActivity, setBuyerActivity] = useState([]);
    const [nbrBooked, setNbrBooked] = useState([]);
    const [nbrContacts, setNbrContacts] = useState([]);
    const [nbrLots, setNbrLots] = useState([]);
    const [promoteurList, setPromoteurList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [nbrDepartments, setNbrDepartments] = useState();
    const [timeline, setTimeline] = useState([])
    const [nbrVilles, setNbrVilles] = useState();
    const [now, setNow] = useState();
    const [balance, setBalance] = useState();
    const [nbrProgrammes, setNbrProgrammes] = useState();
    const [departmentList, setDepartmentList] = useState([]);
    const [cards, setCards] = useState([]);
    const [sales, setSales] = useState();
    const [role, setRole] = useState();
    const [donutParams, setDonutParams] = useState({legend: {}, footer: {}});
    const [donutOptions, setDonutOptions] = useState({fill: {colors: {}}});
    const [chartBarSeries, setChartBarSeries] = useState([]);
    const [barChartOptions, setBarChartOptions] = useState();
    const [percentageAdf, setPercentageAdf] = useState();
    const [percentageBooked, setPercentageBooked] = useState();
    const [percentageOptioned, setPercentageOptioned] = useState();
    const [percentageAvailable, setPercentageAvailable] = useState();
    const [showAction, setShowAction] = useState(false);
    const [series, setSeries] = useState([]);
    const tableRef = React.useRef();
    const geoUrl = "/assets/json/fr-departments.json";
    const ENTITY_ADF = 1;
    const ENTITY_TMA = 2;
    const ENTITY_INVOICE = 3;
    const ENTITY_MARKET = 4;
    const ENTITY_AMENDMENT = 5;
    const STATUS_CREATE = 1;
    const STATUS_UPDATE = 2;
    const [markers, setMarkers] = useState([]);
    const [filteredData, setFilteredData] = useState([])

    const openModal = (marker) => {

        setModalContent(marker); // Assignez le contenu de la modale
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalContent(null);
    };
    useEffect(() => {

    }, [modalIsOpen]);

    const getActivity = () => {

        axios.get(`/admin/contacts/${user.id}/timeline?type=user&&role=ROLE_PROSPECT`)
            .then(function (response) {

                var timeline = response.data;
                setTimeline(timeline)

            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
    useEffect(() => {
        if (user.role === 'ROLE_PROSPECT') {

            getActivity();


        }
    }, []);

    // Définir les liens en fonction du rôle de l'utilisateur
    const linksByRole = {
        ROLE_ADMIN: [
            {
                title: 'Programmes',
                url: '/admin/programmes/list',
                description: 'Consulter mes programmes',
                icon: 'hugeicons:real-estate-02',
                plus: false,
                background: 'bg-label-primary',
            },
            {
                title: 'Réservations',
                url: '/admin/reservation/list',
                url2: '/admin/reservation/ajouter',
                description: 'Consulter mes réservations',
                icon: 'bi:houses',
                icon2: 'lucide:house-plus',
                plus: true,
                background: 'bg-label-danger'
            },
            {
                title: 'Options',
                url: '/admin/option/liste',
                url2: '/admin/option/ajouter',
                description: 'Consulter mes options',
                icon: 'mdi:calendars',
                icon2: 'tabler:calendar-plus',
                plus: true,
                background: 'bg-label-success'
            },
            {
                title: 'Contacts',
                url: '/admin/contact/list',
                url2: '/admin/contact/list?show=true',
                description: 'Consulter mes prospects',
                icon: 'uil:users-alt',
                icon2: 'lucide:user-plus',
                plus: true,
                background: 'bg-label-warning'
            }
        ],
        ROLE_PROMOTEUR: [
            {
                title: 'Programmes',
                url: '/admin/programmes/list',
                description: 'Consulter mes programmes',
                icon: 'lucide-lab:floor-plan',
                plus: false,
                background: 'bg-label-primary'
            },
            {
                title: 'Réservations',
                url: '/admin/reservation/list',
                url2: '/admin/reservation/ajouter',
                description: 'Consulter mes réservations',
                icon: 'bi:houses',
                icon2: 'lucide:house-plus',
                plus: true,
                background: 'bg-label-danger'
            },
            {
                title: 'Options',
                url: '/admin/option/liste',
                url2: '/admin/option/ajouter',
                description: 'Consulter mes options',
                plus: true,
                icon: 'mdi:calendars',
                icon2: 'tabler:calendar-plus',
                background: 'bg-label-success'
            },
            {
                title: 'Contacts',
                url: '/admin/contact/list',
                url2: '/admin/contact/list?show=true',
                description: 'Consulter mes prospects',
                plus: true,
                icon: 'uil:users-alt',
                icon2: 'lucide:user-plus',
                background: 'bg-label-warning'
            }
        ],

        ROLE_PRESCRIPTEUR: [
            {
                title: 'Programmes',
                url: '/admin/programmes/list',
                description: 'Consulter mes programmes',
                icon: 'lucide-lab:floor-plan',
                plus: false,
                background: 'bg-label-primary'
            },
            {
                title: 'Réservations',
                url: '/admin/reservation/list',
                url2: '/admin/reservation/ajouter',
                description: 'Consulter mes réservations',
                icon: 'bi:houses',
                icon2: 'material-symbols:in-home-mode-outline',
                plus: true,
                background: 'bg-label-danger'
            },
            {
                title: 'Options',
                url: '/admin/option/liste',
                url2: '/admin/option/ajouter',
                description: 'Consulter mes options',
                icon: 'mdi:calendars',
                icon2: 'tabler:calendar-plus',
                plus: true,
                background: 'bg-label-success'
            },
            {
                title: 'Contacts',
                url: '/admin/contact/list',
                url2: '/admin/contact/list?show=true',
                description: 'Consulter mes prospects',
                plus: true,
                icon: 'uil:users-alt',
                icon2: 'lucide:user-plus',
                background: 'bg-label-warning'
            }
        ],
        ROLE_FOURNISSEUR: [
            {
                title: 'Facture',
                url: '/admin/facture/liste',
                description: 'Consulter mes factures',
                plus: false,
                icon: 'lucide-lab:floor-plan',
                background: 'bg-label-primary'
            },
            {
                title: 'Marché',
                url: '/admin/marche/liste',
                description: 'Consulter mes marchés',
                plus: false,
                icon: 'material-symbols:in-home-mode-outline',
                background: 'bg-label-danger'
            },
            {
                title: 'Réserve',
                url: '/admin/reserve/liste',
                description: 'Consulter mes réserves',
                plus: false,
                icon: 'lucide:user-check',
                background: 'bg-label-success'
            },
            {
                title: 'Médias',
                url: '/admin/media/liste',
                description: 'Consulter mes documents',
                icon: 'uil:users-alt',
                plus: false,
                background: 'bg-label-warning'
            }
        ],
        ROLE_ACQUEREUR: [
            {
                title: 'Lots',
                url: '/admin/programmes/list',
                description: 'Consulter les lots',
                icon: 'uil:create-dashboard',
                plus: false,
                background: 'bg-label-primary',
            },
            {
                title: 'Appels de fonds',
                url: '/admin/adf/list',
                description: 'Consulter mes appels de fonds',
                plus: false,
                icon: 'ph:bank',
                background: 'bg-label-danger'
            },
            {
                title: 'Options',
                url: '/admin/option/liste',
                url2: '/admin/option/ajouter?prom=' + user.whiteLabelId+'&user='+user.id,
                description: 'Consulter mes options',
                icon: 'mdi:calendars',
                icon2: 'tabler:calendar-plus',
                plus: user.isWhiteLabel ? true : false,
                background: 'bg-label-success'
            },
            {
                title: 'Médias',
                url: '/admin/media/liste',
                description: 'Consulter mes documents',
                plus: false,
                icon: 'material-symbols:perm-media-outline',
                background: 'bg-label-warning'
            }
        ],
        ROLE_PROSPECT: [
            {
                title: 'Lots',
                url: '/admin/programmes/list',
                description: 'Consulter les lots',
                plus: false,
                icon: 'uil:create-dashboard',
                background: 'bg-label-primary'
            },
            {
                title: 'Programmes',
                url: '/admin/programmes/list',
                description: 'Consulter mes programmes',
                icon: 'lucide-lab:floor-plan',
                plus: false,
                background: 'bg-label-danger',
            },
            {
                title: 'Options',
                url: '/admin/option/liste',
                url2: '/admin/option/ajouter?prom=' + user.whiteLabelId+'&user='+user.id,
                description: 'Consulter mes options',
                icon: 'mdi:calendars',
                icon2: 'tabler:calendar-plus',
                plus: user.isWhiteLabel ? true : false,
                background: 'bg-label-success'
            },
            {
                title: 'Médias',
                url: '/admin/media/liste',
                description: 'Consulter mes documents',
                icon: 'material-symbols:perm-media-outline',
                plus: false,
                background: 'bg-label-warning'
            }
        ]

    };

    // Sélectionner les liens en fonction du rôle de l'utilisateur
    const userLinks = linksByRole[user.role];


    const lineChartOptions = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },

        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 1.5,
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
        },

        colors: ['#FDAC41', '#00B5B8']

    };

    useEffect(() => {
        user.role==='ROLE_ACQUEREUR'?fetchReservations():null;

        getUserStats();
        user.role==='ROLE_PRESCRIPTEUR'?getAllContacts():null;
    }, []);

    useEffect(() => {

    }, [role]);

    const fetchReservations = () => {
        axios.get(`/admin/reservations`)
            .then(function (response) {

                    response.data ? setFilteredData(response.data) : null;
            })
            .catch(function (error) {
                console.log(error)

                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })

            })
    }


    const getAllContacts = () => {

        axios.get(`/admin/transactions/contacts`)
            .then(function (response) {

                response.data.length > 0 ? setContactList(response.data) : null;

            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getUserStats = () => {

        axios.get(`/admin/users/stats`)
            .then(function (response) {

                let statistics = response.data;
                statistics.cards ? setCards(statistics.cards) : null;
                setDonutParams(statistics.donut);
                setBuyerActivity(statistics.buyerActivity);
                setPromoteurList(statistics.promoteurList);
                setNow(statistics.now);
                setBalance(statistics.balance);
                setRole(statistics.role);
                setChartBarSeries(statistics.barSeries)
                setOptionsByMonth(statistics.optionsByMonth);
                setTextLotCard(statistics.textLotCard);
                statistics.activityList ? setActivityList(statistics.activityList) : null;
                setDepartmentList(statistics.departmentList)
                setNbrDepartments(statistics.nbrDepartments)
                setNbrVilles(statistics.nbrVilles)
                setNbrProgrammes(statistics.nbrProgrammes)
                setMarkers(statistics.programmesList)
                setNbrOptions(statistics.options);
                setNbrContacts(statistics.contacts)
                setSales(statistics.sales)
                setNbrLots(statistics.lots);
                setNbrBooked(statistics.booked)
                setPercentageAvailable(statistics.series[0])
                setPercentageOptioned(statistics.series[1])
                setPercentageBooked(statistics.series[2])
                setSeries(statistics.series)
                setPercentageAdf(statistics.percentageAdf)

                var labels = [];
                if (user.role === 'ROLE_ACQUEREUR') {
                    labels = ["Appelé", "Reste à appeler"]
                } else if (user.role === 'ROLE_FOURNISSEUR') {
                    labels = ["Payé", "Reste à payer"]
                } else {
                    labels = ["Signé", "Réservé", "Optionné", "Libre"]
                }

                setDonutOptions({
                    chart: {
                        type: "donut",
                        height: 40,

                    },
                    labels: labels,
                    dataLabels: {
                        enabled: false,
                    },
                    legend: {
                        show: false
                    },
                    stroke: {
                        width: 0,

                    },
                    fill: {
                        colors: statistics.donut.colors
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: "92%"
                            }
                        }
                    }
                })

                setBarChartOptions({
                    colors: ['#76B2FE', '#4998FE', '#1C7FFE', '#1DD7C6'],
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false  // Désactive l'affichage des données dans les barres
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0
                            }
                        }
                    }],
                    plotOptions: {
                        bar: {
                            columnWidth: '60%',
                            horizontal: false,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        },
                    },
                    xaxis: {
                        tickPlacement: 'on',
                        categories: statistics.barCategories,
                        labels: {
                            rotate: -45,
                            rotateAlways: true,
                            maxHeight: 120,
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: '12px',
                            },
                        }
                    },
                    legend: {
                        position: 'right',
                        offsetY: 40
                    },
                    fill: {
                        opacity: 1
                    }
                });


            })
            .catch(function (error) {

                if (error.response && error.response.status === '401') {

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }

    const getTransactionValueForRow = transaction => {

        if (transaction == 1) {
            var elem = <div className={'text-center'}><span className={"label label-warning"}>option</span></div>;
        } else if (transaction == 2) {
            var elem = <div className={'text-center'}><span className={"label label-success"}>réservation</span></div>;
        } else {
            var elem = <div className={'text-center'}><span className={"label label-default"}>aucune</span></div>;
        }

        return elem;
    };

    const getStatusValueForRow = rowData => {
//Création
        if (rowData.status == STATUS_CREATE) {

            var alert = 'text-warning';
            var label;


            if (rowData.type == ENTITY_ADF) {
                if (role == "ROLE_ACQUEREUR") {

                    label = 'Montant appelé';
                } else if (rowData.type == ENTITY_TMA) {
                    label = 'Montant facturé';
                }

            } else if (rowData.type == ENTITY_INVOICE) {
                label = 'Montant facturé';
            }
        }

        if (rowData.status == STATUS_UPDATE) {
            var alert = 'text-success';
            //ou mise à jour
            if (rowData.type == ENTITY_MARKET) {
                label = 'Marché';
            } else {
                label = 'Montant versé';
            }
        }

        if (rowData.type == ENTITY_MARKET) {
            var alert = 'text-success';
            label = 'Marché';
        } else if (rowData.type == ENTITY_AMENDMENT) {
            var alert = 'text-warning';
            label = 'Avenant';
        }

        var elem = <div><span
            //Si c'est une entité adf a
            className={alert + " font-size-sm"}>{label}</span></div>;

        return elem;
    };

    const getTypeValueForRow = type => {

        if (type == 2) {
            var elem = <div><span className={"border-lft-red"}>TMA</span></div>;
        } else if (type == 1) {
            var elem = <div><span className={"border-lft-blue"}>ADF</span></div>;
        } else if (type == 3) {
            var elem = <div><span className={"border-lft-blue"}>FACT</span></div>;
        } else if (type == 4) {
            var elem = <div><span className={"border-lft-red"}>MARC</span></div>;
        } else if (type == 5) {
            var elem = <div><span className={"border-lft-red-light"}>AVN</span></div>;
        }


        return elem;
    };

    return (
        <div className={''}>

            <div id={"main-card-list"} className="row g-4 mt-4">
                {cards.length > 0 ? (
                    userLinks.map((link, index) => (
                        <div key={index} className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">

                                    <div className="d-flex align-items-start justify-content-between">
                                        <div className="content-left">
                                            <span>     {link.title}  </span>
                                            <div className="d-flex align-items-end mt-2">
                                                <h4 className="mb-0 me-2">{cards[index].nbr}</h4>
                                                {/*<small className="text-success">(+29%)</small>*/}
                                            </div>
                                            <small>{link.description}</small>
                                        </div>
                                        <div>
                                            {link.plus ? <a className={'cursor-pointer'} onClick={() => {
                                                navigate(link.url2)
                                            }}><span className={`rounded p-2 me-1 ${link.background}`}> <Icon
                                                width={'25px'} height={'25px'} icon={link.icon2}/>  </span></a> : null}

                                            <a className={'cursor-pointer'} onClick={() => {
                                                navigate(link.url)
                                            }}>
                                        <span className={`rounded p-2 ${link.background}`}>
                <Icon width={'25px'} height={'25px'} icon={link.icon}/>
          </span> </a></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))) : (<></>)}
            </div>

            <div className={'row'}>

                {user.role === 'ROLE_PROSPECT' ? (
                    <Col xs={6}>
                        <Card>
                            <TimelineTab setShowAction={setShowAction} timeline={timeline}/>
                        </Card>
                    </Col>
                ) : null}
                {user.role !== 'ROLE_ACQUEREUR' && user.role !== 'ROLE_FOURNISSEUR' && user.role !== 'ROLE_PROSPECT' ? (
                    <div className={'col-lg-3'}>
                        <div className={'card card-activity'}>
                            <div
                                className="top-row statistics-card-title border-bottom-blue-grey border-bottom-lighten-5">
                                <div className="px-4 py-3 pl-2 primary">
                                    <h4 className="mt-2">Activité</h4>
                                </div>
                            </div>
                            <div className={'card-body'}>

                                {activityList.length > 0 ? (
                                    <div className="steamline m-t-40">

                                        {activityList.map((listValue, index) => {
                                            return (
                                                <div className="sl-item mb-4" key={index}>
                                                    <a href={`${listValue.link}`}>
                                                        <div className={`mr-4 sl-left ${listValue.alert}`}>
                                                            <Icon icon={`${listValue.icon}`}
                                                                  style={{fontSize: '24px', paddingBottom: '2px'}}/>
                                                        </div>
                                                        <div className="sl-right">
                                                            <div className="font-weight-medium">{listValue.message}
                                                                {/*<span className="sl-date"> 5pm</span>*/}
                                                            </div>
                                                            <div
                                                                className="desc mb-4 font-weight-light"> {listValue.contact}
                                                                <span
                                                                    className="text-muted ms-auto">{listValue.updatedAt}</span>
                                                                {listValue.timer != 0 ? (

                                                                    <div className="text-default"><b
                                                                        ng-bind="invoice.status"
                                                                        className="ng-binding text-danger"><Icon
                                                                        icon={'mdi:timer-off-outline'}/> {listValue.timer} jours</b>
                                                                    </div>
                                                                ) : (<></>)}


                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            );
                                        })}
                                        <Button className={'float-right'} onClick={() => {
                                            navigate('/admin/suivis/liste')
                                        }}>Voir plus <Icon width={'10px'} height={'10px'}
                                                           icon="mdi:arrow-right"/></Button>

                                    </div>
                                ) : (<div className={"no-data-message"}>Aucune activité à afficher</div>)}

                            </div>


                        </div>

                    </div>

                ) : (<></>)}

                {optionsByMonth.length > 0 && role !== 'ROLE_ACQUEREUR' && role !== 'ROLE_FOURNISSEUR' ? (

                    <div className={'col-md-5'}>
                        <div className={'card'}>
                            <div
                                className="top-row statistics-card-title border-bottom-blue-grey border-bottom-lighten-5">
                                <div className="px-4 py-3 pl-2 primary">
                                    <h4 className="mt-2">Transactions</h4>
                                </div>
                            </div>
                            <Chart options={lineChartOptions} series={optionsByMonth} type="line" height={430}/>

                        </div>
                    </div>
                ) : (<></>)}

                {user.role != 'ROLE_PROSPECT' ? <div
                    className={(role == 'ROLE_ACQUEREUR') || (role == 'ROLE_FOURNISSEUR') ? ('col-md-3') : ('col-md-4')}>
                    {donutOptions.fill.colors.length > 0 ? (

                        <div className={'card'}>
                            {(role == 'ROLE_ACQUEREUR') || (role == 'ROLE_FOURNISSEUR') ?
                                <div id={"percentage"}>{percentageAdf} %</div> : ''}
                            <div
                                className="top-row statistics-card-title border-bottom-blue-grey border-bottom-lighten-5">
                                <div className="px-4 py-3 pl-2 primary">
                                    <h4 className="mt-2">{donutParams.title}</h4>
                                </div>
                            </div>
                            <Chart options={donutOptions} series={series} type="donut" height={330}/>

                            {donutParams.legend.length > 0 ? (
                                <div className="mt-4 d-flex justify-content-center ml-auto mr-auto pb-50 mb-2">
                                    {donutParams.legend.map((listValue, index) => {
                                        return (
                                            <div className="me-3" key={index}>

                                                <span className={`me-2 bullet bullet-xs bg-${listValue.color}`}></span>
                                                <span className="font-weight-bold">{listValue.label}</span>
                                            </div>
                                        );
                                    })}
                                </div>

                            ) : (<></>)}

                            {donutParams.footer.length > 0 ? (
                                <div className="statistic-card-footer d-flex">
                                    {donutParams.footer.map((listValue, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="column-data py-1 text-center border-top-blue-grey border-top-lighten-5 flex-grow-1 text-center border-right-blue-grey border-right-lighten-5">
                                                <p className="font-large-1 mb-0">{listValue.nbr}</p>

                                                <span>{listValue.label}</span>
                                            </div>
                                        );
                                    })}
                                </div>

                            ) : (<></>)}


                        </div>
                    ) : (<></>)}
                </div> : null}

                {(user.role == 'ROLE_ACQUEREUR') || (user.role == 'ROLE_FOURNISSEUR') ? (

                    <div className="col-md-6">
                        {user.role === 'ROLE_ACQUEREUR' ? (
                                <DashboardReservationTab filteredData={filteredData}/>

                        ) : null}
                        <div id="buyer-activity-table" style={{maxWidth: '100%'}}>
                            <MaterialTable

                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                        labelRowsSelect: 'lignes'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Aucune activité récente à afficher',
                                    }
                                }}
                                options={{
                                    pageSize: 5,
                                    search: false,
                                    paging: false
                                }}
                                columns={[
                                    {
                                        title: 'Type',
                                        field: 'type',
                                        width: "10%",
                                        render: rowData => getTypeValueForRow(rowData.type)
                                    },
                                    {title: 'Libellé', field: 'label'},
                                    {title: 'Date', field: 'date'},
                                    {title: "Montant", field: 'amount'},
                                    {
                                        title: "Statut",
                                        field: 'status',
                                        render: rowData => getStatusValueForRow(rowData)
                                    }
                                ]}
                                tableRef={tableRef}
                                data={buyerActivity}
                                title={'Transactions'}
                            />
                        </div>
                    </div>

                ) : (<></>)}
                {(user.role == 'ROLE_ACQUEREUR') || (user.role == 'ROLE_FOURNISSEUR') ? (
                    <div className={'col-md-3'}>
                        <div className="card bg-ultramarine">
                            <div className="card-header">
                                <h4 className="card-title text-center  mx-auto">Solde au {now}</h4>
                            </div>
                            <div className="card-content collapse show">
                                <div className="card-body pt-0">
                                    <div className="row">
                                        <div
                                            className="col-md-12 col-12 border-right-blue-grey border-right-lighten-5 text-center">

                                            <h4 className="font-large-2 text-bold-400 text-white pt-2">{balance}</h4>
                                            <p className="text-white mb-0">{user.role == 'ROLE_ACQUEREUR' ? 'Incluant ADF et TMA' : 'En attente de paiement'}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {promoteurList.length > 0 ? (
                            <div>
                                {promoteurList.map((value, index) => {
                                        return (
                                            <div key={index} className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title text-center  mx-auto">Mon Contact</h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className={'text-center'}>

                                                        <p className={'h2 mt-0'}><strong>{value.name}</strong></p>
                                                        <p className={'text-uppercase'}>{value.address}</p>

                                                        <p className={'bg-light w-75 mx-auto'}>
                                                            <svg width="25px" height="25px"
                                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                <path fill="#b3b3b3"
                                                                      d="M19.44,13c-.22,0-.45-.07-.67-.12a9.44,9.44,0,0,1-1.31-.39,2,2,0,0,0-2.48,1l-.22.45a12.18,12.18,0,0,1-2.66-2,12.18,12.18,0,0,1-2-2.66L10.52,9a2,2,0,0,0,1-2.48,10.33,10.33,0,0,1-.39-1.31c-.05-.22-.09-.45-.12-.68a3,3,0,0,0-3-2.49h-3a3,3,0,0,0-3,3.41A19,19,0,0,0,18.53,21.91l.38,0a3,3,0,0,0,2-.76,3,3,0,0,0,1-2.25v-3A3,3,0,0,0,19.44,13Zm.5,6a1,1,0,0,1-.34.75,1.05,1.05,0,0,1-.82.25A17,17,0,0,1,4.07,5.22a1.09,1.09,0,0,1,.25-.82,1,1,0,0,1,.75-.34h3a1,1,0,0,1,1,.79q.06.41.15.81a11.12,11.12,0,0,0,.46,1.55l-1.4.65a1,1,0,0,0-.49,1.33,14.49,14.49,0,0,0,7,7,1,1,0,0,0,.76,0,1,1,0,0,0,.57-.52l.62-1.4a13.69,13.69,0,0,0,1.58.46q.4.09.81.15a1,1,0,0,1,.79,1Z"/>
                                                            </svg>
                                                            {value.phone}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>

                        ) : (<></>)}


                    </div>) : (<></>)}
            </div>


            <div className="row g-4 mb-4">
                {(user.role !== 'ROLE_ACQUEREUR') && (user.role !== 'ROLE_FOURNISSEUR') ? (
                    <div className="col-sm-6 col-lg-4">
                        <div className="card">
                            <div
                                className="top-row statistics-card-title border-bottom-blue-grey border-bottom-lighten-5">
                                <div className="px-4 py-3 pl-2 primary">
                                    <h4 className="mt-2">Répartition géographique</h4>
                                </div>
                            </div>
                            <div className="card-body">

                                {departmentList.length > 0 ? (

                                    <ComposableMap
                                        viewBox="140 -3750 800 700"
                                        projectionConfig={{
                                            scale: 4200,

                                        }}
                                        style={{
                                            width: "100%",
                                            height: "auto"
                                        }}
                                    >
                                        <ZoomableGroup onMove={({zoom}) => setScaleFactor(zoom)}>
                                            <Geographies geography={geoUrl}>

                                                {({geographies}) =>
                                                    geographies.map((geo) => {

                                                        const d = geo.properties.NAME_2;

                                                        return (
                                                            <Geography
                                                                style={{
                                                                    default: {outline: "none"},
                                                                    hover: {outline: "none"},
                                                                    pressed: {outline: "none"},
                                                                }}
                                                                key={geo.rsmKey}
                                                                geography={geo}
                                                                fill={departmentList.includes(d.replace(/-/g, "").toLowerCase()) ? "#5a8dee" : "#d5e3ff"}
                                                            />
                                                        );
                                                    })
                                                }
                                            </Geographies>
                                            {markers.map((marker) => (

                                                <Marker key={marker.name} coordinates={marker.coordinates}
                                                        onClick={() => openModal(marker)}>
                                                    <Icon
                                                        style={{
                                                            color: "#1DD7C6",
                                                            cursor: 'pointer',
                                                            pointerEvents: 'bounding-box'
                                                        }}
                                                        width={`${40 / scaleFactor}px`}
                                                        height={`${40 / scaleFactor}px`}
                                                        icon="fa-solid:map-marker-alt"
                                                    />
                                                </Marker>
                                            ))}
                                        </ZoomableGroup>

                                    </ComposableMap>
                                ) : (<div className={"no-data-message"}>Aucun département à afficher</div>)}


                                <div className="statistic-card-footer d-flex">
                                    <div
                                        className="column-data py-1 text-center border-top-blue-grey border-top-lighten-5 flex-grow-1 text-center border-right-blue-grey border-right-lighten-5">
                                        <p className="font-large-1 mb-0">{nbrVilles}</p>
                                        <span>Communes</span>
                                    </div>
                                    <div
                                        className="column-data py-1 flex-grow-1 text-center border-top-blue-grey border-top-lighten-5">
                                        <p className="font-large-1 mb-0">{nbrProgrammes}</p>
                                        <span>Programmes</span>
                                    </div>
                                    <div
                                        className="column-data py-1 flex-grow-1 text-center border-top-blue-grey border-top-lighten-5 border-left-blue-grey border-left-lighten-5">
                                        <p className="font-large-1 mb-0">{nbrDepartments}</p>
                                        <span>Départements</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (<></>)}
                {(role == 'ROLE_PRESCRIPTEUR') ? (

                    <div className="col-md-8">
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                        labelRowsSelect: 'lignes'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Aucun contact à afficher',
                                    }
                                }}
                                options={{
                                    pageSize: 8,
                                    search: false
                                }}
                                columns={[
                                    {title: 'Nom', field: 'nom'},
                                    {title: 'Email', field: 'email'},
                                    {title: "Adresse", field: 'adresse'},
                                    {
                                        title: "Transaction",
                                        field: 'transaction',
                                        render: rowData => getTransactionValueForRow(rowData.transaction),
                                        width: "10%"
                                    }
                                ]}
                                tableRef={tableRef}
                                data={contactList}
                                title={'Contacts actifs'}
                            />
                        </div>
                    </div>

                ) : (<></>)}


                {(role == 'ROLE_PROMOTEUR') ? (
                    <div className={'col-md-8'}>


                        {(chartBarSeries.length > 0) ? (

                            <div className={'card'}>

                                <div
                                    className="top-row statistics-card-title border-bottom-blue-grey border-bottom-lighten-5">
                                    <div className="px-4 py-3 pl-2 primary">
                                        <h4 className="mt-2">Opérations</h4>
                                    </div>
                                </div>
                                <Chart options={barChartOptions} series={chartBarSeries} type="bar" height={550}/>

                            </div>
                        ) : (<></>)}


                    </div>
                ) : (<></>)}

            </div>
            <Modal
                id={"program-card"}
                size="sm"
                show={modalIsOpen}
                onHide={closeModal}
                contentLabel="Programme Info"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                }}
            >

                <Modal.Body>
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                        {/* Section image - 50% de hauteur */}
                        <div style={{flex: 1}}>
                            <img
                                src={modalIsOpen ? modalContent.thumbnail ? modalContent.thumbnail : '/assets/img/aucune-image-disponible.jpg' : null}
                                alt="placeholder"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '8px 8px 0px 0px'
                                }}
                            />
                        </div>
                    </div>

                    <div className={'p-3'}>
                        <h6> {modalIsOpen ? modalContent.name : null}</h6>
                        <p>{modalIsOpen ? modalContent.address : null}</p>

                    </div>


                </Modal.Body>

                <Modal.Footer>

                    {/* Section des boutons */}
                    <div style={{display: 'flex', width: '100%'}}>
                        <Button
                            variant="secondary"
                            onClick={closeModal}
                            style={{flex: 1, marginRight: '10px'}}
                        >
                            Fermer
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                navigate(modalIsOpen ? `/admin/programme/${modalContent.id}/voir` : null)
                            }}
                            style={{flex: 1}}
                        >
                            Voir
                        </Button>
                    </div>


                </Modal.Footer>
            </Modal>

        </div>
    )

}

export default Dashboard;