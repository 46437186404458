import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Layout from "../../component/Layout"
import axios from 'axios';
import MessageListRow from "./MessageListRow";
import {Col, Row} from "react-bootstrap";
import Swal from "sweetalert2";
import Sidebar from "./component/Sidebar";

function MailList({user}) {

    const [messages, setMessages] = useState([]);
    const [urlMessages, setUrlMessages] = useState(`http://www.t1.viteimmo.com:3000/apo/mail/messages/admin@laei.fr`);
    let navigate = useNavigate();
    const [modalShow, setModalShow] = React.useState(false);
    // Supposons que vous avez initialisé un ensemble (Set) pour stocker les contacts uniques.
    const [mailSuggestions, setMailSuggestions] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mbox = searchParams.get('mbox');
    const [initialValues, setInitialValues] = useState({
        receiver: '',
        subject: '',
        message: ''
    });


    useEffect(() => {

    }, [mailSuggestions]);

    useEffect(() => {

        getAllMessages();
    }, [mbox]);
    useEffect(() => {

    }, [messages]);

    const getAllMessages = () => {

        var url = `http://www.t1.viteimmo.com:3000/apo/mail/messages/admin@laei.fr`;
        if (mbox === 'draft') {

            url = 'http://www.t1.viteimmo.com:3000/apo/mail/drafts/admin@laei.fr';
        }
        else if(mbox === 'sent'){

            url = 'http://www.t1.viteimmo.com:3000/apo/mail/messages/sent/admin@laei.fr';
        }
// Effectuer une requête GET
        axios.get(url)
            .then(response => {
                // La réponse de l'API est stockée dans response.data
                var messages;
                if (mbox === 'draft') {
                    messages = response.data.drafts.slice(0, 25);
                } else {
                    messages = response.data.messages.slice(0, 25);
                }

                setMessages(messages)
            })
            .catch(error => {

                if (error.response.status = '401') {

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            });
    }


    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1">
                                <div className={'navbar-header'}>
                                    <h1>Courriels</h1>
                                </div>
                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des courriels</li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'content'}>
                {messages.length ?
                    <Row>
                        <Col xs={3}>
                            <Sidebar mailSuggestions={mailSuggestions} mbox={mbox} modalShow={modalShow}
                                     setModalShow={setModalShow} initialValues={initialValues}/>
                        </Col>
                        <Col xs={9}>
                            <MessageListRow messages={messages} setMailSuggestions={setMailSuggestions}
                                        mailSuggestions={mailSuggestions} mbox={mbox} setModalShow={setModalShow} setInitialValues={setInitialValues}/>
                        </Col></Row>
                    : null}

            </section>

        </Layout>
    );
}

export default MailList;