import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import MaterialTable from "@material-table/core";
import {Icon} from '@iconify/react';
import LogApiList from "../Log/LogApiList";
import Button from 'react-bootstrap/Button';
import ScrollWrapper from "../../utils/ScrollWrapper";
import {renderLotList} from "../../utils/lots"
import Badge from 'react-bootstrap/Badge';
import ReservationTab from "./component/ReservationTab";
import AddOptionButton from "../../component/AddOptionButton";
import AddReservationButton from "../../component/AddReservationButton";

function ReservationList({user, isFullSize}) {

    const [show, setShow] = useState(false);

    const [reservationList, setReservationList] = useState()
    const [filteredData, setFilteredData] = useState([])
    const [rowCount, setRowCount] = useState('Liste des lots')

    const [programId, setProgramId] = useState('all')
    const [filtered, setFiltered] = useState(false)
    let navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({

        typeBien: [],
        minPrice: '',
        maxPrice: '',
        minSurface: '',
        maxSurface: '',
        programme: 'all',
    });

    useEffect(() => {

        fetchReservations();
        // getUser();
    }, [initialValues])

    useEffect(() => {

        setFilteredData(programId === 'all' ? reservationList : reservationList.filter(dt => dt.programId === programId))
    }, [programId])

    const fetchReservations = () => {
        axios.get(`/admin/reservations`)
            .then(function (response) {

                if (filtered == false) {

                    setReservationList(response.data);
                    response.data ? setFilteredData(response.data) : null;
                    setRowCount('Liste des réservations (' + response.data.length + ')')
                }
            })
            .catch(function (error) {
                console.log(error)


                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })

            })
    }




    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1 d-flex">
                                <div>
                                    <h1>Réservations</h1>

                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des réservations</li>
                                    </ol>
                                </div>

                                <div>
                                    <AddReservationButton user={user}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">
                            <ScrollWrapper isFullSize={isFullSize}>

                                <ReservationTab filteredData={filteredData} user={user} fetchReservations={fetchReservations}/>
                            </ScrollWrapper>

                        </div>
                    </div>
                </div>
            </section>


        </Layout>
    );
}

export default ReservationList;