import {Formik, ErrorMessage} from 'formik';
import * as Yup from "yup";
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import DateFnsAdapter from "@mui/lab/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import Col from "react-bootstrap/Col";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import {Row} from "react-bootstrap";
import * as yup from "yup";

const ActionAddEdit = ({showAction, setShowAction,idAction,isAddMode,getAllActions,contact,getActivity}) => {

    const handleClose = () => setShowAction(false);

    const [optionsProgramme, setOptionsProgramme] = useState([]);
    const [optionsContact, setOptionsContact] = useState([]);
    const [optionsLot, setOptionsLot] = useState([]);

    useEffect(() => {
        fetchContacts()
        getProgrammeList()
    }, [])


    useEffect(() => {
        console.log(optionsLot)
    }, [optionsLot]);

    const initialValues = {
        date: new Date(),
        type:'',
        statut:2,
        alert:{value: 1, label: 'Info'},
        programme:'',
        comment:'',
        contacts:contact?[contact]:null,
        lots:'',
        toBeDeleted:0

    };
    const optionsType = [
        {value: 4, label: 'Téléphone'},
        {value: 5, label: 'Courrier'},
        {value: 6, label: 'Email'},
        {value: 7, label: 'Rdv'},
    ]

    const optionsAlert = [
        {value: 1, label: 'Info'},
        {value: 2, label: 'Succès'},
        {value: 3, label: 'Avertissement'},
        {value: 4, label: 'Danger'},
    ]

    const getProgrammeList = () => {
        axios.get(`/admin/list/programmes`)
            .then(function (response) {
                var options = [];

                response.data?response.data.map((item,index) => {
                    options.push({'value': item.id,'label': item.label});
                }):null;

               setOptionsProgramme(options);
            })
            .catch(function (error) {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    text:'getProgrammeList :'+error,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
    }

    const fetchContacts = () => {
        axios.get(`/api/filter/contacts`)
            .then(function (response) {

                var options = [];
                var contacts=response.data.items;

                if(contacts){
                    contacts.map((item,index) => {
                        options.push({'value': item.id,'label': item.name});
                    });

                    setOptionsContact(options);
                }
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    text:'Récupération des contacts en échec. '+error,
                    showConfirmButton: false,
                    timer: 5000
                })
            })
    }


    const fetchLots = (programId) => {
        console.log(programId)
        axios.get(`/admin/programmes/${programId}/lot-options`)
            .then(function (response) {

                setOptionsLot(response.data);

            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }



    // Créez une fonction de formatage de date personnalisée
    function formatDateCustom(date) {

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }



    function onSubmit(values, actions) {

        const fields = {...values};
        const formattedDate = formatDateCustom(new Date(fields.date));
        fields.date = formattedDate;
        fields.lots =fields.lots.map(lot => lot.value);
        console.log(fields)
        if (isAddMode) {

            createAction(fields);
        } else {

            updateAction(idAction, fields);
        }

        actions.setSubmitting(false);
        getActivity()
    }

    const ActionForm = ({
                             handleSubmit,
                             handleChange,
                             values,
                             errors,
                             isValid,
                             isSubmitting,
                             touched,
                             setFieldValue,
                             handleBlur
                         }) => {
        console.log(errors)
            return (

                <div>
                    <div className={"px-5 pt-4 pb-5 contact-wrapper-form"}>
                        <Form.Group className="mb-3">
                            <LocalizationProvider dateFormats={{
                                shortDate: "d MMMM"
                            }} dateAdapter={DateFnsAdapter} locale={frLocale}>

                                    <MobileDateTimePicker
                                        clearable
                                        ampm={false}
                                        name="date"
                                        format="d/MM/yyyy HH:mm"
                                        label="Date"
                                        clearText="vider"
                                        cancelText="annuler"
                                        onChange={(value) => {
                                            setFieldValue('date', value);
                                        }}
                                        value={values.date}
                                        okText="ok"
                                        renderInput={props =>
                                            <TextField
                                                name="date"
                                                variant="outlined"
                                                id={"date"}
                                                {...props}
                                            />}
                                    />


                            </LocalizationProvider>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Commentaire</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                name="comment"
                                value={values.comment}
                                onBlur={handleBlur}
                                type="text"/>
                        </Form.Group>
                        <ErrorMessage name={`comment`} component="div" className="error"/>
                        <Row>
                            <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Alerte</Form.Label>
                            <Select
                                options={optionsAlert}
                                name={`alert`}
                                placeholder="Sélectionnez une option"
                                value={values.alert}
                                onChange={value =>
                                    setFieldValue(`alert`, value)}
                            />
                        </Form.Group>
                        </Col>
                            <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Type</Form.Label>
                            <Select
                                placeholder="Sélectionnez une option"
                                options={optionsType}
                                name={`type`}
                                value={values.type}
                                onChange={value =>
                                    setFieldValue(`type`, value)}
                            />
                        </Form.Group>
                                <ErrorMessage name={`type`} component="div" className="error"/>
                            </Col></Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Programme</Form.Label>
                            <Select
                                placeholder="Sélectionnez un programme"
                                options={optionsProgramme}
                                name={`programme`}
                                value={values.programme}
                                onBlur={handleBlur}
                                onChange={value => handleProgramChange(setFieldValue, value)}

                            />
                            <ErrorMessage name={`programme`} component="div" className="error"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Contacts</Form.Label>
                            <Select
                                placeholder="Sélectionnez un contact"
                                options={optionsContact}
                                name={`contacts`}
                                value={values.contacts}
                                isMulti
                                onChange={value =>
                                    setFieldValue(`contacts`, value)}
                            />
                            <ErrorMessage name={`contacts`} component="div" className="error"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Lots</Form.Label>
                            <Select
                                placeholder="Sélectionnez un lot"
                                options={optionsLot}
                                name={`lots`}
                                value={values.lots}
                                isMulti
                                isDisabled={!values.programme} // 🛑 Désactivation ici
                                onChange={value =>
                                    setFieldValue(`lots`, value)}
                            />
                            <ErrorMessage name={`lots`} component="div" className="error"/>
                        </Form.Group>


                    </div>
                    <footer>
                        <div className={"d-flex justify-content-center py-3"}>
                            <button
                                variant="primary"
                                className="btn btn-success w-50"
                                disabled={isSubmitting}
                                type="submit">
                                {isSubmitting ? "S'il vous plaît, attendez..." : "Valider"}
                            </button>
                        </div>
                    </footer>
                </div>
            )
        }
    ;




    const validationSchema = Yup.object().shape({
        comment: yup.string()
            .required("Un libellé est requis"),
        type: Yup.mixed().test('is-selected', 'Sélectionner un type', value => {
            return !!value; // Vérifie si une option est sélectionnée (non nulle ou non vide)
        }),
        programme: Yup.mixed()
            .nullable()
            .test('is-selected', 'Sélectionner un programme', value => !!value),


        contacts: Yup.array()
            .test('at-least-one-selected', 'Sélectionner au moins un contact', value => {
                return value && value.length > 0; // Vérifie si au moins un produit est sélectionné
            }).nullable(),
    });

    function createAction(fields) {

                axios.post(`/admin/actions`, fields)
                    .then(function (response) {

                        setShowAction(false);
                        if(getAllActions){
                            getAllActions();
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Action créée avec succès!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Une erreur s\'est produite!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
    }

    function handleProgramChange(setFieldValue, value) {
        fetchLots(value.value);
        setFieldValue(`programme`, value)
};

    function updateAction(id, fields) {

        axios.patch(`/admin/actions/${id}`, fields)
            .then(function (response) {
                setShowAction(false);
                getAllActions();
                Swal.fire({
                    icon: 'success',
                    title: 'Action mise à jour avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                })


            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }

    return (

        <Offcanvas className={'offcanvas-size-xl'} id={"offcanvas-contact"} show={showAction} onHide={handleClose}
                   placement="end">
            <Offcanvas.Header closeButton className={"border-bottom"}>
                <Offcanvas.Title className={'py-2 ps-4'}>Ajouter un suivi</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnBlur={true}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {


                        useEffect(() => {

                            if (!isAddMode) {
                                axios.get(`/admin/actions/${idAction}`)
                                    .then(function (response) {

                                        let action = response.data;

                                        const fields = ['date','type','programme','alert','contacts','lots','comment'];

                                        fields.forEach(field =>
                                            setFieldValue(field, action[field], false)
                                        );
                                    })
                                    .catch(function (error) {

                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Une erreur s\'est produite!',
                                            showConfirmButton: false,
                                            timer: 1500
                                        })
                                    })
                            }
                        }, [idAction]);

                        return (

                            <Form noValidate onSubmit={handleSubmit}>


                                <ActionForm
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    values={values}
                                    errors={errors}
                                    isValid={isValid}
                                    isSubmitting={isSubmitting}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}

                                />


                            </Form>)
                    }}
                </Formik>
            </Offcanvas.Body>
        </Offcanvas>

    );
}

export default ActionAddEdit;