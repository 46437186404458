import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import {useNavigate} from "react-router-dom";
import {Icon} from '@iconify/react';
const TimelineTab = ({setShowAction,timeline}) => {
    let navigate = useNavigate();
     const renderLotList = value => {
        return (
            <> [
                {value.lots && value.lots.length > 0 ? value.lots.map((lot, index) => (
                    <span key={index} >
                   <span style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/lot/${lot.id}/voir`)}} className={'link-primary'}>
                       {lot.nrLot}</span>
                        {index < value.lots.length - 1 && ', '}
                    </span>
                )) : null}]
                  <span style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/programme/${value.programme.id}/voir`)}} className={'link-primary'}> {value.programme.label}</span>
            </>
        );
    };


    return (

        <div>
            <div className={'d-flex align-items-center justify-content-between'}>
                <h5 className={'card-header'}>Chronologie de l'activité</h5>
                <div><Icon width={'20px'} height={'20px'} icon="material-symbols-light:add-box-outline" className={'fill-green navbar-toggler'} onClick={() => {
                    setShowAction(true)
                }}/></div>
            </div>
            <Card.Body>
                <ul className={"timeline"}>

                    {timeline.length > 0 ? (

                        <div>

                            {timeline.map((value, index) => {
                                    const point = `timeline-point timeline-point-primary ${value.alert}`;

                                    return (

                                        <li className={"timeline-item timeline-item-transparent"} key={index}>
                                            <span className={point}></span>
                                            <div className={'timeline-event'}>
                                                <div className={'timeline-header mb-1'}>
                                                    <h6>{value.message}
                                                        {value.type===1 || value.type===2 ? <span style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/${value.context}/${value.entityId}/modifier`)}} className={'link-primary'}> [{value.entityId}]</span>  : null}


                                                   </h6>
                                                    <small className="text-muted">{value.updatedAt}</small>
                                                </div>
                                                <p className={"text-muted"}>{value.description} {renderLotList(value)}</p>

                                            </div>
                                        </li>
                                    );
                                }
                            )}
                        </div>
                    ) : (<></>)}
                </ul>
            </Card.Body>
        </div>


    )
}

export default TimelineTab;