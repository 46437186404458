import axios from "axios";
import React, {useState, useEffect, useRef} from 'react';
import {getSender} from "./GetSender";




export async function loadDynamicContent(email,setDynamicContent) {


    // Recherchez la partie text/plain dans les parties text/alternative
    const promises = [];
    if (email.payload.parts) {
        const textAlternativePart = email.payload.parts.find(part => part.mimeType === 'multipart/alternative');
        const textMixedPart = email.payload.parts.find(part => part.mimeType === "multipart/related");
        const plainTextPart = email.payload.parts.find(part => part.mimeType === 'text/plain');
        const htmlPart = email.payload.parts.find(part => part.mimeType === 'text/html');
        // const attachment = email.payload.parts.find(part => part.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
        const files = email.payload.parts.filter(part => part.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

        if (files) {
            var medias=[]
            files.map((attachment, index) => {
                const attachmentId = attachment.body.attachmentId;
                const imageUrl = `http://www.t1.viteimmo.com:3000/apo/mail/user/admin@laei.fr/read/${id}/attachment/` + attachmentId;

                promises.push(
                    axios.get(imageUrl)
                        .then(response => {

                            const attachmentResponse = response.data; // Supposons que les données de l'image sont renvoyées en base64

                            var base64Data = attachmentResponse.data.replace(/_/g, '/').replace(/-/g, '+') // important line

                            let urlBlob = b64toBlob(base64Data, attachment.mimeType, attachment.size)

                            let file = {fileName: attachment.filename, url: urlBlob}
                            medias.push(file);
                            setAttachments(medias)

                            return `src="${imageUrl}"`;
                        })
                        .catch(error => {
                            console.error('Erreur lors de la récupération de l\'image :', error);
                            return match;
                        })
                );

            })
        }


        if (textAlternativePart) {

            return getContent(textAlternativePart,email,setDynamicContent);

        } else if (textMixedPart) {

            const textAlternativePart = textMixedPart.parts.find(part => part.mimeType === 'multipart/alternative');
            const result = await getContent(textAlternativePart,email,setDynamicContent);

            return result;
        } else if (htmlPart) {

            //Le contenu texte brut est dans plainTextPart.body.data
            const emailContentBase64 = htmlPart.body.data;
            //Décoder le contenu base64 en texte
            const emailContent = Buffer.from(emailContentBase64, 'base64').toString('utf-8');

            setDynamicContent(emailContent);
        } else
            //Accès direct
        {
            if (htmlPart) {
                //Le contenu texte brut est dans plainTextPart.body.data
                const emailContentBase64 = htmlPart.body.data;
                //Décoder le contenu base64 en texte
                const emailContent = Buffer.from(emailContentBase64, 'base64').toString('utf-8');

                // Si une version HTML est disponible, l'afficher
                return <div dangerouslySetInnerHTML={{__html: emailContent}}/>;
            } else if (plainTextPart) {
                // Sinon, afficher la version texte brut
                //Le contenu texte brut est dans plainTextPart.body.data
                const emailContentBase64 = plainTextPart.body.data;
                //Décoder le contenu base64 en texte
                const emailContent = Buffer.from(emailContentBase64, 'base64').toString('utf-8');

                const textContent = atob(emailContent); // Décoder le contenu base64
                return <pre>{textContent}</pre>;
            }
        }
    } else {

        //Le contenu texte brut est dans plainTextPart.body.data
        const emailContentBase64 = email.payload.body.data;
        //Décoder le contenu base64 en texte
        const emailContent = Buffer.from(emailContentBase64, 'base64').toString('utf-8');

        setDynamicContent(emailContent);
    }
    return <p>Contenu non disponible.</p>;
}

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    var byteCharacters = atob(b64Data)
    var byteArrays = []

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize)

        var byteNumbers = new Array(slice.length)
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        var byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }

    var blob = new Blob(byteArrays, {type: contentType})
    let urlBlob = URL.createObjectURL(blob)
    return urlBlob
}

//Converti le base64 en contenu lisible
async function getContent(textAlternativePart,email,setDynamicContent) {

    const plainTextPart = textAlternativePart.parts.find(part => part.mimeType === 'text/plain');
    const htmlPart = textAlternativePart.parts.find(part => part.mimeType === 'text/html');

    if (htmlPart) {

        //Le contenu texte brut est dans plainTextPart.body.data
        const emailContentBase64 = htmlPart.body.data;
        //Décoder le contenu base64 en texte
        const emailContent = Buffer.from(emailContentBase64, 'base64').toString('utf-8');
// Mettez à jour le contenu de l'e-mail avec les images incorporées
        const modifiedEmailContent = await processContentWithCid(emailContent,email);
//             console.log(modifiedEmailContent)
        // Si une version HTML est disponible, l'afficher

        setDynamicContent(modifiedEmailContent)
    } else if (plainTextPart) {

        // Sinon, afficher la version texte brut
        //Le contenu texte brut est dans plainTextPart.body.data
        const emailContentBase64 = plainTextPart.body.data;
        //Décoder le contenu base64 en texte
        const emailContent = Buffer.from(emailContentBase64, 'base64').toString('utf-8');

        const textContent = atob(emailContent); // Décoder le contenu base64
        return <pre>{textContent}</pre>;
    }
    // return {plainTextPart, htmlPart};
}

const processContentWithCid = async (content,email) => {

    const promises = [];
    var messageId = email.id;
    content.replace(/src="cid:([^"]+)"/g, (match, cid) => {

        const cleanCid = cid.split('@')[0];

        if (email.payload.parts) {

            const attachment = email.payload.parts.find(part => part.filename === cleanCid);

            if (attachment) {
                const attachmentId = attachment.body.attachmentId;
                const imageUrl = `http://www.t1.viteimmo.com:3000/apo/mail/user/admin@laei.fr/read/${messageId}/attachment/` + attachmentId;

                promises.push(
                    axios.get(imageUrl)
                        .then(response => {
                            const attachmentResponse = response.data; // Supposons que les données de l'image sont renvoyées en base64
                            var str = attachmentResponse.data.replace(/_/g, '/').replace(/-/g, '+') // important line
                            const imageUrl = `data:${attachment.mimeType};base64,${str}`;
                            return `src="${imageUrl}"`;
                        })
                        .catch(error => {
                            console.error('Erreur lors de la récupération de l\'image :', error);
                            return match;
                        })
                );
            } else {

                promises.push(Promise.resolve(match));
            }

        } else {


        }

    });

    const results = await Promise.all(promises);

    return content.replace(/src="cid:([^"]+)"/g, () => results.shift());
};