
import React, {useState, useEffect} from 'react';
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {useNavigate, useParams} from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import {Row} from "react-bootstrap";

const CardImpersonate  =({user,setUser,isImpersonating,setIsImpersonating}) =>{

    const [optionsImpersonateUser, setOptionsImpersonateUser] = useState([]);
    const [userId, setUserId] = useState(''); // Identifiant de l'utilisateur à impersonaliser

    let navigate = useNavigate();


    const getAllImpersonateUsers = () => {
        axios.get(`/admin/impersonate/users`)
            .then(function (response) {

                if (response.data) {
                    setOptionsImpersonateUser(response.data);

                }
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }


    const startImpersonation = () => {
        if (!userId) {
            alert('Veuillez sélectionner un utilisateur à impersonaliser.');
            return;
        }

        // Envoyer une requête au backend pour activer l'impersonation de l'utilisateur sélectionné
        axios.post(`/admin/impersonate/start/${userId}`)
            .then((response) => {
                setIsImpersonating(true);

                setUser()

                navigate('/admin/tableau-de-bord')
            })
            .catch((error) => {
                console.error('Erreur lors du démarrage de l\'impersonation', error);
            });
    };

    const stopImpersonation = () => {
        // Envoyer une requête au backend pour désactiver l'impersonation
        axios.post('/admin/impersonate/stop')
            .then((response) => {
                setIsImpersonating(false);
                setUser()

                navigate('/admin/tableau-de-bord')
            })
            .catch((error) => {
                console.error('Erreur lors de l\'arrêt de l\'impersonation', error);
            });
    };


    useEffect(() => {

        getAllImpersonateUsers();
    }, [setUserId]);


    return(
        <Card id={'theft-card'}>
            <Card.Body className={'px-4'}>

                <h5 className={"pb-4 border-bottom mb-4"}>Changement de compte</h5>
                <div className={"info-container mb-4"}>
                    <Row className="mt-4">
                        <Col xs={12}>
                            <Form.Label>Utilisateur</Form.Label>
                            <Select
                                options={optionsImpersonateUser}
                                defaultValue={{label: "Choisir un utilisateur", value: 'all'}}
                                styles={{menuPortal: base => ({...base, zIndex: 9999, flex: 1})}}
                                onChange={newValue =>

                                    setUserId(newValue.value)
                                }
                            />
                        </Col>
                    </Row>


                </div>
                <div className={'d-flex pt-3'}>
{isImpersonating ? <Button className={'w-100'} onClick={stopImpersonation} variant="primary">Quitter l'emprunt</Button>   : <Button className={'w-100'} onClick={startImpersonation} variant="primary">Valider</Button>}

                </div>
            </Card.Body>

        </Card>
    )
}

export default CardImpersonate;