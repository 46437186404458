import React, {useState, useEffect} from 'react';
import MaterialTable from "@material-table/core";
import {Typography} from '@mui/material';
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import handleLotRowUpdate from "./handleLotRowUpdate";
import {getCustomComponents} from '../../../component/table/customComponents';
import {addTotalToState} from "../../../utils/tableUtils"; // adapte le chemin
import {handleHeaderCheckboxChange} from "./handleHeaderCheckboxChange"
import { Checkbox } from '@mui/material';
import LotTable from "./LotTable";
export default function DualTableSelection({setSelectedLots, selectedLots, availableLots}) {

    const [show, setShow] = useState(false);
    const [total, setTotal] = useState(0)
    const [totalMntDG, setTotalMntDG] = useState(0)
    const [checked, setChecked] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //Lots sélectionnés parmi les lots disponibles
    const [leftSelection, setLeftSelection] = useState([]);

    useEffect(() => {
        console.log(selectedLots)
    }, [selectedLots]);

    // Ajouter les éléments sélectionnés du tableau de gauche
    const handleAddSelected = () => {

        // const newItems = leftSelection;
        // setSelectedLots(newItems);
        setLeftSelection([]); // clear selection
        setShow(false)
    };

    useEffect(() => {

        getCustomComponents(total, totalMntDG, true)
    }, [totalMntDG]);

    useEffect(() => {

        addTotalToState({
            data: selectedLots,
            byColumn: 'salePrice',
            setTotal,
            setTotalGlobal:null,
            setTotalMntDG,
            dataTab:null,
            setDataTab:null,
        });

        addTotalToState({
            data: selectedLots,
            byColumn: 'mntDG',
            setTotal,
            setTotalMntDG,
        });
    }, [selectedLots]);

    return (
        <Row>
            <div>
                <Modal id={'available-lots-tab'} size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Lots disponibles</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <div className={'px-0'}>
                            {/*<MaterialTable*/}
                            {/*    title=""*/}
                            {/*    columns={[*/}
                            {/*        {title: 'N° lot', field: 'nrLot'},*/}
                            {/*        {title: 'N° copropriété', field: 'nrCopro'},*/}
                            {/*        {title: 'Type de bien', field: 'typeProperty'},*/}
                            {/*        {title: 'TVA', field: 'TVA'},*/}
                            {/*        {title: 'Dépôt de garantie', field: 'mntDG', align: "right"},*/}
                            {/*        {title: 'Prix de vente TTC', field: 'salePrice', align: "right"},*/}

                            {/*    ]}*/}
                            {/*    data={availableLots}*/}
                            {/*    options={{*/}
                            {/*        selection: true,*/}
                            {/*        search: false,*/}
                            {/*        toolbar: false,*/}
                            {/*        paging: false,*/}
                            {/*        rowStyle: (rowData) => {*/}
                            {/*            const isSelected = rowData.tableData.checked ?? false;*/}
                            {/*            return isSelected*/}
                            {/*                ? {*/}
                            {/*                    color: '#0c0c0c',*/}
                            {/*                }*/}
                            {/*                : {*/}
                            {/*                    backgroundColor: 'white',*/}
                            {/*                    color: '#5b5656',*/}
                            {/*                };*/}
                            {/*        },*/}
                            {/*    }}*/}
                            {/*    localization={{*/}
                            {/*        body: {*/}
                            {/*            emptyDataSourceMessage: 'Aucune donnée à afficher',*/}
                            {/*            addTooltip: 'Ajouter',*/}
                            {/*            deleteTooltip: 'Supprimer',*/}
                            {/*            editTooltip: 'Modifier',*/}
                            {/*            filterRow: {*/}
                            {/*                filterTooltip: 'Filtrer',*/}
                            {/*            },*/}
                            {/*            editRow: {*/}
                            {/*                deleteText: 'Voulez-vous supprimer cette ligne ?',*/}
                            {/*                cancelTooltip: 'Annuler',*/}
                            {/*                saveTooltip: 'Enregistrer',*/}
                            {/*            },*/}
                            {/*            bulkEditTooltip: 'Modifier plusieurs lignes',*/}
                            {/*        },*/}
                            {/*        pagination: {*/}
                            {/*            labelRowsPerPage: 'Lignes par page',*/}
                            {/*            labelDisplayedRows: '{from}-{to} sur {count}',*/}
                            {/*            firstTooltip: 'Première page',*/}
                            {/*            previousTooltip: 'Page précédente',*/}
                            {/*            nextTooltip: 'Page suivante',*/}
                            {/*            lastTooltip: 'Dernière page',*/}
                            {/*        },*/}
                            {/*        toolbar: {*/}
                            {/*            searchTooltip: 'Rechercher',*/}
                            {/*            searchPlaceholder: 'Recherche',*/}
                            {/*            nRowsSelected: '{0} ligne(s) sélectionnée(s)', // C’est ici qu’on traduit "rows"*/}
                            {/*        },*/}
                            {/*    }}*/}
                            {/*    onSelectionChange={(rows) => setLeftSelection(rows)}*/}
                            {/*/>*/}
                            <LotTable
                                setFilteredData={setLeftSelection}
                                filteredData={availableLots}
                                setSelectedLots={setSelectedLots}
                                // setFilteredData={setFilteredData}

                            />
                        </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Fermer
                        </Button>
                        <Button variant="primary" onClick={handleAddSelected}>
                            Valider
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            {/* Tableau de gauche - éléments disponibles */}
            <Col md={12}>
                <Card id={'lots-card'}>
                    {/* Tableau de droite - éléments sélectionnés */}
                    <Card.Body className={'px-0'}>


                        <div className="d-flex justify-content-between align-items-center">
                            <Typography variant="h6" gutterBottom className={'ps-3'}>
                                Lots sélectionnés
                            </Typography>
                            <Button
                                className={'me-2 mb-2'}
                                variant="primary"
                                onClick={handleShow}
                            >
                                Modifier la sélection
                            </Button></div>

                        <MaterialTable
                            title=""
                            columns={[
                                {title: 'N° lot', field: 'nrLot', editable: false},
                                {title: 'N° copropriété', field: 'nrCopro', editable: false},
                                {title: 'Type de bien', field: 'typeProperty', editable: false},
                                {title: 'TVA', field: 'TVA'},
                                {           title: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginRight: 8 }}>Dépôt de garantie</span>
                                            <Checkbox
                                                checked={checked}
                                                onChange={() =>
                                                    handleHeaderCheckboxChange({
                                                        checked,
                                                        setChecked,
                                                        lotList:selectedLots,
                                                        setLotList:setSelectedLots,
                                                        setTotalMntDG,
                                                    })
                                                }
                                                inputProps={{ 'aria-label': 'select all' }}
                                            />
                                        </div>
                                    ), field: 'mntDG', align: "right"},
                                {title: 'Prix de vente TTC', field: 'salePrice', align: "right"},

                            ]}
                            data={selectedLots}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'Aucune donnée à afficher',
                                    addTooltip: 'Ajouter',
                                    deleteTooltip: 'Supprimer',
                                    editTooltip: 'Modifier',
                                    filterRow: {
                                        filterTooltip: 'Filtrer',
                                    },
                                    editRow: {
                                        deleteText: 'Voulez-vous supprimer cette ligne ?',
                                        cancelTooltip: 'Annuler',
                                        saveTooltip: 'Enregistrer',
                                    },
                                    bulkEditTooltip: 'Modifier plusieurs lignes',
                                },
                                pagination: {
                                    labelRowsPerPage: 'Lignes par page',
                                    labelDisplayedRows: '{from}-{to} sur {count}',
                                    firstTooltip: 'Première page',
                                    previousTooltip: 'Page précédente',
                                    nextTooltip: 'Page suivante',
                                    lastTooltip: 'Dernière page',
                                },
                                toolbar: {
                                    searchTooltip: 'Rechercher',
                                    searchPlaceholder: 'Recherche',
                                    nRowsSelected: '{0} ligne(s) sélectionnée(s)', // C’est ici qu’on traduit "rows"
                                },
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) => {
                                    return handleLotRowUpdate({
                                        newData,
                                        oldData,
                                        lotList: selectedLots,
                                        setLotList: setSelectedLots,
                                    });
                                }
                            }}
                            components={getCustomComponents(total, totalMntDG, true)}
                            options={{
                                search: false,
                                toolbar: false,
                                paging: false,
                            }}
                        />

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
