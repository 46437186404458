import React, {useState, useEffect, useRef} from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CardPassword from "../Card/CardPassword";

const SecurityTab = ({user,setUser,isImpersonating,setIsImpersonating}) => {

    return (

        <div>
            <Row>
                <Col lg="3">
                    <CardPassword user={user} setUser={setUser} isImpersonating={isImpersonating} setIsImpersonating={setIsImpersonating}/>
                </Col>
                <Col lg="8">
                    <Card>
                        <Card.Body>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default SecurityTab;