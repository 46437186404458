import React from 'react';
import {useNavigate} from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';


export function indentChildren(rowData) {


        var elem = rowData.parentId ?  <span style={{paddingLeft: '15px'}} >{rowData.nrLot}</span>  : <span>{rowData.nrLot}</span>;

        return elem;
}

