import {Formik, ErrorMessage} from 'formik';
import * as Yup from "yup";
import React, {useState, useEffect} from 'react';
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import * as yup from "yup";
import MailchimpService from "../../../service/MailchimpService";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import campaignList from "../CampaignList";
import axios from "axios";
import Swal from "sweetalert2";

const CampaignAddEdit = ({isAddMode, show, setShow, getAllCampaigns, setIsAddMode, id}) => {

    const handleClose = () => setShow(false);
    const initialValues = {
        title: '',
        subjectLine: '',
        template: '',
        test:true,
        programme:''
    };

    const [optTemplate, setOptTemplate] = useState([]);
    const [campaign, setCampaign] = useState(null);
    const [programList, setProgramList] = useState([])
    const callGetTemplateList = async (fields) => {
        try {
            const data = await MailchimpService.listTemplate();
            var optionTemplate = [];

            {
                data.templates.map((template, index) => {
                    optionTemplate.push({value: template.id, label: template.name});

                })
            }

            setOptTemplate(optionTemplate);
        } catch (error) {
            console.error('Erreur lors de la requête :', error.message);
        }
    };


    const callPostCreateCampaign = async (fields, actions) => {
        try {

            await MailchimpService.createCampaign(fields);
            setShow(false);
            setIsAddMode(true);
            getAllCampaigns();
            actions.setSubmitting(false);
        } catch (error) {
            console.error('Erreur lors de la requête :', error.message);
            actions.setSubmitting(false);
        }
    };

    const callPatchUpdateCampaign = async (fields, actions) => {
        try {

            const data = await MailchimpService.updateCampaign(fields,id);
            setShow(false);
            setIsAddMode(false);
            actions.setSubmitting(false);
            getAllCampaigns();
        } catch (error) {
            console.error('Erreur lors de la requête :', error.message);
            actions.setSubmitting(false);
        }
    };



    useEffect(() => {
        getProgrammeList();
        callGetTemplateList()
    }, [])

    useEffect(() => {

    }, [campaignList])


    const getProgrammeList = () => {
        axios.get(`/admin/programmes`)
            .then(function (response) {

                setProgramList(response.data);


            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    function onSubmit(values, actions) {

        const fields = {...values};
        getAllCampaigns();

        if (isAddMode) {
            callPostCreateCampaign(fields, actions);

        } else {

            callPatchUpdateCampaign(fields,actions);
        }


    }

    const CampaignForm = ({
                              handleSubmit,
                              handleChange,
                              values,
                              errors,
                              isValid,
                              isSubmitting,
                              touched,
                              setFieldValue,
                              handleBlur
                          }) => {

            return (

                <div>
                    <div className={"px-5 pt-4 pb-5 contact-wrapper-form"}>

                        <Form.Group className="mb-3">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                name="title"
                                value={values.title}
                                type="text"/>
                        </Form.Group>
                        <Form.Label>Objet</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Control
                                onChange={handleChange}
                                name="subjectLine"
                                value={values.subjectLine}
                                type="text"/>
                        </Form.Group>
                        <ErrorMessage name={`comment`} component="div" className="error"/>

                        <Form.Group className="mb-3">
                            <Form.Label>Modèle</Form.Label>
                            <Select
                                options={optTemplate}
                                name={`template`}
                                value={values.template}
                                onChange={value =>
                                    setFieldValue(`template`, value)}
                            />
                            <ErrorMessage name={`template`} component="div" className="error"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Programme</Form.Label>
                            <Select
                                placeholder={<div>Programme</div>}
                                className={'px-0'}
                                options={programList}
                                // styles={customStyles}
                                onChange={(value) => {
                                    setFieldValue('programme', value, false);
                                }}
                                name="programme"
                                value={values.programme}
                                defaultValue={values.programme}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className={'me-3'}>Tester</Form.Label>
                            <Form.Check
                                inline
                            checked={values.test === true ? true : false}
                                type="checkbox"
                                onChange={(e) => setFieldValue(`test`, e.target.checked)}
                                name="test"
                                label="Oui"
                            />
                            <ErrorMessage name={`template`} component="div" className="error"/>
                        </Form.Group>
                    </div>
                    <footer>
                        <div className={"d-flex justify-content-center py-3"}>


                            {isSubmitting ?
                                <Button variant="primary" disabled className={"w-75"}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> Veuillez patienter</Button> :
                                <Button
                                    variant="success"
                                    className="btn btn-success w-50"
                                    disabled={isSubmitting}
                                    type="submit">Valider</Button>}

                        </div>
                    </footer>
                </div>
            )
        }
    ;


    const validationSchema = Yup.object().shape({
        title: yup.string()
            .required("L'ojbet est requis"),
        template: Yup.mixed().test('is-selected', 'Sélectionner un template', value => {
            return !!value; // Vérifie si une option est sélectionnée (non nulle ou non vide)
        }),

    });


    const [contacts, setContacts] = useState()

    return (

        <Offcanvas className={'offcanvas-size-xl'} id={"offcanvas-contact"} show={show}
                   onHide={handleClose}
                   placement="end">
            <Offcanvas.Header closeButton className={"border-bottom"}>
                <Offcanvas.Title className={'py-2 ps-4'}>Ajouter une campagne</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {

                        useEffect(() => {

                            if (!isAddMode) {
                                try {

                                    axios.get(`/mailchimp/campaigns/${id}`)
                                        .then(function (response) {
                                            let campaign = response.data;
                                            const fields = ['title','subjectLine','template'];

                                            fields.forEach(field =>
                                                setFieldValue(field, campaign[field], false)
                                            );


                                        })
                                        .catch(function (error) {

                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Une erreur s\'est produite!',
                                                showConfirmButton: false,
                                                timer: 1500
                                            })
                                        })


                                } catch (error) {

                                    console.error('Erreur lors de la requête :', error.message);

                                }

                            }
                        }, [id]);

                        return (

                            <Form noValidate onSubmit={handleSubmit}>

                                <CampaignForm
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    values={values}
                                    errors={errors}
                                    isValid={isValid}
                                    isSubmitting={isSubmitting}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}

                                />


                            </Form>)
                    }}
                </Formik>
            </Offcanvas.Body>
        </Offcanvas>

    );
}

export default CampaignAddEdit;