import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import {useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SimpleSlider from "../../../component/SimpleSlider";
import ListGroup from "react-bootstrap/ListGroup";
import {switchValue} from "../../../component/SwitchValue";

const LotHome =({lot,user}) =>{

    const [isBuyerHidden, setIsBuyerHidden] = useState(true);
    let navigate = useNavigate();

    useEffect(() => {

        user.role==='ROLE_ACQUEREUR'?setIsBuyerHidden(true):setIsBuyerHidden(false)
    }, []);

    return(
        <div>
            <Row>
                <Col lg="4">
                    <Card>
                        <Card.Body>
                            { lot && lot.medias && lot.medias.slider1 && lot.medias.slider1.length>0 ? <SimpleSlider medias={lot.medias}/>  : <div>
                                <div className="d-flex justify-content-center align-items-center mb-4">
                                <img src='/assets/img/no-files.jpg' width={'80%'}
                                                                                                                                                       />

                                </div>
                                <div className={'text-center'}>
                                <strong >Aucun média pour l'instant.</strong></div>
                            </div>}
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="8">
                    <Card>
                        <Card.Body>
                            <h2 className={'pt-3'}>Description</h2>
                            <p >{lot.descriptif ? <div dangerouslySetInnerHTML={{ __html: lot.descriptif }} />  : <div dangerouslySetInnerHTML={{ __html: lot.programme.descriptif }} />}</p>
                        </Card.Body>

                    </Card>
                    <Card>
                        <Card.Body>
                            <h2 className={'pt-3'}>Caractéristiques</h2>
                            <Row>
                                <Col lg="6">
                                    <ListGroup className={"borderless"} as="ul">
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>N° de copropriété</div> <span>{lot.nrCopro}</span></ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}> <div className={"text-muted"}>Type de bien</div> <span>{ lot.typeBienLibelle  }</span></ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Surface habitable</div> <span>{lot.sfcHabitable} m²</span></ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Surface terrain</div> <span>{lot.sfcTerrain}</span></ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Niveau</div> <span>{lot.niveau}</span></ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Orientation</div> <span>{lot.orientation}</span></ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col lg="6">
                                    <ListGroup className={"borderless"} as="ul" id={"program-features"}>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>VEFA</div> <span>{switchValue(lot.nfoVefa)}</span></ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>PSLA</div> <span>{switchValue(lot.nfoPsla)}</span></ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>TVA</div> <span>{switchValue(lot.pxVenteTvaTaux)}</span></ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Prix de vente TTC</div> <span>{lot.pxVenteTtc}</span></ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Programme</div><span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/programme/${lot.programme.id}/voir`)}}>{lot.programme.libelle}</span></ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Body>

                        </Card.Body>
                    </Card>
                </Col>

            </Row>

        </div>


    )
}

export default LotHome;