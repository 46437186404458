import React, {useState} from 'react';
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ContactAddEdit from "../../Contact/ContactAddEdit";
import UserAddEdit from "../UserAddEdit";
import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";

const CardInfoUser  =({user}) =>{

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);


    return(
        <Card>
            <Card.Body className={'px-4'}>
                <div className={'d-flex align-items-center flex-column'}>
                    <Image src='/assets/img/blank.png' fluid width={'110'} className="my-4"/>
                    <h5 className={"mb-2"}> { user.name}</h5>
                    <span>
                            <Badge className={"bg-label-secondary text-uppercase"} bg={'secondary'}>{user.role}</Badge>
                        </span>

                </div>
                <div className="d-flex justify-content-around flex-wrap my-4 py-3">
                    <div className="d-flex align-items-start me-4 mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded">
                          <svg className="" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg"
                               viewBox="0 0 24 24"><path fill="#5a8dee"
                                                         d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"></path></svg>
                                </span>
                        <div>
                            <h5 className="mb-0">{user.nbrOptions}</h5>
                            <span>Options</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-start mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded">
                                 <svg className="" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"><path fill="#5a8dee"
                                                                d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39ZM13.54,9.59l-2.69,2.7-.89-.9a1,1,0,0,0-1.42,1.42l1.6,1.6a1,1,0,0,0,1.42,0L15,11a1,1,0,0,0-1.42-1.42Z"></path></svg>
                                </span>
                        <div>
                            <h5 className="mb-0">{user.nbrBooked}</h5>
                            <span>Réservations</span>
                        </div>
                    </div>
                </div>
                <h5 className={"pb-4 border-bottom mb-4"}>Détails</h5>
                <div className={"info-container mb-4"}>

                    <Table responsive="xl" borderless>
                        <tbody>
                        <tr>
                            <td className={"font-weight-bold"}>Email</td>
                            <td>{user.email}</td>
                        </tr>

                        <tr>
                            <td className={"font-weight-bold"}>Téléphone</td>
                            <td>{user.phone}</td>
                        </tr>

                        <tr>
                            <td className={"font-weight-bold"}>Adresse</td>
                            <td>{user.adresse}</td>
                        </tr>
                        <tr>
                            <td className={"font-weight-bold"}>Ville</td>
                            <td>{user.city}</td>
                        </tr>
                        </tbody>
                    </Table>


                </div>
                <div className={'d-flex justify-content-center pt-3'}>
                    <Button onClick={handleShow} variant="primary">Modifier</Button></div>
            </Card.Body>
            <UserAddEdit show={show} setShow={setShow} user={user}/>
        </Card>
    )
}

export default CardInfoUser;