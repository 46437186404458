import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import MaterialTable, {MTableHeader} from "@material-table/core";
import ScrollWrapper from "../../utils/ScrollWrapper";
import Pagination from "../../component/Pagination";
import TablePaginationActions from "../../component/TablePaginationActions";
import TablePaginationNullActions from "../../component/TablePaginationNullActions";

function MediaList(isFullSize) {

    const [show, setShow] = useState(false);
    const [mediaList, setMediaList] = useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let navigate = useNavigate();
    // Variables d'état pour la pagination
    const [dataCount, setDataCount] = useState(0); // Les données de l'API ou de la source
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
        fetchMedias();

    }, [page, rowsPerPage]); // Exécuter à chaque changement de page ou de nombre de lignes

    const downloadPathAction = rowData => {

        var elem = <a className="btn btn-sm btn-default view_link" title="Télécharger"
                      href={rowData.downloadPath}
                      download><i className="fa fa-download" aria-hidden="true"></i></a>;

        return elem;
    };

    const displayThumbnailAction = rowData => {

        var elem = <div className="text-center">
            <img className={"thumbnail-list"} src={rowData.thumbnail}/>
        </div>;

        return elem;
    };

    const fetchMedias = () => {

        axios.get(`/admin/medias`, {params: {
                page: page + 1,  // Certaines APIs commencent la pagination à 1
                limit: rowsPerPage,
            },page: page + 1,  // Certaines APIs commencent la pagination à 1
            limit: rowsPerPage,})
            .then(function (response) {
                setDataCount(response.data.meta.total_items)
                setMediaList(response.data.items)

            })
            .catch(function (error) {

                if(error.response.status='401'){

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })

        ;





    }

    const getLinkValueForRow  = (rowData,entity) => {

        var label;
        var entityId;

        if(entity==='programme'){
            label=rowData.programme;
            entityId=rowData.programId;

        }
        else if(entity==='lot'){
            label=rowData.lot;
            entityId=rowData.lotId;
        }
        else if(entity==='media'){
            label=rowData.id;
            entityId=rowData.id;
        }

        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
            navigate(`/admin/${entity}/${entityId}/voir`)
        }}>{label}</span>;

        return elem;

    };

    const getCreatedAtValueForRow = createdAt => {

        var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
            <time>{createdAt}</time>
        </div>;

        return elem;
    };

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1">
                                <div>
                                    <h1>Médias</h1>
                                </div>

                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des médias</li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">
                            <ScrollWrapper isFullSize={isFullSize}>

                                <MaterialTable
                                    title={false}
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} of {count}',
                                            labelRowsSelect: 'lignes'
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} média(s) sélectionnée(s)',
                                            searchPlaceholder: 'Chercher'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Aucun média à afficher',
                                            filterRow: {
                                                filterTooltip: 'Filter'
                                            }
                                        }
                                    }}
                                    options={{
                                        pageSize: 25,
                                        paging:false,
                                    }}
                                    columns={[
                                        {
                                            title: 'Id',
                                            field: 'id',
                                            width: "10%",
                                            render: rowData => getLinkValueForRow(rowData,'media')
                                        },
                                        {
                                            title: 'Ajouté le',
                                            field: 'createdAt',
                                            align: "center",
                                            render: rowData => getCreatedAtValueForRow(rowData.createdAt)
                                        },
                                        {
                                            title: '',
                                            field: 'thumbnail',
                                            render: rowData => displayThumbnailAction(rowData),
                                            width: "10%"
                                        },

                                        {title: 'Nom', field: 'name'},
                                        {
                                            title: 'Programme',
                                            field: 'programme',
                                            filtering: false,
                                            render: rowData => getLinkValueForRow(rowData,'programme')
                                        },
                                        {title: "Lot", field: 'lot', filtering: false,render: rowData => getLinkValueForRow(rowData,'lot')},
                                        {title: 'Taille', field: 'size'},
                                        {
                                            title: 'Téléchargement',
                                            field: 'downloadPath',
                                            render: rowData => downloadPathAction(rowData),
                                            align: "center"
                                        },

                                    ]}
                                    data={mediaList}
                                />
                                </ScrollWrapper>
                            {mediaList.length > 0 && (
                                <Pagination
                                    dataCount={dataCount}  // Total des données
                                    page={page}  // Page actuelle
                                    rowsPerPage={rowsPerPage}  // Nombre de lignes par page
                                    onPageChange={(newPage) => setPage(newPage)}  // Changer la page
                                    onRowsPerPageChange={(newRowsPerPage) => {
                                        setRowsPerPage(newRowsPerPage);
                                        setPage(0);  // Réinitialiser à la première page quand le nombre de lignes change
                                    }}
                                    ActionsComponent={TablePaginationActions}  // Composant personnalisé de pagination
                                    TablePaginationNullActions={TablePaginationNullActions}  // Composant sans actions
                                />
                            )}
                        </div>
                    </div>
                </div>
            </section>


        </Layout>
    );
}

export default MediaList;