import React, {useState, useEffect} from 'react';
import MaterialTable from "@material-table/core";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {dateValueForRow} from "../../../component/DateValueForRow";
import axios from "axios";
import Swal from "sweetalert2";
import {Icon} from "@iconify/react/dist/iconify";
import {useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import MailchimpService from "../../../service/MailchimpService";
import ScrollWrapper from "../../../utils/ScrollWrapper";
const CampaignTable = ({campaignList, getAllCampaigns, setIsAddMode, setShow, setId,isFullSize}) => {

    const [filter, setFilter] = useState(false)
    const [rowCount, setRowCount] = useState('Liste des campagnes')
    const [selectedRow, setSelectedRow] = useState(null);
    const handleShow = () => setShow(true);
    const [actionsTab, setActionsTab] = useState([])
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {

    }, [campaignList]);
    useEffect(() => {

        actions()
    }, [selectedRow]);

    const handleIconClick = (event, rowData) => {

        setAnchorEl(event.currentTarget);
        setSelectedRow(rowData);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const actions = () => {

        const actionsTab = [];
        if (selectedRow && selectedRow[0] && !selectedRow[0].send_time) {

            actionsTab.push({
                icon: () => <Button variant={'primary'}> <Icon width={'20px'} icon="fluent:send-16-regular"/> Envoyer la
                    campagne</Button>,
                tooltip: "Envoyer la campagne",
                onClick: (event, rows) => {
                    callSendCampaign(rows[0].id);
                }
            })
        }

        actionsTab.push({
            icon: () => <Icon icon="fluent:edit-16-filled"/>,
            tooltip: "Modifier la campagne",
            onClick: (event, rows) => {

                setId(rows[0].id);
                setIsAddMode(false);
                handleShow()
            }
        })

        actionsTab.push({
            icon: () => <Icon icon="pepicons-pop:dots-y"/>,
            tooltip: 'Autres actions',
            onClick: (event, rows) => {
                setSelectedRow(null);
                handleIconClick(event, rows)
            },
        });

        setActionsTab(actionsTab);
    };

    const getStatusValueForRow = {

        1: <span className="label label-success">envoyé</span>,
        2: <span className="label label-warning">en attente</span>, // just for example, remove it if you don't need

    };

    const handleDelete = (event) => {

        Swal.fire({
            icon: 'warning',
            title: 'Confirmer la suppression des campagnes sélectionnées ?',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            dangerMode: true,
        })
            .then(result => {

                if (result.isConfirmed) {

                    var ids = selectedRow.map(s => s.id);
                    let idsToString = ids.toString();

                    axios.delete('/mailchimp/campaigns?ids=' + idsToString)
                        .then(res => {

                            getAllCampaigns();

                            if (selectedRow.length == 1) {
                                var message = "La campagne a bien été supprimée"
                            } else {
                                var message = "Les campagnes ont bien été supprimées"
                            }
                            Swal.fire({
                                title: "Fait!",
                                text: message,
                                icon: "success",
                                timer: 2000,
                                button: false
                            })
                        })
                        .catch(function (error) {

                                if (error.response.status === 401) {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'Vous n\'êtes pas autorisé à supprimer cette campagnes',
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                }

                            }
                        );


                } else {
                    Swal.fire('Votre campagne est préservée', '', 'info')
                }
            })
        setAnchorEl(null);
    };


    const tableRef = React.useRef();

    const getListLinkValueForRow = rowData => {
        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
            navigate(`/admin/contact/list`, {state: {mailchimp: rowData.list_id}})
        }}>{rowData.list_name}</span>;

        return elem;

    };

    const callSendCampaign = async (id) => {

        try {
            var res = await MailchimpService.sendCampaign(id);

            Swal.fire('Votre campagne a été envoyée', '', 'success')
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: "Une erreur s'est produite!",
                showConfirmButton: false,
                timer: 1500
            })
        }
    };

    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <ScrollWrapper isFullSize={isFullSize}>

                        <MaterialTable
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} of {count}',
                                    labelRowsSelect: 'lignes'
                                },
                                toolbar: {
                                    nRowsSelected: '{0} sélectionné',
                                    searchPlaceholder: 'Chercher'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Aucune campagne à afficher',
                                    filterRow: {
                                        filterTooltip: 'Filter'
                                    }
                                }
                            }}
                            onSelectionChange={(rows) => setSelectedRow(rows)}
                            options={{
                                pageSize: 25,
                                selection: true,
                                filtering: filter,
                                actionsColumnIndex: -1,
                                toolbarButtonAlignment: "left",
                                addRowPosition: 'last', // Ajouter les nouvelles lignes en bas du tableau
                            }}

                            columns={[
                                {
                                    title: 'Titre',
                                    field: 'title',
                                    align: "left",
                                },
                                {
                                    title: 'Statut',
                                    field: 'status',
                                    align: "center",
                                    render: rowData => getStatusValueForRow[rowData.send_time ? 1 : 2],
                                },
                                {
                                    title: 'Envoyé le',
                                    field: 'send_time',
                                    align: "right",
                                    width: "10%",
                                    render: rowData => dateValueForRow(rowData.send_time)
                                },
                                {
                                    title: 'Destinataires',
                                    field: 'recipient_count',
                                    align: "center",
                                },
                                {
                                    title: 'Ouvert',
                                    field: 'opens',
                                    align: "center",
                                },
                                {
                                    title: 'Cliqué',
                                    field: 'clicks',
                                    align: "center",
                                },
                                {
                                    title: 'Liste',
                                    field: 'list_name',
                                    align: "left",
                                    render: rowData => getListLinkValueForRow(rowData)
                                },
                                {
                                    title: 'Modèle',
                                    field: 'template_name',
                                    align: "left",
                                },

                            ]}

                            tableRef={tableRef}
                            onSearchChange={() => {

                                setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                            }}
                            data={campaignList}
                            title={rowCount}
                            actions={actionsTab}

                        />
                    </ScrollWrapper>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            style={{top: '50px', left: '0'}}
                        >
                            <MenuItem onClick={handleDelete}> <Icon style={{color: "#a8a5a5"}}
                                                                    icon="material-symbols:delete-outline"/><small>Supprimer</small></MenuItem>

                        </Menu>

                </div>
            </div>
        </div>

    )
}

export default CampaignTable;