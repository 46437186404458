import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import MaterialTable from "@material-table/core";
import {useParams} from "react-router-dom";

const ReservationTab = ({}) => {


    const [id, setId] = useState(useParams().id)
    const [reservationList, setReservationList] = useState([])

    const getMedias = () => {

        axios.get(`/admin/contacts/${id}/reservations`)
            .then(function (response) {

                var reservations = response.data;
                setReservationList(reservations)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getMedias();
    }, [])

    const getLotsForRow = lots => {

        if (lots) {

            return (
                lots.map((elem, index) => {

                    return elem.url ? <a href={elem.url}>{(index ? ', ' : '')}{elem.nrLot}</a> : (index ? ', ' : '')
                    {
                        elem.nrLot
                    }
                }))
        }

    };

    const getProgrammeForRow = programme => {

        return <a href={programme.url}>{programme.label}</a>
    };

    const getCreatedAtValueForRow = createdAt => {

        var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
            <time>{createdAt}</time>
        </div>;

        return elem;
    };

    const renderStatutSwitch = (status) => {
        switch (status) {
            case 0:
                return <div className={'text-center'}><span className={"label label-danger"}>refusée</span></div>;
            case 1:

                return <div className={'text-center'}><span className={"label label-success"}>acceptée</span></div>;
            case 2:
                return <div className={'text-center'}><span className={"label label-warning"}>en cours</span></div>;
            case 3:
                return <div className={'text-center'}><span className={"label label-default"}>expirée</span></div>;
        }
    }

    return (

        <div>
            <div>

            </div>

            {reservationList.length > 0 ? (
                <MaterialTable
                    title="Liste des réservations"
                    localization={{
                        toolbar: {
                            searchPlaceholder: 'Chercher'
                        },

                    }}
                    columns={[
                        {
                            title: 'Id',
                            field: 'id',
                            width: "5%"
                        },
                        {
                            title: 'Date',
                            field: 'date',
                            render: rowData => getCreatedAtValueForRow(rowData.date),
                            width: "15%"
                        },
                        {title: 'Lot', field: 'lots', render: rowData => getLotsForRow(rowData.lots)},
                        {
                            title: 'Programme',
                            field: 'programme',
                            render: rowData => getProgrammeForRow(rowData.programme)
                        },
                        {
                            title: 'Statut',
                            field: 'statut',
                            render: rowData => renderStatutSwitch(rowData.statut),
                            align: "center"
                        },
                    ]}
                    data={reservationList}
                />
            ) : (<></>)}

        </div>


    )
}

export default ReservationTab;