import {useParams} from 'react-router-dom';
import {Formik, ErrorMessage} from 'formik';
import {string} from 'yup' ;
import * as Yup from "yup";
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from 'react-bootstrap/Form';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import de la locale française

// Configurez dayjs pour utiliser la locale française
dayjs.locale('fr');
const WorkSituationAddEdit = ({show, setShow, setIsAddMode,isAddMode,getAllWorkSituations,idWorkSituation}) => {

    const handleClose = () => setShow(false);
    const [idProgramme, setIdProgramme] = useState(useParams().id)
    const initialValues = {
        description: '',
        label: '',
        date: dayjs(new Date()),
    };

    useEffect(() => {

    }, [idWorkSituation]);

    const WorkSituationForm = ({
                             handleSubmit,
                             handleChange,
                             values,
                             errors,
                             isValid,
                             isSubmitting,
                             touched,
                             setFieldValue,
                             handleBlur
                         }) => {
            return (
                <div>
                    <div id="work-situation-form" className={"px-5 pt-4 pb-5"}>


                        <Form.Group className="mb-3">
                            <Form.Label>Libellé</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                name="label"
                                value={values.label}
                                type="text"/>
                            <ErrorMessage name={`label`} component="div" className="error"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                name="description"
                                value={values.description}
                                type="text"/>
                            <ErrorMessage name={`description`} component="div" className="error"/>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <LocalizationProvider dateFormats={{
                                shortDate: "d MMMM"
                            }} dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <MobileDateTimePicker
                                    name="date"
                                    format="DD-MM-YYYY HH:mm"
                                    label="Début"
                                    clearText="vider"
                                    cancelText="annuler"
                                    onChange={(value) => {
                                        setFieldValue('date', value)
                                    }}
                                    value={values.date}
                                    okText="ok"
                                />
                            </LocalizationProvider>
                        </Form.Group>

                    </div>
                    <footer>
                        <div className={"d-flex justify-content-center py-3"}>
                            <button
                                id={'work-situation-submit'}
                                variant="primary"
                                className="btn btn-success w-50"
                                disabled={isSubmitting}
                                type="submit">
                                {isSubmitting ? "S'il vous plaît, attendez..." : "Valider"}
                            </button>
                        </div>
                    </footer>
                </div>)
        }

    ;


    const validationSchema = Yup.object().shape({
        label: string()
            .required("Un libellé est requis"),
        description: string()
            .required("Une description est requise")
    });


    function onSubmit(values, actions) {

        const fields = {...values};
        var date= fields.date.format('DD-MM-YYYY HH:mm')
        fields.date = date; // Exemple de formatage

        if (isAddMode) {
            createWorkSituation(fields);
            setIsAddMode(false)
        } else {

            updateWorkSituation(fields);
        }

        actions.setSubmitting(false);
    }


    function createWorkSituation(fields) {

        axios.post(`/admin/programme/${idProgramme}/worksituations`, fields)
            .then(function (response) {

                setShow(false);
                getAllWorkSituations();

                Swal.fire({
                    icon: 'success',
                    title: "Etat d'avancement créé",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {

                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            });
    }


    function updateWorkSituation(fields) {

        axios.patch(`/admin/worksituations/${idWorkSituation}`, fields)
            .then(function (response) {

                setShow(false);
                // getAllMeetings();
                getAllWorkSituations();
                Swal.fire({
                    icon: 'success',
                    title: 'Etat d\'avancement mis à jour!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }

    return (

        <Offcanvas className={'offcanvas-size-xl'} id={"offcanvas-contact"} show={show} onHide={handleClose}
                   placement="end">
            <Offcanvas.Header closeButton className={"border-bottom"}>
                <Offcanvas.Title className={'py-2 ps-4'}>{isAddMode ? 'Ajouter un état d\'avancement'  : "Modifier l'état d'avancement"}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {
                        console.log(errors)

                        useEffect(() => {

                            if (!isAddMode) {
                                axios.get(`/admin/worksituations/${idWorkSituation}`)
                                    .then(function (response) {

                                        let work = response.data;

                                        const fields = ['label', 'date','description'];

                                        fields.forEach(field =>{

                                                field==='date'?setFieldValue(field, dayjs(work[field]), false):setFieldValue(field, work[field], false)
                                        }


                                        );


                                    })
                                    .catch(function (error) {

                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Une erreur s\'est produite!',
                                            showConfirmButton: false,
                                            timer: 1500
                                        })
                                    })
                            }
                        }, [idWorkSituation]);


                        return (

                            <Form noValidate onSubmit={handleSubmit}>


                                <WorkSituationForm
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    values={values}
                                    errors={errors}
                                    isValid={isValid}
                                    isSubmitting={isSubmitting}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}

                                />


                            </Form>)
                    }}
                </Formik>
            </Offcanvas.Body>
        </Offcanvas>

    );
}

export default WorkSituationAddEdit;