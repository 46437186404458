import {useDropzone} from "react-dropzone";
import React from 'react';
import {formatDate} from "@fullcalendar/core";
import axios from "axios";
import Swal from "sweetalert2";

export async function getProjectDir() {

    try {
        const response = await axios.get(`/config/project-dir`);

        return response.data;
    } catch (error) {
        console.error('Erreur :', error);
        throw error;
    }

}