export function numberWithCommas(x) {

    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
}
export function convertToFloat(amount) {
    if (amount == null || amount == 0) {
        return 0; // ou une valeur par défaut
    }

    var pxVente = amount.replace(/\s/g, ''); // Enlever les espaces
    var px = parseFloat(pxVente);

    // Vérifier si la conversion a échoué (NaN)
    if (isNaN(px)) {
        return 0; // ou une autre valeur par défaut
    }

    return px;
}