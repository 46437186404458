import React, {useState, useEffect} from 'react';
import Layout from "../../component/Layout";
import Container from 'react-bootstrap/Container';
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {Icon} from '@iconify/react';
import MediaTab from "../../component/MediaTab";
import ReservationContractCard from "../../component/ReservationContractCard";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {fetchUserData} from "../../component/fetchUserData";
import MarketTable from "../Market/component/MarketTable";
import InvoiceTable from "../Invoice/component/InvoiceTable";
import LotHome from "./component/LotHome";
import TabAdf from "../Adf/component/TabAdf";
import TabTma from "../Tma/component/TabTma";
import Button from "react-bootstrap/Button";


function LotView({history, match,user}) {

    const getStatusValueForRow = {

        1: <span className="badge text-bg-success">libre</span>,
        2: <span className="badge text-bg-warning text-white">optionné</span>, // just for example, remove it if you don't need
        3: <span className="badge text-bg-danger">réservé</span>,
        4: <span className="badge text-bg-default">signé</span>,
        5: <span className="badge text-bg-default">livré</span>
    };
    const [lot, setLot] = useState({lat: '', long: '', medias: {slider1: {}}})
    const [codeClient, setCodeClient] = useState('')
    const [templateReservation, setTemplateReservation] = useState('')
    const [id, setId] = useState(useParams().id)
    let navigate = useNavigate();
    const [mediaList, setMediaList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const fetchLotData = async () => {
        try {
            const response = await axios.get(`/admin/lots/${id}`);
            var data = response.data;
            setLot(data);
            setCodeClient(data.codeClient)
            setTemplateReservation(data.templateReservation)
            setIsLoading(true); // Définir isLoading sur false lorsque les données sont chargées
        } catch (error) {
            setIsLoading(true);
            if(error.response.status='401'){

                Swal.fire({
                    icon: 'warning',
                    title: error.response.data,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else{

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
    };

    const handleAddReservation = () => {
        // Récupérer les identifiants des lignes sélectionnées

        const idsLot = lot.idsLot.map(item => item.id).join(',');
        navigate(`/admin/reservation/ajouter?selectedIds=${idsLot}&programId=${lot.programme.id}`)
    }

    const handleAddOption = () => {
            // Récupérer les identifiants des lignes sélectionnées

        const idsLot = lot.idsLot.map(item => item.id).join(',');
            navigate(`/admin/option/ajouter?selectedIds=${idsLot}&programId=${lot.programme.id}&prom=${lot.promoteur}`)
    }

    const getMedias = () => {

        axios.get(`/admin/entity/${id}/medias?entity=lot`)
            .then(function (response) {
                setMediaList(response.data)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }



    useEffect(() => {
        fetchLotData();
        getMedias();

    }, [])


    useEffect(() => {


    }, [templateReservation])


    const renderTabsSwitch = () => {

        return <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
        ><Tab eventKey="home" title={<span><Icon icon="cil:description"/>{" Caractéristiques"}</span>}>
            {user.role != ''  && lot.codeClient?
                <LotHome lot={lot} user={user}/> : null}
        </Tab>
            {user.role != 'ROLE_FOURNISSEUR' ?
            <Tab eventKey="setting" title={<span><Icon icon="iconoir:media-image"/>{" Médias"}</span>}>
                <Row>
                    {user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN'? <Col lg={'3'}>
                        <ReservationContractCard codeClient={codeClient} idProgramme={id}
                                                 templateReservation={templateReservation}
                                                 setTemplateReservation={setTemplateReservation}/>
                    </Col> : null}

                    <Col lg={user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ? '9' : '12'}>
                        <MediaTab medias={mediaList}/></Col></Row>
            </Tab>: null}
            {(user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' && !user.isPropertyTrader) || user.role === 'ROLE_ACQUEREUR' ?
            <Tab eventKey="adf"
                 title={<span><Icon icon="ph:bank"/>{" Adf"}</span>}>
                {isLoading ? <TabAdf idLot={id}/> : null}

            </Tab> : null}

            {(user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' && !user.isPropertyTrader) || user.role === 'ROLE_ACQUEREUR' ?
                <Tab eventKey="tma"
                     title={<span><Icon icon="carbon:tools-alt"/>{" Tma"}</span>}>
                    {isLoading ? <TabTma list={false} idLot={id}/> : null}

                </Tab> : null}

            {user.role === 'ROLE_FOURNISSEUR' ?
                <Tab eventKey="invoice"
                     title={<span><Icon icon="la:file-invoice"/>{" Factures"}</span>}>
                    <InvoiceTable user={user}/>
                </Tab> : null}
            {user.role === 'ROLE_FOURNISSEUR' ? <Tab eventKey="market"
                                                     title={<span><Icon
                                                         icon="icon-park-outline:pie-two"/>{" Marchés"}</span>}>
                <MarketTable user={user}/>

            </Tab> : null}
        </Tabs>
            ;


    }

    return (<Layout>

            <section id={'lot-page'} className="content-header">
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">
                            <div className="navbar navbar-default p-1 d-flex">
                                <div>
                                    <div className="d-flex align-items-center mb-2">
                                        <h1 className={"d-inline me-2"}>{lot.nrLot} </h1>{getStatusValueForRow[lot.statut]}</div>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span></li>
                                        <li className={"link-primary"} onClick={() => {
                                            navigate('/admin/lots/list')
                                        }}
                                        >
                                            <span className={"px-1 cursor-pointer"}>Liste des lots</span>
                                        </li>
                                        <li>
                                            <span className={"ps-1"}>{lot.label ? lot.label : lot.nrLot}</span>
                                        </li>
                                    </ol></div>
                                <div>  {user.role === 'ROLE_PRESCRIPTEUR' || user.role === 'ROLE_PROMOTEUR' ? <Button variant="primary" border="success" className="me-2" size="sm"  onClick={() => {
                                    handleAddReservation(lot.packageLotsId);
                                }}>Poser une réservation</Button>  : null}
                                        {user.role === 'ROLE_PROSPECT' || user.role === 'ROLE_ACQUEREUR' || user.role === 'ROLE_PRESCRIPTEUR' || user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' && lot.statut===1  ? <Button variant="warning" border="success" size="sm" className={"bg-warning text-white"} onClick={() => {
                                        handleAddOption(lot.packageLotsId);
                                    }}><Icon icon="ic:round-add"/> Poser une option</Button>  : null}


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <Container fluid>
                    {renderTabsSwitch()}
                </Container>
            </section>

        </Layout>
    )

}

export default LotView;