import React from 'react';
import {useNavigate} from "react-router-dom";

/**
 *
 * @param rows L'ensemble des lignes du tableau Material Table
 * @param row La ligne actuellement sélectionnée
 * @param setSelectedLots Lots sélectionnés dans la réservation
 * @param setFieldValue Permet de mettre à jour le formulaire Formik
 * @param setFilteredData Mets à jour la liste des lots éventuellement filtrés par tranche, grille, etc
 * @param filteredData La liste des lots éventuellement filtrés par tranche, grille, etc
 * @param setActive Permet d'activer le bouton Poser une option dans la liste des lots de la fiche programme
 */
export const handleLotSelectChange = (rows, row,setSelectedLots,setFieldValue,setFilteredData,filteredData,setActive) => {
    console.log(filteredData)
// On met à jour la donnée de l'élément actuellement sélectionné ou désélectionné
    let updatedData = filteredData.map(d =>
        d.id === row.id // Si l'ID de l'élément courant correspond à celui de la ligne sélectionnée
            ? { ...d, tableData: { ...row.tableData, checked: row.tableData.checked } } // Met à jour l'état 'checked' de cet élément
            : d // Sinon, ne change rien pour cet élément
    );

// // Vérifie si l'élément sélectionné a un parent (ce qui signifie que c'est un enfant)
//     if (row.parentId) {
//         if (!row.tableData.checked) {
//             // Si l'enfant est décoché, alors on doit décocher son parent et ses frères
//             updatedData = updatedData.map(d => {
//                 if (d.id === row.parentId) {
//                     // Décoche le parent (élément ayant l'ID correspondant au 'parentId' de l'enfant)
//                     return { ...d, tableData: { ...d.tableData, checked: false } };
//                 } else if (d.parentId === row.parentId) {
//                     // Décoche également les frères (éléments ayant le même 'parentId')
//                     return { ...d, tableData: { ...d.tableData, checked: false } };
//                 }
//                 return d; // Ne change rien pour les autres éléments non concernés
//             });
//         } else {
//
//             updatedData = updatedData.map(d => {
//                 if (d.id === row.parentId) {
//                     // Si tous les frères sont cochés, cocher également le parent
//                     return { ...d, tableData: { ...d.tableData, checked: true } };
//                 } else if (d.parentId === row.parentId) {
//                     // Coche également les frères
//                     return { ...d, tableData: { ...d.tableData, checked: true } };
//                 }
//                 return d; // Ne change rien pour les autres éléments non concernés
//             });
//         }
//     }
//     else {
//         // Si l'élément sélectionné est un parent
//         updatedData = updatedData.map(d => {
//             if (d.parentId === row.id) {
//                 // Applique le même état (coché ou décoché) à tous les enfants de ce parent
//                 return { ...d, tableData: { ...d.tableData, checked: row.tableData.checked } };
//             }
//             return d; // Ne change rien pour les autres éléments non concernés
//         });
//     }
    console.log(updatedData)
    const filtersedData = updatedData.filter(row => row.tableData.checked === true);

    console.log(filtersedData)
    console.log(rows)

    setFilteredData?setFilteredData(updatedData):null;
    setSelectedLots?setSelectedLots(filtersedData):null;
    setFieldValue? setFieldValue('lots', filtersedData):null;
    setActive?setActive(false):null;

};

