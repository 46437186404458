var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Document, Page, pdfjs } from "react-pdf";
import { Icon } from "@iconify/react";
import axios from "axios";
import Swal from "sweetalert2";
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
var currentId = 0;
pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/".concat(pdfjs.version, "/pdf.worker.js");
function getNewId() {
    // we could use a fancier solution instead of a sequential ID :)
    return ++currentId;
}
var Dropzone = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        border: "2px dashed ".concat(theme.palette.primary.main),
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.background.default,
        height: '100%',
        outline: 'none',
    });
});
export function MultipleFileUpload(props, _a) {
    var name = _a.name;
    var dataFiles = props.dataFiles, setDataFiles = props.setDataFiles;
    var classes = Dropzone;
    var _b = useState(null), numPages = _b[0], setNumPages = _b[1];
    var _c = useState(1), pageNumber = _c[0], setPageNumber = _c[1];
    var _d = useState(props.isAddMode), isAddMode = _d[0], setAddMode = _d[1];
    var _e = useState([]), files = _e[0], setFiles = _e[1];
    var openPdfInNewTab = function (event, url) {
        event.stopPropagation();
        window.open(url, '_blank');
    };
    var onDrop = useCallback(function (accFiles, rejFiles) {
        var mappedAcc = accFiles.map(function (file) { return ({
            file: file,
            errors: [], id: getNewId(), uploaded: false,
            preview: URL.createObjectURL(file)
        }); });
        var mappedRej = rejFiles.map(function (r) { return (__assign(__assign({}, r), { id: getNewId() })); });
        setFiles(function (curr) { return __spreadArray(__spreadArray(__spreadArray([], curr, true), mappedAcc, true), mappedRej, true); });
    }, []);
    function onDocumentLoadSuccess(_a) {
        var numPages = _a.numPages;
        setNumPages(numPages);
    }
    function onLoadError(error) {
    }
    function onSourceError(error) {
    }
    function removeObjectWithId(arr, id, gallery, uploaded) {
        var objWithIdIndex = arr.findIndex(function (obj) { return obj.id === id; });
        if (objWithIdIndex > -1) {
            arr.splice(objWithIdIndex, 1);
        }
        if (uploaded) {
            var url = "/admin/galerie/".concat(gallery, "/medias/").concat(id);
            axios.delete(url, {})
                .then(function (response) {
                if (response.data.tag === 'reservation_contract') {
                    props.setIsPdfContractAvailable(false);
                }
                if (response.data.tag === 'contrat') {
                    props.setIsContractAvailable(false);
                }
                Swal.fire({
                    icon: 'success',
                    title: 'Média supprimé avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                });
            })
                .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        }
        return arr;
    }
    useEffect(function () {
        // setMedias(props.medias);
        var mergedFiles = __spreadArray(__spreadArray([], props.previousFiles, true), files, true);
        files.length > 0 ? setDataFiles(mergedFiles) : null;
        if (files.length > 0) {
            props.setFieldValue('medias', mergedFiles);
        }
    }, [files]);
    var _f = useDropzone({
        onDrop: onDrop,
        accept: ['image/*', 'video/*', '.pdf'],
        maxSize: 10000000,
        noClick: false, noDrag: false
    }), getRootProps = _f.getRootProps, getInputProps = _f.getInputProps;
    var handleDelete = function (event, id, gallery, uploaded) {
        event.stopPropagation();
        setFiles(function (curr) { return curr.filter(function (fw) { return fw.id !== id; }); });
        setDataFiles(function (curr) { return removeObjectWithId(curr, id, gallery, uploaded); });
    };
    var displayMedias = function (dataFiles) {
        return (_jsx(React.Fragment, { children: _jsx(Grid, __assign({ item: true }, { children: _jsxs(Dropzone, __assign({}, getRootProps(), { className: 'dropzone' }, { children: [_jsx("div", __assign({ className: "file-preview-thumbnails clearfix" }, { children: dataFiles.length > 0 ? (dataFiles.map(function (dataFile, index) { return (_jsxs(Grid, __assign({ item: true, className: "file-preview-card mb-3" }, { children: [_jsx("div", __assign({ className: "file-preview-frame" }, { children: _jsx("div", __assign({ className: "crop" }, { children: dataFile.file.type == 'application/pdf' ?
                                                _jsx(Document, __assign({ error: "Une erreur s'est produite", loading: "En cours de chargement", language: 'fr', file: dataFile.preview, onLoadSuccess: onDocumentLoadSuccess, onLoadError: onLoadError, onSourceError: onSourceError }, { children: _jsx(Page, { pageNumber: pageNumber }) })) : dataFile.file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ?
                                                _jsx(Icon, { onClick: function (e) { return openPdfInNewTab(e, dataFile.preview); }, style: { color: "#3FA6EE" }, width: '28px', height: '28px', icon: "bi:filetype-docx" })
                                                : _jsx("img", { src: dataFile.preview }) })) })), _jsxs("div", __assign({ className: "file-thumbnail-footer" }, { children: [_jsx("div", __assign({ className: "file-caption-info" }, { children: dataFile.file.name.slice(0, 25) })), _jsxs("div", __assign({ className: "file-size-info" }, { children: ["(", dataFile.file.size, " KB)"] }))] })), _jsxs("div", __assign({ className: "file-actions" }, { children: [_jsx(Icon, { onClick: function (e) { return handleDelete(e, dataFile.id, props.gallery, dataFile.uploaded); }, className: "me-4", style: { color: "#8c8c8d" }, width: '20px', height: '20px', icon: "material-symbols:delete-outline-rounded" }), _jsx(Icon, { onClick: function (e) { return openPdfInNewTab(e, dataFile.preview); }, style: { color: "#8c8c8d" }, width: '18px', height: '18px', icon: "ic:outline-open-in-new" })] }))] }), index)); })) : (_jsx(_Fragment, {})) })), dataFiles.length === 0 ?
                            _jsx("div", __assign({ className: "file-drop-zone clearfix" }, { children: _jsx("div", __assign({ className: "file-drop-zone-title" }, { children: "Faites glisser et d\u00E9posez des fichiers ici, ou cliquez pour s\u00E9lectionner des fichiers" })) })) : null, _jsx("input", __assign({ disabled: isAddMode }, getInputProps()))] })) }), dataFiles.id) }));
    };
    return (_jsx(_Fragment, { children: displayMedias(dataFiles) }));
}
