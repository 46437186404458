import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import TabTma from "./component/TabTma";

function TmaList() {

    const [show, setShow] = useState(false);
    const [tmaList, setTmaList] = useState([])
    const [adminRole, setAdminRole] = useState('')
    let navigate = useNavigate();

    useEffect(() => {

        getAllTma();
        getUser();
    }, [])


    const getUser = (setFieldValue) => {

        axios.get(`/user-info/`)
            .then(function (response) {

                var user = response.data;
                setAdminRole(user.role);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getAllTma = () => {
        axios.get(`/admin/tma`)
            .then(function (response) {

                if (response.data) {
                    setTmaList(response.data);
                }
            })
            .catch(function (error) {

                if(error.response.status='401'){

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1">

                                <div>
                                    <h1>Travaux modificatifs acquéreur</h1></div>
                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des Tma</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{maxWidth: '100%'}}>

                    <TabTma list={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </Layout>
    );
}

export default TmaList;