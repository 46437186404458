import React from 'react';


export function getPdfValueForRow(pdf,download=true) {

        if (pdf) {
            var elem = <a href={pdf} className={'btn btn-sm btn-default view_link'}  target="_blank" download={download ? true : false}><i className="fa fa-download" aria-hidden="true" ></i></a>;
        }

        return elem;
}

