import React, {useState, useEffect, useRef} from 'react';
import Layout from "../../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import {Icon} from '@iconify/react';
import mammoth from 'mammoth';
import {load} from 'cheerio';
import Row from "react-bootstrap/Row";
import Card from 'react-bootstrap/Card';

function BuildContract({id,htmlContent,setHtmlContent,reservation}) {


    const [codeClient, setCodeClient] = useState('')
    const urlParams = new URLSearchParams(window.location.search);
    const fetchData = async () => {
        try {
            // Première fonction asynchrone
            const codeClient = await getCodeClient();
            // Lancer la deuxième fonction après la fin de la première fonction
            fetchAndConvertDocx(codeClient);
        } catch (error) {
            // Gérer les erreurs de la première fonction
            console.error(error);
        }
    };

    useEffect(() => {

        fetchData();
    }, [reservation]);

    const getCodeClient = async () => {
        try {
            const response = await axios.get(`/admin/codeclient?idreservation=${id}`);

            if (response.status!=200) {
                throw new Error('Erreur lors de la récupération des données');
            }
            var data = response.data;
            setCodeClient(data.codeClient)

            return data.codeClient;
        } catch (error) {
            throw new Error('Une erreur s\'est produite lors de la récupération des données');
        }
    };

    function addTableClassToHTML(htmlContent, cssContent,codeClient) {



        const content = load(htmlContent);
        content('body:first').attr('id', 'contrat_reservation');
        // Sélectionner la ligne où la première colonne contient "Zone de sismicité" et changer la couleur de fond de la colonne de droite
        content('td:contains("Zone de sismicité")').parent().find('td:nth-child(2)').css('background-color', '#e0dfda');
        content('td:contains("Risque sismique modéré")').parent().find('td:nth-child(1)').css('background-color', '#e0dfda');
        content('td:contains("Risque sismique moyenne")').parent().find('td:nth-child(3)').css('background-color', '#c0bfbb');
        content('table').addClass('table table-bordered mb-4').wrap('<div class="col-12 mx-auto"></div>');
        content('h1:first').attr('style', 'font-size: 50px;');
        content('h1').addClass('my-5 text-center');

        // content('h1:nth-child(2)').addClass('fw-bold');
        content('h3').addClass('my-4');
        content('h4').addClass('my-4');
        content('h5').addClass('my-4');
        content('h2').addClass('mt-5 mb-4');
        content('head').append(`<style>${cssContent.content}</style>`);
        content('body').wrapInner('<div style="width: 95%; margin:0 auto"></div>');
        content('img').each((index, element) => {
            const id = `${codeClient}-image-${index + 1}`;
            content(element).attr('id', id);
        });
        content('p:contains("pourraient naître à l\'occasion des présentes")').after('<div style="page-break-before: always;"></div>');
        content('h1:contains("PRELIMINAIRE A UNE VENTE EN L\’ETAT FUTUR D\’ACHEVEMENT")').after('<div style="page-break-before: always;"></div>');
// Sélectionner toutes les balises de tableau (éléments <table>)
        const tables = content('table');
        // Parcourir toutes les tables
        tables.each((index, element) => {
            const table = content(element);

            // // Ajouter la classe "toto" au paragraphe
            // precedingParagraph.addClass('toto');

            // Vérifier si le contenu de la table contient le mot "Monsieur"
            if (table.html().includes('Domicile') || table.html().includes('Régime choisi')) {

                // Ajouter un identifiant à la table
                table.attr('class', 'table-contact mb-4');
            }

            if (table.html().includes('OUI') && table.html().includes('NON')) {
                // Trouver le parent <div> de la balise <table>
                const parentDiv = table.parent('div');

                // Si le parent <div> est trouvé, sélectionner les balises <p> parmi ses frères
                if (parentDiv.length > 0) {
                    const precedingElements = parentDiv.prevAll();

                    // // Filtrer les éléments pour sélectionner uniquement les balises <p>
                    const precedingParagraph = precedingElements.filter('p').first();
                    precedingParagraph.addClass("mb-0")
                    // console.log(precedingParagraph)
                }
                // Ajouter un identifiant à la table
                table.attr('class', 'table-checkbox');
            }

            if (table.html().includes('Rappel du prix')) {
                // Ajouter un identifiant à la table
                table.attr('class', 'table-overall-cost mb-3');
            }
        });

        // Sélectionner les balises <p> contenant 'OUI' et 'NON'
        const ouiParagraph = content('p:contains("OUI")');
        const nonParagraph = content('p:contains("NON")');

        ouiParagraph.each((index, element) => {
            const paragraph = content(element);
            // Sélectionner l'élément 'img' précédent
            const previousImage = paragraph.prev('img');

            // Vérifier si l'élément 'img' précédent existe
            if (previousImage.length) {
                // Créer un conteneur <div> avec la classe 'wrapper'
                previousImage.addClass('form-check-input');
                const htmlFragment = '<div></div>';

// Charger le fragment HTML dans cheerio
                const $ = load(htmlFragment);

                const wrapper = $('<div>').addClass('checkbox');

                // Envelopper le paragraphe et l'élément 'img' précédent dans le conteneur
                previousImage.add(paragraph).wrapAll(wrapper);
            }



        });
        content('td:contains("**signature_")').parent().find('td p').css('color', 'white');
        nonParagraph.each((index, element) => {
            const paragraph = content(element);
            // Sélectionner l'élément 'img' précédent
            const previousImage = paragraph.prev('img');

            // Vérifier si l'élément 'img' précédent existe
            if (previousImage.length) {
                previousImage.addClass('form-check-input');
                const htmlFragment = '<div></div>';

// Charger le fragment HTML dans cheerio
                const $ = load(htmlFragment);

                // Créer un conteneur <div> avec la classe 'wrapper'
                const wrapper = $('<div>').addClass('checkbox');

                // Envelopper le paragraphe et l'élément 'img' précédent dans le conteneur
                previousImage.add(paragraph).wrapAll(wrapper);
            }
        });

        //Valorise la variable qui afffiche le contenu modifié
        setHtmlContent(content.html())

        return content.html();
    }

    const fetchAndConvertDocx = async (codeClient) => {


        var keyParam = urlParams.get('key');
        //Afficher le loader
        if(keyParam==='contract'){
            Swal.fire({
                title: 'Veuillez patienter',
                text: 'En cours de traitement...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

        }


        try {
            const response = await axios.get(`/admin/contrat-reservation/${id}`, {
                params: {
                    tag: 'contrat',
                },
                responseType: 'arraybuffer',  // Spécifiez le type de réponse comme tableau d'octets
            });

            const buffer = response.data;
            const result = await mammoth.convertToHtml({arrayBuffer: buffer});
            const rsp = await axios.get('/admin/file-get-content', {
                // Spécifiez le type de réponse comme tableau d'octets
            });
            addTableClassToHTML(result.value, rsp.data,codeClient);
            Swal.close();

        } catch (error) {

            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Erreur lors de la récupération ou de la conversion du fichier:', error,
            });
        }
    };

    return (
        <Layout>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7 mx-auto">
                            <Card  id={"card-pdf"}>
                                <Card.Header className="border-bottom d-flex justify-content-between align-items-center">
                                    <h2 className={'mb-0'}>Contrat de réservation</h2>
                                </Card.Header>
                                <Card.Body id={'reservation-contract'}>
                                    <Row>

                                    </Row>
                                    <div dangerouslySetInnerHTML={{__html: htmlContent}}></div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>


        </Layout>
    );
}

export default BuildContract;