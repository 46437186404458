import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import MaterialTable from "@material-table/core";
import {dateValueForRow} from "../../../component/DateValueForRow";
import axios from "axios";
import Swal from "sweetalert2";
import {Icon} from "@iconify/react/dist/iconify";
import FilterLog from "./FilterLog";

const LogApiTable  =({user,programmeId}) =>{

    const [logApiList, setLogApiList] = useState([])
    const handleShow = () => setShow(true);
    const [filter, setFilter] = useState(false)
    const [show, setShow] = useState(false);
    const [rowCount, setRowCount] = useState('Consultation des logs')

    useEffect(() => {

        getAllLogApi();

    }, [])

    const logLinkValue = rowData => {

        var elem = <span style={{cursor: 'pointer'}} onClick={() => {window.open(`/admin/log/api/${rowData.id}/voir`, '_blank');}} className={ 'link-primary'}
        >{rowData.id}</span>

        return elem;
    }

    const tableRef = React.useRef();

    const getStatusValueForRow = {

        1: <span className="label label-success">POST</span>,
        2: <span className="label label-primary">GET</span>, // just for example, remove it if you don't need
        3: <span className="label label-warning">PATCH</span>,
        4: <span className="label label-danger">DELETE</span>,
    };

    const getChannelValueForRow = {
        1: <span className="label label-default">programme</span>,
        2: <span className="label label-default">lot</span>,
        3: <span className="label label-default">media</span>,
        4: <span className="label label-default">contact</span>,
        5: <span className="label label-default">allotement</span>,
        6: <span className="label label-default">adf</span>,
        7: <span className="label label-default">adfparametre</span>,
        8: <span className="label label-default">adfMedia</span>,
        9: <span className="label label-default">reservation</span>,
        10: <span className="label label-default">tma</span>,
        11: <span className="label label-default">contactcomptes</span>,
        12: <span className="label label-default">options</span>,
        13: <span className="label label-default">environnement</span>,
        14: <span className="label label-default">adfecheanciers</span>,
        15: <span className="label label-default">comptes</span>,
        16: <span className="label label-default">webhook</span>,
        17: <span className="label label-default">reservationlot</span>,
        18: <span className="label label-default">facture</span>,
        19: <span className="label label-default">marché</span>,
        20: <span className="label label-default">réserve</span>,
    };

    const getHTTPCodeValueForRow = level => {

        var alertType ;

        if (level>=200 && level<400) {
            alertType = "success" ;
        }

         else if (level>=400 && level<500 )
        {alertType = "warning" }
         else if (level>=500)
        {
            alertType = "danger"
        }

        const alert = `label label-${alertType}`;



        var elem = <span className={alert}>{level}</span>;

        return elem;
    };


    const getAllLogApi = () => {


        axios.get('/admin/logs')
            .then(function (response) {

                if (response.data) {
                    setLogApiList(response.data);
                }
                else{

                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    return(

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div style={{maxWidth: '100%'}}>

                            <MaterialTable
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                        labelRowsSelect: 'lignes'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} log(s) sélectionnée(s)',
                                        searchPlaceholder: 'Chercher'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Aucun log à afficher',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                options={{
                                    pageSize: 25,
                                    selection:false,
                                    filtering: filter,
                                    addRowPosition: 'last', // Ajouter les nouvelles lignes en bas du tableau
                                }}
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}

                                columns={[
                                    {
                                        title: 'id',
                                        field: 'id',
                                        align: "left",
                                        width: "5%",
                                        render: rowData => logLinkValue(rowData)

                                    },
                                    {
                                        title: 'Date',
                                        field: 'date',
                                        align: "center",
                                        width: "20%",
                                        render: rowData => dateValueForRow(rowData.date)
                                    },


                                    {
                                        title: 'Canal',
                                        field: 'channel',
                                         render: rowData => getChannelValueForRow[rowData.channel]
                                    },
                                    {
                                        title: 'Méthode HTTP',
                                        field: 'httpMethod',
                                        align: "center",
                                        width: "10%",
                                        render: rowData => getStatusValueForRow[rowData.httpMethod]
                                    },
                                    {
                                        title: 'Identifiant',
                                        field: 'entityId',
                                        align: "center",
                                        width: "10%",
                                    },

                                    {
                                        title: 'Code HTTP',
                                        field: 'level',
                                        align: "center",
                                        render: rowData => getHTTPCodeValueForRow(rowData.level)
                                    },
                                    {
                                        title: 'Message',
                                        field: 'message',
                                        align: "center",
                                    },
                                    {
                                        title: 'Promoteur',
                                        field: 'promoteur'
                                        , width: "5%"
                                    },



                                ]}

                                tableRef={tableRef}
                                onSearchChange={() => {

                                    setRowCount('Liste log (' + tableRef.current.state.data.length + ')');
                                }}
                                data={logApiList}
                                title={rowCount}
                                actions={[

                                    {
                                        icon: () => <Icon icon="mi:filter"/>,
                                        onClick: (event) => {
                                            handleShow()
                                        },
                                        tooltip: 'Afficher le filtre',
                                        isFreeAction: true,

                                    },

                                ]
                                }

                            />
                        </div>
                    </div>
                </div>
                <FilterLog show={show} setShow={setShow} setLogApiList={setLogApiList}/>
            </div>

    )
}

export default LogApiTable;