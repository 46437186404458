import React, {useState, useEffect} from 'react';
import {ErrorMessage, FieldArray, Formik} from "formik";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Button, Form, ProgressBar} from 'react-bootstrap';
import CityAndZipAutocomplete from "./CityAutocomplete";
import axios from "axios";
import Select from "react-select";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import de la locale française
import ReCAPTCHA from 'react-google-recaptcha';
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";

const params = new URLSearchParams();
import Offcanvas from "react-bootstrap/Offcanvas";
import FormValidation from "./FormValidation";


const SellerForm = ({
                        values,
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                        optSuggestions,
                        handleRecaptchaChange,
                        role,
                        setInitialValues,
                        isValid,
                        isSubmitting,
                        step,
                        setStep,
                        validateForm,
                        handleNextStep,
                        setTouched
                    }) => {


    let navigate = useNavigate();
    const [countryList, setCountryList] = useState([])
    const initialSellerValues = {
        nom: '',
        prenom: '',
        email: '',
        phones: '',
        property: {
            address: '',
            ville: {nom: '', codePostal: ''},
            type: '',
            constructionDate: '',
            vacantProperty: '',
            totalSurface: '',
            pays: {label: "France", value: 'FR'},
        },
        role: 'ROLE_VENDEUR',
    };


    useEffect(() => {

    }, [values]);

    const handlePrevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    useEffect(() => {

    }, [step]);


    useEffect(() => {

        if (!role) {

            navigate({
                search: `?role=vendeur`
            });
            setInitialValues(initialSellerValues)
        }

    }, []);





    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getAllCountries();

    }, [])

    return (
        <div>

            {/*<p className={"w-100 mx-auto text-center mb-4"}>Vous envisagez de vendre votre maison, appartement ou terrain ? Remplissez notre formulaire*/}
            {/*en quelques minutes et recevez une évaluation gratuite et sans engagement. Notre équipe de*/}
            {/*professionnels vous contactera pour discuter de la meilleure stratégie de vente adaptée à votre*/}
            {/*bien. Faites le premier pas vers une vente réussie !</p>*/}
            <ProgressBar now={(step / 7) * 100} className="mb-4" style={{height: '10px'}}/>
            {step === 2 && (
                <div className={'mb-3'}>

                    <h5 className={'pb-3 bold'}>Veuillez saisir vos coordonnés</h5>

                    <Row>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.nom}
                                    name="nom"
                                    aria-label="nom"
                                    placeholder={"Nom"}
                                    type="text"/>
                                <ErrorMessage name={`nom`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    name="prenom"
                                    placeholder={"Prénom"}
                                    aria-label="prenom"
                                    value={values.prenom}
                                    type="text"/>
                                <ErrorMessage name={`prenom`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    aria-label="email"
                                    placeholder={"Email"}
                                    type="text"/>
                                <ErrorMessage name={`email`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.phone}
                                    name="phones"
                                    aria-label="phones"
                                    placeholder={"Téléphone"}
                                    type="text"/>
                                <ErrorMessage name={`phones`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>


                    </Row>
                    <Button  type="submit" disabled={!isValid} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}}
                        // disabled={files.length === 0}
                            className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}
            {step === 3 && (
                <div>
                    <h5 className={'pb-3 bold'}>Quel type de bien souhaitez-vous vendre ?</h5>

                    <div key={`inline-checkbox1`} className="mb-3">
                        <Form.Check
                            inline
                            label="Appartement"
                            name="property.type"
                            type={'checkbox'}
                            value={1}
                            onChange={handleChange}
                            id={`inline-checkbox-1`}
                        /></div>
                    <div key={`inline-checkbox3`} className="mb-3">
                        <Form.Check
                            inline
                            label="Maison"
                            name="property.type"
                            type={'checkbox'}
                            value={2}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox2`} className="mb-3">
                        <Form.Check
                            inline
                            label="Terrain"
                            name="property.type"
                            type={'checkbox'}
                            value={3}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox3`} className="mb-3">
                        <Form.Check
                            inline
                            label="Immeuble"
                            name="property.type"
                            type={'checkbox'}
                            value={4}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>


                    <div key={`inline-checkbox3`} className="mb-3">
                        <Form.Check
                            inline
                            label="Local commercial"
                            name="property.type"
                            type={'checkbox'}
                            value={5}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox3`} className="mb-3">
                        <Form.Check
                            inline
                            label="Entrepôt"
                            name="property.type"
                            type={'checkbox'}
                            value={6}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox3`} className="mb-3">
                        <Form.Check
                            inline
                            label="Hotel"
                            name="property.type"
                            type={'checkbox'}
                            value={7}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox4`} className="mb-3">
                        <Form.Check
                            inline
                            label="Autre"
                            name="property.type"
                            type={'checkbox'}
                            value={8}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <ErrorMessage name={`property.type`} component="div" className="error mt-1"/>
                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}

            {step === 4 && (
                <div>
                    <h5 className={'pb-3 bold'}>Quelle est l’adresse complète du bien svp ?</h5>

                    <Row>

                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.property.address}
                                    name="property.address"
                                    aria-label="property.address"
                                    placeholder={"Adresse"}
                                    type="text"/>
                                <ErrorMessage name={`property.address`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <CityAndZipAutocomplete setFieldValue={setFieldValue} values={values}/>

                        <Col>

                            {countryList.length > 0 ? (
                                <Form.Group className="mb-3">

                                    <Select
                                        options={countryList}
                                        onChange={newValue =>
                                            setFieldValue(`property.pays`, newValue)}
                                        name="property.pays"
                                        value={values.property.pays}
                                        defaultValue={{label: "France", value: 'FR'}}
                                    />

                                </Form.Group>
                            ) : (<></>)}


                        </Col>

                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" type="submit" onClick={() => {handleNextStep(validateForm,setTouched)}}
                        // disabled={!mediaType}
                            className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}
            {step === 5 && (
                <div>
                    <h5 className={'pb-3 bold'}>En quelle année a-til été construit ?</h5>

                    <Row>

                        <Col>
                            <Form.Group className="mb-3">
                                <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{
                                    shortDate: "d MMMM"
                                }} adapterLocale="fr">
                                    <Col xs={6}>

                                        <DatePicker
                                            name="property.constructionDate"
                                            format="DD-MM-YYYY"
                                            label="Date de construction"
                                            clearText="vider"
                                            cancelText="annuler"
                                            onChange={(value) => {
                                                setFieldValue('property.constructionDate', value.format('DD-MM-YYYY'));
                                            }}
                                            okText="ok"

                                        />
                                    </Col>


                                </LocalizationProvider>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}}
                        // disabled={!mediaType}
                            disabled={!isValid || isSubmitting} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}

            {step === 6 && (
                <div>
                    <h5 className={'pb-3 bold'}>Est-il actuellement libre de toute occupation ?</h5>

                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check

                            inline
                            label="Oui"
                            onChange={(value) => {
                                setFieldValue('property.vacantProperty', true);
                            }}
                            value={true}
                            name="property.vacantProperty"
                            type={'radio'}
                            id="ouiOption"
                            defaultChecked
                        /></div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Non"
                            value={false}
                            onChange={(value) => {
                                setFieldValue('property.vacantProperty', false);
                            }}
                            name="property.vacantProperty"
                            type={'radio'}
                            id="nonOption"
                        />
                    </div>


                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}}
                        // disabled={!mediaType}
                            disabled={!isValid} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}
            {step === 7 && (
                <div>
                    <h5 className={'pb-3 bold'}>Quelle est la surface totale du bien immobilier en mètres carrés ?</h5>

                    <Row>

                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.property.totalSurface}
                                    name="property.totalSurface"
                                    aria-label="totalSurface"
                                    placeholder={"Surface totale"}
                                    type="text"/>
                                <ErrorMessage name={`property.totalSurface`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button variant="primary" type="submit" onClick={() => {handleNextStep(validateForm,setTouched)}}
                        // disabled={!mediaType}
                            disabled={!isValid} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}

            {step === 8 && (
                <FormValidation handleRecaptchaChange={handleRecaptchaChange} optSuggestions={optSuggestions}
                                handleSubmit={handleSubmit} values={values} setFieldValue={setFieldValue}
                                handleChange={handleChange}/>
            )}

        </div>
    )
}

export default SellerForm;