import Table from 'react-bootstrap/Table';
import React, {useState, useEffect, useRef} from 'react';
import {getProgramLinkValueForRow} from "../service/ProgrammeService";
import {renderLotList} from "../utils/lots";
import {getReservationLinkValueForRow, getStatusValueForRow} from "../service/ReservationService";
import Card from "react-bootstrap/Card";
import {toFrDateString} from "../service/UtilService";

function DashboardReservationTab({filteredData}) {

    return (
<Card className={'p-4'}>
    <Card.Title className={'mb-3'}>
        <h6>Réservations</h6>
    </Card.Title>
        <Table id={'dashboard-reservation-tab'} bordered hover>
            <thead className={'table-light'}>
            <tr>
                <th>ID Réservation</th>
                <th>Date</th>
                <th>Lot</th>
                <th>Programme</th>
                <th>Statut</th>
            </tr>
            </thead>
            <tbody>
            {filteredData.map((reservation, index) => {
                return (

                    <tr key={index}>
                        <td><strong>{getReservationLinkValueForRow(reservation)}</strong></td>
                        <td>{toFrDateString(reservation.date)}</td>
                        <td>{renderLotList(reservation)}</td>
                        <td>{getProgramLinkValueForRow(reservation)}</td>
                        <td>{getStatusValueForRow(reservation)}</td>
                    </tr>
                )
            })}


            </tbody>
        </Table></Card>
    );
}

export default DashboardReservationTab;