import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import MaterialTable from "@material-table/core";
import {Icon} from '@iconify/react';
import Button from 'react-bootstrap/Button';
import {dateValueForRow} from "../../component/DateValueForRow";
import {convertFormatDate} from "../../component/ConvertFormatDate";
import ActionAddEdit from "./ActionAddEdit";
import {renderLotList} from "../../utils/lots"
import FilterActivity from "./component/FilterActivity";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function ActionList() {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [actionList, setActionList] = useState([])
    const [idAction, setIdAction] = useState(null)
    const [isAddMode, setIsAddMode] = useState(false);
    const [rowCount, setRowCount] = useState('Historique des actions')
    const [filter, setFilter] = useState(false)
    let navigate = useNavigate();
    const [filtered, setFiltered] = useState(false)
    const [initialValues, setInitialValues] = useState({
        programme: 0,
        type: [],
        contact:''
    });

    const getStatusValueForRow = {

        1: <div className={'text-center'}><span className="custom-badge bg-info"></span></div>,
        2: <div className={'text-center'}><span className="custom-badge bg-success"></span></div>, // just for example, remove it if you don't need
        3: <div className={'text-center'}><span className="custom-badge bg-warning"></span></div>,
        4: <div className={'text-center'}><span className="custom-badge bg-danger"></span></div>,
    };

    const handleChange = () => {

        setFilter(!filter)
    }



    const getTypeValueForRow = {
        1: <div className={'text-center'}><span className="label label-default">Option</span></div>,
        2: <div className={'text-center'}><span className="label label-default">Réservation</span></div>,
        3: <div className={'text-center'}><span className="label label-default">Téléphone</span></div>,
        4: <div className={'text-center'}><span className="label label-default">Email</span></div>,
        5: <div className={'text-center'}><span className="label label-default">Courrier</span></div>,
        6: <div className={'text-center'}><span className="label label-default">Rdv</span></div>,

    };

    const getProgramLinkValueForRow = rowData => {

        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/programme/${rowData.programId}/voir`)}}>{rowData.programme}</span>;

        return elem;
    };

    const renderContactList = rowData => {
        return (
            <>
                {rowData.contacts && rowData.contacts.length > 0 ? rowData.contacts.map((contact, index) => (
                    <Button key={index} variant="primary" border="success" className="me-1 mb-1" size="sm"><a className={"remove-link-color"} style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/contacts/${contact.id}/voir`)}}>{contact.name} {contact.firstname}</a></Button>
                )) : null}
            </>
        );
    };

    useEffect(() => {

        getAllActions();
    }, [initialValues])

    const tableRef = React.useRef();

    const getAllActions = () => {
        axios.get(`/admin/actions`)
            .then(function (response) {

                if (response.data) {
                    setActionList(response.data);

                }
            })
            .catch(function (error) {

                if(error.response.status='401'){

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }


    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1">
                                <div>
                                    <h1>Suivi</h1>
                                </div>
                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des actions</li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <Row className="container-fluid">
                    <Row>
                        <Col xs={12}>

                            <FilterActivity  initialValues={initialValues} setFiltered={setFiltered} setActionList={setActionList} actionList={actionList}/>
                        </Col>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <div style={{maxWidth: '100%'}}>

                                <MaterialTable
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} of {count}',
                                            labelRowsSelect: 'lignes'
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} action(s) sélectionnée(s)',
                                            searchPlaceholder: 'Chercher'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Aucune action à afficher',
                                            filterRow: {
                                                filterTooltip: 'Filter'
                                            }
                                        }
                                    }}
                                    options={{
                                        pageSize: 25,
                                        search:false,
                                        filtering: filter,
                                        selection: true,
                                        addRowPosition: 'last', // Ajouter les nouvelles lignes en bas du tableau
                                    }}
                                    columns={[
                                        {
                                            title: 'Id',
                                            field: 'id',
                                            align: "center",
                                        },
                                        {
                                            title: 'Date',
                                            field: 'date',
                                            initialEditValue: convertFormatDate(new Date(), 'numeric'),
                                            align: "right",
                                            width: "15%",
                                            render: rowData => dateValueForRow(rowData.date)
                                        },
                                        {
                                            title: 'Statut', field: 'alert',
                                            width: "10%",
                                            render: rowData => getStatusValueForRow[rowData.alert],
                                        },
                                        {
                                            title: 'Commentaire',
                                            field: 'comment',
                                            align: "left",
                                        },


                                        {
                                            title: 'Type', field: 'type',
                                            render: rowData => getTypeValueForRow[rowData.type],
                                        },

                                        {
                                            title: 'Programme',
                                            field: 'programme',
                                          render: rowData => getProgramLinkValueForRow(rowData)
                                        },
                                        {
                                            title: "Lots",
                                            field: 'label',
                                            type: 'date',
                                            render: rowData => renderLotList(rowData)
                                        },
                                        {
                                            title: "Contacts",
                                            field: 'label',
                                            type: 'date',
                                            render: rowData => renderContactList(rowData)
                                        },

                                    ]}

                                    tableRef={tableRef}
                                    onSearchChange={() => {

                                        setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                                    }}
                                    data={actionList}
                                    title={rowCount}
                                    actions={[

                                        {
                                            icon: () => <Icon icon="material-symbols-light:add-box-outline"/>,
                                            tooltip: 'Ajouter',
                                            onClick: (event) => {
                                                setIsAddMode(true);
                                                handleShow()
                                            },
                                            isFreeAction: true
                                        }
                                        ,
                                        {
                                            icon: () => <Icon icon="material-symbols:delete-outline"/>,
                                            tooltip: "Supprimer toutes les actions sélectionnées",
                                            onClick: (event, rows) =>

                                                Swal.fire({
                                                    icon: 'warning',
                                                    title: 'Confirmer la suppression des actions sélectionnées ?',
                                                    showConfirmButton: true,
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Annuler',
                                                    dangerMode: true,
                                                })
                                                    .then(result => {

                                                        if (result.isConfirmed) {

                                                            var ids = rows.map(s => s.id);
                                                            let idsToString = ids.toString();

                                                            axios.delete('/admin/actions?ids=' + idsToString)
                                                                .then(res => {

                                                                    getAllActions()

                                                                    if (rows.length == 1) {
                                                                        var message = "L'action a bien été supprimée"
                                                                    } else {
                                                                        var message = "Les actions ont bien été supprimées"
                                                                    }
                                                                    Swal.fire({
                                                                        title: "Fait!",
                                                                        text: message,
                                                                        icon: "success",
                                                                        timer: 2000,
                                                                        button: false
                                                                    })
                                                                })
                                                                .catch(function (error) {

                                                                        if (error.response.status === 401) {
                                                                            Swal.fire({
                                                                                icon: 'warning',
                                                                                title: 'Vous n\'êtes pas autorisé à supprimer cette action',
                                                                                showConfirmButton: false,
                                                                                timer: 1500
                                                                            })
                                                                        }

                                                                    }
                                                                );


                                                        } else {
                                                            Swal.fire('Votre action est préservée', '', 'info')
                                                        }
                                                    })
                                        },
                                        {
                                            icon: () => <Icon icon="fluent:edit-16-filled"/>,
                                            tooltip: "Modifier l'action",
                                            onClick: (event, rows) => {

                                                setIdAction(rows[0].id);
                                                setIsAddMode(false);
                                                handleShow()
                                            }
                                        }
                                        //
                                        //
                                        //
                                        //
                                        // }
                                    ]
                                    }
                                />
                            </div>
                        </div>
                    </Row>
                </Row>
            </section>

            <ActionAddEdit showAction={show} setShowAction={setShow} idAction={idAction} isAddMode={isAddMode} getAllActions={getAllActions}/>
        </Layout>
    );
}

export default ActionList;