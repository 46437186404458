import {ErrorMessage, FieldArray, Formik} from "formik";
import Form from "react-bootstrap/Form";
import {FormContext} from "../ReservationAddEdit";
import * as yup from "yup";
import React, {useState, useEffect, useContext} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {MultipleFileUpload} from "../../../component/MultipleFileUpload";
import LoanForm from "./LoanForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Loan() {

    const [isFinancementToogled, setIsFinancementToogled] = useState(true);
    const {
        activeStepIndex,
        setActiveStepIndex,
        formData,
        setFormData,
        medias,
        setMedias,
        prets,
        setPrets,
    } = useContext(FormContext);
    var [key, setKey] = useState('0');

    useEffect(() => {

    }, [key])

    const title = (index) => {
        var i = index + 1
        var title = index == 0 ? 'Prêt' : 'Prêt ' + i;

        return title;
    }

    const onIsLoiPinelChange = (setFieldValue, value) => {

        setFieldValue(`isLoiPinel`, value)
        // value === false ? setIsNotaryToogled(false) : setIsNotaryToogled(true)

    }

    const onConditionSuspensiveChange = (setFieldValue, value) => {

        setFieldValue(`isConditionSuspensive`, value)
        value === false ? setIsFinancementToogled(false) : setIsFinancementToogled(true)
    }

    // Schéma de validation pour chaque prêt
    const pretSchema = yup.object().shape({
        montant: yup.number()
            .when('$isConditionSuspensive', {
                is: true, // si conditionSuspensive est vrai
                then: yup.number().required('Le montant est requis.'),
                otherwise: yup.number().notRequired(),
            }),
        dureeMax: yup.number()
            .when('$isConditionSuspensive', {
                is: true, // si conditionSuspensive est vrai
                then: yup.number().required('La durée est requise.'),
                otherwise: yup.number().notRequired(),
            }),
        txInteretAnnuelMaxHorsAssurance: yup.number()
            .when('$isConditionSuspensive', {
                is: true, // si conditionSuspensive est vrai
                then: yup.number().required('Le taux d\'intérêt est requis.'),
                otherwise: yup.number().notRequired(),
            }),
        // Ajoutez d'autres champs ici si nécessaire
    });

    // Schéma de validation principal
    const ValidationSchema = yup.object().shape({
        isConditionSuspensive: yup.boolean().required(),
        prets: yup.array()
            .of(pretSchema)
            .when('isConditionSuspensive', {
                is: true,
                then: yup.array()
                    .of(pretSchema)
                    .required('Le tableau de prêts est requis lorsque la condition suspensive est vraie.')
                    .min(1, 'Au moins un prêt doit être renseigné lorsque la condition suspensive est vraie.'),
            }),
    });


    function handleSelect(key) {
        setKey(key)
    }

    return (
        <Formik
            initialValues={{
                medias: medias,
                prets: prets,
                isConditionSuspensive: true,
                apportPersonnel: 0,
                isLoiPinel:false,
            }}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {

                setPrets(values.prets);
                const data = {...formData, ...values};
                setFormData(data);
                setMedias(values.medias);
                setActiveStepIndex(activeStepIndex + 1);
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  isValid,
                  isSubmitting,
                  touched,
                  setFieldValue,
                  handleBlur, // handler for onBlur event of form elements
              }) => {

                return (

                    <Form className="flex flex-col justify-center items-center mt-4" noValidate onSubmit={handleSubmit}>
                        <div><h6>Plan de financement</h6>
                            <small>
                                Condition suspensive de l'obtention d'un prêt.</small>
                        </div>

                        <div key={`inline-checkbox`} className={'mt-4'}>
                            <Form.Label className={"me-2"}>Financement de l'achat par un emprunt
                                bancaire ?</Form.Label>

                            <Form.Check
                                inline
                                checked={values.isConditionSuspensive === true ? true : false}
                                type="radio"
                                value={true}
                                onChange={selection =>
                                    onConditionSuspensiveChange(setFieldValue, true)

                                }
                                name="isConditionSuspensive"
                                label="Oui"
                                id={`inline-radio-1`}
                            />
                            <Form.Check
                                inline
                                checked={values.isConditionSuspensive === false ? true : false}
                                value={false}
                                type="radio"
                                onChange={selection =>
                                    onConditionSuspensiveChange(setFieldValue, false)

                                }
                                name="isConditionSuspensive"
                                label="Non"
                                id={"inline-radio-2"}
                            />
                        </div>

                        {isFinancementToogled == false ? <></> :

                            <div>

                                <FieldArray name="prets">

                                    {({insert, remove, push}) => (
                                        <div>
                                            <div className={"d-flex align-items-center justify-content-between my-4"}>
                                                <div>
                                                    <Form.Label>Apport personnel</Form.Label>
                                                    <Form.Control


                                                        key={`apportPersonnel`}
                                                        name={`apportPersonnel`}
                                                        id={`apportPersonnel`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="text"
                                                        value={values.apportPersonnel}


                                                    />
                                                    <ErrorMessage name={`apportPersonnel`} component="div"
                                                                  className="error"/>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="math_symbol me-2"
                                                        onClick={() => push({
                                                            type: {label: 'Prêt amortissable', value: '1'},
                                                            montant: '',
                                                            montantMax: '',
                                                            dureeMax: '',
                                                            delaiDepotDossier: '',
                                                            delaiObtention: '',
                                                            txInteretAnnuelMaxHorsAssurance: ''
                                                        })}
                                                    >
                                                        +
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="math_symbol"
                                                        onClick={() => remove(key)}
                                                    >
                                                        -
                                                    </button>
                                                </div>

                                            </div>
                                            <Tabs
                                                defaultActiveKey="0"
                                                activeKey={key}
                                                className="mb-3"
                                                onSelect={handleSelect}
                                            >
                                                {values.prets.length > 0 ?
                                                    values.prets.map((pret, index) => (

                                                        <Tab
                                                            key={index}
                                                            eventKey={index}
                                                            title={title(index)}>

                                                            {
                                                                <LoanForm isValid={isValid} pret={pret} index={index}
                                                                          handleChange={handleChange}
                                                                          title={title(index)}
                                                                          setKey={setKey} setFieldValue={setFieldValue}
                                                                          values={values} errors={errors}
                                                                          touched={touched}
                                                                          handleBlur={handleBlur}/>
                                                            }


                                                        </Tab>

                                                    ))


                                                    : null}

                                            </Tabs>

                                        </div>
                                    )}
                                </FieldArray>
                            </div>
                        }
                        <hr className={'mt-5'}/>
                        <div className={'mb-4 mt-4'}><h6>Dispositif fiscal de réduction d’impôt </h6>
                            <small>
                                Veuillez indiquer si l'acquéreur bénéficie d'une réduction d'impôt. </small>
                        </div>
                        <Row className={'mb-3 mt-4'}>
                            <Col md={2}>
                                <Form.Label className={"me-2"}>Eligible à la réduction d'impôt Pinel </Form.Label>
                            </Col>
                            <Col md={6}>
                                <Form.Check
                                    inline
                                    checked={values.isLoiPinel === true ? true : false}
                                    type="radio"
                                    value={true}
                                    onChange={selection =>
                                        onIsLoiPinelChange(setFieldValue, true)

                                    }
                                    name="isLoiPinel"
                                    label="Oui"
                                    id={`inline-radio-1`}
                                />
                                <Form.Check
                                    inline
                                    checked={values.isLoiPinel === false ? true : false}
                                    value={false}
                                    type="radio"
                                    onChange={selection =>
                                        onIsLoiPinelChange(setFieldValue, false)

                                    }
                                    name="isLoiPinel"
                                    label="Non"
                                    id={"inline-radio-2"}
                                />
                            </Col>
                        </Row>

                        <div className="actions clearfix">
                            <button className="rounded-md font-medium text-white my-2 p-2 me-2"
                                    onClick={() => {
                                        setActiveStepIndex(activeStepIndex - 1)
                                    }}>
                                Précédent
                            </button>
                            <button
                                className="rounded-md font-medium text-white my-2 p-2"
                                type="submit"
                            >
                                Suivant
                            </button>
                        </div>
                    </Form>

                )
            }}

        </Formik>

    );
}

export default Loan;