// utils/tableUtils.js

import {convertToFloat, numberWithCommas} from "../utils/formatters"; // adapte le chemin

export function addTotalToState({
                                    data,
                                    byColumn,
                                    setTotal,
                                    setTotalGlobal = null,
                                    setTotalMntDG,
                                    dataTab = null,
                                    setDataTab = null,
                                    values=null
                                }) {

    console.log(data)
    //Additionne toutes les valeurs de la colonne salePrice ou mntDG dans le tableau data après les avoir converties en nombres sûrs.
    let total = data.reduce((accumulator, element) => accumulator + convertToFloat(element[byColumn]), 0);

    if (byColumn === "salePrice") {
        let additionalCosts=0;
        setTotal(total);

        if(values){
            const extra1 = convertToFloat(values.notaireFraisVente);
            const extra2 = convertToFloat(values.quotePart);
            const extra3 = convertToFloat(values.notaireFraisPret);

            if (extra1 !== 0) additionalCosts += extra1;
            if (extra2 !== 0) additionalCosts += extra2;
            if (extra3 !== 0) additionalCosts += extra3;
        }

        let totalAmount = total + additionalCosts;

        setTotalGlobal ? setTotalGlobal(numberWithCommas(totalAmount.toFixed(2))) : null;

        if (dataTab) {

            setDataTab(
                dataTab.map((row) =>
                    row.id === 1
                        ? {...row, amount: numberWithCommas(total.toFixed(2))}
                        : row
                )
            );
        }

    }

    if (byColumn === "mntDG") {
        setTotalMntDG(total);
    }
}
