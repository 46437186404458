import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import MaterialTable from "@material-table/core";
import ScrollWrapper from "../../utils/ScrollWrapper";
import {switchValue} from "../../component/SwitchValue";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from "react-bootstrap/Button";
import MeetingAddEdit from "../../component/MeetingAddEdit";
import AddOptionButton from "../../component/AddOptionButton";

function ProgramList({user,isFullSize}) {

    const [lotList, setLotList] = useState()
    const [filteredData, setFilteredData] = useState()
    const [filter, setFilter] = useState(false)
    const [programId, setProgramId] = useState('all')
    const [programList, setProgramList] = useState([])
    const [show, setShow] = useState(false);
    const [hideColumn, setHideColumn] = useState(false)
    const [hideAvailabilityColumn, setHideAvailabilityColumn] = useState(false)
    let navigate = useNavigate();

    const getProgramLinkValueForRow = rowData => {

        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
            navigate(`/admin/programme/${rowData.id}/voir`)
        }}>{rowData.label}</span>;

        return elem;
    };

    const progressBarForRow = progress => {
        var bgColor;

        if (progress > 0 && progress < 33) {
            bgColor = 'danger';
        } else if (progress > 32 && progress < 66) {
            bgColor = 'warning';
        } else if (progress > 65 && progress <= 100) {
            bgColor = 'success';
        }

        return <div className={'text-center'}><span className={'text-sm'}>{Math.floor(progress)} %</span><ProgressBar
            now={Math.floor(progress)} label={`${Math.floor(progress)}%`} variant={bgColor} visuallyHidden/></div>;
    };

    useEffect(() => {

        getProgrammeList();
    }, [])

    const handleShow = () => setShow(true);

    useEffect(() => {

        setFilteredData(programId === 'all' ? lotList : lotList.filter(dt => dt.programId === programId))

    }, [programId])

    const displayThumbnailAction = rowData => {

        var elem = <div className="prg-thumb">
            <img className={"thumbnail-list"} src={rowData.thumbnail?rowData.thumbnail:'/assets/img/provisoire.jpg'}/>
        </div>;

        return elem;
    };

    const tableRef = React.useRef();

    const getProgrammeList = () => {
        axios.get(`/admin/list/programmes`)
            .then(function (response) {

                setProgramList(response.data);
            })
            .catch(function (error) {

                if (error.response.status = '401') {

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1 d-flex">
                                <div>
                                    <h1>Programme</h1>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des programmes</li>
                                    </ol>
                                </div>


                                <div>

                                    {user.role !== "ROLE_FOURNISSEUR" || user.role === "ROLE_ACQUEREUR" && !user.isWhiteLabel || user.role === "ROLE_PROSPECT" && !user.isWhiteLabel ? <AddOptionButton user={user}/>  : null}
                                    {user.codeClient === '57004' || user.codeClient === '00003' && user.role !== "ROLE_PROMOTEUR" && user.role !== "ROLE_ADMIN" ?
                                        <Button variant="primary" border="success" className="me-1" size="sm"
                                                onClick={() => {
                                                    handleShow();
                                                    // setIsAddMode(true);
                                                }}
                                        >Prendre rendez-vous</Button> : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">
                            <ScrollWrapper isFullSize={isFullSize}>

                                <MaterialTable
                                    title={' '}
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} of {count}',
                                            labelRowsSelect: 'lignes'
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} lot(s) sélectionnée(s)',
                                            searchPlaceholder: 'Chercher'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Aucun programme à afficher',
                                            filterRow: {
                                                filterTooltip: 'Filter'
                                            }
                                        }
                                    }}

                                    options={{
                                        pageSize: 50,
                                        filtering: filter,
                                        searchFieldAlignment: 'left',
                                    }}
                                    columns={[
                                        {
                                            title: '',
                                            field: 'thumbnail',
                                            render: rowData => displayThumbnailAction(rowData)
                                        },
                                        {
                                            title: 'Libellé', field: 'label',
                                            filtering: false, render: rowData => getProgramLinkValueForRow(rowData)
                                        },
                                        {
                                            title: 'Région', field: 'region',
                                            filtering: false
                                        },
                                        {
                                            title: 'Ville', field: 'city',
                                            filtering: false
                                        },

                                        {
                                            title: "Prêt à taux zéro",
                                            field: 'PTZ',
                                            filtering: false,
                                            render: rowData => switchValue(rowData.PTZ),
                                            align: "center"
                                        },

                                        {
                                            title: 'Promoteur',
                                            field: 'promoteur',
                                            filtering: false,
                                            hidden: hideColumn
                                        }
                                        ,
                                        {
                                            title: 'Disponibilité',
                                            field: 'progress',
                                            filtering: false,
                                            width: "5%",
                                            render: rowData => progressBarForRow(rowData.progress),
                                            hidden: hideAvailabilityColumn
                                        }
                                    ]}

                                    tableRef={tableRef}
                                    onSearchChange={() => {

                                        // setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                                    }}
                                    data={programList}
                                />
                            </ScrollWrapper>


                        </div>
                    </div>

                </div>
            </section>
            {/*</form>*/}
            <MeetingAddEdit user={user} show={show} setShowAction={setShow} user={user}/>
        </Layout>
    );
}

export default ProgramList;