import React from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import {fetchMediaData} from "./FetchMediaData";


export function refreshMediaList(id,setDataFiles,setPreviousFiles) {

    axios.get(`/admin/reservations/${id}`)
        .then(function (response) {
            let reservation = response.data;
            if(reservation.medias){
                fetchMediaData(reservation.medias,setDataFiles,setPreviousFiles);

            }

        })
        .catch(function (error) {

            Swal.fire({
                icon: 'error',
                title: 'Une erreur s\'est produite!',
                showConfirmButton: false,
                timer: 1500
            })
        })
}