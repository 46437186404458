import React, { useContext, useEffect } from "react";
import { FormContext} from "../ReservationAddEdit";

function Stepper() {
    const { activeStepIndex } = useContext(FormContext);
    useEffect(() => {
        const stepperItems = document.querySelectorAll(".stepper-item");
        stepperItems.forEach((step, i) => {
            if (i <= activeStepIndex) {
                step.classList.add("bg-indigo-500", "text-white");
            } else {
                step.classList.remove("bg-indigo-500", "text-white");
            }
        });
    }, [activeStepIndex]);
    return (

        <div className="steps clearfix">
            <ul>
                <li key="0" className="stepper-item current nav-item bg-indigo-500"><a className="active nav-link"><span
                    className="number">1.</span> Réservataire</a></li>
                <li key="1" className="stepper-item nav-item"><a className="nav-link"><span className="number">2.</span> <span>Lots</span></a>
                </li>
                <li key="2" className="stepper-item nav-item"><a className="done nav-link"><span className="number">3.</span> Coût global</a></li>
                <li key="3" className="stepper-item nav-item"><a className="done nav-link"><span className="number">4.</span> Financement</a></li>
                <li key="4" className="stepper-item nav-item"><a className="done nav-link"><span className="number">4.</span> Contrat</a></li>
                <li key="5" className="stepper-item nav-item"><a className="done nav-link"><span className="number">5.</span> Validation</a>
                </li>
            </ul>
        </div>
    );
}

export default Stepper;