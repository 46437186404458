import React, {useState, useEffect, useRef} from 'react';
import MaterialTable from "@material-table/core";
import Tooltip from "@mui/material/Tooltip";
import {Icon} from '@iconify/react';
import Swal from "sweetalert2";
import axios from 'axios';
import {getMedias} from "../services/mediaService";
import {useParams} from "react-router-dom";

const MediaTab = ({medias,reservation,getStatusContract,setMediaList}) => {

    const [actionsTab, setActionsTab] = useState([])
    const [id, setId] = useState(useParams().id)

    useEffect(() => {
        actions()
    }, []);

    const getCreatedAtValueForRow = createdAt => {

        var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
            <time>{createdAt}</time>
        </div>;

        return elem;
    };

    const downloadPathAction = rowData => {

        var elem = <a className="btn btn-sm btn-default view_link" title="Télécharger"
                      href={rowData.downloadPath}
                      download><i className="fa fa-download" aria-hidden="true"></i></a>;

        return elem;
    };

    const displayThumbnailAction = rowData => {

        var elem = <div className="text-center">
            <a href={rowData.mediaPublicUrl} target="_blank"><img className={"thumbnail-list"} src={rowData.thumbnail}/></a>
        </div>;

        return elem;
    };


    const actions = () => {

        const actionsTab = [];

        if(reservation){
        actionsTab.push(               {
            icon: () => <Icon id='baseline-delete' icon="material-symbols:delete-outline"/>,
            tooltip: "Supprimer tous les médias sélectionnés",
            onClick: (event, rows) =>

                Swal.fire({
                    icon: 'warning',
                    title: 'Confirmation',
                    text: 'Confirmer la suppression des médias sélectionnées ?',
                    showConfirmButton: true,
                    showCancelButton: true,
                    cancelButtonText: 'Annuler',
                    dangerMode: true,
                })
                    .then(result => {

                        if (result.isConfirmed) {

                            var ids = rows.map(s => s.id);
                            let idsToString = ids.toString();

                            axios.delete(`/admin/galerie/${reservation.galerie}/medias?ids=` + idsToString)
                                .then(res => {

                                    getMedias(id,setMediaList)

                                    if (rows.length == 1) {
                                        var message = "Le média a bien été supprimé"
                                    } else {
                                        var message = "Les médias ont bien été supprimés"
                                    }
                                    getStatusContract();
                                    Swal.fire({
                                        title: "Fait!",
                                        text: message,
                                        icon: "success",
                                        timer: 2000,
                                        button: false
                                    })

                                })
                                .catch(function (error) {

                                        if (error.response.status === 401) {
                                            Swal.fire({
                                                icon: 'warning',
                                                title: 'Vous n\'êtes pas autorisé à supprimer ce média',
                                                showConfirmButton: false,
                                                timer: 1500
                                            })
                                        }

                                    }
                                );


                        }
                    })
        })
        }


        setActionsTab(actionsTab);
    };



    return (
        <div>

                <MaterialTable
                    title="Liste des documents"
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Aucun média disponible', // Texte personnalisé
                        },
                        toolbar: {
                            searchPlaceholder: 'Chercher',
                            nRowsSelected: '{0} média sélectionné ',
                        },
                    }}
                    options={{
                        selection: reservation?true:false,
                        pageSize: 6,
                    }}
                    columns={[
                        {
                            title: '',
                            field: 'thumbnail',
                            render: rowData => displayThumbnailAction(rowData),
                            width: "10%"
                        },
                        {
                            title: 'Ajouté le',
                            field: 'createdAt',
                            render: rowData => getCreatedAtValueForRow(rowData.createdAt)
                        },
                        {
                            title: 'Nom', field: 'name',
                            render: rowData => (
                                <Tooltip title={`Id: ${rowData.id}`} arrow>
                                    <span>{rowData.name}</span>
                                </Tooltip>
                            )
                        } ,
                        {
                            title: 'Téléchargement',
                            field: 'downloadPath',
                            render: rowData => downloadPathAction(rowData),
                            align: "center"
                        },
                        {title: 'Taille', field: 'size'},
                    ]}
                    data={medias}
                    actions={actionsTab}
                />

        </div>


    )
}

export default MediaTab;