import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const steps = ['Commercialisation', 'Début des travaux', 'Achèvement des travaux', 'Livraison'];

export default function HorizontalNonLinearStepper({ progress, setProgress }) {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});


    // Total number of steps
    const totalSteps = () => steps.length-1;

    // Effect to synchronize `completed` and `activeStep` with `progress`
    useEffect(() => {
        // Initialise les étapes terminées en fonction de la variable « progress »
        const initialCompleted = {};

        for (let i = 0; i < progress; i++) {
            initialCompleted[i] = true;
        }

        setCompleted(initialCompleted);
        // Set active step to the next step that is not completed
        setActiveStep(progress >= steps.length ? steps.length - 1 : progress);
    }, [progress]);

    // Compute the number of completed steps
    const completedSteps = () => Object.keys(completed).length;

    // Check if all steps are completed
    const allStepsCompleted = () => completedSteps() === totalSteps();

    // Handle step navigation
    const handleNext = () => {
        const newActiveStep =
            activeStep >= totalSteps() - 1 ? 0 : activeStep + 1; // Loop back to the start
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => Math.max(0, prevActiveStep - 1));
        setCompleted((prevCompleted) => ({
            ...prevCompleted,
            [activeStep]: true,
        }));

        // Update the progress prop to reflect the completed step
        setProgress(activeStep - 1);

    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        setCompleted((prevCompleted) => ({
            ...prevCompleted,
            [activeStep]: true,
        }));

        // Update the progress prop to reflect the completed step
        setProgress(activeStep + 1);

        handleNext();
    };

    const handleReset = () => {
        setCompleted({});
        setProgress(0);
        setActiveStep(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            {/*activeStep indique l'étape actuellement active*/}
            <Stepper nonLinear activeStep={activeStep}>
                {/*Itère sur le tableau steps pour créer dynamiquement une étape (<Step>) pour chaque élément.*/}
                {/*steps: Un tableau contenant les noms des étapes ou leurs libellés*/}
                {/*Chaque élément du tableau est transformé en un composant <Step>.*/}
                {/*Fournit une clé unique pour chaque étape, basée sur le libellé. */}
                {steps.map((label, index) => (
                    // Détermine si l'étape est marquée comme "terminée".
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit">
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            Toutes les étapes sont complétées !
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Réinitialiser</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Retour
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleComplete}>
                                {completedSteps() === totalSteps() - 1
                                    ? 'Terminer'
                                    : 'Terminer l\'étape'}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </div>
        </Box>
    );
}
