import React, {useState, useEffect} from 'react';
import {ErrorMessage} from "formik";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Button, Form, ProgressBar} from 'react-bootstrap';
import CityAndZipAutocomplete from "./CityAutocomplete";
import axios from "axios";
import Select from "react-select";
import 'dayjs/locale/fr'; // Import de la locale française
import {useNavigate} from "react-router-dom";
import FormValidation from "./FormValidation";



const ProspectForm = ({values,setFieldValue,handleChange,handleSubmit,optSuggestions,handleRecaptchaChange,role,setInitialValues,isValid,
                          isSubmitting,
                          step,
                          setStep,
                          validateForm,
                          handleNextStep,
                          setTouched}) => {


    const [countryList, setCountryList] = useState([])
    let navigate = useNavigate();

    const initialProspectValues = {
        nom: '',
        prenom: '',
        email: '',
        phones: '',
        societyName: '',
        property: {
            address: '',
            ville: {nom: '', codePostal: ''},
            works: '',
            totalSurface: '',
            pays: {label: "France", value: 'FR'},
        },
        role: 'ROLE_CONSOMMATEUR',
    };

    useEffect(() => {

    }, [isValid]);
    useEffect(() => {

        if (!role) {
            navigate({
                search: `?role=lead`
            });
            setInitialValues(initialProspectValues)
        }
    }, []);


    const handlePrevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };


    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getAllCountries();

    }, [])

    return (
        <div>
            <ProgressBar now={(step / 5) * 100} className="mb-4" style={{ height: '10px' }}/>
            {step === 2 && (
                <div className={'mb-3'}>
                    <h5 className={'pb-3 bold'}>Veuillez saisir vos coordonnés</h5>

                    <Row>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.nom}
                                    name="nom"
                                    aria-label= "nom"
                                    placeholder={"Nom"}
                                    type="text"/>
                                <ErrorMessage name={`nom`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    name="prenom"
                                    placeholder={"Prénom"}
                                    aria-label= "prenom"
                                    value={values.prenom}
                                    type="text"/>
                                <ErrorMessage name={`prenom`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    aria-label= "email"
                                    placeholder={"Email"}
                                    type="text"/>
                                <ErrorMessage name={`email`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.phone}
                                    name="phones"
                                    aria-label= "phones"
                                    placeholder={"Téléphone"}
                                    type="text"/>
                                <ErrorMessage name={`phones`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>
                        <Col xs={3}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.societyName}
                                    name="societyName"
                                    aria-label= "societyName"
                                    placeholder={"Entreprise"}
                                    type="text"/>
                                <ErrorMessage name={`societyName`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>


                    </Row>
                    <Button  disabled={!isValid } variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}
            {step === 3 && (
                <div>
                    <h5 className={'pb-3 bold'}>Quel genre de travaux souhaitez-vous réaliser ?</h5>

                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Maçonnerie"
                            name="property.works"
                            type={'checkbox'}
                            value={1}
                            onChange={handleChange}
                            id={`inline-checkbox-1`}
                        />   </div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Gros oeuvre"
                            name="property.works"
                            type={'checkbox'}
                            value={2}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Plâterie"
                            name="property.works"
                            type={'checkbox'}
                            value={3}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Electricité"
                            name="property.works"
                            type={'checkbox'}
                            value={4}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Peinture"
                            name="property.works"
                            type={'checkbox'}
                            value={5}
                            onChange={handleChange}
                            id={`inline-checkbox-1`}
                        />   </div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Menuiserie extérieure"
                            name="property.works"
                            type={'checkbox'}
                            value={6}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Menuiserie intérieure"
                            name="property.works"
                            type={'checkbox'}
                            value={7}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Aménagement extérieur"
                            name="property.works"
                            type={'checkbox'}
                            value={8}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Autre"
                            name="property.works"
                            type={'checkbox'}
                            value={9}
                            onChange={handleChange}
                            id={`inline-checkbox-2`}
                        />
                    </div>
                    <ErrorMessage name={`property.works`} component="div" className="error mt-1"/>
                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}

            {step === 4 && (
                <div>
                    <h5 className={'pb-3 bold'}>Quelle est l’adresse complète du bien svp ?</h5>

                    <Row>

                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.property.address}
                                    name="property.address"
                                    aria-label= "property.address"
                                    placeholder={"Adresse"}
                                    type="text"/>
                                <ErrorMessage name={`property.address`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <CityAndZipAutocomplete setFieldValue={setFieldValue} values={values}/>

                        <Col>

                            {countryList.length > 0 ? (
                                <Form.Group className="mb-3">

                                    <Select
                                        options={countryList}
                                        onChange={newValue =>
                                            setFieldValue(`pays`, newValue)}
                                        name="pays"
                                        value={values.pays}
                                        defaultValue={{label: "France", value: 'FR'}}
                                    />

                                </Form.Group>
                            ) : (<></>)}



                        </Col>

                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}
            {step === 5 && (
                <div>
                    <h5 className={'pb-3 bold'}>Quelle est la surface du bien ?</h5>

                    <Row>

                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.totalSurface}
                                    name="property.totalSurface"
                                    aria-label= "totalSurface"
                                    placeholder={"Surface totale"}
                                    type="text"/>
                                <ErrorMessage name={`property.totalSurface`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}



            {step === 6 && (
                <FormValidation handleRecaptchaChange={handleRecaptchaChange} optSuggestions={optSuggestions}
                                handleSubmit={handleSubmit} values={values} setFieldValue={setFieldValue}
                                handleChange={handleChange}/>
            )}

        </div>
    )
}

export default ProspectForm;