import React from 'react';
import {useNavigate} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import axios from "axios";
import Swal from "sweetalert2";
import Tooltip from '@mui/material/Tooltip';

// Fonction pour vérifier si tous les éléments d'un tableau sont identiques
export const areAllElementsIdentical = (array) => {
    return array.every((element) => element === array[0]);
};

// Fonction de validation personnalisée pour vérifier si productId est identique pour toutes les lignes sélectionnées
export const validateProgramId = (selectedRows) => {
    // Récupérer les productId des lignes sélectionnées
    const programIds = selectedRows.map((row) => row.programId);
    // Vérifier si tous les productId sont identiques
    const areProgramIdsIdentical = areAllElementsIdentical(programIds);

    return areProgramIdsIdentical;
};
export const renderLotList = rowData => {
    return (
        <>
            {rowData.lots && rowData.lots.length > 0 ? rowData.lots.map((lot, index) => (
                <Button key={index} variant="success" size="sm" className={"me-1 mb-1"}><a href={lot.link}
                                        className={'remove-link-color'}><Tooltip title={`nrCopro: ${lot.nrCopro}`} arrow>
                    <span>{lot.label}</span>
                </Tooltip></a></Button>
            )) : null}
        </>
    );
};

export const getLotList = (programId,selectedIds,setLotList,setFilteredData) => {

    axios.get(`/admin/users/programmes/${programId}/lots?selectedLotsIds=${selectedIds}`)
        .then(function (response) {

            setFilteredData(response.data.lotList);
            setLotList(response.data.lotList);
        })
        .catch(function (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: "Une erreur s'est produite!",
                text: error.response.data,
                showConfirmButton: false,
                timer: 4000
            })
        })
}

export function handleAddOption (selectedRows) {
    let navigate = useNavigate();
    if (!validateProgramId(selectedRows)) {
        // Empêcher la sélection si les productId ne sont pas identiques
        event.preventDefault();
        Swal.fire({
            icon: 'warning',
            title: "Information",
            text: "Les lots d'une option doivent appartenir au même programme",
            showConfirmButton: false,
            timer: 3000
        })
    } else {
        // Récupérer les identifiants des lignes sélectionnées
        const ids = selectedRows.map(row => row.id);
        navigate(`/admin/option/ajouter?selectedIds=${ids.join(',')}&programId=${selectedRows[0].programId}&prom=${selectedRows[0].promoteur}`)
    }







}
