import { useJsApiLoader } from '@react-google-maps/api';

function GoogleMapComponent() {

    const libraries = ['places', 'geometry'];
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBfJxYeGMtBnel2Sxu-bxRIfxKCukE_KuY',
        version: "3.58",
        libraries,
        language: 'fr',
        region: 'FR',
    });

    if (loadError) {
        console.error('Google Maps API could not be loaded:', loadError);
        return null;
    }


    return isLoaded;
}

export default GoogleMapComponent;
