import React from 'react';
import Swal from 'sweetalert2'
import LogApiList from "../page/Log/LogApiList";


// src/utils/familyUtils.js

// Fonction pour vérifier si tous les éléments d'un tableau sont identiques
export const areAllElementsIdentical = (array) => {
    return array.every((element) => element === array[0]);
};

// Fonction de validation personnalisée pour vérifier si programId est identique pour toutes les lignes sélectionnées
export const validateProgramId = (selectedRows) => {
    // Récupérer les programId des lignes sélectionnées
    const programIds = selectedRows.map((row) => row.programId);
    // Vérifier si tous les programId sont identiques
    const areProgramIdsIdentical = areAllElementsIdentical(programIds);

    return areProgramIdsIdentical;
};

export const validateLots = (lots) => {
    // Utilisation de la méthode some() pour vérifier si un produit a le statut 2
    return lots.some(lot => lot.status === 2)?false:true;
};

export function handleAddOption (selectedRows,user) {

    if(!selectedRows.length){

        Swal.fire({
            icon: 'warning',
            title: "Aucun lot sélectionné",
            text: "Veuillez sélectionner un ou plusieurs lots",
            showConfirmButton: false,
            timer: 3000
        })
    }
    else{

        if(validateLots(selectedRows))
        {

            if (!validateProgramId(selectedRows)) {
                // Empêcher la sélection si les programId ne sont pas identiques
                event.preventDefault();
                Swal.fire({
                    icon: 'warning',
                    title: "Information",
                    text: "Les lots d'une option doivent appartenir au même programme",
                    showConfirmButton: false,
                    timer: 3000
                })
            } else {
                // Récupérer les identifiants des lignes sélectionnées
                const ids = selectedRows.map(row => row.id);

                if(user.role==='ROLE_ADMIN' || user.role==='ROLE_PROMOTEUR'){
                    var url=`/admin/option/ajouter?selectedIds=${ids.join(',')}&programId=${selectedRows[0].programId}&prom=${selectedRows[0].promoteur}`;
                }
                else{
                    var url=`/admin/option/ajouter?selectedIds=${ids.join(',')}&programId=${selectedRows[0].programId}&prom=${selectedRows[0].promoteur}&user=${user.id}`;
                }

                return url;
            }
        }
        else{
            Swal.fire({
                icon: 'warning',
                title: "Lot indisponible",
                text: "Une option a déjà été posé pour ce lot.",
                showConfirmButton: false,
                timer: 3000
            })
        }

    }



}
