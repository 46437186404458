import React from 'react';
import {useNavigate} from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';


export function lotLinkValue(rowData,role) {

        let navigate = useNavigate();

        var elem = role == 'ROLE_PRESCRIPTEUR' ? rowData.status > 2 ? <span>{rowData.nrLot}</span> :
                <span  style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/lot/${rowData.id}/voir`)}} className={rowData.hasOwnProperty('parentId') ? 'link-primary ps-4' : 'link-primary'}
                   >
                     <Tooltip title={`id: ${rowData.id}`} arrow>
              <span>{rowData.nrLot}</span>
            </Tooltip>

                </span> :
            <span style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/lot/${rowData.id}/voir`)}} className={rowData.hasOwnProperty('parentId') ? 'link-primary ps-4' : 'link-primary'}
               >         <Tooltip title={`id: ${rowData.id}`} arrow>
              <span>{rowData.nrLot}</span>
            </Tooltip></span>

        return elem;
}

