import React, {useState, useEffect, useRef} from 'react';
import {Icon} from '@iconify/react';
import {Link, NavLink} from 'react-router-dom';
import Col from "react-bootstrap/Col";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {useWindowSize} from 'react-use';

const MenuSidebar = ({user, isFullSize}) => {

    const {width} = useWindowSize();
    const [minHeight, setMinHeight] = useState(window.innerHeight);
    // Mettez à jour la hauteur minimale lorsque la fenêtre est redimensionnée


    useEffect(() => {

    }, [isFullSize]);

    useEffect(() => {

        const handleResize = () => {
            setMinHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const contentRef = useRef(null);


    // Créez des tableaux pour chaque type d'utilisateur
    const menuItems = [
        {
            groupName: 'Navigation',
            roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_FOURNISSEUR', 'ROLE_ACQUEREUR', 'ROLE_ADMIN', 'ROLE_PROSPECT'],
            items: [
                {
                    react: true,
                    label: 'Tableau de bord',
                    url: '/admin/tableau-de-bord',
                    icon: "tdesign:dashboard",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_FOURNISSEUR', 'ROLE_ACQUEREUR', 'ROLE_ADMIN', 'ROLE_PROSPECT']
                }
            ],
        },
        {
            groupName: 'Biens',
            roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_FOURNISSEUR', 'ROLE_ACQUEREUR', 'ROLE_ADMIN', 'ROLE_PROSPECT'],
            items: [
                // {
                //     label: 'Lots',
                //     url: '/admin/lots/list',
                //     icon: "uil:create-dashboard",
                //     roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_FOURNISSEUR', 'ROLE_ACQUEREUR', 'ROLE_ADMIN', 'ROLE_PROSPECT'],
                // },
                {
                    label: 'Programmes',
                    url: '/admin/programmes/list',
                    icon: "ph:frame-corners",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_FOURNISSEUR', 'ROLE_ACQUEREUR', 'ROLE_ADMIN', 'ROLE_PROSPECT'],
                    react: true,
                },
                // Ajoutez d'autres éléments de menu pour les utilisateurs
            ],
        },
        {
            groupName: 'Transactions',
            roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_ACQUEREUR', 'ROLE_ADMIN', 'ROLE_PROSPECT'],
            items: [
                {
                    label: 'Options',
                    url: '/admin/option/liste',
                    icon: "gg:check-r",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_ADMIN', 'ROLE_PROSPECT','ROLE_ACQUEREUR']
                },
                {
                    label: "Réservations",
                    url: '/admin/reservation/list',
                    icon: "material-symbols:in-home-mode-outline",
                    roles: user.deniedResources && user.deniedResources.includes('RESERVATION') ? ['ROLE_PROMOTEUR', 'ROLE_ACQUEREUR', 'ROLE_ADMIN'] : ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_ACQUEREUR', 'ROLE_ADMIN']
                },
                // Ajoutez d'autres éléments de menu pour les invités
            ],
        },
        {
            groupName: 'Intervenants',
            roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_ADMIN'],
            items: [
                {
                    label: 'Contacts',
                    url: '/admin/contact/list',
                    icon: 'uil:users-alt',
                    roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_ADMIN']
                },
                {
                    label: "Collaborateurs",
                    url: '/admin/collaborateur/list',
                    icon: "ant-design:user-add-outlined",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_ADMIN']
                },
                // Ajoutez d'autres éléments de menu pour les invités
            ],
        },
        {
            groupName: 'Marketing',
            roles: ['ROLE_PROMOTEUR', 'ROLE_ADMIN'],
            items: [
                {
                    label: 'Campagnes',
                    url: '/admin/campagnes/liste',
                    icon: "uil:create-dashboard",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_ADMIN'],
                },
            ],
        },
        {
            groupName: 'Journalisation',
            roles: ['ROLE_ADMIN', 'ROLE_PROMOTEUR', 'ROLE_API_CONSUMER'],
            items: [
                {
                    label: 'API',
                    url: '/admin/log/api/liste',
                    icon: "ant-design:api-outlined",
                    roles: ['ROLE_ADMIN', 'ROLE_PROMOTEUR', 'ROLE_API_CONSUMER']
                },
                {label: "Mail", url: '/register', icon: "solar:logout-3-outline", roles: ['ROLE_ADMIN']},
                // Ajoutez d'autres éléments de menu pour les invités
            ],
        },
        {
            groupName: 'Intéractions',
            roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_ADMIN', 'ROLE_ACQUEREUR', 'ROLE_FOURNISSEUR', 'ROLE_PROSPECT'],
            items: [
                // {label: 'Agenda', url: '/login', icon: 'ion:calendar-outline', roles: ['ROLE_PROMOTEUR','ROLE_PRESCRIPTEUR']},
                {
                    label: "Tâches",
                    url: '/admin/tache/liste',
                    icon: "fluent:task-list-square-16-regular",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_ADMIN']
                },
                {
                    label: 'Suivis',
                    url: '/admin/suivis/liste',
                    icon: 'ic:outline-calendar-today',
                    roles: ['ROLE_PRESCRIPTEUR', 'ROLE_ADMIN']
                },
                // {label: 'Agenda', url: '/admin/reunion/liste', icon: 'fluent-mdl2:search-calendar', roles: ['ROLE_PRESCRIPTEUR','ROLE_ACQUEREUR','ROLE_FOURNISSEUR','ROLE_PROSPECT']},
                {
                    label: 'Courriels',
                    url: '/admin/courriel/liste?mbox=inbox',
                    icon: 'fluent:mail-16-regular',
                    roles: ['ROLE_ADMIN', 'ROLE_ADMIN']
                },
                // Ajoutez d'autres éléments de menu pour les invités
            ],
        },
        {
            groupName: user.role == 'ROLE_PROMOTEUR' ? 'Fournisseurs' : 'Relevés',
            roles: ['ROLE_PROMOTEUR', 'ROLE_FOURNISSEUR', 'ROLE_ADMIN'],
            items: [
                {
                    label: 'Factures',
                    url: '/admin/facture/liste',
                    icon: 'ion:calendar-outline',
                    roles: ['ROLE_PROMOTEUR', 'ROLE_FOURNISSEUR', 'ROLE_ADMIN']
                },
                {
                    label: "Marchés",
                    url: '/admin/marche/liste',
                    icon: "fluent:task-list-square-16-regular",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_FOURNISSEUR', 'ROLE_ADMIN']
                },
                {
                    label: "Réserves",
                    url: '/admin/reserve/liste',
                    icon: "ri:menu-search-line",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_FOURNISSEUR', 'ROLE_ADMIN']
                },
                // Ajoutez d'autres éléments de menu pour les invités
            ],
        },
        {
            groupName: user.role == 'ROLE_PROMOTEUR' ? 'Acquéreurs' : 'Echéanciers',
            roles: ['ROLE_PROMOTEUR', 'ROLE_ACQUEREUR', 'ROLE_ADMIN'],
            items: [
                {
                    label: 'Appel de fonds',
                    url: '/admin/adf/list',
                    icon: "ph:bank",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_ACQUEREUR', 'ROLE_ADMIN']
                },
                {
                    label: 'Tma',
                    url: '/admin/tma/liste',
                    icon: "carbon:tools-alt",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_ACQUEREUR', 'ROLE_FOURNISSEUR', 'ROLE_ADMIN']
                },
                // Ajoutez d'autres éléments de menu pour les invités
            ],
        },
        {
            groupName: 'Document',
            roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_ACQUEREUR', 'ROLE_FOURNISSEUR', 'ROLE_ADMIN'],
            items: [
                {
                    label: 'Médias',
                    url: '/admin/media/liste',
                    icon: "material-symbols:perm-media-outline",
                    roles: ['ROLE_PROMOTEUR', 'ROLE_PRESCRIPTEUR', 'ROLE_ACQUEREUR', 'ROLE_FOURNISSEUR', 'ROLE_ADMIN']
                },
                // Ajoutez d'autres éléments de menu pour les invités
            ],
        },
    ];


    // Filtrer les groupes et éléments du menu en fonction du type d'utilisateur
    const filteredGroups = menuItems
        .filter(group => group.roles.includes(user.role) && group.items.some(item => item.roles.includes(user.role)))
        .map(group => ({
            ...group,
            items: group.items.filter(item => item.roles.includes(user.role)),
        }));

    return (
        <Col xs={2}
             className={isFullSize ? 'vertical-menu sidebar-full-size' : 'vertical-menu sidebar-compact'}>
            <div className={"vertical-menu-items"}>
                <div className={"navbar-brand-box"}>
        <span className={width < 993 || !isFullSize ? 'logo-sm' : 'logo-lg'}>
            <a href="" className={width < 993 || !isFullSize ? '' : 'logo'}>
            <img
                src={width < 993 || !isFullSize ? user.logoSm : user.logo}
                height="60"
                className="d-inline-block align-top ps-2 logo-lg"
                alt="Logo Viteimmo"
            /></a>

        </span>
                </div>
                <div style={{ height: 'calc(100vh - 70px)'}} >
                    <SimpleBar ref={contentRef} style={isFullSize ? {height: '100%'} : {}} className={'sidebar'}
                               forceVisible="y" autoHide={false}>
                        <ul className={"sidebar-menu dashboard-item"}>

                            {isFullSize ? filteredGroups.map((group, groupIndex) => (
                                <li key={groupIndex} className={'treeview'}>
                                    <a href="#">
                                        <span>{group.groupName}</span></a>
                                    <ul className={"treeview-menu menu_level_1"}>
                                        {group.items.map((item, index) => (
                                            <li key={index}>
                                                <NavLink to={item.url} className={({isActive, isPending}) =>
                                                    isPending ? "pending" : isActive ? "active" : ""
                                                }>

                                                    <Icon className="uil"
                                                          height={item.icon === 'fluent:task-list-square-16-regular' ? '23px' : '20px'}
                                                          width={item.icon === 'fluent:task-list-square-16-regular' ? '23px' : '20px'}
                                                          icon={item.icon}/>
                                                    <span>{item.label}</span>
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            )) : filteredGroups.map((group, groupIndex) => (


                                group.items.map((item, index) => (
                                    <li key={groupIndex} className={'treeview'}>
                                        <Link to={item.url}>
                                            <Icon className="uil" height="20px" width="20px" icon={item.icon}/>
                                            <span>{item.label} </span>
                                        </Link>
                                    </li>
                                ))

                            ))}
                            {}</ul>

                    </SimpleBar>
                </div>
            </div>
        </Col>
    );
};

export default MenuSidebar;