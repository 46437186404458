import React, {createContext, useState, useEffect} from 'react';
import Layout from "../../component/Layout"
import Card from 'react-bootstrap/Card';
import OptionStepper from "./component/OptionStepper";
import OptionStep from "./component/OptionStep";
export const FormContext = createContext();
import {useNavigate, useParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Icon} from "@iconify/react/dist/iconify";
import ReservationHome from "../Reservation/ReservationHome";
import BuildContract from "../Reservation/BuildContract";
import {fetchIsContractAvailable} from "../../component/FetchIsContractAvailable";
import axios from "axios";
import Swal from "sweetalert2";

import useUrlParams from "../../hook/useUrlParams";

function OptionAddEdit({user}) {

    let navigate = useNavigate();
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [formData, setFormData] = useState({});
    const [id, setId] = useState(useParams().id)
    const [isAddMode, setIsAddMode] = useState(!id)
    const [isPdfContractAvailable, setIsPdfContractAvailable] = useState(false);
    var [key, setKey] = useState('home');

    const [buyers, setBuyers] = useState([
        {
            typeOfPerson: {label: 'Physique', value: 0},
            familySituation: {label: 'Célibataire', value: 0},
            nationality: {label: 'Française', value: null},
            society: {siretNumber:'',cityRCSRegistration:'',nom:'',actingAs:''},
            id:'',
            civilite: {label: 'Monsieur', value: 1},
            nom: '',
            prenom: '',
            address: '',
            zip: '',
            city: '',
            role: 'ROLE_PROSPECT',
            pays: {label: 'France', value: 'FR'},
            email: '',
            cellphone: '',
            phone: '',
            profession:'',
            birthDate:''
        }
    ]);

    const [programId, setProgramId] = useState(0)
    const [codeClient, setCodeClient] = useState('')
    const [nbrJrVldOpt, setNbrJrVldOpt] = useState('')
    const [filteredData, setFilteredData] = useState();
    const [isContractAvailable, setIsContractAvailable] = useState(false);
    const [lotList, setLotList] = useState([]);
    const [medias, setMedias] = useState([])
    const [selectedLots, setSelectedLots] = useState([])
    const [dataFiles, setDataFiles] = useState([]);
    const [previousFiles, setPreviousFiles] = useState([]);
    const [sideBarTab, setSideBarTab] = useState({
            program: '-',
            email: '-',
            nom: '-',
            prenom: '-',
            societe: null,
            cellphone: '-',
            lots: [{}]
        }
    );

    useEffect(() => {

    }, [buyers]);

    const getPromoteur= (idPromoteur) => {
        axios.get(`/admin/users/${idPromoteur}`)
            .then(function (response) {
                setNbrJrVldOpt(response.data.nbrJrVldOpt);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    // Utiliser le hook personnalisé
    useUrlParams({
        user,
        setProgramId,
        setLotList,
        setFilteredData,
        getPromoteur,
        setBuyers
    });

    function handleSelect(key) {
        setKey(key)
    }

    async function getStatusContract() {
        try {
            const result = await fetchIsContractAvailable(id, 'contrat');

            if (result.status === 200) {

                setIsContractAvailable(true);
            } else if (result.status === 204) {
                // L'élément n'a pas été trouvé, traiter l'indisponibilité
                console.log("Aucun contrat trouvé");
            }
            // Faites quelque chose avec les données utilisateur
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la récupération des données utilisateur :', error);
            // Gérez l'erreur de manière appropriée
        }
    }

    useEffect(() => {
        if (!isAddMode) {
        getStatusContract()
        }

    }, [sideBarTab])

    const renderTabsSwitch = () => {

        return <Tabs
            defaultActiveKey="home"
            activeKey={key}
            onSelect={handleSelect}
            id="uncontrolled-tab-example"
            className="mb-3"
        ><Tab eventKey="home" title={<span><Icon icon="cil:description"/>{" Caractéristiques"}</span>}>
            <ReservationHome setIsPdfContractAvailable={setIsPdfContractAvailable} setPreviousFiles={setPreviousFiles} previousFiles={previousFiles}
                             handleSelect={handleSelect} setIsContractAvailable={setIsContractAvailable}
                             dataFiles={dataFiles} setDataFiles={setDataFiles} id={id}/>
        </Tab>
            {isContractAvailable ? <Tab eventKey="contract" title={<span><Icon icon="uil:file"/>{" Contrat"}</span>}>
                <BuildContract handleSelect={handleSelect} setIsPdfContractAvailable={setIsPdfContractAvailable} isPdfContractAvailable={isPdfContractAvailable} id={id} setDataFiles={setDataFiles}
                               setPreviousFiles={setPreviousFiles}/>
            </Tab> : null}

        </Tabs>
            ;
    }

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1">
                                <div>
                                    <h1>Option</h1>
                                </div>

                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
                                            navigate(`/admin/option/liste`)
                                        }}>
                                            Liste des options
                                        </li>
                                        <li className="breadcrumb-item active ">{isAddMode ? "Ajouter une option" : "Editer une option"}</li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    {isAddMode ?
                        <Row>
                            <Row className="justify-content-center mb-5">
                            <Col xl={12} >
                                <Card className={"h-100"}>
                                    <Card.Body>
                                        <FormContext.Provider
                                            value={{
                                                activeStepIndex,
                                                setActiveStepIndex,
                                                formData,
                                                setFormData,
                                                buyers,
                                                setBuyers,
                                                medias,
                                                setMedias,
                                                sideBarTab,
                                                setSideBarTab,
                                                programId,
                                                setProgramId,
                                                filteredData,
                                                setFilteredData,
                                                lotList,
                                                setLotList,
                                                selectedLots,
                                                setSelectedLots,
                                                dataFiles,
                                                setDataFiles,
                                                isAddMode,
                                                setIsAddMode,
                                                codeClient,
                                                setCodeClient,
                                                nbrJrVldOpt,
                                                setNbrJrVldOpt

                                            }}>
                                            <div className="wizard orange">
                                                <OptionStepper/>
                                                <OptionStep user={user}/>
                                            </div>
                                        </FormContext.Provider>
                                    </Card.Body>
                                </Card>
                            </Col>  </Row>
                        </Row>
                        :
                        <Row>
                            {renderTabsSwitch()}
                        </Row>


                    }
                </div>
            </section>
            {/*</form>*/}

        </Layout>
    );
}

export default OptionAddEdit;