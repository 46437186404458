import React, {useState, useEffect} from 'react';
import Layout from "../../component/Layout"
import CampaignTable from "./component/CampaignTable";
import CampaignAddEdit from "./component/CampaignAddEdit";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";

function CampaignList({user, isFullSize}) {

    const [show, setShow] = useState(false);
    const [id, setId] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [isAddMode, setIsAddMode] = useState(false);
    const [campaignList, setCampaignList] = useState([])
    const handleShow = () => setShow(true);


    useEffect(() => {

        getAllCampaigns();
    }, [])

    // Ajoutez un interceptor pour afficher le loader avant chaque requête
    axios.interceptors.request.use(config => {
        Swal.fire({
            title: 'Veuillez patienter...',
            allowOutsideClick: () => !Swal.isLoading(),
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        return config;
    }, error => {
        return Promise.reject(error);
    });

    // Ajoutez un interceptor pour fermer le loader après chaque réponse
    axios.interceptors.response.use(response => {
        Swal.close();
        return response;
    }, error => {
        Swal.close();
        return Promise.reject(error);
    });


    const getAllCampaigns = () => {
        var url = `/mailchimp/campaign`;

        axios.get(url)
            .then(function (response) {

                if (response.data) {
                    setCampaignList(response.data);

                }
            })
            .catch(function (error) {

                if (error.response.status = '401') {
                    setDisabled(true);
                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 3000
                    })

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">
                            <div className="navbar navbar-default p-1 d-flex">
                                <div>
                                    <h1>Campagnes</h1>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des campagnes</li>
                                    </ol>
                                </div>
                                <div>
                                    <Button disabled={disabled} variant="primary" border="success" className="me-1" size="sm"
                                            onClick={() => {
                                                handleShow();
                                                setIsAddMode(true);
                                            }}>Créer une
                                        campagne </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'content'}>
                <CampaignTable isFullSize={isFullSize} user={user} campaignList={campaignList}
                               getAllCampaigns={getAllCampaigns} setIsAddMode={setIsAddMode} setShow={setShow}
                               setId={setId}/>
            </section>
            <CampaignAddEdit show={show} setShow={setShow} isAddMode={isAddMode} getAllCampaigns={getAllCampaigns}
                             setIsAddMode={setIsAddMode} id={id}/>
        </Layout>
    );
}

export default CampaignList;