import {Formik, useFormikContext} from 'formik';
import * as Yup from "yup";
import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import Form from 'react-bootstrap/Form';

const Filter = ({setLogApiList}) => {

    const {setFieldValue, values} = useFormikContext();
    const optChannel = [
        {label: "adf", value: 6},
        {label: "adfecheanciers", value: 14},
        {label: "adfMedia", value: 8},
        {label: "adfparametre", value: 7},
        {label: "allotement", value: 5},
        {label: "comptes", value: 15},
        {label: "contact", value: 4},
        {label: "contactcomptes", value: 11},
        {label: "environnement", value: 13},
        {label: "lot", value: 2},
        {label: "media", value: 3},
        {label: "options", value: 12},
        {label: "programme", value: 1},
        {label: "reservation", value: 9},
        {label: "reservationlot", value: 17},
        {label: "tma", value: 10},
        {label: "webhook", value: 16},
    ];

    const optHttpMethod = [
        {label: "POST", value: 1},
        {label: "GET", value: 2},
        {label: "PATCH", value: 3},
        {label: "DELETE", value: 4},
    ];

    const optHttpCode = [
        {label: "200", value: 200},
        {label: "201", value: 201},
        {label: "204", value: 204},
        {label: "400", value: 400},
        {label: "401", value: 401},
        {label: "404", value: 404},
        {label: "500", value: 500},
    ];

    function setFilteredData(params) {

        axios.get(`/admin/logs`, {params})
            .then(function (response) {
                setLogApiList(response.data);

            });
    }

    useEffect(() => {

        const fields = {...values};
        fields.channel = fields.channel.value;
        fields.httpMethod = fields.httpMethod.value;
        fields.level = fields.level.value;
        setFilteredData(fields);

    }, [values]);

    return (
        <div>
            <Form.Group className="mb-3 mt-4" >
                <h5>Canal</h5>
                <hr/>

                <Select
                    placeholder={<div></div>}
                    options={optChannel}
                    onChange={(value) => {
                        setFieldValue('channel', value, false);
                    }}
                    name="channel"
                />

            </Form.Group>
            <Form.Group className="mb-3 mt-4" >
                <h5>Méthode HTTP</h5>
                <hr/>
            <Select
                placeholder={<div></div>}
                options={optHttpMethod}
                onChange={(value) => {
                    setFieldValue('httpMethod', value, false);
                }}
                name="httpMethod"
            />
            </Form.Group>
                <Form.Group className="mb-3 mt-4" >
                    <h5>Code HTTP</h5>
                    <hr/>
                    <Select
                        placeholder={<div></div>}
                        options={optHttpCode}
                        onChange={(value) => {
                            setFieldValue('level', value, false);
                        }}
                        name="level"
                    />
        </Form.Group>
        </div>
    )
}

const FilterLog = ({show, setShow, setLogApiList}) => {

    const [property, setProperty] = useState('');
    const defaultValue = (options, value) => {

        return phoneTypeOptions ? phoneTypeOptions.find(option => option.value === value) : "";
    };

    const validationSchema = Yup.object().shape({});

    function handleClose() {

        setShow(false);
    }

    // const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton className={"border-bottom bg-dark"}>
                <Offcanvas.Title className={"text-white"}>Filtre</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className={"px-4"}>
                <Formik
                    initialValues={{
                        channel: '',
                        httpMethod:'',
                        level:''
                        ,
                    }}
                    validationSchema={validationSchema}
                >
                    {({}) => {

                        return (

                            <Form noValidate>

                                <Filter setLogApiList={setLogApiList} show={show}
                                />
                            </Form>)
                    }}
                </Formik>
            </Offcanvas.Body>
        </Offcanvas>

    );
}

export default FilterLog;