import {useParams} from 'react-router-dom';
import {Formik, useFormikContext} from 'formik';
import * as Yup from "yup";
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import Select from "react-select";
import {InputGroup,Col,Row,Form,Card} from "react-bootstrap";



const Filter = ({fetchContacts, setInitialValues}) => {

    const {setFieldValue, values, submitForm, handleChange,dirty } = useFormikContext();
    const [programList, setProgramList] = useState([])
    const [optionsFilter, setOptionsFilter] = useState([])

    React.useEffect(() => {

        getProgrammeList();
        dirty?fetchContacts(values):null;

    }, [values, submitForm]);

    const getProgrammeList = () => {
        axios.get(`/admin/programmes`)
            .then(function (response) {

                setProgramList(response.data);


            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const customStyles = {
        control: base => ({
            ...base,
            height: 35,
            minHeight: 35
        })
    };

    const getFilterList = () => {

        return axios.get(`/api/search-preferences`)
            .then(function (response) {

                const searchPreferences=response.data;
                return searchPreferences?searchPreferences:[];
            })
            .catch(function (error) {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const roleList = [
        {value: 'all', label: 'Tous les contacts'},
        {value: 'ROLE_PROSPECT', label: 'Prospect'},
        {value: 'ROLE_ACQUEREUR', label: 'Acquereur'},
        {value: 'ROLE_PRESCRIPTEUR', label: 'Prescripteur'},

    ]

    useEffect(() => {
        getFilterList()
            .then((options) => {
               setOptionsFilter(options);
            })
            .catch((error) => {
                // Gérer les erreurs de récupération des options
            });
    }, []);


    return (
        <Row className={'d-flex'}>
            <Col lg={3} xl={3} xxl={2} className={'mt-2'}>
                <Form.Control
                    onChange={handleChange}
                    style={{ padding: '.45rem' }}
                    className={'mx-0'}
                    name="q"
                    placeholder={'Nom'}
                    value={values.q}
                    type="text"
                />
            </Col>
            <Col lg={3} xl={3} xxl={2} className={'mt-2'}>
                <Select
                    options={roleList}
                    styles={customStyles}
                    onChange={(value) => {
                        setFieldValue('role', value, false);
                    }}
                    name="role"
                    value={values.role}
                    placeholder={<div>Rôle</div>}
                />

            </Col>
            {programList.length > 0 ?
                <Col lg={3} xl={3} xxl={2} className={'mt-2'}>
                <Select
                    placeholder={<div>Programme</div>}
                    options={programList}
                    styles={{menuPortal: base => ({...base, zIndex: 9999, flex: 1})}}
                    // styles={customStyles}
                    onChange={(value) => {
                        setFieldValue('programme', value, false);
                    }}
                    name="programme"
                    value={values.programme}
                    defaultValue={values.programme}
                />

            </Col> : ''}
            <Col lg={3} xl={3} xxl={2} className={'mt-2'}>
            <InputGroup>
                <InputGroup.Text>Budget</InputGroup.Text>
                <Form.Control
                    onChange={handleChange}
                    name="minPrice"
                    placeholder={'Min'}
                    value={values.minPrice}
                    type="text"
                />
                <Form.Control
                    onChange={handleChange}
                    name="maxPrice"
                    placeholder={'Max'}
                    value={values.maxPrice}
                    type="text"
                />
            </InputGroup>
            </Col>
        </Row>

    )
}


const FilterContact = ({fetchContacts}) => {

    const [id, setId] = useState(useParams().id);
    const isAddMode = !id;

    const [initialValues, setInitialValues] = useState({

        minPrice: '',
        maxPrice: '',
        role: '',
        programme: 'all',
    });

    const validationSchema = Yup.object().shape({});

    function onSubmit(values, actions) {

        const fields = {...values};
        fields.programme = fields.programme.value;
        fields.typeBien.length === 0 ? delete fields.typeBien : null;
        createSearchPreferences(fields);
        actions.setSubmitting(false);
    }

    function createSearchPreferences(fields) {

        Swal.fire({
            title: "Enregistrer votre recherche",
            input: "text",
            inputLabel: "Nom de la recherche",
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            inputValidator: (value) => {
                if (!value) {
                    return "Vous devez saisir un nom pour sauvegarder votre recherche";
                }
            }
        })
            .then(result => {

                if (result.isConfirmed) {

                    fields.programme === 'all' ? delete fields.programme : null;
                    fields.label = result.value;

                    axios.post(`/admin/search-preferences`, fields)
                        .then(function (response) {

                            Swal.fire({
                                icon: 'success',
                                title: 'Votre recherche est enregistrée!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        })
                        .catch(function (error) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Une erreur s\'est produite!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        });


                }

            })

    }

    return (
        <Card id={'theft-card'} className={'mb-3'}>

            <Card.Body className={'px-4'}>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {


                        return (

                            <Form noValidate onSubmit={handleSubmit}>

                                <Filter
                                    setInitialValues={setInitialValues}
                                    fetchContacts={fetchContacts}
                                />
                            </Form>)
                    }}
                </Formik>
            </Card.Body>
        </Card>


    );
}

export default FilterContact;