import React, {useState, useEffect, useRef} from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {useNavigate, useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Formik} from 'formik';
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import Table from 'react-bootstrap/Table';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers';
import {fetchUserData} from "../../component/fetchUserData";
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import logApiList from "../Log/LogApiList";
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import de la locale française
dayjs.locale('fr');


const OptionHome = ({
                        formikRef, option, user, updateOption,setLots
                    }) => {

    const [id, setId] = useState(useParams().id)
    const [isDisabled, setIsDisabled] = useState(false)
    const initialValues = {
        dteDebutValidite: dayjs(new Date()),
        dteFinValidite: dayjs(new Date()),
        lots: []

    };
    let navigate = useNavigate();
    // const hasLeadFalse = option.acquereurs.some(acquereur => acquereur.lead === false);
    const validationSchema = Yup.object().shape({});
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const handleChange = (e) => {

        setSelectedOption(e.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        // Logique pour traiter le fichier et le type de média sélectionné
        selectedOption === null ? setSelectedOption("Annexes à signer") : null;
        handleClose();
    };

    async function getUserData() {
        try {
            const userData = await fetchUserData();
            userData.role != 'ROLE_PRESCRIPTEUR' ? setIsDisabled(true) : null;
            // Faites quelque chose avec les données utilisateur
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la récupération des données utilisateur :', error);
            // Gérez l'erreur de manière appropriée
        }
    }

    async function onSubmit(values, actions) {

        var dteFinValidite = values.dteFinValidite.format('DD-MM-YYYY')
        const fields = {...values};
        fields.dteFinValidite = dteFinValidite;
        delete fields.dteDebutValidite;
        const simplifiedLots = fields.lots.map((lot) => ({pxProposeTTC: lot.pxProposeTTC, id: lot.optionLotId}));
        fields.lots = simplifiedLots;

        updateOption(id, fields);
    }


    return (
        <div>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      isValid,
                      isSubmitting,
                      touched,
                      setFieldValue,
                      handleBlur, // handler for onBlur event of form elements
                  }) => {

                    useEffect(() => {
                        const simplifiedLots = values.lots.map((lot) => ({
                            pxProposeTTC: lot.pxProposeTTC,
                            id: lot.optionLotId
                        }));
                        setLots(simplifiedLots);
                    }, [values]);

                    useEffect(() => {

                        getUserData();
                        const fields = [
                            {
                                name: "dteDebutValidite",
                            },
                            {
                                name: "dteFinValidite",

                            },
                            {
                                name: "lots",

                            }
                        ];

                        fields.forEach(field => {

                            field.name === "dteDebutValidite" || field.name === "dteFinValidite" ? setFieldValue(field.name, dayjs(new Date(option[field.name])), false) : setFieldValue(field.name, option[field.name], false);
                        });

                    }, []);

                    return (

                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col  lg={12} xl={5} xxl={4}>


                                    <Card>
                                        <Card.Body className={'px-4'}>
                                            <div className={'d-flex align-items-center flex-column mt-5'}>
                                                <h5 className={"mb-2"}> {option.contact[0].nom} {option.contact[0].prenom}</h5>
                                                <span>
                            <Badge className={"bg-label-secondary"} bg={'secondary'}>Acquereur principal</Badge>
                        </span>

                                            </div>
                                            <div className="d-flex justify-content-around flex-wrap my-4 py-3">
                                                <div className="d-flex align-items-start me-4 mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded">
                          <svg className="" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg"
                               viewBox="0 0 24 24"><path fill="#5a8dee"
                                                         d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"></path></svg>
                                </span>
                                                    <div>
                                                        <h5 className="mb-0">{option.nbrLots}</h5>
                                                        <span>Lots</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <h5 className={"pb-2 border-bottom mb-4"}>Détails</h5>
                                            <div className={"info-container mb-4"}>


                                                <Table responsive="xl" borderless>
                                                    <tbody>
                                                    <tr>
                                                        <td className={"font-weight-bold"}>Civilité</td>
                                                        <td>{option.contact[0].civility}</td>

                                                    </tr>
                                                    <tr>
                                                        <td className={"font-weight-bold"}>Email</td>
                                                        <td>{option.contact[0].email}</td>

                                                    </tr>
                                                    <tr>

                                                        <td className={"font-weight-bold"}>Adresse</td>
                                                        <td>{option.contact[0].address}</td>

                                                    </tr>
                                                    <tr>

                                                        <td className={"font-weight-bold"}>Ville</td>
                                                        <td>{option.contact[0].city}</td>

                                                    </tr>
                                                    <tr>

                                                        <td className={"font-weight-bold"}>Téléphone</td>
                                                        <td>{option.contact[0].phone}</td>

                                                    </tr>
                                                    <tr>
                                                        <td className={"font-weight-bold"}>Pays</td>
                                                        <td>{option.contact[0].country}</td>

                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col xl={7} lg={12} xxl={8}>

                                    <Card>
                                        <Card.Body>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="card-title align-items-start flex-column">
                                                    <h2 className={'mb-3'}>
                                                        Caractéristiques
                                                    </h2>

                                                </div>
                                                <div className="card-toolbar">


                                                </div>
                                            </div>


                                            <Row className="my-4">

                                                <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{
                                                    shortDate: "DD MMM"
                                                }} adapterLocale="fr">
                                                    <Col xs={6}>

                                                        <MobileDatePicker
                                                            name="dteDebutValidite"
                                                            format="DD-MM-YYYY"
                                                            label="Date de début de validité"
                                                            clearText="vider"
                                                            cancelText="annuler"
                                                            readOnly={true}
                                                            value={values.dteDebutValidite}
                                                            okText="ok"
                                                            className={"w-100"}

                                                        />
                                                    </Col>
                                                    <Col xs={6}>
                                                        <MobileDatePicker
                                                            name="dteFinValidite"
                                                            format="DD-MM-YYYY"
                                                            slots={{toolbar: null}} // Masque le toolbar en définissant null
                                                            label="Date de fin de validité"
                                                            clearText="vider"
                                                            cancelText="annuler"
                                                            readOnly={user.role === 'ROLE_ADMIN' || user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_PRESCRIPTEUR' ? false : true}
                                                            onChange={(value) => {
                                                                setFieldValue('dteFinValidite', value);
                                                            }}
                                                            value={values.dteFinValidite}
                                                            okText="ok"
                                                            className={"w-100"}
                                                        /></Col>


                                                </LocalizationProvider>
                                            </Row>


                                            {values.pret && (

                                                <div>
                                                    <h2 className={'pt-3'}>Prêt</h2>

                                                    <Row className="mt-4">

                                                        <Col>
                                                            <Form.Label>Date de début de validité</Form.Label>
                                                            <Form.Control
                                                                name={`pret.montant`}
                                                                type="text"
                                                                disabled={isDisabled}
                                                                onChange={handleChange}
                                                                value={values.pret.montant}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Label>Date de fin de validité</Form.Label>
                                                            <Form.Control
                                                                name={`pret.apportPersonnel`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                disabled={isDisabled}
                                                                value={values.pret.apportPersonnel}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Label>Délai du dépot du dossier</Form.Label>
                                                            <Form.Control
                                                                name={`pret.delaiDepotDossier`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                disabled={isDisabled}
                                                                value={values.pret.delaiDepotDossier}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Label>Délai d'obtention du du crédit</Form.Label>
                                                            <Form.Control
                                                                name={`pret.delaiObtention`}
                                                                type="text"
                                                                disabled={isDisabled}
                                                                onChange={handleChange}
                                                                value={values.pret.delaiObtention}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </Card.Body>

                                    </Card>
                                    <Row className="mt-4">

                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <h2 className={'pt-1'}>Lots</h2>
                                                    <div className="table" id={'lots-list'}>


                                                        {option && option.lots && option.lots.length > 0 && values.lots.length > 0 ? option.lots.map((lot, index) => {

                                                            return (

                                                                <Row key={index}>


                                                                    <Col lg={6} xl={6} xxl={2}><Form.Label>N°lot</Form.Label>
                                                                        <div className={"link-primary"}
                                                                             style={{cursor: 'pointer'}}
                                                                             onClick={() => {
                                                                                 navigate(`/admin/lot/${lot.id}/voir`)
                                                                             }}> {lot.nrLot}</div>
                                                                    </Col>

                                                                    <Col lg={6} xl={6} xxl={2}>
                                                                        <Form.Label>Type de bien</Form.Label>
                                                                        <div>{lot.typeProperty}</div>
                                                                    </Col>
                                                                    <Col lg={6} xl={6} xxl={2}>
                                                                        <Form.Label>Surface habitable</Form.Label>
                                                                        <div>{lot.sfcHabitable} m²</div>
                                                                    </Col>
                                                                    <Col lg={6} xl={6} xxl={2}>
                                                                        <Form.Label>Prix de vente</Form.Label>
                                                                        <div>{lot.pxVenteTtc}</div>
                                                                    </Col>
                                                                    <Col lg={6} xl={6} xxl={2}>
                                                                        <Form.Label>Prix proposé</Form.Label>
                                                                        <div>{lot.proposedPrice}</div>
                                                                    </Col>
                                                                    <Col lg={6} xl={6} xxl={2}>
                                                                        <Form.Label>Prix négocié</Form.Label>
                                                                        <Form.Control
                                                                            name={`lots[${index}]pxProposeTTC`}
                                                                            type="text"
                                                                            // disabled={isDisabled}
                                                                            onChange={handleChange}
                                                                            value={values.lots[index].pxProposeTTC}
                                                                        /></Col>

                                                                </Row>


                                                            )
                                                        }) : null}
                                                    </div>


                                                </Card.Body>
                                            </Card> </Col>

                                    </Row>
                                </Col>

                            </Row>
                        </Form>)

                }}

            </Formik>

            <Modal id={'add-medias'} show={open} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className={'h5'}>Sélectionner le type de média</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Label className={'mb-4'}>Type de média</Form.Label>
                        <Form.Check
                            label="Annexes à signer"
                            type={'radio'}
                            value="Annexes à signer"
                            name="group1"
                            className={'mb-4'}
                            id={`reverse`}
                            onChange={handleChange}
                            defaultChecked
                        />
                        <Form.Check
                            name="group1"
                            label="Autres"
                            type={'radio'}
                            id={`reverse`}
                            value="Autres"
                            onChange={handleChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={handleConfirm} variant="primary">
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>


    )
}

export default OptionHome;