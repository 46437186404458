import React from 'react';

export function convertDate(date,order=null,type='datetime') {

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    if(type==='date'){
        return order===`invert`?`${month}-${day}-${year}`:`${day}-${month}-${year}`;
    }


    return order===`invert`?`${month}-${day}-${year} ${hours}:${minutes}`:`${day}-${month}-${year} ${hours}:${minutes}`;
}

