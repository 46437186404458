import React, {useEffect, useState, useCallback} from 'react';
import {GoogleMap, Marker} from '@react-google-maps/api';
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from 'react-bootstrap/Row';

const MyMap = ({longitude, latitude,isLoaded}) => {

    const [places, setPlaces] = useState({
        doctor: [], school: [], supermarket: [], train_station: [], airport: []
    });
    const position = {lat: latitude, lng: longitude}; // Position d'origine (Paris, France)
    const serviceTypes = ['train_station', 'airport', 'school', 'pharmacy', 'doctor', 'supermarket'];

    const fetchPlaces = useCallback(async (google) => {

        const service = new google.maps.places.PlacesService(document.createElement('div'));
        const promises = serviceTypes.map((serviceType) =>

            new Promise((resolve) => {
                var radius = '10000';
                if (serviceType == 'airport') {
                    radius = '30000';
                }
                service.nearbySearch(
                    {
                        location: position,
                        radius: radius,
                        type: [serviceType],
                    },
                    (results, status) => {


                            resolve([]);

                    }
                );
            })
        );

        const results = await Promise.all(promises);

        const sortedPlaces = serviceTypes.reduce((acc, serviceType, index) => {
            const sorted = results[index].sort((a, b) => {
                const distanceA = a.geometry.location;
                const distanceB = b.geometry.location;
                const distanceFromOriginA = google.maps.geometry.spherical.computeDistanceBetween(
                    position,
                    distanceA
                );
                const distanceFromOriginB = google.maps.geometry.spherical.computeDistanceBetween(
                    position,
                    distanceB
                );
                return distanceFromOriginA - distanceFromOriginB;
            });
            return {...acc, [serviceType]: sorted};
        }, {});

        setPlaces(sortedPlaces);
    }, []);


    useEffect(() => {
        if (isLoaded) {
            fetchPlaces(window.google);
        }
    }, [isLoaded, fetchPlaces]);

    return (
        isLoaded && (
            <div>
                <Card>
                    <Card.Body>
                        <Row>
                            <h2>A proximité</h2>
                            <Col lg="6" className={"pe-5"}>

                                <div className={'mt-4'}>
                                    <h3>Enfance/Scolarité</h3>

                                    {places.school.slice(0, places.school.length > 4 ? 5 : places.school.length).map((place, index) => {

                                        return (
                                            <div className={"justify-content-between d-flex"} key={index}>
                                                <div className={"text-muted"}> {place.name}</div>
                                                <div>{(google.maps.geometry.spherical.computeDistanceBetween(position, place.geometry.location) / 1000).toFixed(2)} km</div>
                                            </div>

                                        )

                                    })}</div>
                                <div className={'mt-4'}>

                                    <h3>Santé</h3>

                                    {places.doctor.slice(0, places.doctor.length > 4 ? 5 : places.doctor.length).map((place, index) => {

                                        return (
                                            <div className={"justify-content-between d-flex"} key={index}>
                                                <div className={"text-muted"}> {place.name}</div>
                                                <div>{(google.maps.geometry.spherical.computeDistanceBetween(position, place.geometry.location) / 1000).toFixed(2)} km</div>
                                            </div>

                                        )

                                    })}</div>

                            </Col>
                            <Col lg="6">
                                <div className={'mt-4'}>

                                    <h3>Gare ferroviaire</h3>

                                    {places.train_station.slice(0, places.train_station.length > 4 ? 5 : places.train_station.length).map((place, index) => {

                                        return (
                                            <div className={"justify-content-between d-flex"} key={index}>
                                                <div className={"text-muted"}> {place.name}</div>
                                                <div>{(google.maps.geometry.spherical.computeDistanceBetween(position, place.geometry.location) / 1000).toFixed(2)} km</div>
                                            </div>
                                        )

                                    })}</div>
                                <div className={'mt-4'}>

                                    <h3>Aéroport</h3>

                                    {places.airport.slice(0, places.airport.length > 4 ? 5 : places.airport.length).map((place, index) => {

                                        return (
                                            <div className={"justify-content-between d-flex"} key={index}>
                                                <div className={"text-muted"}> {place.name}</div>
                                                <div>{(google.maps.geometry.spherical.computeDistanceBetween(position, place.geometry.location) / 1000).toFixed(2)} km</div>
                                            </div>

                                        )

                                    })}</div>
                                <div className={'mt-4'}>

                                    <h3>Vie pratique</h3>

                                    {places.supermarket.slice(0, places.supermarket.length > 4 ? 5 : places.supermarket.length).map((place, index) => {

                                        return (
                                            <div className={"justify-content-between d-flex"} key={index}>
                                                <div className={"text-muted"}> {place.name}</div>
                                                <div>{(google.maps.geometry.spherical.computeDistanceBetween(position, place.geometry.location) / 1000).toFixed(2)} km</div>
                                            </div>

                                        )

                                    })}</div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <GoogleMap
                    mapContainerStyle={{height: '400px', width: '100%'}}
                    center={position}
                    zoom={12}
                >
                    {Object.entries(places).map(([serviceType, servicePlaces]) =>
                        servicePlaces.map((place) => (
                            <Marker key={place.place_id} position={place.geometry.location}/>
                        ))
                    )}
                </GoogleMap>


            </div>

        )
    );
};

export default MyMap;
