import React, {useState, useEffect} from 'react';
import Layout from "../component/Layout";
import axios from "axios";
import Swal from "sweetalert2";
import {Formik} from "formik";
import Container from 'react-bootstrap/Container';
import {Card} from "react-bootstrap";
import {useNavigate, useLocation} from "react-router-dom";
import SellerForm from "./Register/Component/SellerForm";
import FormRedistributor from "./Register/Component/FormRedistributor";
import PartnerForm from "./Register/Component/PartnerForm";
import ProspectForm from "./Register/Component/ProspectForm";
import InvestorForm from "./Register/Component/InvestorForm";
import FournisseurForm from "./Register/Component/FournisseurForm";
import DefaultForm from "./Register/Component/DefaultForm";
import {Button, Form} from 'react-bootstrap';
import ValidationManager from "./Register/Component/ValidationManager";
import ColleagueForm from "./Register/Component/ColleagueForm";

function Registration({}) {

    const [isVerified, setIsVerified] = useState(process.env.REACT_APP_DISABLE_RECAPTCHA === 'true'?true:false);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [selectedRole, setSelectedRole] = useState('ROLE_PROSPECT');
    const [validationSchema, setValidationSchema] = useState();
    const [initialValues, setInitialValues] = useState({
        username: '',
        nom: '',
        prenom: '',
        zip: '',
        city: '',
        email: '',
        promoCode: '',
        phones: '',
        password: '',
        confirmPassword: '',
        roles: [],
        role: 'ROLE_PROSPECT',
        permissionContact: true
    });


    let navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    //Utiliser pour préselectionner une option dans le menu déroulant des roles
    const role = params.get('role');

    const renderTitle = () => {

        switch (role) {
            case 'prospect':
                return <div className={'mb-5'}>S'enregistrer</div>;
            case 'lead':
                return <div className={'mb-5'}>Obtenez un devis pour vos travaux de rénovations !</div>;
            case 'vendeur':
                return <div className={'mb-5'}>Vendez votre bien immobilier rapidement et facilement !</div>;
            case 'partner':
                return <div className={'mb-5'}>Devenez notre prochain associé et faites évoluer vos idées</div>;
            default:
                return <div className={'mb-5'}>S'enregistrer</div>;
        }
    };

    const optSuggestions = [
        {value: 0, label: 'Je souhaite être partenaire artisan', role: 'fournisseur'},
        {value: 1, label: 'Je souhaite être partenaire prescripteur', role: 'prescripteur'},
        {value: 2, label: 'Je souhaite faire un investissement locatif', role: 'prospect'},
        {value: 3, label: 'Autre', role: 'autre'},
    ]

    const steps = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const validationManager = new ValidationManager();
    const currentStep = steps[step];

    const setNestedTouched = (errors) => {
        const touched = {};
        const traverseErrors = (errorObject, path = []) => {
            Object.keys(errorObject).forEach((key) => {
                const value = errorObject[key];
                const currentPath = [...path, key];

                if (typeof value === 'object' && value !== null) {
                    // Traverse the object if it is an object and not null
                    let pointer = touched;
                    currentPath.forEach((p, i) => {

                        if (!pointer[p]) {
                            // pointer[p] = i === currentPath.length - 1 ? true : {};
                        }
                        pointer = pointer[p];
                    });
                    traverseErrors(value, currentPath);
                } else {
                    // Set the final path to true if it is not an object
                    let pointer = touched;
                    currentPath.forEach((p, i) => {

                        if (!pointer[p]) {
                            pointer[p] = i === currentPath.length - 1 ? true : {};
                        }
                        pointer = pointer[p];
                    });
                }
            });
        };

        traverseErrors(errors);
        return touched;
    };

    const isEmpty = (obj) => Object.keys(obj).length === 0;
    const handleNextStep = async (validateForm, setTouched) => {

        const errors = await validateForm();
        const multiStepFormSize = validationManager.getNumberOfStepsPerRole(selectedRole)

        if (isEmpty(errors)) {

            if (step < multiStepFormSize) {

                setStep(step + 1);
            }
        } else {
            setTouched(setNestedTouched(errors));
        }
    };

    useEffect(() => {
        selectInitialValues()
    }, []);

    useEffect(() => {

        const objectSchema = validationManager.getValidationSchema(selectedRole, currentStep);
        setValidationSchema(objectSchema);

    }, [selectedRole, currentStep]);

    useEffect(() => {

    }, [validationSchema]);

    const selectInitialValues = () => {

        switch (role) {
            case 'vendeur':
                setInitialValues(initialSellerValues);
                break;
            case 'partner':
                setInitialValues(initialPartnerValues);
                break;
            case 'lead':
                setInitialValues(initialProspectValues);
                break;
            case 'investisseur':
                setInitialValues(initialInvestorValues);
                break;
            case 'artisan':
                setInitialValues(initialFournisseurValues);
                break;
            case 'collegue':
                setInitialValues(initialColleagueValues);
                break;
            default:
                console.log('Rôle non reconnu');
        }
    };

    useEffect(() => {

    }, [initialValues]);

    const handleRecaptchaChange = (value) => {
        setIsVerified(true);
    };


    const renderDescription = () => {
        if (step === 1 || step === 2) {

            switch (role) {
                case 'vendeur':
                    return <div className={'mb-5'}>Vous envisagez de vendre votre maison, appartement ou terrain ?
                        Remplissez notre formulaire en quelques minutes et recevez une offre d’achat dans les 48H .
                        Notre équipe de professionnels vous contactera pour discuter afin de prendre rdv avec si besoin.
                        Faites le premier pas vers une vente réussie et rapide. </div>;
                case 'partner':
                    return <div className={'mb-5'}>Vous avez une idée innovante et des compétences uniques ? Nous sommes
                        à la recherche de
                        partenaires passionnés pour co-créer des projets ambitieux. Remplissez notre formulaire et
                        présentez-nous votre idée ainsi que vos atouts. Ensemble, nous pouvons transformer votre vision
                        en
                        réalité !</div>;
                case 'investisseur':
                    return <div className={'mb-5'}>Nous aimerions en savoir plus sur vous pour mieux répondre à vos
                        besoins en investissement immobilier. Veuillez prendre un moment pour remplir ce formulaire
                        rapide. Vous nous aiderez à vous fournir des conseils et des offres personnalisées.</div>;
                case 'artisan':
                    return <div className={'mb-5'}>Bienvenue ! Nous sommes ravis de vous accueillir. Veuillez remplir ce
                        formulaire d'inscription pour commencer à profiter de tous nos services. Vous aurez accès à un
                        espace dédié où vous pourrez consulter l'ensemble des programmes en cours et nous contacter si
                        l'un d'eux vous intéresse.</div>;
                case 'lead':
                    return <div className={'mb-5'}>Nous vous invitons à remplir notre formulaire en ligne. En quelques
                        minutes, vous pourrez nous indiquer les détails de votre projet de rénovation, et nous serons en
                        mesure de vous envoyer une réponse appropriée.</div>;
                default:
                    return <div className={'mb-5'}>Bienvenue ! Nous sommes ravis de vous accueillir. Veuillez remplir ce
                        formulaire
                        d'inscription pour commencer à profiter de tous nos services. Cochez toutes les options qui vous
                        intéressent.</div>;
            }

        }
    };


    const initialSellerValues = {
        nom: '',
        prenom: '',
        email: '',
        phones: '',
        property: {
            address: '',
            ville: {nom: '', codePostal: ''},
            type: '',
            constructionDate: '',
            vacantProperty: '',
            totalSurface: '',
            pays: {label: "France", value: 'FR'},
        },
        role: 'ROLE_VENDEUR',
    };

    const initialPartnerValues = {
        nom: '',
        prenom: '',
        email: '',
        phones: '',
        societyName: '',
        profilePartner: {
            motivation: '',
            businessIdea: '',
            contribution: '',
        },
        role: 'ROLE_PARTENAIRE',
    };


    const initialProspectValues = {
        nom: '',
        prenom: '',
        email: '',
        phones: '',
        societyName: '',
        property: {
            address: '',
            ville: {nom: '', codePostal: ''},
            works: '',
            totalSurface: '',
            pays: {label: "France", value: 'FR'},
        },
        role: 'ROLE_CONSOMMATEUR',
    };


    const initialColleagueValues = {

        email: '',

        role: 'ROLE_COLLEAGUE',
    };

    const initialInvestorValues = {
        nom: '',
        prenom: '',
        email: '',
        age: '',
        phones: '',
        investorProfile: {
            previousBondInvestment: '',
            awareInvestmentRisks: '',
            awareBondIssuance: '',
            plannedBondInvestmentAmount: '',
        },
        role: 'ROLE_INVESTISSEUR',
        pays: {label: "France", value: 'FR'},
    };


    const initialFournisseurValues = {
        profession: '',
        username: '',
        nom: '',
        prenom: '',
        zip: '',
        city: '',
        email: '',
        promoCode: '',
        phones: '',
        password: '',
        confirmPassword: '',
        roles: [],
        role: 'ROLE_FOURNISSEUR',
        permissionContact: true
    };

    /**
     * Formate un objet d'erreurs pour une alerte SweetAlert2
     * @param {Object} errors - Objet contenant les erreurs de validation
     * @returns {string} Chaîne formatée avec les erreurs
     */
    const formatErrorsForSwal = (errors) => {
        let formattedErrors = '';

        // Parcourir chaque champ et ses erreurs
        for (const field in errors) {
            // formattedErrors += `${field} :`; // Nom du champ en gras
            errors[field].forEach(error => {
                formattedErrors += ` ${error}`; // Lister chaque message d'erreur
            });
        }

        return formattedErrors; // Retourner la chaîne formatée
    };
    const createColleagueRegistration = async (fields) => {

        setLoading(true);

        // Afficher le loader
        Swal.fire({
            title: 'Veuillez patienter',
            text: 'L\'enregistrement est en cours...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        // Filtrer les éléments undefined
        const {email, password, username, role} = fields;
        const user = {email, password, username};
        // Réindexer le tableau
        user.roles = [role];

        axios.post(`/register`, user)
            .then(function (response) {

                Swal.close();
                navigate(`/confirmation?role=` + fields.role)
                fields.user = response.id;

            })
            .catch(function (error) {
                setLoading(false);
                if (error.response.status === 401) {

                    console.log(error)
                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else if (error.response.status === 400) {
                    Swal.fire({
                        icon: 'warning',
                        title: "Erreur de validation",
                        text: formatErrorsForSwal(error.response.data.errors),
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }

            }).finally(function () {
                setLoading(false);
            }
        )

        ;
    }

    const createRegistration = async (fields) => {

        setLoading(true);

        // Afficher le loader
        Swal.fire({
            title: 'Veuillez patienter',
            text: 'L\'enregistrement est en cours...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        // Filtrer les éléments undefined
        const {email, password, username, role, permissionContact, nom, prenom, city, zip, phones, profession} = fields;
        const user = {email, password, username, permissionContact, nom, prenom, city, zip, phones};
        // Réindexer le tableau
        user.roles = [role];
        const contact = {email, nom, prenom, city, zip, phones, role, profession};
        // contact.role = role;
        contact.phones = [{number: phones, type: 1}];
        user.phones = [{number: phones, type: 1}]; // Ajoute la chaîne au tableau


        axios.post(`/register`, user)
            .then(function (response) {


                fields.user = response.id;

                axios.post(`/mailchimp/add-contact`, contact)
                    .then(function (response) {
                        // Fermer le loader

                        Swal.close();
                        navigate(`/confirmation?role=` + fields.role)
                    })
                    .catch(function (error) {
                        // Fermer le loader
                        Swal.close();
                        if (error.response.status === 401) {

                            console.log(error)
                            Swal.fire({
                                icon: 'warning',
                                title: error.response.data.message,
                                showConfirmButton: false,
                                timer: 3000
                            })
                        } else {

                            Swal.fire({
                                icon: 'warning',
                                title: "Erreur de validation",
                                text: formatErrorsForSwal(error.response.data.errors),
                                showConfirmButton: false,
                                timer: 3000
                            })
                        }


                    }).finally(function () {
                        // Fermer le loader
                        Swal.close();
                    }
                )

                ;


            })
            .catch(function (error) {
                setLoading(false);
                if (error.response.status === 401) {

                    console.log(error)
                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }

            }).finally(function () {
                setLoading(false);
            }
        )

        ;
    }


    function createSellerContact(fields) {

        const contact = fields;
        contact.phones = [{number: fields.phones, type: 1}];
        contact.property.pays = fields.property.pays.value;
        contact.property.ville.nom = fields.city;
        contact.property.ville.codePostal = fields.zip;

        delete fields.zip;
        delete fields.city;

        axios.post(`/mailchimp/add-contact`, contact)
            .then(function (response) {

                navigate(`/confirmation?role=` + fields.role)
            })
            .catch(function (error) {

                if (error.response.status === 401) {

                    console.log(error)
                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }


            });
    }

    function createPartnerContact(fields) {

        const contact = fields;
        contact.phones = [{number: fields.phones, type: 1}];
        // contact.property.pays = fields.property.pays.value;

        axios.post(`/mailchimp/add-contact`, contact)
            .then(function (response) {

                navigate(`/confirmation?role=` + fields.role)
            })
            .catch(function (error) {

                if (error.response.status === 401) {

                    console.log(error)
                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            });
    }


    function createInvestorContact(fields) {

        const contact = fields;
        // contact.property.pays = fields.property.pays.value;
        contact.phones = [{number: fields.phones, type: 1}];
        axios.post(`/mailchimp/add-contact`, contact)
            .then(function (response) {

                navigate(`/confirmation?role=` + fields.role)
            })
            .catch(function (error) {

                if (error.response.status === 401) {

                    console.log(error)
                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            });
    }

    function onSubmit(values, actions) {

        if (!isVerified) {
//On fournit le formulaire du user et du contact qui seront créer consécutivement
            Swal.fire({
                icon: 'warning',
                title: "Sécurité anti robot",
                text: "Veuillez cocher la case anti robot",
                showConfirmButton: false,
                timer: 3000
            })
        } else {

            const fields = {...values};
            //Conserve uniquement les entrés sélectionné

            switch (fields.role) {
                case 'ROLE_VENDEUR':
                    return createSellerContact(fields);
                case 'ROLE_PARTENAIRE':
                    return createPartnerContact(fields);
                case 'ROLE_INVESTISSEUR':
                    return createInvestorContact(fields)
                case 'ROLE_CONSOMMATEUR':
                    return createSellerContact(fields);
                case 'ROLE_COLLEAGUE':
                    return createColleagueRegistration(fields);
                default:
                    return createRegistration(fields);
            }


            // Vérifiez si le CAPTCHA a été résolu avant de soumettre le formulaire


            actions.setSubmitting(false);

        }


    }


    const RegistrationForm = ({
                                  handleSubmit,
                                  handleChange,
                                  values,
                                  errors,
                                  isValid,
                                  isSubmitting,
                                  touched,
                                  setFieldValue,
                                  handleBlur,
                                  validateForm,
                                  setTouched
                              }) => {
            const renderForm = () => {

                if (role === 'vendeur' || role === 'partner' || role === 'artisan' || role === 'lead' || role === 'investisseur' || role === 'prescripteur' || role === 'prospect') {
                    step === 1 ? setStep(2) : null;
                }

                switch (role) {
                    case 'vendeur':
                        setSelectedRole('ROLE_VENDEUR')
                        return <SellerForm
                            setTouched={setTouched}
                            handleNextStep={handleNextStep}
                            validateForm={validateForm}
                            step={step}
                            setStep={setStep}
                            role={role}
                            isValid={isValid}
                            isSubmitting={isSubmitting}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}/>;
                    case 'partner':
                        setSelectedRole('ROLE_PARTENAIRE')
                        return <PartnerForm
                            setTouched={setTouched}
                            handleNextStep={handleNextStep}
                            validateForm={validateForm}
                            step={step}
                            setStep={setStep}
                            role={role}
                            isValid={isValid}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}/>;
                    case 'artisan':
                        step === 1 ? setStep(2) : null;
                        setSelectedRole('ROLE_FOURNISSEUR')
                        return <FournisseurForm
                            setTouched={setTouched}
                            handleNextStep={handleNextStep}
                            validateForm={validateForm}
                            step={step}
                            setStep={setStep}
                            role={role}
                            isValid={isValid}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            handleSubmit={handleSubmit} values={values} setFieldValue={setFieldValue}
                            handleChange={handleChange}/>;
                    case 'lead':

                        setSelectedRole('ROLE_CONSOMMATEUR')
                        return <ProspectForm
                            setTouched={setTouched}
                            handleNextStep={handleNextStep}
                            validateForm={validateForm}
                            step={step}
                            setStep={setStep}
                            role={role}
                            isValid={isValid}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}/>;
                    case 'investisseur':

                        setSelectedRole('ROLE_INVESTISSEUR')
                        return <InvestorForm
                            setTouched={setTouched}
                            handleNextStep={handleNextStep}
                            validateForm={validateForm}
                            step={step}
                            setStep={setStep}
                            role={role}
                            isValid={isValid}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}/>;
                    case 'prospect':

                        setSelectedRole('ROLE_PROSPECT')
                        return <div>
                            <DefaultForm
                                setTouched={setTouched}
                                handleNextStep={handleNextStep}
                                validateForm={validateForm}
                                step={step}
                                setStep={setStep}
                                handleRecaptchaChange={handleRecaptchaChange}
                                optSuggestions={optSuggestions}
                                handleSubmit={handleSubmit}
                                values={values}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}/>
                            <div className={'d-flex justify-content-between'}>

                                <Button variant="primary"
                                        type="submit"
                                    // disabled={!mediaType}
                                        className="mt-4 w-100">
                                    Envoyer
                                </Button></div>
                        </div>;
                    case 'collegue':

                        setSelectedRole('ROLE_COLLEAGUE')
                        return <div>
                            <ColleagueForm
                                setTouched={setTouched}
                                handleNextStep={handleNextStep}
                                validateForm={validateForm}
                                step={step}
                                setStep={setStep}
                                handleRecaptchaChange={handleRecaptchaChange}
                                optSuggestions={optSuggestions}
                                handleSubmit={handleSubmit}
                                values={values}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}/>
                            <div className={'d-flex justify-content-between'}>

                                <Button variant="primary"
                                        type="submit"
                                    // disabled={!mediaType}
                                        className="mt-4 w-100">
                                    Envoyer
                                </Button></div>
                        </div>;
                    case 'prescripteur':

                        setSelectedRole('ROLE_PRESCRIPTEUR')
                        return <div>
                            <DefaultForm
                                setTouched={setTouched}
                                handleNextStep={handleNextStep}
                                validateForm={validateForm}
                                step={step}
                                setStep={setStep}
                                handleRecaptchaChange={handleRecaptchaChange}
                                optSuggestions={optSuggestions}
                                handleSubmit={handleSubmit}
                                values={values}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}/>
                            <div className={'d-flex justify-content-between'}>

                                <Button variant="primary"
                                        type="submit"
                                        className="mt-4 w-100">
                                    Envoyer
                                </Button></div>
                        </div>;
                    default:
                        return <FormRedistributor
                            selectedRole={selectedRole}
                            setSelectedRole={setSelectedRole}
                            isValid={isValid}
                            setTouched={setTouched}
                            step={step}
                            setStep={setStep}
                            validateForm={validateForm}
                            handleNextStep={handleNextStep}
                            setInitialValues={setInitialValues} role={role}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            isValid={isValid}
                            isSubmitting={isSubmitting}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}/>;
                }
            };
            return (
                <div>
                    {setInitialValues ? renderForm() : null}
                </div>
            )
        }
    ;

    return (
        <Layout>
            <Container>
                <div
                    className={'d-flex justify-content-center align-items-center register-container mx-auto col-xxl-7 col-xl-7 col-lg-8 col-sm-12 col-xs-12 py-5'}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validateOnChange={false}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values,
                              errors,
                              isValid,
                              isSubmitting,
                              touched,
                              setFieldValue,
                              handleBlur, // handler for onBlur event of form elements
                              validateForm,
                              setTouched

                          }) => {

                            return (

                                <Form noValidate onSubmit={handleSubmit}
                                      className={"registration-form form wrapper-login"}>

                                    <Card.Title className={'fw-bold text-center fs-2 mb-4'}>
                                        {renderTitle()}

                                    </Card.Title>
                                    <p className={'w-100 mx-auto text-center mb-4'}>{renderDescription()}</p>
                                    <RegistrationForm
                                        setTouched={setTouched}
                                        validateForm={validateForm}
                                        handleSubmit={handleSubmit}
                                        handleChange={handleChange}
                                        values={values}
                                        errors={errors}
                                        isValid={isValid}
                                        isSubmitting={isSubmitting}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        handleBlur={handleBlur}

                                    />


                                </Form>

                            )
                        }}
                    </Formik>

                </div>
            </Container>


        </Layout>
    );
}

export default Registration;