import React from 'react';
import { InlineWidget } from 'react-calendly';

const CalendlyWidget = () => {
    const calendlyUrl = 'https://calendly.com/s-desforges/rdv_en_physique';

    return (
        <div>

            <InlineWidget url={calendlyUrl} />
        </div>
    );
};

export default CalendlyWidget;
