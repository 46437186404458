import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import Layout from "../../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import MaterialTable from "@material-table/core";
import {Icon} from '@iconify/react';
import ContactAddEdit from "./ContactAddEdit";
import Button from "react-bootstrap/Button";
import {Col, Row} from "react-bootstrap";
import FilterContact from "./component/FilterContact";
import api from "../../src/utils/api"
import Tooltip from '@mui/material/Tooltip';
import {useMeasure} from 'react-use';
import ScrollWrapper from "../../utils/ScrollWrapper";
import Pagination from "../../component/Pagination";
import TablePaginationActions from "../../component/TablePaginationActions";
import TablePaginationNullActions from "../../component/TablePaginationNullActions";
import Badge from 'react-bootstrap/Badge';


function ContactList({user, isFullSize}) {

    const [ref, {width}] = useMeasure();
    // Calculer la nouvelle hauteur après soustraction du pourcentage
    const location = useLocation();
    // Crée un objet URLSearchParams basé sur location.search
    const searchParams = new URLSearchParams(location.search);
    const [show, setShow] = useState(searchParams.get("show") ? true : false);
    const handleShow = () => setShow(true);
    const [isAddMode, setIsAddMode] = useState(searchParams.get("show") ? true : false);
    const [filteredData, setFilteredData] = useState([])
    const [rowCount, setRowCount] = useState('Liste des contacts')
    const [filter, setFilter] = useState(false)
    let navigate = useNavigate();
    // Variables d'état pour la pagination
    const [dataCount, setDataCount] = useState(0); // Les données de l'API ou de la source
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {

    }, [width]);


    const handleChange = () => {

        setFilter(!filter)
    }

    const exportToCSV = (rows, fileName) => {

        var arrContact = [];
        var rowValues = [];
        arrContact.push(['name', 'id', 'societe', 'city', 'mail', 'phone']);
        rows.forEach(object => {
            var rowValues = [];
            Object.values(object).forEach(value => {
                rowValues.push(value)
            });

        });
        arrContact.push(rowValues);
    };

    const handleDeleteRows = (event, rowData) => {

        let _data = [...filteredData];

        rowData.forEach(rd => {

            _data = _data.filter(row => row.id !== rd.id);
        });

        rowData.forEach(rd => {

            _data = _data.filter(row => row.id !== rd.id);
        });

        setFilteredData(_data);
    };

    useEffect(() => {

        fetchContacts();

    }, [page, rowsPerPage])

    useEffect(() => {

    }, [filteredData]);

    const tableRef = React.useRef();

    const renderRole = rowData => {
        return (
            <>
                <Badge className={"bg-label-secondary text-uppercase"} bg={'secondary'}>{rowData.role}</Badge>
            </>
        );
    };

    function fetchContacts(fields) {
        var url = location.state && location.state.mailchimp ? '/api/filter/contacts?mailchimp=' + location.state.mailchimp : '/api/filter/contacts';
        axios.get(url, {
            params: {
                filterData: fields,
                page: page + 1,  // Certaines APIs commencent la pagination à 1
                limit: rowsPerPage,
            }
        })
            .then(function (response) {
                setDataCount(response.data.meta.total_items)
                setFilteredData(response.data.items);
            });
    }

    const getCreatedAtValueForRow = createdAt => {

        var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
            <time>{createdAt}</time>
        </div>;

        return elem;
    };

    const getLastLoginValueForRow = lastLogin => {

        if (lastLogin != 'inactif') {

            var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
                <time>{lastLogin}</time>
            </div>
        } else {
            var elem = <div className={'text-center'}><span className={"label label-default"}>inactif</span></div>;
        }

        return elem;
    };


    const getNameValueForRow = rowData => {

        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
            navigate(`/admin/contacts/${rowData.id}/voir`)
        }}>
               <Tooltip title="name">
      <span>
{rowData.society ? rowData.society : rowData.name}
      </span>
    </Tooltip> </span>;

        return elem;
    };

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">
                            <div className="navbar navbar-default p-1 d-flex">
                                <div>
                                    <h1>Contact</h1>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des contacts</li>
                                    </ol>
                                </div>
                                <div>
                                    <Button variant="primary" border="success" className="me-1" size="sm"
                                            onClick={() => {
                                                handleShow();
                                                setIsAddMode(true);
                                            }}>Créer un contact</Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <section className="content mt-0 pt-0">
                <div id="contact-list" className="container-fluid">
                    <Row>
                        <Col xs={12}>

                            <FilterContact fetchContacts={fetchContacts}/>
                        </Col>
                        <Col xs={12}>
                            <ScrollWrapper isFullSize={isFullSize}>
                                <MaterialTable
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} of {count}',
                                            labelRowsSelect: 'lignes'
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} sélectionné ',
                                            searchPlaceholder: 'Chercher'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Aucun contact à afficher',
                                            filterRow: {
                                                filterTooltip: 'Filter'
                                            }
                                        }
                                    }}

                                    options={{
                                        toolbar: true,
                                        search:false,
                                        selection: true,
                                        pageSize: 20,
                                        toolbarButtonAlignment: "left",
                                        filtering: filter,
                                        paging: false,
                                        searchFieldAlignment: 'left',
                                    }}
                                    columns={[
                                        {
                                            title: 'Nom', field: 'name', filtering: false,
                                            render: rowData => getNameValueForRow(rowData),

                                        },
                                        {
                                            title: 'Rôle', field: 'role', render: rowData => renderRole(rowData),
                                            filtering: false,

                                        },
                                        {title: 'Ville', field: 'city', filtering: false},
                                        {title: "Courriel", field: 'mail', type: 'date', filtering: false},
                                        {
                                            title: 'Téléphone',
                                            field: 'phone',
                                            filtering: false
                                        },
                                        {
                                            title: 'Date de création',
                                            field: 'createdAt',
                                            filtering: false,
                                            render: rowData => getCreatedAtValueForRow(rowData.createdAt),

                                        },
                                        {
                                            title: 'Dernière connexion',
                                            field: 'lastLogin',
                                            render: rowData => getLastLoginValueForRow(rowData.lastLogin),
                                            filtering: true,
                                            width: "10%"
                                        }
                                    ]}
                                    tableRef={tableRef}
                                    onSearchChange={() => {

                                        setRowCount('Liste contact (' + tableRef.current.state.data.length + ')');
                                    }}
                                    data={filteredData}
                                    title={rowCount}
                                    actions={[

                                        // {
                                        //     icon: () => <Icon icon="mi:filter"/>,
                                        //     tooltip: 'Afficher le filtre',
                                        //
                                        //     isFreeAction: true,
                                        //     onClick: (event) => {
                                        //         handleChange()
                                        //     }
                                        // },

                                        {
                                            icon: () => <Icon id='baseline-delete'
                                                              icon="material-symbols:delete-outline"/>,
                                            tooltip: "Supprimer tous les contacts sélectionnés",
                                            onClick: (event, rows) =>

                                                Swal.fire({
                                                    icon: 'warning',
                                                    title: 'Confirmation',
                                                    text: 'Confirmer la suppression des contacts sélectionnés ?',
                                                    showConfirmButton: true,
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Annuler',
                                                    dangerMode: true,
                                                })
                                                    .then(result => {

                                                        if (result.isConfirmed) {

                                                            var ids = rows.map(s => s.id);
                                                            let idsToString = ids.toString();

                                                            axios.delete('/admin/contacts?ids=' + idsToString)
                                                                .then(res => {

                                                                    handleDeleteRows(event, rows);

                                                                    if (rows.length == 1) {
                                                                        var message = "Le contact a bien été supprimé"
                                                                    } else {
                                                                        var message = "Les contacts ont bien été supprimés"
                                                                    }
                                                                    Swal.fire({
                                                                        title: "Fait!",
                                                                        text: message,
                                                                        icon: "success",
                                                                        timer: 2000,
                                                                        button: false
                                                                    })
                                                                })
                                                                .catch(function (error) {

                                                                        if (error.response.status === 401) {
                                                                            Swal.fire({
                                                                                icon: 'warning',
                                                                                title: 'Vous n\'êtes pas autorisé à supprimer ce contact',
                                                                                showConfirmButton: false,
                                                                                timer: 1500
                                                                            })
                                                                        }

                                                                    }
                                                                );


                                                        }
                                                    })
                                        },
                                        {
                                            icon: () => <Icon icon="uil:file-export"/>,
                                            tooltip: 'Exporter',
                                            onClick: (event, rows) => {
                                                exportToCSV(rows)
                                            },

                                        },
                                        {
                                            icon: () => <Icon icon="icon-park-outline:email-push"/>,
                                            tooltip: "Renvoyer le mail d'inscription",
                                            onClick: (event, rows) =>


                                                Swal.fire({
                                                    icon: 'warning',
                                                    title: rows.length == 1 ? "Confirmer l'envoi du mail de réinitialisation de mot de passe ?" : "Confirmer l'envoi du mail de réinitialisation de mot de passe ?",
                                                    showConfirmButton: true,
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Annuler',
                                                    dangerMode: true,
                                                })
                                                    .then(result => {

                                                        if (result.isConfirmed) {

                                                            var ids = rows.map(s => s.id);
                                                            let idsToString = ids.toString();

                                                            axios.post('/admin/mails/' + idsToString)
                                                                .then(res => {

                                                                    handleDeleteRows(event, rows);

                                                                    if (rows.length == 1) {
                                                                        var message = "Le mail a bien été envoyé"
                                                                    } else {
                                                                        var message = "Les mails ont bien été envoyés"
                                                                    }
                                                                    Swal.fire({
                                                                        title: "Fait!",
                                                                        text: message,
                                                                        icon: "success",
                                                                        timer: 2000,
                                                                        button: false
                                                                    })
                                                                })
                                                            ;


                                                        } else {
                                                            Swal.fire("Envoi(s) annulé(s)", '', 'info')
                                                        }
                                                    })
                                        }
                                    ]
                                    }
                                />
                            </ScrollWrapper>
                            {filteredData.length > 0 && (
                                <Pagination
                                    dataCount={dataCount}  // Total des données
                                    page={page}  // Page actuelle
                                    rowsPerPage={rowsPerPage}  // Nombre de lignes par page
                                    onPageChange={(newPage) => setPage(newPage)}  // Changer la page
                                    onRowsPerPageChange={(newRowsPerPage) => {
                                        setRowsPerPage(newRowsPerPage);
                                        setPage(0);  // Réinitialiser à la première page quand le nombre de lignes change
                                    }}
                                    ActionsComponent={TablePaginationActions}  // Composant personnalisé de pagination
                                    TablePaginationNullActions={TablePaginationNullActions}  // Composant sans actions
                                />
                            )}

                        </Col>
                    </Row>
                </div>
            </section>
            <ContactAddEdit user={user} isAddMode={isAddMode} show={show} setShow={setShow}
                            getAllContacts={fetchContacts}/>
        </Layout>
    );
}

export default ContactList;