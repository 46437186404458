// src/utils/lotUtils.js
export const handleHeaderCheckboxChange = ({
                                               checked,
                                               setChecked,
                                               lotList,
                                               setLotList,
                                               setTotalMntDG,
                                               formData=null
                                           }) => {
    const newChecked = !checked;
    formData?formData.mntDGChecked = newChecked:null;
    setChecked(newChecked);
    const updatedLots = lotList.map(row => ({ ...row, mntDG: 0 }));
    setLotList(updatedLots);
    setTotalMntDG(0);
};
