import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Container, Table, Button} from 'react-bootstrap';
import {convertFormatDate} from "../../component/ConvertFormatDate";
import {formatShortDate} from "../../component/formatShortDate";
import MailTable from "./component/MailTable";
import Swal from "sweetalert2";

function MessageListRow({messages, setMailSuggestions, mailSuggestions, mbox,setModalShow,setInitialValues}) {
    const [msgList, setMsgList] = useState([]);
    const [loading, setLoading] = useState(false);

    // Utilisation d'une expression régulière pour extraire le texte entre guillemets
    // Supposons que vous avez initialisé un ensemble (Set) pour stocker les contacts uniques.
    const contactsUniques = new Set();

    useEffect(() => {
        getMessageDetails();
    }, [messages]);
    useEffect(() => {

    }, [msgList]);

    useEffect(() => {

    }, [mailSuggestions]);

    const getMessageDetails = () => {
        const messageList = [];

        function getMessageDetails(message) {

            var messageUrl = `http://www.t1.viteimmo.com:3000/apo/mail/user/admin@laei.fr/read/` + message.id;

            if (mbox === 'draft') {

                messageUrl = `http://www.t1.viteimmo.com:3000/apo/mail/user/admin@laei.fr/read/draft/` + message.id;
            }

            return axios.get(messageUrl)
                .then((messageResponse) => {

                    const email = mbox === 'draft'?messageResponse.data.message:messageResponse.data;

                    if (email.payload && email.payload.headers) {
                        const headers = email.payload.headers;
                        const id = email.id;
                        const text = headers.find((header) => header.name === 'From').value;
                        const matches = text.match(/(.*?)</);
                        // Utilisation d'une expression régulière pour extraire le texte entre les chevrons

                        // Utilisation de l'expression régulière pour vérifier si des chevrons existent
                        const chevronsExistents = /<[^>]+>/g.test(text);

                        if (chevronsExistents) {
                            const email = text.match(/<([^>]*)>/);

                            if (!contactsUniques.has(email[1])) {
                                contactsUniques.add(email[1]);
                            }
                        } else {
                            const email = text;
                            if (!contactsUniques.has(email)) {
                                contactsUniques.add(email);
                            }
                        }

                        const from = matches ? matches[1].replace(/"/g, '').slice(0, 20) : text;
                        const subject = headers.find((header) => header.name === 'Subject').value;
                        const date = new Date(headers.find((header) => header.name === 'Date').value);
                        var unread = email.labelIds.includes('UNREAD') ? true : false;
                        messageList.push({
                            sender: from,
                            subject: subject,
                            date: formatShortDate(date),
                            id: id,
                            unread: unread
                        });
                    }
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des détails du message :', error);
                });
        }

        // Récupérez les détails de chaque message en parallèle
        const messagePromises = messages.map((message) => getMessageDetails(message));
        // Une fois que tous les détails ont été récupérés, affichez le tableau de messages
        Promise.all(messagePromises)
            .then(() => {
                const monTableau = Array.from(contactsUniques);
                setMailSuggestions(monTableau)
                setMsgList(messageList);
                setLoading(true);
                // console.log('Tableau de messages :', messageList);
            })
            .catch((error) => {
                console.error('Erreur lors de la récupération des détails des messages :', error);
            });
    }


    return (
        <div>

            <MailTable msgList={msgList} mbox={mbox} setModalShow={setModalShow} setInitialValues={setInitialValues}/>

        </div>
    );
}

export default MessageListRow;