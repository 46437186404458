import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import EmailComposer from "./EmailComposer";
import {Icon} from "@iconify/react/dist/iconify";
import {useNavigate} from "react-router-dom";

function Sidebar({mailSuggestions,modalShow,setModalShow,mbox,initialValues}) {

    let navigate = useNavigate();

    return (
                    <Card>
                        <Card.Body>
                            <>
                                <div className="d-flex justify-content-center align-items-center" > {/* Pour centrer verticalement */}
                                    <Button variant="primary w-100 mb-4" onClick={() => setModalShow(true)}>
                                        Nouveau message
                                    </Button></div>
                                {mailSuggestions.length > 0 ? <EmailComposer
                                    show={modalShow}
                                    setShow={setModalShow}
                                    mailSuggestions={mailSuggestions}
                                    onHide={() => setModalShow(false)}
                                    initialValues={initialValues}
                                /> : null}

                            </>
                            <ListGroup>
                                <ListGroup.Item className={`border-0 ${mbox=='inbox' ? 'link-primary' : ''}`} style={{cursor: 'pointer'}} onClick={() => {
                                    navigate('/admin/courriel/liste?mbox=inbox')
                                }}
                                                > <Icon width={'20px'} icon="uil:inbox"/> Boîte de réception</ListGroup.Item>
                                <ListGroup.Item className={`border-0 ${mbox=='draft' ? 'link-primary' : ''}`}  style={{cursor: 'pointer'}} onClick={() => {navigate('/admin/courriel/liste?mbox=draft')}}><Icon width={'20px'} icon="material-symbols:drafts-outline" /> Brouillons</ListGroup.Item>
                                <ListGroup.Item  className={`border-0 ${mbox=='sent' ? 'link-primary' : ''}`}  style={{cursor: 'pointer'}} onClick={() => {navigate('/admin/courriel/liste?mbox=sent')}}>
                                    <Icon width={'20px'} icon="fluent:send-16-regular"/> Messages envoyés</ListGroup.Item>

                            </ListGroup>
                        </Card.Body>
                    </Card>
    )
}

export default Sidebar;