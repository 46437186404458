import React, {useState, useEffect} from 'react';
import {Icon} from '@iconify/react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate,Link} from "react-router-dom";

const TopBar = ({user, toggleSize,handleTabSelect,activeTab,setVerticalCollapsed}) => {



    const [mediaNotifications, setMediaNotifications] = useState([]);
    const [lotNotifications, setLotNotifications] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    let navigate = useNavigate();

    const getNbrNotifications = () => {
        axios.get(`/admin/notifications`)
            .then(function (response) {

                setMediaNotifications(response.data.media)
                setLotNotifications(response.data.lot)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }


    const handleClickTopbar = () => {
        handleTabSelect('connection')

            navigate(`/admin/profil/${user.id}/voir?activeTab=connection`)

    }

    useEffect(() => {
        getNbrNotifications();

    }, []);
    useEffect(() => {
    }, [user]);


    return (
        <div className="bg-white navbar-header" id={'topbar'}>
            <div className={'d-flex navbar-collapse collapse'}>
                {/*<div className="navbar-brand-box d-lg-none d-md-block">*/}
                {/*    <span className="logo-sm">*/}
                {/*        <img*/}
                {/*            src={user.logoSm}*/}
                {/*            height="60"*/}
                {/*            className="d-inline-block align-top ps-2 logo-lg"*/}
                {/*            alt="Logo Viteimmo"*/}
                {/*        />*/}
                {/*       </span>*/}
                {/*</div>*/}
                <div>
            <Icon className="uil" height="20px" width="20px" icon='fe:bar' onClick={toggleSize}/></div>
                </div>
            <div className={"d-flex"}>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                { (user.role != 'ROLE_API_CONSUMER') ? (
                <Nav>

                    <NavDropdown title={<Icon style={{color: "#FE9C8B"}} height="20px" width="20px"
                                              icon='ic:twotone-browser-updated'/>} id="basic-nav-dropdown"
                                 drop='start'>
                        <div className={"notifications px-2"}>
                            <h2>Notifications -
                                <span> {mediaNotifications && mediaNotifications.length ? mediaNotifications.length : '0'}</span>
                            </h2>
                            {mediaNotifications && mediaNotifications.map((media, index) => {
                                return (
                                    <NavDropdown.Item key={index}>
                                        <Link to={`/admin/media/${media.id}/voir`}>
                                        <div
                                            className="notifications-item">

                                            <img src={media.url} alt="img"/>
                                            <div
                                                className="text">
                                                <h4>{media.name}</h4>
                                                <p>Ajout
                                                    d'un
                                                    nouveau
                                                    document</p>
                                            </div>
                                        </div>
                                        </Link>    </NavDropdown.Item>
                                )
                            })}
                        </div>
                    </NavDropdown>
                    <NavDropdown title={<Icon style={{color: "#39da8a"}} height="20px" width="20px"
                                              icon='ic:outline-update'/>} id="basic-nav-dropdown"
                                 drop='start'>
                        <div className={"notifications px-2"}>
                            <h2>Notifications -
                                <span>{lotNotifications && lotNotifications.length ? lotNotifications.length : '0'}</span>
                            </h2>
                            {lotNotifications && lotNotifications.map((media, index) => {
                                return (


                                    <div key={index}>           <a href="">
                                        <div
                                            className="notifications-item">

                                            <img src={media.url} alt="img"/>
                                            <div
                                                className="text">
                                                <h4>{media.name}</h4>
                                                <p>Mise à jour du prix de vente</p>
                                            </div>
                                        </div>
                                    </a>    </div>
                                )
                            })}
                        </div>

                    </NavDropdown>


                    <NavDropdown title={user.username} id="basic-nav-dropdown" drop='start'>
                        <div className={'dropdown-menu dropdown-menu-lg-end dropdown-menu-big p-4 user-menu show'}>

                            <div className={'d-flex align-items-center'}>

                                <figure className={'me-4 avatar avatar-lg border-0'}>
                                    <img src={'/assets/img/blank.png'}/>
                                </figure>
                                <div className={'d-flex flex-column'}>
                                    <div
                                        className={'font-weight-bold font-size-h5 text-dark-75 text-hover-primary'}>{user.username}</div>
                                    <div className={'navi mt-2'}>

                                        <div className={'navi-item mb-2 text-muted'}>
                                            <Icon className="uil" height="20px" width="20px" icon='cib:mail-ru'
                                                  onClick={toggleSize}/>
                                            <span>{user.email}</span>

                                        </div>
                                        <div onClick={() => {
                                            navigate(`/admin/profil/${user.id}/voir`)
                                        }} className={'btn-light-primary btn btn-outline-light btn-rounded'}>Gérer votre
                                            compte
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={"list-group"}>
                                <NavDropdown.Item >
                                    <div className={'bg-light-warning list-group-item mt-4 border-0'} onClick={handleClickTopbar}>
                                        <Icon style={{color: "#ffa800"}} className="uil" height="20px" width="20px"
                                              icon='carbon:password' onClick={toggleSize}/>
                                        <span>
                                  Changement de mot de passe
                                </span>

                                    </div>

                                </NavDropdown.Item>
                                <NavDropdown.Item >
                                    <div className={'bg-light-red mt-1 list-group-item border-0'}>
                                        <Icon style={{color: "#FE9C8B"}} className="uil" height="20px" width="20px"
                                              icon='octicon:mail-24' onClick={toggleSize}/>
                                        <span>
                                  Paramètres de messagerie
                                </span>

                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/logout">
                                    <div className={"bg-light-success mt-1 list-group-item border-0"}>
                                        <Icon style={{color: "#1bc5bd"}} className="uil" height="20px" width="20px"
                                              icon='ic:twotone-logout' onClick={toggleSize}/>

                                        <span>Déconnexion</span>
                                    </div>

                                </NavDropdown.Item>
                            </div>
                        </div>
                    </NavDropdown>
                </Nav>
                ) : (<></>)}
            </Navbar.Collapse></div>
        </div>
    );
};

export default TopBar;