import React from 'react';

export function pxVenteValue(rowData,role) {

        {
                var elem =  rowData.status > 2 ? '-' : rowData.salePrice;
        }

        return elem;
}

