import React from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../../component/Layout"
import LogApiTable from "./component/LogApiTable";

function LogApiList() {

    let navigate = useNavigate();




    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1">
                                <div className={'navbar-header'}>
                                    <h1>Logs</h1>
                                </div>
                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des logs</li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'content'}>
      <LogApiTable/>
            </section>

        </Layout>
    );
}

export default LogApiList;