import React from 'react';
import { Button } from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import {Icon} from '@iconify/react';

const AddReservationButton = ({ user }) => {

    let navigate = useNavigate();
    const shouldShowButton = () => {
        const codeClients = ['57004'];

        var show;
//Un prescripteur d'Immotep57 ne peut pas passer d'option
        if (user.role === "ROLE_PRESCRIPTEUR" && codeClients.includes(user.codeClient)) {
            show = false;
        } else if (user.role === "ROLE_PROMOTEUR" || user.role === "ROLE_ADMIN") {
            show = true;
        } else if (user.role === "ROLE_PRESCRIPTEUR") {
            show = true;
        } else {
            show = false;
        }

        return show;
    };
    const handleAddReservation = () => {

        navigate('/admin/reservation/ajouter')
    }

    return (
        <>
            {shouldShowButton() && (
                <Button
                    variant="primary"
                    border="success"
                    onClick={handleAddReservation}
                    className="me-1 text-white"
                    size="sm"
                >
                    <Icon icon="ic:round-add"/>
                    Réservation
                </Button>
            )}
        </>
    );


};



export default AddReservationButton;
