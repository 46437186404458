import React, {useState, useEffect} from 'react';
import {ErrorMessage} from "formik";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Button, Form, ProgressBar} from 'react-bootstrap';
import axios from "axios";
import Select from "react-select";
import 'dayjs/locale/fr'; // Import de la locale française
import ReCAPTCHA from 'react-google-recaptcha';
import {useNavigate} from "react-router-dom";
import FormValidation from "./FormValidation";

const InvestorForm = ({values, setFieldValue, handleChange, handleSubmit, optSuggestions, handleRecaptchaChange,role,setInitialValues,isValid,
                          isSubmitting,
                          step,
                          setStep,
                          validateForm,
                          handleNextStep,
                          setTouched}) => {


    const [countryList, setCountryList] = useState([])
    let navigate = useNavigate();

    const handleCheckChange = (event) => {

        setFieldValue('role',event.target.value)
        setSelectedRole(event.target.value);
    };

    const initialInvestorValues = {
        nom: '',
        prenom: '',
        email: '',
        age: '',
        phones: '',
        investorProfile: {
            previousBondInvestment: '',
            awareInvestmentRisks: '',
            awareBondIssuance: '',
            plannedBondInvestmentAmount: '',
        },
        role: 'ROLE_INVESTISSEUR',
        pays: {label: "France", value: 'FR'},
    };



    useEffect(() => {

        if (!role) {

            navigate({
                search: `?role=investisseur`
            });
            setInitialValues(initialInvestorValues)
        }
    }, []);




    const handlePrevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };


    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getAllCountries();

    }, [])

    return (
        <div>
            <ProgressBar now={(step / 8) * 100} className="mb-4" style={{height: '10px'}}/>
            {step === 2 && (
                <div className={'mb-3'}>
                    <h5 className={'pb-3 bold'}>Veuillez saisir vos coordonnés</h5>

                    <Row>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.nom}
                                    name="nom"
                                    aria-label="nom"
                                    placeholder={"Nom"}
                                    type="text"/>
                                <ErrorMessage name={`nom`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    name="prenom"
                                    placeholder={"Prénom"}
                                    aria-label="prenom"
                                    value={values.prenom}
                                    type="text"/>
                                <ErrorMessage name={`prenom`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    aria-label="email"
                                    placeholder={"Email"}
                                    type="text"/>
                                <ErrorMessage name={`email`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.phone}
                                    name="phones"
                                    aria-label="phones"
                                    placeholder={"Téléphone"}
                                    type="text"/>
                                <ErrorMessage name={`phones`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>


                    </Row>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}

            {step === 3 && (
                <div>
                    <h5 className={'pb-3 bold'}>Quel est votre âge ?</h5>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    name="age"
                                    placeholder={"Tapez votre réponse ici..."}
                                    aria-label="age"
                                    value={values.age}
                                    type="text"/>
                                <ErrorMessage name={`age`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}


            {step === 4 && (
                <div>
                    <h5 className={'pb-3 bold'}>Avez-vous déjà investi dans des obligations ?</h5>

                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check

                            inline
                            label="Oui"
                            onChange={(value) => {
                                setFieldValue('investorProfile.previousBondInvestment', true);
                            }}
                            value="true"
                            name="investorProfile.previousBondInvestment"
                            type={'radio'}
                            id="ouiOption"
                        /></div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Non"
                            value="false"
                            onChange={(value) => {
                                setFieldValue('investorProfile.previousBondInvestment', false);
                            }}
                            name="investorProfile.previousBondInvestment"
                            type={'radio'}
                            id="nonOption"
                        />
                    </div>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}

            {step === 5 && (
                <div>
                    <h5 className={'pb-3 bold'}>Savez-vous que l’investissement comporte des risques ?</h5>

                    <Row>

                        <div key={`inline-checkbox`} className="mb-3">
                            <Form.Check

                                inline
                                label="Oui"
                                onChange={(value) => {
                                    setFieldValue('investorProfile.awareInvestmentRisks', true);
                                }}
                                value={true}
                                name="investorProfile.awareInvestmentRisks"
                                type={'radio'}
                                id="ouiOption"
                            /></div>
                        <div key={`inline-checkbox`} className="mb-3">
                            <Form.Check
                                inline
                                label="Non"
                                value={false}
                                onChange={(value) => {
                                    setFieldValue('investorProfile.awareInvestmentRisks', false);
                                }}
                                name="investorProfile.awareInvestmentRisks"
                                type={'radio'}
                                id="nonOption"
                            />
                        </div>

                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}
            {step === 6 && (
                <div>
                    <h5 className={'pb-3 bold'}>Savez-vous ce qu’est une émission obligataire ?</h5>

                    <Row>

                        <div key={`inline-checkbox`} className="mb-3">
                            <Form.Check

                                inline
                                label="Oui"
                                onChange={(value) => {
                                    setFieldValue('investorProfile.awareBondIssuance', true);
                                }}
                                value={true}
                                name="investorProfile.awareBondIssuance"
                                type={'radio'}
                                id="ouiOption"
                            /></div>
                        <div key={`inline-checkbox`} className="mb-3">
                            <Form.Check
                                inline
                                label="Non"
                                value={false}
                                onChange={(value) => {
                                    setFieldValue('investorProfile.awareBondIssuance', false);
                                }}
                                name="investorProfile.awareBondIssuance"
                                type={'radio'}
                                id="nonOption"
                            />
                        </div>

                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}

            {step === 7 && (
                <div>
                    <h5 className={'pb-3 bold'}>Combien envisagez-vous d’investir dans les obligations ?</h5>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    name="investorProfile.plannedBondInvestmentAmount"
                                    placeholder={"Tapez votre réponse ici..."}
                                    aria-label="plannedBondInvestmentAmount"
                                    value={values.investorProfile.plannedBondInvestmentAmoun}
                                    type="text"/>
                                <ErrorMessage name={`investorProfile.plannedBondInvestmentAmount`} component="div"
                                              className="error mt-1"/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}


            {step === 8 && (
                <div>
                    <h5 className={'pb-3 bold'}>Dans quel pays résidez-vous actuellement ?</h5>

                    <Row>
                        <Col>

                            {countryList.length > 0 ? (
                                <Form.Group className="mb-3">

                                    <Select
                                        options={countryList}
                                        onChange={newValue =>
                                            setFieldValue(`pays`, newValue)}
                                        name="pays"
                                        value={values.pays}
                                        defaultValue={{label: "France", value: 'FR'}}
                                    />

                                </Form.Group>
                            ) : (<></>)}


                        </Col>
                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}

            {step === 9 && (
                <FormValidation handleRecaptchaChange={handleRecaptchaChange} optSuggestions={optSuggestions}
                                handleSubmit={handleSubmit} values={values} setFieldValue={setFieldValue}
                                handleChange={handleChange}/>
            )}


        </div>
    )
}

export default InvestorForm;