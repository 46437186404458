import React, {createContext, useState, useEffect, useRef} from 'react';
import Layout from "../../component/Layout"
import {useNavigate, useParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Swal from 'sweetalert2'
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Icon} from "@iconify/react/dist/iconify";
import OptionHome from "./OptionHome";

import {fetchIsContractAvailable} from "../../component/FetchIsContractAvailable";
import Button from "react-bootstrap/Button";
import axios from 'axios';
import {refreshMediaList} from "../../component/RefreshMediaList";
import {getMedias} from "../../services/mediaService";

export const FormContext = createContext();

function OptionEdit({user}) {

    let navigate = useNavigate();
    const [id, setId] = useState(useParams().id)
    const [isAddMode, setIsAddMode] = useState(!id)
    var [key, setKey] = useState('home');
    const [option, setOption] = useState({acquereurs: [], lots: [], medias: []});
    const formikRef = useRef(null);
    const [lots, setLots] = useState(false);
    async function optionConfirm(id, fields) {

        if (fields.statut === 1) {

            Swal.fire({
                icon: 'warning',
                title: "Validation",
                text: "Souhaitez-vous accepter l'option ? Un mail de confirmation sera envoyé au contact.",
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                dangerMode: true,
            })
                .then(result => {

                        if (result.isConfirmed) {

                            updateOption(id, fields);
                        }
                    }
                );
        } else if (fields.statut === 0) {

            Swal.fire({
                icon: 'warning',
                title: "Confirmation",
                text: "Souhaitez-vous refuser l'option ? Un mail de refus sera envoyé au contact.",
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                dangerMode: true,
            })
                .then(result => {

                        if (result.isConfirmed) {

                            updateOption(id, fields);
                        }
                    }
                );
        }


    }

    async function updateOption(id, fields) {

        try {
            axios.patch(`/admin/option/${id}`, fields)

            Swal.fire({
                icon: 'success',
                title: 'Option mise à jour avec succès!',
                showConfirmButton: false,
                timer: 1500
            })
            getOption()
        } catch (error) {
            // Gérer les erreurs
            console.error('Une erreur s\'est produite lors du téléchargement des fichiers', error);
            // Lancez une exception pour arrêter l'exécution du reste du code
            throw error;
        }
    }


    function handleSelect(key) {
        setKey(key)
    }

    const getStatusValueForRow = {
        0: <span className="badge text-bg-danger">refusé</span>,
        1: <span className="badge text-bg-success">accepté</span>,
        2: <span className="badge text-bg-warning text-white">en cours</span>, // just for example, remove it if you don't need
        3: <span className="badge text-bg-danger">expiré</span>,

    };

    function getOption() {
        axios.get(`/admin/options/${id}`)
            .then(function (response) {
                let option = response.data;

                setOption(option)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getOption();

    }, []);
    useEffect(() => {


    }, [lots]);

    const renderTabsSwitch = () => {

        return <Tabs
            defaultActiveKey="home"
            activeKey={key}
            onSelect={handleSelect}
            id="uncontrolled-tab-example"
            className="mb-3"
        ><Tab eventKey="home" title={<span><Icon icon="cil:description"/>{" Caractéristiques"}</span>}>
            {option.id ?
                <OptionHome option={option} formikRef={formikRef}
                            handleSelect={handleSelect} id={id} user={user} updateOption={updateOption} setLots={setLots}/> : null}
        </Tab>

        </Tabs>
            ;
    }

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1 d-flex">
                                <div>

                                    <div className="d-flex align-items-center mb-2">
                                        <h1 className={"d-inline me-2"}>Option </h1>{getStatusValueForRow[option.statut]}
                                    </div>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
                                            navigate(`/admin/option/liste`)
                                        }}>
                                            Liste des options
                                        </li>
                                        <li className="breadcrumb-item active ">{isAddMode ? "Ajouter une option" : "Editer une option"}</li>
                                    </ol>
                                </div>
                                {user.role === 'ROLE_PRESCRIPTEUR' || user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ?
                                    <div>

                                        <Button
                                            variant={"success"}
                                            onClick={() => {
                                                if (formikRef.current) {
                                                    formikRef.current.handleSubmit();
                                                }
                                            }}
                                        >
                                            <Icon icon="radix-icons:update" className={'me-2'}/>
                                            Mettre à jour
                                        </Button>
                                        {user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ?
                                            <span> <Button
                                                className={'ms-2'}
                                                variant={"outline-danger"}
                                                onClick={() => {
                                                    optionConfirm(id, {statut: 0,lots:lots})
                                                }}
                                            >
                                            <Icon icon="icon-park-outline:reject" className={'me-2'}/>
                                            Refuser
                                        </Button>   <Button
                                                className={'ms-2'}
                                                variant={"primary"}
                                                onClick={() => {
                                                    optionConfirm(id, {statut: 1,lots:lots,sendMail:true})
                                                }}
                                            >
                                            <Icon icon="icon-park-outline:reject" className={'me-2'}/>
                                            Accepter
                                        </Button></span> : null}


                                    </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    {
                        <Row>
                            {renderTabsSwitch()}
                        </Row>


                    }
                </div>
            </section>
            {/*</form>*/}

        </Layout>
    );
}

export default OptionEdit;