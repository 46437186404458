import React, {useState, useEffect} from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import Card from 'react-bootstrap/Card';
import {useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CardInfoUser from "../Component/CardInfoUser";

const TimelineTabUser =({user}) =>{

    const [id, setId] = useState(useParams().id)
    const [timeline, setTimeline] = useState([])

    const getActivity = () => {

        var url=`/admin/users/${id}/timeline`;

        axios.get(url)
            .then(function (response) {

                var timeline = response.data;
                setTimeline(timeline)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getActivity();
    }, [])

    return(

     <div>
             <Row>
             <Col lg="4">
             <CardInfoUser user={user}/>
         </Col>
             <Col lg="8">
                 <Card>
                     <Card.Body>
                         <Card.Title className={'pt-4'}>Chronologie des activités</Card.Title>
            <ul className={"timeline mt-5"}>

                {timeline.length > 0 ? (

                   <div>

                {timeline.map((value, index) => {
                    const point = `timeline-point timeline-point-primary ${value.alert}`;
                    return (

                        <li key={index} className={"timeline-item timeline-item-transparent"}>
                            <span className={point}></span>
                            <div className={'timeline-event'}>
                                <div className={'timeline-header mb-1'}>
                                    <h6>{value.message}</h6>
                                    <small className="text-muted">{value.updatedAt}</small>
                                </div>
                                <p className={"text-muted"}>{value.description}</p>

                            </div>
                        </li>
                    );
                }
                )}
                   </div>
                ) : (<></>)}
            </ul>
                     </Card.Body>
                 </Card>
             </Col>
             </Row>

</div>


    )
}

export default TimelineTabUser;