import React from 'react';
import {useNavigate} from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';


export function indentChildren(rowData) {
        const padding = rowData.parentId ? 15 : 0;

        return (
            <div style={{ paddingLeft: `${padding}px` }}>
                    {rowData.nrLot}
            </div>
        );
}

