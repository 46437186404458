import {ErrorMessage, FieldArray, Formik} from "formik";
import React, {useContext, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {FormContext} from "../ReservationAddEdit";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import MaterialTable from "@material-table/core";
import {Icon} from '@iconify/react';
import Select from "react-select";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {handleLotSelectChange} from "../../../component/handleLotSelectChange";
import {indentChildren} from "../../../component/indentChildren";

function Property({user}) {

    const {
        activeStepIndex,
        setActiveStepIndex,
        formData,
        setFormData,
        sideBarTab,
        setSideBarTab,
        programId,
        setProgramId,
        filteredData,
        setFilteredData,
        lotList,
        setLotList,
        selectedLots,
        setSelectedLots,
        codeClient,
        setCodeClient,
        option
    } = useContext(FormContext);

    const [hideColumn, setHideColumn] = useState(user.role === 'ROLE_PROMOTEUR' ? false : true)
    const [programList, setProgramList] = useState([])
    const [grilleList, setGrilleList] = useState([])
    const [trancheList, setTrancheList] = useState([])
    const [selectedOption, setSelectedOption] = useState()
    const [filteredOptions, setFilteredOptions] = useState()
    const [nrTranche, setNrTranche] = useState('')
    const [nrGrille, setNrGrille] = useState('')
    const renderError = (message) => (
        <p className="error">{message}</p>
    );

    const handleCellClick = (rowData) => {
        // Action à effectuer lors du clic sur un champ d'une colonne
        setProgramId(rowData.id)
        setCodeClient(rowData.codeClient)
        const dataSideBarUpdate = {...sideBarTab};
        dataSideBarUpdate.program = rowData.label;
        setSideBarTab({...dataSideBarUpdate})
        getLotList(rowData.id)
        getTrancheGrilleList(rowData.id)
    };

    const renderGrillesSelect = rowData => {

        return (
            <Select
                options={filteredOptions}
                placeholder={'Filtrer par grille'}
                onChange={(option) => handleSelectedOption({'label': 'grille', 'value': option.value})}
                name="grille"
            />
        )
    };

    const handleSelectedOption = option => {

        if (option.label === 'tranche') {

            setSelectedOption(option.value)
            setNrTranche(option.value)
        } else {
            setNrGrille(option.value)
        }
    };

    const renderTranchesSelect = rowData => {

        return (
            <Select
                options={trancheList}
                placeholder={'Filtrer par tranche'}
                onChange={(option) => handleSelectedOption({'label': 'tranche', 'value': option.value})}
                name="tranche"
            />)
    };

    const ValidationSchema = yup.object().shape({
        lots: yup.array()
            .min(1, "Veuillez sélectionner au moins un lot")
    });

    useEffect(() => {

        if (option) {

            setProgramId(option.programId);
            setFilteredData(option.lots);
            setLotList(option.lots);
        } else {

            setFilteredData(nrTranche === 'all' && nrGrille === 'all' ? lotList : lotList.filter(function (item) {

                if (nrGrille && nrGrille != 'all') {
                    if (item.grille != nrGrille)
                        return false;
                }
                if (nrTranche && nrTranche != 'all') {
                    if (item.tranche != nrTranche)
                        return false;
                }

                return true;
            }))

            getProgrammeList()
            //Liste déroulante conditionnelle dans react-select
            setFilteredOptions(selectedOption === '' || nrTranche == 'all' ? grilleList : grilleList.filter(dt => dt.link === nrTranche || dt.link === 'all'));
        }
    }, [selectedOption, nrTranche, nrGrille])

    const getSwitchValueForRow = value => {

        var elem = value ? <Icon style={{color: "#27A9E1"}} width={'30px'} height={'30px'} icon="mdi:toggle-switch"/> :
            <Icon style={{color: "#8c8c8d"}} width={'30px'} height={'30px'} icon="mdi:toggle-switch-off"/>

        return elem;
    };

    const displayThumbnailAction = rowData => {

        var elem = <div className="prg-thumb">
            <img className={"thumbnail-list"} src={rowData.thumbnail}/>
        </div>;

        return elem;
    };

    const getTrancheGrilleList = (programId) => {
        axios.get(`/admin/tranche/programmes/${programId}`)
            .then(function (response) {

                setTrancheList(response.data.tranches);
                setGrilleList(response.data.grilles);
                setFilteredOptions(response.data.grilles);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getProgrammeList = () => {
        axios.get(`/admin/list/programmes`)
            .then(function (response) {

                setProgramList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getLotList = (programId) => {
        axios.get(`/admin/users/programmes/${programId}/lots?tranches=${nrTranche}&grilles=${nrGrille}`)
            .then(function (response) {

                setFilteredData(response.data.lotList);
                setLotList(response.data.lotList);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    return (
        <div>
            {programId == 0 ?
                <div className={'ms-2 mt-4 mb-1'}><h6>Programme</h6>
                    <small>
                        Veuillez sélectionner un programme.</small>
                </div>
                : null}

            <section>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12 mt-4">

                            {programId == 0 ?

                                <div id="program-selection" style={{maxWidth: '100%'}}>

                                    <MaterialTable
                                        localization={{
                                            pagination: {
                                                labelDisplayedRows: '{from}-{to} of {count}',
                                                labelRowsSelect: 'lignes'
                                            },
                                            toolbar: {
                                                nRowsSelected: '{0} programme(s) sélectionnée(s)',
                                                searchPlaceholder: 'Chercher'
                                            },
                                            body: {
                                                emptyDataSourceMessage: 'Aucun programme à afficher',
                                                filterRow: {
                                                    filterTooltip: 'Filter'
                                                }
                                            }
                                        }}

                                        options={{
                                            showSelectAllCheckbox: false,
                                            pageSize: 5,
                                            toolbar: false
                                        }}
                                        columns={[
                                            {
                                                title: '',
                                                field: 'thumbnail',
                                                render: rowData => displayThumbnailAction(rowData)
                                            },
                                            {
                                                title: 'Libellé', field: 'label',
                                                filtering: false,
                                                render: rowData => (
                                                    <div onClick={() => handleCellClick(rowData)}
                                                         className={"link-primary"} style={{cursor: 'pointer'}}>
                                                        {rowData.label}
                                                    </div>
                                                )
                                            },
                                            {
                                                title: "Prêt à taux zéro",
                                                field: 'PTZ',
                                                filtering: false,
                                                render: rowData => getSwitchValueForRow(rowData.PTZ),
                                                align: "center"
                                            },
                                            {
                                                title: 'Promoteur',
                                                field: 'promoteur',
                                                filtering: false,
                                                hidden: hideColumn
                                            }
                                        ]}


                                        onSearchChange={() => {

                                            setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                                        }}
                                        data={programList}


                                    />
                                    <div className="actions clearfix">
                                        <button className="rounded-md font-medium text-white my-2 p-2 me-2"
                                                onClick={() => {
                                                    setActiveStepIndex(activeStepIndex - 1)
                                                }}>
                                            Précédent
                                        </button>
                                        <button
                                            className="rounded-md font-medium text-white my-2 p-2"
                                            type="submit"
                                        >
                                            Suivant
                                        </button>
                                    </div>
                                </div>


                                :
                                <Formik
                                    initialValues={{
                                        lots: selectedLots,
                                        idPromoteur: codeClient,
                                        option: option ? option.id : null
                                    }}
                                    validationSchema={ValidationSchema}
                                    onSubmit={(values) => {

                                        const data = {...formData, ...values};
                                        setFormData(data);
                                        setActiveStepIndex(activeStepIndex + 1);
                                    }}
                                >
                                    {({
                                          handleSubmit,
                                          handleChange,
                                          values,
                                          errors,
                                          isValid,
                                          isSubmitting,
                                          touched,
                                          setFieldValue,
                                          handleBlur, // handler for onBlur event of form elements
                                      }) => {

                                        return (
                                            <Form className="flex flex-col justify-center items-center" noValidate
                                                  onSubmit={handleSubmit}>
                                                <div style={{maxWidth: '100%'}}>
                                                    <Row className={"my-4"}>
                                                        <Col xs={12} xl={6}>
                                                            <div className={'ms-2 mb-1'}><h6>Lot</h6>
                                                                <small>
                                                                    Veuillez sélectionner un ou plusieurs lots.</small>
                                                            </div>
                                                        </Col>
                                                        <Col xxs={12} xl={3} className={'mb-1'}>{renderTranchesSelect()}</Col>
                                                        <Col xs={12} xl={3}>{renderGrillesSelect()}</Col>
                                                    </Row>
                                                    <MaterialTable
                                                        localization={{
                                                            pagination: {
                                                                labelDisplayedRows: '{from}-{to} of {count}',
                                                                labelRowsSelect: 'lignes'
                                                            },
                                                            toolbar: {
                                                                nRowsSelected: '{0} lot(s) sélectionnée(s)',
                                                                searchPlaceholder: 'Chercher'
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Aucun lot à afficher',
                                                                filterRow: {
                                                                    filterTooltip: 'Filter'
                                                                }
                                                            }
                                                        }}
                                                        parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                                        title={""}
                                                        options={{
                                                            showSelectAllCheckbox: false,
                                                            selection: true,
                                                            pageSize: 5,
                                                            toolbar: true,
                                                        }}

                                                        columns={[
                                                            {
                                                                title: 'N° Lot', field: 'nrLot', filtering: false,
                                                                render: rowData => indentChildren(rowData),
                                                            },
                                                            {
                                                                title: 'Tranche', field: 'tranche',
                                                            },
                                                            {
                                                                title: 'Grille', field: 'grille',
                                                            },
                                                            {
                                                                title: 'Prix de vente',
                                                                field: 'salePrice',
                                                                align: 'right',
                                                                filtering: false,
                                                            },
                                                        ]}
                                                        onSelectionChange={(rows, row) => handleLotSelectChange(rows, row, setSelectedLots, setFieldValue, setFilteredData, filteredData)}
                                                        onSearchChange={() => {

                                                            // setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                                                        }}
                                                        data={filteredData}
                                                    />
                                                </div>
                                                <FieldArray name="lots">
                                                    {({insert, remove, push}) => (
                                                        <div></div>
                                                    )

                                                    }
                                                </FieldArray>

                                                <ErrorMessage className={"error"} name="lots" render={renderError}/>
                                                <div className="actions clearfix">
                                                    <button className="rounded-md font-medium text-white my-2 p-2 me-2"
                                                            onClick={() => {
                                                                setProgramId(0)
                                                                setActiveStepIndex(activeStepIndex - 1)
                                                            }}>
                                                        Précédent
                                                    </button>
                                                    <button
                                                        disabled={!isValid || isSubmitting}
                                                        className="rounded-md font-medium text-white my-2 p-2"
                                                        type="submit"
                                                    >
                                                        Suivant
                                                    </button>
                                                </div>
                                            </Form>)
                                    }}
                                </Formik>
                            }


                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
}

export default Property;