import React, {useState, useEffect} from 'react';
import MaterialTable from "@material-table/core";
import axios from "axios";
import Swal from "sweetalert2";
import {Icon} from "@iconify/react/dist/iconify";
import {useNavigate} from "react-router-dom";
import {dateValueForRow} from "../../../component/DateValueForRow";
import {convertDate} from "../../../component/convertDate";



const MeetingTable  =({meetingList, getAllMeetings, setIsAddMode,setShow}) =>{

    const [filter, setFilter] = useState(false)
    const [rowCount, setRowCount] = useState('Liste des rendez-vous')
    const [selectedRow, setSelectedRow] = useState(null);
    const handleShow = () => setShow(true);
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {

    }, [meetingList]);

    const handleIconClick = (event,rowData) => {

        setAnchorEl(event.currentTarget);
        setSelectedRow(rowData);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = (event,selectedRow) => {

        Swal.fire({
            icon: 'warning',
            title: 'Confirmer la suppression des rendez-vous sélectionnées ?',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            dangerMode: true,
        })
            .then(result => {

                if (result.isConfirmed) {

                    var ids = selectedRow.map(s => s.id);
                    let idsToString = ids.toString();

                    axios.delete('/admin/meetings?ids=' + idsToString)
                        .then(res => {

                            getAllMeetings();

                            if (selectedRow.length == 1) {
                                var message = "Le rendez-vous a bien été supprimée"
                            } else {
                                var message = "Les rendez-vous ont bien été supprimées"
                            }
                            Swal.fire({
                                title: "Fait!",
                                text: message,
                                icon: "success",
                                timer: 2000,
                                button: false
                            })
                        })
                        .catch(function (error) {

                                if (error.response.status === 401) {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'Vous n\'êtes pas autorisé à supprimer ce rendez-vous',
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                }

                            }
                        );


                } else {
                    Swal.fire('Votre rendez-vous est maintenu', '', 'info')
                }
            })
        setAnchorEl(null);
    };

    const tableRef = React.useRef();


    return(

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div style={{maxWidth: '100%'}}>

                            <MaterialTable
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                        labelRowsSelect: 'lignes'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} sélectionné',
                                        searchPlaceholder: 'Chercher'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Aucune campagne à afficher',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                options={{
                                    pageSize: 25,
                                    selection:true,
                                    filtering: filter,
                                    actionsColumnIndex: -1,
                                    toolbarButtonAlignment:"right",
                                    addRowPosition: 'last', // Ajouter les nouvelles lignes en bas du tableau
                                }}

                                columns={[
                                    {
                                        title: 'Objet',
                                        field: 'title',
                                        align: "left",
                                    },

                                    {
                                        title: 'Début',
                                        field: 'start',
                                        align: "center",
                                        render: rowData => dateValueForRow(convertDate(new Date(rowData.start)))
                                    },
                                    {
                                        title: 'Fin',
                                        field: 'end',
                                        align: "center",
                                        render: rowData => dateValueForRow(convertDate(new Date(rowData.end)))
                                    },
                                    {
                                        title: 'Promoteur',
                                        field: 'promoteur',
                                        align: "left",
                                    },
                                    // {
                                    //     title: 'Contact',
                                    //     field: 'endTime',
                                    //     align: "center",
                                    // }

                                ]}

                                tableRef={tableRef}
                                onSearchChange={() => {

                                    setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                                }}
                                data={meetingList}
                                title={rowCount}
                                actions={[
                                    // {
                                    //     icon: () => <Icon icon="fluent:edit-16-filled"/>,
                                    //     tooltip: "Modifier la campagne",
                                    //     onClick: (event, rows) => {
                                    //
                                    //         setId(rows[0].id);
                                    //         setIsAddMode(false);
                                    //         handleShow()
                                    //     }
                                    // },

                                    // {
                                    //     icon: () => <Icon width={'20px'} icon="fluent:send-16-regular"/>,
                                    //     tooltip: "Envoyer la campagne",
                                    //     onClick: (event, rows) => {
                                    //         callSendCampaign(rows[0].id);
                                    //         // setId(rows[0].id);
                                    //         // setIsAddMode(false);
                                    //         // handleShow()
                                    //     }
                                    // },
                                    {
                                        icon: () => <Icon className={'text-secondary float-right me-4'} icon="material-symbols:delete-outline"/>,
                                        tooltip: "Supprimer tous les rendez-vous sélectionnés",
                                        onClick: (event, rows) =>{

                                handleDelete(event,rows);

                                    }},
                                    // {
                                    //     icon: () => <Icon icon="pepicons-pop:dots-y"/>,
                                    //     tooltip: 'Autres actions',
                                    //     onClick: (event,rows) => {
                                    //         setSelectedRow(null);
                                    //         handleIconClick(event,rows)
                                    //     },
                                    // }
                                    ,
                                ]
                                }

                            />
                            {/*<Menu*/}
                            {/*    anchorEl={anchorEl}*/}
                            {/*    open={Boolean(anchorEl)}*/}
                            {/*    onClose={handleMenuClose}*/}
                            {/*    style={{ top: '50px', left: '0' }}*/}
                            {/*>*/}
                            {/*    <MenuItem onClick={handleDelete}> <Icon style={{color: "#a8a5a5"}} icon="material-symbols:delete-outline"/><small>Supprimer</small></MenuItem>*/}

                            {/*</Menu>*/}
                        </div>
                    </div>
                </div>
            </div>

    )
}

export default MeetingTable;