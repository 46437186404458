import React, {useState, useEffect} from 'react';
import Layout from "../../component/Layout";
import Container from 'react-bootstrap/Container';
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import {Document, Page, pdfjs} from 'react-pdf';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {getPdfValueForRow} from "../../component/getPdfValueForRow";
import {Icon} from "@iconify/react/dist/iconify";


pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function MediaView({history, match}) {

    const [lot, setLot] = useState({lat: '', long: '', medias: {slider1: {}}})
    const [lots, setLots] = useState('')
    const [codeClient, setCodeClient] = useState('')
    const [media, setMedia] = useState('')
    const [center, setCenter] = useState({lat: '', long: ''})
    const [adminRole, setAdminRole] = useState('')
    const [id, setId] = useState(useParams().id)
    let navigate = useNavigate();
    const [mediaList, setMediaList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const containerStyle = {
        width: '100%', // Ajustez la largeur du conteneur selon vos besoins// Ajustez la hauteur du conteneur selon vos besoins
        overflow: 'hidden', // Empêche le débordement du contenu
    };
    const [numPages, setNumPages] = useState(null);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < numPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const fetchMedia = async () => {
        try {
            const response = await axios.get(`/admin/medias/${id}`);
            var data = response.data;
            fetchMediaData(response.data.contentType);
            setMedia(data);
            setIsLoading(true);

        } catch (error) {
            setIsLoading(true);
            Swal.fire({
                icon: 'error',
                title: 'Une erreur s\'est produite!',
                showConfirmButton: false,
                timer: 1500
            })

        }
    };


    const fetchMediaData = async (contentType) => {
        if(contentType==="application/pdf"){

            try {

                const response = await axios.get(`/admin/media-data/${id}`, {
                    responseType: 'arraybuffer',  // Spécifiez le type de réponse comme tableau d'octets
                });
                const buffer = response.data;
                const pdf = await pdfjs.getDocument({data: buffer}); // Supposons que vous ayez déjà chargé votre PDF

                const pdfDocument = await pdf.promise;
                setNumPages(pdfDocument.numPages);

            } catch (error) {
                console.error('Une erreur s\'est produite lors de la récupération du média :', error);
            }

        }

    };


    useEffect(() => {

        fetchMedia();
    }, [])


    return (

        <Layout>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4 ">
                            <div className="navbar navbar-default" role="navigation">
                                <div className="navbar-header">
                                    <a href="#"><h1>
                                        Média
                                    </h1></a></div>

                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span></li>
                                        <li className={"link-primary"} onClick={() => {
                                            navigate('/admin/media/liste')
                                        }}
                                        >
                                            <span className={"px-1 cursor-pointer"}>Liste des médias</span>
                                        </li>
                                        <li>
                                            <span className={"ps-1"}>Média</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <Container fluid>
                    <Row>
                        <Col lg="3">
                            <Card>
                                <Card.Body className={'px-4'}>
                                    <h5 className={"pb-2 border-bottom mb-4"}>Média</h5>
                                    <div className={"info-container mb-4"}>
                                        <Table responsive="xl" borderless>
                                            <tbody>
                                            <tr>
                                                <td className={"font-weight-bold"}>Téléchargement</td>
                                                <td>{getPdfValueForRow(media.url)}</td>
                                            </tr>
                                            <tr>
                                                <td className={"font-weight-bold"}>Nom</td>
                                                <td>{media.name}</td>

                                            </tr>
                                            <tr>

                                                <td className={"font-weight-bold"}>Largeur</td>
                                                <td>{media.width}</td>

                                            </tr>
                                            <tr>

                                                <td className={"font-weight-bold"}>Hauteur</td>
                                                <td>{media.height}</td>

                                            </tr>
                                            <tr>

                                                <td className={"font-weight-bold"}>Type de fichier</td>
                                                <td>{media.contentType}</td>

                                            </tr>
                                            <tr>
                                                <td className={"font-weight-bold"}>Taille</td>
                                                <td>{media.size}</td>

                                            </tr>
                                            <tr>
                                                <td className={"font-weight-bold"}>Contexte</td>
                                                <td>{media.context}</td>

                                            </tr>
                                            <tr>
                                                <td className={"font-weight-bold"}>Créé le</td>
                                                <td>{media.createdAt}</td>

                                            </tr>
                                            <tr>
                                                <td className={"font-weight-bold"}>Etiquette</td>
                                                <td>{media.tags}</td>

                                            </tr>
                                            </tbody>
                                        </Table>


                                    </div>
                                    {/*<div className={'d-flex justify-content-center pt-3'}>*/}
                                    {/*    <Button variant="primary">Modifier</Button></div>*/}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="9">
                            <Card>
                                <Card.Body>
                                    <h2 className={'pt-2'}>Visualisation</h2>
                                    {media.contentType!="application/pdf"? <img src={media.url} />  :               <div style={containerStyle}>
                                        <Document file={media.url}>
                                            <Page pageNumber={currentPage} renderTextLayer={false}
                                                  renderAnnotationLayer={false}/>
                                        </Document>
                                        <div>
                                            <button onClick={handlePreviousPage} disabled={currentPage === 1}
                                                    className={"btn btn-primary"}>
                                                <Icon
                                                    // onClick={(e) => openPdfInNewTab(e,dataFile.preview)}
                                                    // style={{color: "#8c8c8d"}}
                                                    // width={'18px'}
                                                    // height={'18px'}
                                                    icon="ooui:previous-ltr"

                                                />
                                            </button>
                                            <span> Page {currentPage} de {numPages} </span>
                                            <button onClick={handleNextPage} disabled={currentPage === numPages}
                                                    className={"btn btn-primary"}>
                                                <Icon
                                                    // onClick={(e) => openPdfInNewTab(e,dataFile.preview)}
                                                    // style={{color: "#8c8c8d"}}
                                                    // width={'18px'}
                                                    // height={'18px'}
                                                    icon="ooui:previous-rtl"
                                                />
                                            </button>
                                        </div>
                                    </div>}

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

        </Layout>
    )

}

export default MediaView;