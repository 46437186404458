var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Grid } from '@mui/material';
export function FileHeader(_a) {
    var file = _a.file, onDelete = _a.onDelete;
    var name = file.name;
    var result;
    return (_jsxs(Grid, __assign({ container: true, justifyContent: "space-between", alignItems: "center" }, { children: [_jsx("svg", __assign({ width: '25', height: '25', xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }, { children: _jsx("path", { fill: "#f55e30", d: "M9,10h1a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm0,2a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,13.05,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Zm-3-3H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" }) })), _jsx(Grid, __assign({ item: true }, { children: name.slice(0, 30) + '...' })), _jsx(Grid, __assign({ item: true }, { children: _jsx(Button, __assign({ size: "small", onClick: function () { return onDelete(file); } }, { children: _jsx("svg", __assign({ width: '25px', height: '25px', xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }, { children: _jsx("path", { fill: "#f55e30", d: "M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" }) })) })) }))] })));
}
