import React, {useState, useEffect} from 'react';
import {ErrorMessage} from "formik";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Form, ProgressBar} from 'react-bootstrap';
import CityAndZipAutocomplete from "./CityAutocomplete";
import axios from "axios";
import 'dayjs/locale/fr'; // Import de la locale française
import ReCAPTCHA from 'react-google-recaptcha';

const DefaultForm = ({values,setFieldValue,handleChange,handleRecaptchaChange,role}) => {

    const [countryList, setCountryList] = useState([])
    const [idsSelectionnes, setIdsSelectionnes] = useState([2, 3, 4]);
    const [showPassword, setShowPassword] = useState(false);

    function selectCheckOptions(role) {
        if (role === 'fournisseur') {

            setIdsSelectionnes([1, 2, 3, 4]);
        } else {

            setIdsSelectionnes([2, 3, 4]);

        }
    }

    useEffect(() => {

        selectCheckOptions(role)
    }, []);

    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getAllCountries();

    }, [])

    return (

                <div>
                    <ProgressBar now={ 100} className="mb-4" style={{ height: '10px' }}/>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.username}
                                    name="username"
                                    id= "username"
                                    aria-label= "username"
                                    placeholder={"Nom d'utilisateur"}
                                    type="text"/>
                                <ErrorMessage name={`username`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    aria-label= "email"
                                    placeholder={"Email"}
                                    type="text"/>
                                <ErrorMessage name={`email`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.nom}
                                    name="nom"
                                    aria-label= "nom"
                                    placeholder={"Nom"}
                                    type="text"/>
                                <ErrorMessage name={`nom`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    name="prenom"
                                    placeholder={"Prénom"}
                                    aria-label= "prenom"
                                    value={values.prenom}
                                    type="text"/>
                                <ErrorMessage name={`prenom`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>

                    <CityAndZipAutocomplete setFieldValue={setFieldValue} values={values}/>
                    <Row>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.phones}
                                    name="phones"
                                    aria-label= "phones"
                                    placeholder={"Téléphone"}
                                    type="text"/>
                                <ErrorMessage name={`phones`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    name="promoCode"
                                    placeholder={"Code promo"}
                                    value={values.promoCode}
                                    type="text"/>
                                <ErrorMessage name={`promoCode`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>

                                <Form.Control
                                    onChange={handleChange}
                                    name="password"
                                    aria-label="password"
                                    placeholder={"Mot de passe"}
                                    value={values.password}
                                    type="password"/>
                            </Form.Group>
                            <ErrorMessage name={`password`} component="div" className="error"/>
                        </Col>
                        <Col>


                            <Form.Control
                                onChange={handleChange}
                                name="confirmPassword"
                                value={values.confirmPassword}
                                type={showPassword ? 'text' : 'password'}
                                placeholder={"Confirmer le mot de passe"}
                                aria-label="confirmPassword"
                                aria-describedby="basic-addon2"
                            />

                            <ErrorMessage name={`confirmPassword`} component="div" className="error"/>
                        </Col>

                    </Row>
                    <Row>
                        <Form.Group className={'mt-3'}>


                            <Form.Check
                                name={`permissionContact`}
                                checked={values.permissionContact} // Définit la valeur
                                type="checkbox"
                                label={"J'autorise le promoteur à me contacter ? (Oui/Non)"}
                                // value={item.value}
                                onChange={handleChange}
                            />

                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6}>
                            <div style={{transform: "scale(0.85)", transformOrigin: "0 0"}}>

                                <ReCAPTCHA
                                    className={"mb-5 w-100 g-recaptcha"}
                                    sitekey="6Lca4ncpAAAAAKJVtAjxE5j_Q_iQ-UddoOeE3F5Z"
                                    onChange={handleRecaptchaChange}
                                /></div>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                    {/*<Button*/}
                    {/*    type="submit"*/}
                    {/*    name={"submit"}*/}
                    {/*    className={'w-100'}>Envoyer</Button>*/}
                </div>
    )
}

export default DefaultForm;