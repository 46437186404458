import { FormContext} from "../ReservationAddEdit";
import Buyer from "../Forms/Buyer";
import Property from "../Forms/Property";
import React, { useContext } from "react";
import Funding from "../Forms/Funding";
import Validation from "../Forms/Validation";
import Loan from "../Forms/Loan";
import Contract from "../Forms/Contract";


function Step({user}) {

    const { activeStepIndex } = useContext(FormContext);
    let stepContent;

    switch (activeStepIndex) {
        case 0:
            stepContent = <Buyer />;
            break;
        case 1:
            stepContent = <Property user={user} />;
            break;
        case 2:
            stepContent = <Funding />;
            break;
        case 3:
            stepContent = <Loan />;
            break;
        case 4:
            stepContent = <Contract />;
            break;
        case 5:
            stepContent = <Validation />;
            break;
        default:
            break;
    }

    return stepContent;
}

export default Step;