import React, {useState, useEffect, useRef} from 'react';
import Layout from "../../component/Layout";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import axios from "axios";
import Swal from "sweetalert2";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {Icon} from '@iconify/react';
import TimelineTabUser from "./Tab/TimelineTabUser";
import ParametersTab from "./Tab/ParametersTab";
import SecurityTab from "./Tab/SecurityTab";

function UserView({user,setUser,handleTabSelect,activeTab}) {

    const [isImpersonating, setIsImpersonating] = useState(false);

    const renderTabsSwitch = (role) => {

                return <Tabs
                    defaultActiveKey='home'
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    id="uncontrolled-tab-example"
                    className="mb-3">
                    <Tab eventKey="home" title={<span><Icon icon="bx:user"/>{" Profil"}</span>}>
                    <TimelineTabUser user={user}/>
                </Tab>
                    <Tab eventKey="setting" title={<span><Icon icon="uil:setting"/>{" Paramètres"}</span>}>
                        <ParametersTab user={user} setUser={setUser} isImpersonating={isImpersonating} setIsImpersonating={setIsImpersonating}/>
                    </Tab>
                    <Tab eventKey="connection" title={<span><Icon icon="bx:link-alt"/>{" Sécurité"}</span>}>
                        <SecurityTab user={user} setUser={setUser} isImpersonating={isImpersonating} setIsImpersonating={setIsImpersonating}/>
                    </Tab>
                </Tabs>
    }

    const getIsImpersonating = () => {
        axios.get(`/admin/is-impersonating`)
            .then(function (response) {

                response.data===true?setIsImpersonating(true):null;
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getIsImpersonating();
    }, []);

    return (<Layout>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="px-3 pt-3">

                        <div className={"sticky-wrapper mt-4"}>
                            <div
                                className="user-profile-header d-flex flex-sm-row text-sm-start text-center mb-4">
                                <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                    <img src='/assets/img/blank.png' width={'110'}
                                           className="d-block h-auto ms-0 ms-sm-4 rounded-3 user-profile-img"/>
                                </div>
                                <div className="align-self-end">
                                    <div
                                        className="d-flex align-items-sm-start justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                        <div className="user-profile-info">
                                            <h4 className={"mb-3 font-weight-bold"}>{user.name}</h4>
                                            <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                                                <li>
                                                    <Icon
                                                        icon="material-symbols:verified-user-outline-rounded"/> {user.role}
                                                </li>
                                                <li>
                                                    <Icon icon="mdi:address-marker-outline"/> {user.city}
                                                </li>
                                                <li>
                                                    <Icon icon="material-symbols:calendar-today-outline-sharp"/> Rejoint
                                                    le {user.joined}
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div id={'profil-tabs'}>
                                {renderTabsSwitch(user.role)}
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export default UserView;