import {ErrorMessage, FieldArray, Formik} from "formik";
import Form from "react-bootstrap/Form";
import {FormContext} from "../ReservationAddEdit";
import * as yup from "yup";
import React, {useState, useEffect, useContext} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {MultipleFileUpload} from "../../../component/MultipleFileUpload";
import ClauseForm from "../component/ClauseForm";


function Contract() {

    const [isClauseToogled, setIsClauseToogled] = useState(false);

    const [previousFiles, setPreviousFiles] = useState([]);
    const {
        activeStepIndex,
        setActiveStepIndex,
        formData,
        setFormData,
        medias,
        setMedias,
        isAddMode,
        clauses,
        isClause,
        setIsClause,
        setClauses,
        setIsAddMode,
        dataFiles,
        setDataFiles
    } = useContext(FormContext);
    var [key, setKey] = useState('0');
    const [initialValues, setInitialValues] = useState({
        isClause: isClause,
    });
    useEffect(() => {

    }, [key])


    useEffect(() => {
        isClause === false ? setIsClauseToogled(false) : setIsClauseToogled(true)
    }, []);

    const title = (index) => {
        var i = index + 1
        var title = index == 0 ? 'Clause' : 'Clause ' + i;

        return title;
    }

    const onClauseChange = (setFieldValue, value, values,setValues) => {

        setFieldValue(`isClause`, value)
        setIsClause(value)

        if (value) {
            setFieldValue(`clauses`, clauses)
        } else {
            delete values.clauses;
            values.isClause=false;
            setValues(values);
        }

        value === false ? setIsClauseToogled(false) : setIsClauseToogled(true)
    }

    // Schéma de validation pour chaque prêt
    const clauseSchema = yup.object().shape({
        title: yup.string()
            .when('$isClause', {
                is: true, // si conditionSuspensive est vrai
                then: yup.string().required('Un titre est requis.'),
                otherwise: yup.string().notRequired(),
            }),
        // Ajoutez d'autres champs ici si nécessaire
    });

    // Schéma de validation principal
    const ValidationSchema = yup.object().shape({
        isClause: yup.boolean().required(),
        clauses: yup.array()
            .of(clauseSchema)
            .when('isClause', {
                is: true,
                then: yup.array()
                    .of(clauseSchema)
                    .required('Le tableau de clauses est requis lorsque la condition suspensive est vraie.')
                    .min(1, 'Au moins une clause doit être renseigné.'),
            }),
    });


    function handleSelect(key) {
        setKey(key)
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {

                setClauses(values.clauses);
                const data = {...formData, ...values};
                setFormData(data);
                setMedias(values.medias);
                setActiveStepIndex(activeStepIndex + 1);
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  isValid,
                  isSubmitting,
                  touched,
                  setFieldValue,
                  setValues,
                  handleBlur, // handler for onBlur event of form elements
              }) => {

                return (

                    <Form className="flex flex-col justify-center items-center mt-4" noValidate onSubmit={handleSubmit}>
                        <div className={'mb-5'}><h6>Clause libre</h6>
                            <small>
                                Ajouter des clauses spécifiques au contrat. Vous pouvez définir des clauses
                                personnalisées en fonction des besoins particuliers du contrat.</small>

                            <div key={`inline-checkbox`} className={'mt-4'}>
                                <Form.Label className={"me-2"}>Ajouter une ou plusieurs clauses au contrat
                                    ?</Form.Label>

                                <Form.Check
                                    inline
                                    checked={values.isClause === true ? true : false}
                                    type="radio"
                                    value={true}
                                    onChange={selection =>
                                        onClauseChange(setFieldValue, true, values, setValues)

                                    }
                                    name="isClause"
                                    label="Oui"
                                    id={`inline-radio-1`}
                                />
                                <Form.Check
                                    inline
                                    checked={values.isClause === false ? true : false}
                                    value={false}
                                    type="radio"
                                    onChange={selection =>
                                        onClauseChange(setFieldValue, false, values, setValues)

                                    }
                                    name="isClause"
                                    label="Non"
                                    id={"inline-radio-2"}
                                />
                            </div>


                            {isClauseToogled == false ? <></> :

                                <div>
                                    <FieldArray name="clauses">

                                        {({insert, remove, push}) => (
                                            <div>
                                                <div
                                                    className={"d-flex align-items-center justify-content-between my-4"}>

                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="math_symbol me-2"
                                                            onClick={() => push({
                                                                description: '',
                                                                title: '',
                                                            })}
                                                        >
                                                            +
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="math_symbol"
                                                            onClick={() => remove(key)}
                                                        >
                                                            -
                                                        </button>
                                                    </div>

                                                </div>
                                                <Tabs
                                                    defaultActiveKey="0"
                                                    activeKey={key}
                                                    className="mb-3"
                                                    onSelect={handleSelect}
                                                >
                                                    {values.clauses && values.clauses.length > 0 ?
                                                        values.clauses.map((clause, index) => (

                                                            <Tab
                                                                key={index}
                                                                eventKey={index}
                                                                title={title(index)}>

                                                                {
                                                                    <ClauseForm isValid={isValid} clause={clause}
                                                                                index={index}
                                                                                handleChange={handleChange}
                                                                                title={title(index)}
                                                                                setKey={setKey}
                                                                                setFieldValue={setFieldValue}
                                                                                values={values} errors={errors}
                                                                                touched={touched}
                                                                                handleBlur={handleBlur}/>
                                                                }


                                                            </Tab>

                                                        ))


                                                        : null}

                                                </Tabs>

                                            </div>
                                        )}
                                    </FieldArray></div>
                            }
                        </div>
                        <div className={'ms-2 mb-4 mt-4'}><h6>Annexes</h6>
                            <small>
                                Annexes </small>
                        </div>
                        <MultipleFileUpload dataFiles={dataFiles} setDataFiles={setDataFiles}
                                            name="files" isAddMode={isAddMode}
                                            setIsAddMode={setIsAddMode} medias={medias}
                                            previousFiles={previousFiles}
                                            setFieldValue={setFieldValue}/>

                        <div className="actions clearfix">
                            <button className="rounded-md font-medium text-white my-2 p-2 me-2"
                                    onClick={() => {
                                        setActiveStepIndex(activeStepIndex - 1)
                                    }}>
                                Précédent
                            </button>
                            <button
                                className="rounded-md font-medium text-white my-2 p-2"
                                type="submit"
                            >
                                Suivant
                            </button>
                        </div>
                    </Form>

                )
            }}

        </Formik>

    );
}

export default Contract;