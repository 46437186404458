import {Formik, useFormikContext} from 'formik';
import * as Yup from "yup";
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import {InputGroup, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Icon} from "@iconify/react/dist/iconify";
import Tooltip from '@mui/material/Tooltip';

const Filter = ({user, lotList,setLotList, setInitialValues, setFiltered, optionsFilter,programmeId,fetchLots,initialValues}) => {


    const {setFieldValue, values, submitForm, handleChange} = useFormikContext();
    const [programList, setProgramList] = useState([])

    useEffect(() => {

    }, [lotList]);

    useEffect(() => {

        getProgrammeList();
        // Appel de la fonction de rafraîchissement automatique avec le délai de debounce
        fetchLots(values);
        setFiltered(true);

    }, [values, submitForm]);


    useEffect(() => {

    }, [optionsFilter]);
    // Fonction de rafraîchissement automatique du formulaire
    const typeOfProperty = [
        {
            label: "Maison",
            options: [
                {label: "Tout types", value: 100},
                {label: "Maison T1", value: 101},
                {label: "Maison T2", value: 102},
                {label: "Maison T3", value: 103},
                {label: "Maison T4", value: 104},
                {label: "Maison T5 et +", value: 105},
                {label: "Maison autre type", value: 199},
            ]
        },
        {
            label: "Appartement",
            options: [
                {label: "Tout types", value: 200},
                {label: "Appartement T1", value: 201},
                {label: "Appartement T2", value: 202},
                {label: "Appartement T3", value: 203},
                {label: "Appartement T4", value: 204},
                {label: "Appartement T5 et +", value: 205},
                {label: "Appartement autre type", value: 299},
            ]
        },
        {
            label: "Terrain",
            options: [
                {label: "Tout types", value: 300},
            ]
        },
        {
            label: "Locaux annexes",
            options: [
                {label: "Tout types", value: 200},
                {label: "Garage", value: 401},
                {label: "Parking", value: 402},
                {label: "Cave", value: 403},
                {label: "Cellier", value: 404},
                {label: "Local", value: 405},
                {label: "Autre type", value: 499},
            ]
        },
        {
            label: "Autres",
            options: [
                {label: "Tout types", value: 500},
            ]
        },
        {
            label: "Bureau",
            options: [
                {label: "Local commercial", value: 500},
            ]
        }
    ];

    function transformIdsToObjects(ids) {
        const transformedArray = ids.map(id => {
            const found = typeOfProperty.flatMap(type => type.options).find(option => option.value === id);
            return {label: found.label, value: found.value};
        });
        return transformedArray;
    }

    const getFilter = (id) => {

        return axios.get(`/api/search-preferences/${id}`)
            .then(function (response) {

                const fields = response.data;

                Object.entries(fields).forEach(([key, value]) => {

                    if (key === 'typeBien' && value) {

                        const transformedArray = transformIdsToObjects(value);
                        setFieldValue(key, transformedArray, false);
                    } else if (key === 'programme' && value) {

                        const programmeFound = programList.find(option => option.value === value.id);

                        setFieldValue(key, programmeFound, false);
                    } else {

                        setFieldValue(key, value, false);
                    }
                });
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }



    const getProgrammeList = () => {
        axios.get(`/admin/programmes`)
            .then(function (response) {

                setProgramList(response.data);


            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const customStyles = {

        menuPortal: base => ({
            ...base,
            zIndex: 9999, flex: 1,
            height: 35,
            minHeight: 35
        })
    };

    return (
        <Row className={'d-flex justify-content-between'}>



            <Col lg={2} xl={2} xxl={1} className={'mt-2'}>

                    <Form.Control
                        onChange={handleChange}
                        style={{ padding: '.55rem' }}
                        className={'mx-0'}
                        name="q"
                        placeholder={'N° de lot'}
                        value={values.q}
                        type="text"
                    />
                </Col>

            {programList.length > 0 ?
                <Col lg={3} xl={2} xxl={2} className={'mt-2'}>
                    <Select
                        placeholder={<div>Programme</div>}
                        className={'px-0'}
                        options={programList}
                        styles={customStyles}
                        onChange={(value) => {
                            setFieldValue('programme', value, false);
                        }}
                        name="programme"
                        value={values.programme}
                        defaultValue={values.programme}
                    />
                </Col> : ''}
            <Col lg={3} xl={3} xxl={2} className={'mt-2'}>


                <Select
                    isMulti
                    placeholder={<div>Type de bien</div>}
                    options={typeOfProperty}
                    styles={customStyles}
                    id={"select-type-bien"}
                    onChange={(value) => {

                        setFieldValue('typeBien', value, false);
                    }}
                    name="typeBien"
                    value={values.typeBien}
                    defaultValue={values.typeBien}
                />

            </Col>

            {user.role === 'ROLE_PROSPECT' ?
                <Col lg={3} xl={3} xxl={2} className={'mt-2'}>

                    <Form.Control
                        onChange={handleChange}
                        placeholder={'Budget Max'}
                        name="maxPrice"
                        value={values.maxPrice}
                        type="text"
                    />

                </Col>
                :
                <Col lg={4} xl={3} xxl={2} className={'mt-2'}>
                    <InputGroup>
                        <InputGroup.Text>Budget</InputGroup.Text>
                        <Form.Control
                            onChange={handleChange}
                            name="minPrice"
                            placeholder={'Min'}
                            value={values.minPrice}
                            type="text"
                        />
                        <Form.Control
                            onChange={handleChange}
                            placeholder={'Max'}
                            name="maxPrice"
                            value={values.maxPrice}
                            type="text"
                        />
                    </InputGroup> </Col>
            }

            <Col lg={4} xl={3} xxl={2} className={'mt-2'}>

                <InputGroup>
                    <InputGroup.Text>Surface</InputGroup.Text>
                    <Form.Control
                        onChange={handleChange}
                        name="minSurface"
                        placeholder={'Min'}
                        value={values.minSurface}
                        type="text"
                    />
                    <Form.Control
                        onChange={handleChange}
                        name="maxSurface"
                        placeholder={'Max'}
                        value={values.maxSurface}
                        type="text"
                    />
                </InputGroup> </Col>



            <Col className="d-flex justify-content-end mt-2">
                {optionsFilter ?
                    <Col lg={4} xl={4} xxl={4} className={'me-1'}>
                        <Select
                            options={optionsFilter}
                            onChange={(option) => option ? getFilter(option.value) : null}
                            id={"select-role"}
                            name="role"
                            isClearable={true}
                            defaultValue={{label: "Choisir un filtre", value: 'all'}}

                        />
                    </Col> : null}
                {/* Colonne pour les boutons alignés à droite */}
                <Button variant="success" type={'submit'} className="me-2">
                <Tooltip title='Enregistrer votre filtre de recherche'>


                    <Icon width={'22px'} icon="line-md:filter-plus"/>


                </Tooltip>
                </Button>
                <Button variant="secondary" onClick={() => { fetchLots(initialValues)}}>
                    <Tooltip title='Réinitialiser la recherche'>
                        <Icon width={'20px'} icon="lucide:filter-x"/></Tooltip>
                </Button>

            </Col>

</Row>

    )
}


const FilterLot = ({user, lotList,setLotList, initialValues, setInitialValues, setFiltered,programmeId,fetchLots}) => {

    const [optionsFilter, setOptionsFilter] = useState([])
    const validationSchema = Yup.object().shape({});

    useEffect(() => {
        getAllFilter();
    }, []);

    const getFilterList = () => {

        return axios.get(`/api/search-preferences`)
            .then(function (response) {

                return response.data;
            })
            .catch(function (error) {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    function getAllFilter() {

        getFilterList()
            .then((filters) => {

                setOptionsFilter(filters);
            })
            .catch((error) => {
                // Gérer les erreurs de récupération des options
            });
    }

    function onSubmit(values, actions) {

        const fields = {...values};
        fields.programme = fields.programme.value;
        fields.typeBien.length === 0 ? delete fields.typeBien : null;
        createSearchPreferences(fields);
        actions.setSubmitting(false);
    }

    function createSearchPreferences(fields) {

        Swal.fire({
            title: "Enregistrer votre recherche",
            input: "text",
            inputLabel: "Nom de la recherche",
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            inputValidator: (value) => {
                if (!value) {
                    return "Vous devez saisir un nom pour sauvegarder votre recherche";
                }
            }
        })
            .then(result => {

                if (result.isConfirmed) {

                    fields.programme === 'all' ? delete fields.programme : null;
                    fields.label = result.value;

                    axios.post(`/admin/search-preferences`, fields)
                        .then(function (response) {

                            getAllFilter();

                            Swal.fire({
                                icon: 'success',
                                title: 'Votre recherche est enregistrée!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        })
                        .catch(function (error) {
                            console.log(error)
                            Swal.fire({
                                icon: 'error',
                                title: 'Une erreur s\'est produite!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        });


                }

            })

    }

    return (
        <Card className={'mb-3'} id={'theft-card'}>

            <Card.Body>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {


                        return (

                            <Form noValidate onSubmit={handleSubmit}>

                                <Filter
                                    user={user}
                                    optionsFilter={optionsFilter}
                                    setInitialValues={setInitialValues}
                                    setLotList={setLotList}
                                    lotList={lotList}
                                    setFiltered={setFiltered}
                                    programmeId={programmeId}
                                    fetchLots={fetchLots}
                                />
                            </Form>)
                    }}
                </Formik>
            </Card.Body>
        </Card>


    );
}

export default FilterLot;