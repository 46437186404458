import React, {useState, useEffect} from 'react';
import Layout from "../../component/Layout";
import Container from 'react-bootstrap/Container';
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {Icon} from '@iconify/react';
import ProgramHome from "./component/ProgramHome";
import MediaTab from "../../component/MediaTab";
import MyMap from "../../component/MyMap";
import ReservationContractCard from "../../component/ReservationContractCard";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {fetchUserData} from "../../component/fetchUserData";
import MarketTable from "../Market/component/MarketTable";
import InvoiceTable from "../Invoice/component/InvoiceTable";
import ReserveTable from "../Reserve/component/ReserveTable";
import GoogleMapComponent from "../../component/GoogleMapComponent";
import LotsTab from "./component/LotsTab";
import {handleAddOption} from "../../utils/handleAddOption";
import Button from 'react-bootstrap/Button';

function ProgramView({history, match, user, isFullSize}) {

    const [program, setProgram] = useState({lat: '', long: '', medias: {slider1: {}}})
    const [lots, setLots] = useState('')
    const [codeClient, setCodeClient] = useState('')
    const [templateReservation, setTemplateReservation] = useState('')
    const [adminRole, setAdminRole] = useState('')
    const [selectedRows, setSelectedRows] = useState([]);
    const [active, setActive] = useState(true);
    const [id, setId] = useState(useParams().id)
    let navigate = useNavigate();
    const [mediaList, setMediaList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const libraries = ['places', 'geometry'];
    const isLoaded = GoogleMapComponent();
    const [workSituationsList, setWorkSituationsList] = useState([])
    const [description, setDescription] = useState('');
    const [plan3D, setPlan3D] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [progress, setProgress] = useState('');

    const fetchProgramme = async () => {
        try {
            const response = await axios.get(`/admin/programmes/${id}`);
            var data = response.data;
            setProgram(data);
            setCodeClient(data.codeClient)
            setDescription(data.description)
            setPlan3D(data.url3DPlan)
            setVideoUrl(data.videoUrl)
            setProgress(data.progress)
            console.log(data.templateReservation)
            setTemplateReservation(data.templateReservation)
            setIsLoading(true); // Définir isLoading sur false lorsque les données sont chargées
        } catch (error) {
            setIsLoading(false);

            if (error.response.status = '401') {

                Swal.fire({
                    icon: 'warning',
                    title: error.response.data,
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
    };
    useEffect(() => {

    }, [progress]);


    const handleProgrammeUpdate = async () => {

        try {
            await axios.patch(`/admin/programme/${id}`, {
                description: description,
                progress: progress,
                url3DPlan: plan3D,
                videoUrl:videoUrl
            });

            Swal.fire({
                icon: 'success',
                title: 'Programme mis à jour avec succès!',
                showConfirmButton: false,
                timer: 1500
            })

        } catch (error) {

        }
    };

    const fetchLotsData = async () => {
        try {
            const response = await axios.get(`/admin/programmes/${id}/lots`);
            setLots(response.data);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Une erreur s\'est produite!',
                showConfirmButton: false,
                timer: 1500
            })
        }
    };

    const getMedias = () => {

        axios.get(`/admin/entity/${id}/medias?entity=programme`)
            .then(function (response) {
                setMediaList(response.data)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    async function getUserData() {
        try {
            const userData = await fetchUserData();
            setAdminRole(userData.role)
            // Faites quelque chose avec les données utilisateur
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la récupération des données utilisateur :', error);
            // Gérez l'erreur de manière appropriée
        }
    }

    useEffect(() => {
        getUserData()
        fetchProgramme();
        fetchLotsData();
        getMedias();
        getAllWorkSituations();

    }, [])

    useEffect(() => {

    }, [templateReservation])

    const getAllWorkSituations = () => {
        axios.get(`/admin/programme/${id}/worksituations`)
            .then(function (response) {

                if (response.data) {
                    setWorkSituationsList(response.data);

                }
            })
            .catch(function (error) {

                if (error.response.status = '401') {

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }


    const [key, setKey] = useState(sessionStorage.getItem("activeTab") || "home");


    useEffect(() => {
        sessionStorage.setItem("activeTab", key);
    }, [key]);
    const renderTabsSwitch = () => {

        return <Tabs
            onSelect={(k) => setKey(k)}
            activeKey={key}
            defaultActiveKey="home"
            id="programme"
            className="mb-3"
        ><Tab eventKey="home" title={<span><Icon icon="cil:description"/>{" Caractéristiques"}</span>}>
            {adminRole != '' ?
                <ProgramHome medias={mediaList} program={program} lots={lots} adminRole={adminRole} user={user}
                             workSituationsList={workSituationsList}
                             getAllWorkSituations={getAllWorkSituations} description={description}
                             setDescription={setDescription} setPlan3D={setPlan3D} plan3D={plan3D} videoUrl={videoUrl}
                             setVideoUrl={setVideoUrl} progress={progress} setProgress={setProgress}/> : null}
        </Tab>
            <Tab eventKey="lots" title={<span><Icon icon="uil:create-dashboard"/>{" Lots"}</span>}>
                <LotsTab setSelectedRows={setSelectedRows} setActive={setActive} programmeId={id} user={user}
                         isFullSize={isFullSize}/>
            </Tab>
            <Tab eventKey="setting" title={<span><Icon icon="iconoir:media-image"/>{" Médias"}</span>}>
                <Row>
                    {user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ?
                        <Col sm={12} md={12} lg={12} xl={4} xxl={3}>
                        <ReservationContractCard codeClient={codeClient} idProgramme={id}
                                                 templateReservation={templateReservation}
                                                 setTemplateReservation={setTemplateReservation} fetchProgramme={fetchProgramme}/>
                    </Col> : null}
                    {adminRole === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ?
                        <Col  lg={12} xl={8} xxl={9} > <MediaTab medias={mediaList}/> </Col>:
                        <Col lg={12}> <MediaTab medias={mediaList}/></Col> }

                       </Row>
            </Tab>
            <Tab eventKey="connection"
                 title={<span><Icon icon="mdi:address-marker-outline"/>{" Géolocalisation"}</span>}>

                {isLoading && isLoaded ?
                    <MyMap longitude={program.long} latitude={program.lat} isLoaded={isLoaded}/> : null}

            </Tab>

            {adminRole === 'ROLE_FOURNISSEUR' ? <Tab eventKey="invoice"
                                                     title={<span><Icon icon="la:file-invoice"/>{" Factures"}</span>}>
                <InvoiceTable user={user} programmeId={id}/>

            </Tab> : null}

            {adminRole === 'ROLE_FOURNISSEUR' ? <Tab eventKey="market"
                                                     title={<span><Icon
                                                         icon="icon-park-outline:pie-two"/>{" Marchés"}</span>}>
                <MarketTable user={user} programmeId={id}/>

            </Tab> : null}
            {adminRole === 'ROLE_FOURNISSEUR' ? <Tab eventKey="reserve"
                                                     title={<span><Icon
                                                         icon="ri:menu-search-line"/>{" Réserves"}</span>}>
                <ReserveTable user={user} programmeId={id}/>

            </Tab> : null}
        </Tabs>;
    }

    return (<Layout>

            <section className="content-header">
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">
                            <div className="navbar navbar-default p-1 d-flex">
                                <div>
                                    <h1> {program.label}</h1>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span></li>
                                        <li className={"link-primary"} onClick={() => {
                                            navigate('/admin/programmes/list')
                                        }}
                                        >
                                            <span className={"px-1 cursor-pointer"}>Liste des programmes</span>
                                        </li>
                                        <li>
                                            <span className={"ps-1"}>{program.label}</span>
                                        </li>
                                    </ol>
                                </div>
                                <div>
                                    {user.role === 'ROLE_PROSPECT' || user.role === 'ROLE_ACQUEREUR' || user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ?
                                        <Button variant="primary" border="success" className="me-1" size="sm"
                                                disabled={active} onClick={() => {
                                            navigate(handleAddOption(selectedRows, user));
                                        }}>Poser une option</Button> : null}
                                    {user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' && user.codeClient === '00003' || user.codeClient === '57006' || user.codeClient === '00000' ?
                                        <Button variant="success" border="success" className="mx-1" size="sm"
                                                onClick={() => {
                                                    navigate(handleProgrammeUpdate(selectedRows, user));
                                                }}>Mettre à jour</Button> : null}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <Container fluid>
                    {renderTabsSwitch()}
                </Container>
            </section>

        </Layout>
    )

}

export default ProgramView;