import React, {useState, useEffect} from 'react';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";
import {Row} from "react-bootstrap";


const CardMailbox = ({user, setUser, isImpersonating, setIsImpersonating}) => {

    const [optionsImpersonateUser, setOptionsImpersonateUser] = useState([]);
    const [userId, setUserId] = useState(''); // Identifiant de l'utilisateur à impersonaliser

    const getAllImpersonateUsers = () => {
        axios.get(`/admin/impersonate/users`)
            .then(function (response) {

                if (response.data) {
                    setOptionsImpersonateUser(response.data);

                }
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }


    useEffect(() => {

        getAllImpersonateUsers();
    }, [setUserId]);

    return (
        <Card>
            <Card.Body className={'px-4'}>

                <h5 className={"pb-4 border-bottom mb-4"}>Messagerie</h5>
                <div className={"info-container mb-4"}>
                    <Row className="mt-4">

                        <div className={'w-90 mx-auto'}>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <img width={'90'} src="/assets/svg/google-gmail.svg" alt="Mon SVG"/></div>
                            <div className={"text-center"}>
                                <div className={'mb-3'}>Gmail</div>
                                Connectez un compte Gmail à votre boîte de réception
                            </div>
                            <div className={'d-flex justify-content-center align-items-center mt-3'}>
                                <Button
                                    href={`https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${window.location.origin}/admin/courriel/liste&response_type=code&client_id=213487261698-v64ltgfr3po2eg6ummg7snhqianp8qpl.apps.googleusercontent.com&scope=https://mail.google.com/+https://www.googleapis.com/auth/calendar+https://www.googleapis.com/auth/userinfo.email&approval_prompt=force&access_type=offline`}
                                    variant="primary">Valider</Button>
                            </div>

                        </div>

                    </Row>


                </div>

            </Card.Body>
        </Card>
    )
}

export default CardMailbox;