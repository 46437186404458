import axios from 'axios';
import Swal from 'sweetalert2'; // Si vous utilisez SweetAlert pour les alertes

export const getMedias = (id, setMediaList) => {
    axios.get(`/admin/entity/${id}/medias?entity=reservation`)
        .then(function (response) {
            setMediaList(response.data);
        })
        .catch(function (error) {
            Swal.fire({
                icon: 'error',
                title: 'Une erreur s\'est produite!',
                showConfirmButton: false,
                timer: 1500
            });
        });
};