import {ErrorMessage, FieldArray, Formik} from "formik";
import Form from "react-bootstrap/Form";
import {FormContext} from "../ReservationAddEdit";
import * as yup from "yup";
import React, {useState, useEffect, useContext} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import 'react-tabs/style/react-tabs.css';
import TabContent from "../../../component/TabContent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import {checkEmailExists} from "../../../service/ContactService";
import {Icon} from '@iconify/react';
function Buyer() {

    const [isNotaryToogled, setIsNotaryToogled] = useState(false);
    const [isPrescripteurToogled, setIsPrescripteurToogled] = useState(false);
    const [isProjectManagerToogled, setIsProjectManagerToogled] = useState(false);
    const [countryList, setCountryList] = useState([])
    const {
        activeStepIndex,
        setActiveStepIndex,
        formData,
        setFormData,
        buyers,
        setBuyers,
        sideBarTab,
        setSideBarTab
    } = useContext(FormContext);
    var [key, setKey] = useState('0');

    useEffect(() => {

    }, [key])

    useEffect(() => {
        getAllCountries();
    }, []);
    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const title = (index) => {

        var title = index == 0 ? 'Acquéreur principal' : 'Coacquéreur ' + index;

        return title;
    }

    const onIsNotaryChange = (setFieldValue, value) => {

        setFieldValue(`isNotary`, value)
        value === false ? setIsNotaryToogled(false) : setIsNotaryToogled(true)

    }

    const onIsPrescripteurChange = (setFieldValue, value) => {

        setFieldValue(`isPrescripteur`, value)
        value === false ? setIsPrescripteurToogled(false) : setIsPrescripteurToogled(true)

    }

    const onIsProjectManagerChange = (setFieldValue, value) => {

        setFieldValue(`isProjectManager`, value)
        value === false ? setIsProjectManagerToogled(false) : setIsProjectManagerToogled(true)

    }

    const ValidationSchema = yup.object().shape({
        // name: yup.string().required(),
        // email: yup.string().email().required(),
        buyers: yup.array()
            .of(
                yup.object().shape({
                    profession: yup.string().required("Veuillez renseigner une profession").nullable(),
                    prenom: yup.string().required("Le prénom est requis"),
                    nom: yup.string().required("Le nom est requis"),
                    // Champ 'nom' de 'product' requis lorsque selectedOption est égal à 3
                    society: yup.object().when("typeOfPerson", {
                        // check if the boolean is false,
                        is: (typeOfPerson) => typeOfPerson.value === 1,
                        // if it is false, then use an object with required properties
                        then: yup.object().shape({
                            nom: yup.string().required('Le nom de la société est requis'),
                            siretNumber: yup.string().required('Le siret de la société est requis').length(17, 'Le numéro de siret doit contenir exactement 17 caractères').nullable(),

                        }),
                       }),
                    cellphone: yup.string().required("Un numéro de téléphone portable est requis").nullable(),
                    // phone: yup.string().required("Un numéro de téléphone fixe est requis").nullable(),
                    zip: yup.string().required("Un code postal est requis").nullable(),
                    city: yup.string().required("Une ville est requise").nullable(),
                    birthDate: yup.string().required("Une date de naissance est requise").matches(/^\d{2}-\d{2}-\d{4}$/, {
                        message: 'La date doit être au format "xx-xx-xxxx".',
                    }).nullable(),
                    birthPlace: yup.string().required("Une ville de naissance est requise").nullable(),
                    email: yup.string()
                        .email("Adresse email invalide")
                        .required("L'adresse email est requise")
                        .test('is-linked', "Un contact existe déjà sous cette adresse email, veuillez le sélectionner dans la liste suggérée", async function (value) {
                                if (!value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return true; // Ne pas valider si le champ est vide

                                const { id } = this.parent;
                                const exists = await checkEmailExists(value) && !id;

                                return !exists; // Retourne `true` si l'email n'existe pas, sinon `false`
                            }
                        )
                    ,
                    lienMatrimonial: yup.boolean().test('is-linked', "Veuillez décocher la case Lien matrimoniale si vous ne l'associez pas à un autre acquéreur", function (value) {

                            // Accéder au contexte de validation
                            const context = this.options.context;
                            // Accéder au tableau de produits à partir du contexte
                            const buyers = context.buyers;
                            const lienMatrimonialSelected = buyers.filter(buyer => buyer.lienMatrimonial === true);
                            // Vérification si au moins une case "lien" est cochée (true) dans le tableau
                            if (lienMatrimonialSelected.length === 0 || lienMatrimonialSelected.length === 2) {
                                console.log('ok')
                                // Si au moins deux cases "lien" sont cochées, retourner true pour passer la validation
                                return true;
                            } else if (lienMatrimonialSelected.length === 1 && buyers.length == 2) {
                                console.log('false')
                                // Si au moins deux cases "lien" sont cochées, retourner true pour passer la validation
                                return false;
                            }


                        }
                    ),

                })
            )


    });

    function handleSelect(key) {
        setKey(key)
    }

    return (
        <Formik
            initialValues={{
                buyers: buyers,
                lots: [],
                isNotary:false,
                isPrescripteur:false,
                isProjectManager:false
            }}
            validateOnChange={false} // Disable validation every field change
            validateOnBlur={false} // Disable validation every field blur
            validationSchema={ValidationSchema}
            onSubmit={(values) => {

                setBuyers(values.buyers);
                const data = {...formData, ...values};
                const new_obj = {
                    ...sideBarTab,
                    email: data.buyers[0].email,
                    nom: data.buyers[0].nom,
                    prenom: data.buyers[0].prenom,
                    cellphone: data.buyers[0].cellphone,
                    societe: data.buyers[0].societe
                }
                setSideBarTab(new_obj)
                setFormData(data);
                setActiveStepIndex(activeStepIndex + 1);
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  isValid,
                  isSubmitting,
                  touched,
                  setFieldValue,
                  handleBlur, // handler for onBlur event of form elements
              }) => {

                return (

                    <Form className="flex flex-col justify-center items-center mt-4" noValidate onSubmit={handleSubmit}>

                        <FieldArray name="buyers">
                            {({insert, remove, push}) => (
                                <div>
                                    <div className={"d-flex align-items-start justify-content-between my-4"}>
                                        <div className={'ms-2'}><h6>Détails de l'acquéreur</h6>
                                            <small>
                                                Entrez les détails de votre acquéreur.</small>
                                        </div>
                                        <div>
                                            <button
                                                id={'push'}
                                                type="button"
                                                className="math_symbol me-2 btn btn-primary"
                                                onClick={() => push({
                                                    nom: '',
                                                    prenom: '',
                                                    address: '',
                                                    society: {siretNumber:'',cityRCSRegistration:'',nom:'',actingAs:''},
                                                    email: '',
                                                    zip: '',
                                                    city: '',
                                                    dateContrat: null,
                                                    regime: null,
                                                    nomNotaire: null,
                                                    villeMariage: null,
                                                    resident: '',
                                                    lienMatrimonial: false,
                                                    pays: {label: 'France', value: 'FR'},
                                                    nationality: {label: 'Française', value: 'FR'},
                                                    familySituation: {label: 'non renseigné', value: '0'},
                                                    typeOfPerson: {label: 'Physique', value: '0'},
                                                    cellphone: '',
                                                    birthDepartment: '',
                                                    phone: '',
                                                    role: 'ROLE_ACQUEREUR',
                                                    dateCelebration: null,
                                                    birthDate: '',
                                                    civilite: {label: 'Monsieur', value: 1},
                                                })}
                                            >
                                                + Ajouter un coacquéreur
                                            </button>
                                            <button
                                                id={'remove'}
                                                type="button"
                                                className="math_symbol btn btn-light text-secondary"
                                                onClick={() => remove(key)}
                                            >
                                                <Icon icon="material-symbols:delete-outline "/> Supprimer un coacquéreur
                                            </button>
                                        </div>
                                    </div>
                                    <Tabs
                                        defaultActiveKey="0"
                                        activeKey={key}
                                        className="mb-3"
                                        onSelect={handleSelect}
                                    >
                                        {Array.isArray(values.buyers) && values.buyers.length > 0 ?
                                            values.buyers.map((buyer, index) => (

                                                <Tab
                                                    key={index}
                                                    eventKey={index}
                                                    title={title(index)}>

                                                    {
                                                        <TabContent countryList={countryList} isValid={isValid} buyer={buyer} index={index}
                                                                    handleChange={handleChange} title={title(index)}
                                                                    setKey={setKey} setFieldValue={setFieldValue}
                                                                    values={values} errors={errors} touched={touched}
                                                                    handleBlur={handleBlur}/>
                                                    }


                                                </Tab>

                                            ))


                                            : null}

                                    </Tabs>

                                </div>
                            )}
                        </FieldArray>
                        <hr className={'mt-4'}/>
                        <Row ><Col md={12}>
                        <div className={'mb-4 mt-4'}>
                            <h6>Intervenants</h6>
                            <small>
                                Veuillez indiquer si un notaire ou un prescripteur interviendra dans votre dossier en sélectionnant les options appropriées. </small>
                        </div>
                        </Col>
                        </Row>
                        <Row className={'mb-3 mt-4'}>
                            <Col md={2}>
                                <Form.Label className={"me-2"}>Recours à un notaire ?</Form.Label>
                            </Col>
                            <Col md={6}>
                                <Form.Check
                                    inline
                                    checked={values.isNotary === true ? true : false}
                                    type="radio"
                                    value={true}
                                    onChange={selection =>
                                        onIsNotaryChange(setFieldValue, true)

                                    }
                                    name="isNotary"
                                    label="Oui"
                                    id={`inline-radio-1`}
                                />
                                <Form.Check
                                    inline
                                    checked={values.isNotary === false ? true : false}
                                    value={false}
                                    type="radio"
                                    onChange={selection =>
                                        onIsNotaryChange(setFieldValue, false)

                                    }
                                    name="isNotary"
                                    label="Non"
                                    id={"inline-radio-2"}
                                />
                            </Col>
                        </Row>
                        {isNotaryToogled === false ? <></> :
                            <div>
                                <Row className={"mb-2"}>

                                    <Col xs={3}>
                                        <Form.Label>Nom du notaire</Form.Label>
                                        <Form.Control
                                            name={`notaireNom`}
                                            id={`notaireNom`}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.notaireNom}
                                        />
                                        <ErrorMessage name={`montant`} component="div"
                                                      className="error"/>
                                    </Col>
                                    <Col xs={3}>
                                        <Form.Label>Siège social</Form.Label>
                                        <Form.Control
                                            name={`notaireVille`}
                                            id={`notaireVille`}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.notaireVille}
                                        />
                                        <ErrorMessage name={`notaireVille`} component="div"
                                                      className="error"/>
                                    </Col>

                                </Row>


                                </div>
                        }

                        <Row className={'mb-3 mt-4'}>

                                <Col md={2}>
                                    <Form.Label className="me-2">Recours à un prescripteur ?</Form.Label>
                                </Col>
                            <Col md={6}>
                                <Form.Check
                                    inline
                                    checked={values.isPrescripteur === true ? true : false}
                                    type="radio"
                                    value={true}
                                    onChange={selection =>
                                        onIsPrescripteurChange(setFieldValue, true)

                                    }
                                    name="isPrescripteur"
                                    label="Oui"
                                    id={`inline-radio-1`}
                                />
                                <Form.Check
                                    inline
                                    checked={values.isPrescripteur === false ? true : false}
                                    value={false}
                                    type="radio"
                                    onChange={selection =>
                                        onIsPrescripteurChange(setFieldValue, false)

                                    }
                                    name="isPrescripteur"
                                    label="Non"
                                    id={"inline-radio-2"}
                                />
                            </Col>
                        </Row>
                        {isPrescripteurToogled === false ? <></> :
                            <div>
                                <Row className={"mb-2"}>

                                    <Col xs={3}>
                                        <Form.Label>Nom du prescripteur</Form.Label>
                                        <Form.Control
                                            name={`prescripteurNom`}
                                            id={`prescripteurNom`}
                                            placeholder={'Exemple : Jean Dupont'}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.prescripteurNom}
                                        />
                                        <ErrorMessage name={`prescripteurNom`} component="div"
                                                      className="error"/>
                                    </Col>
                                    <Col xs={3}>
                                        <Form.Label>N° de mandat</Form.Label>
                                        <Form.Control
                                            name={`prescripteurMandat`}
                                            id={`prescripteurMandat`}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.prescripteurMandat}
                                        />
                                        <ErrorMessage name={`prescripteurMandat`} component="div"
                                                      className="error"/>
                                    </Col>

                                </Row>


                            </div>
                        }
                        <Row className={'mb-3 mt-4'}>

                            <Col md={2}>
                                <Form.Label className="me-2">Chef de projet, si applicable</Form.Label>
                            </Col>
                            <Col md={6}>
                                <Form.Check
                                    inline
                                    checked={values.isProjectManager === true ? true : false}
                                    type="radio"
                                    value={true}
                                    onChange={selection =>
                                        onIsProjectManagerChange(setFieldValue, true)

                                    }
                                    name="isProjectManager"
                                    label="Oui"
                                    id={`inline-radio-1`}
                                />
                                <Form.Check
                                    inline
                                    checked={values.isProjectManager === false ? true : false}
                                    value={false}
                                    type="radio"
                                    onChange={selection =>
                                        onIsProjectManagerChange(setFieldValue, false)

                                    }
                                    name="isProjectManager"
                                    label="Non"
                                    id={"inline-radio-2"}
                                />
                            </Col>
                        </Row>
                        {isProjectManagerToogled === false ? <></> :
                            <div>
                                <Row className={"mb-2"}>

                                    <Col xs={3}>
                                        <Form.Label>Nom du chef de projet</Form.Label>
                                        <Form.Control
                                            name={`projectManagerNom`}
                                            placeholder={'Exemple : Monsieur Jean Dupont'}
                                            id={`projectManagerNom`}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.projectManagerNom}
                                        />
                                        <ErrorMessage name={`projectManagerNom`} component="div"
                                                      className="error"/>
                                    </Col>
                                </Row>


                            </div>
                        }
                        <div className="actions clearfix">
                            <button className="rounded-md font-medium text-white my-2 p-2 me-2 bg-secondary">
                                Précédent
                            </button>
                            <button
                                disabled={isSubmitting}
                                className="rounded-md font-medium text-white my-2 p-2"
                                type="submit"
                            >
                                {isSubmitting ? 'Veuillez patienter' : 'Suivant'}
                            </button>
                        </div>
                    </Form>

                )
            }}

        </Formik>

    );
}

export default Buyer;