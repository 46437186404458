import React, {useState, useEffect} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Button} from 'react-bootstrap';
import axios from "axios";
import 'dayjs/locale/fr'; // Import de la locale française
import ReCAPTCHA from 'react-google-recaptcha';
import CalendlyWidget from "../../../page/Meeting/component/CalendlyWidget";
import Offcanvas from "react-bootstrap/Offcanvas";

const FormValidation = ({
                            values,
                            setFieldValue,
                            handleChange,
                            optSuggestions,
                            handleRecaptchaChange,
                            role,
                            handleSubmit
                        }) => {

    const [countryList, setCountryList] = useState([])
    const [idsSelectionnes, setIdsSelectionnes] = useState([2, 3, 4]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function selectCheckOptions(role) {
        if (role === 'fournisseur') {

            setIdsSelectionnes([1, 2, 3, 4]);
        } else {

            setIdsSelectionnes([2, 3, 4]);

        }
    }

    useEffect(() => {

        selectCheckOptions(role)
    }, []);

    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getAllCountries();

    }, [])

    return (
        <div>
            <div>
                <h5 className={'pb-3 bold'}>Veuillez vérifier et confirmer</h5>
                <p>Merci d'avoir rempli le formulaire. Nous reviendrons vers vous au plus vite. Il ne vous reste plus qu'à cliquer sur le
                    bouton "Envoyer" ci-dessous afin de valider le formulaire.</p>

                <p>Vous pouvez également prendre rendez-vous avec l'un de nos conseillers pour discuter de vos besoins
                    plus
                    en détail. Cliquez sur le bouton ci-dessous pour choisir une date et une heure qui vous
                    conviennent.</p>
                <Button variant="success" size="sm" onClick={handleShow}>Prendre rendez-vous</Button>

                <Row className="mt-3">
                    {/*<Col xs={12}>*/}
                    {/*    <CalendlyWidget/>*/}
                    {/*</Col>*/}

                </Row>

                <Row className="mt-3">
                    <Col xs={6}>
                        <div style={{transform: "scale(0.85)", transformOrigin: "0 0"}}>

                            <ReCAPTCHA
                                className={"mb-5 w-100 g-recaptcha"}
                                sitekey="6Lca4ncpAAAAAKJVtAjxE5j_Q_iQ-UddoOeE3F5Z"
                                onChange={handleRecaptchaChange}
                            /></div>
                    </Col>

                </Row>
                <Button variant="primary" type="submit" onClick={handleSubmit}
                    // disabled={!mediaType}
                        className="mt-4">
                    Envoyer
                </Button>
            </div>

            <Offcanvas className={'offcanvas-size-xl'} id={"offcanvas-contact"} show={show} onHide={handleClose}
                       placement="end">
                <Offcanvas.Header closeButton className={"border-bottom"}>
                    <Offcanvas.Title className={'py-2 ps-4'}>Prendre rendez-vous</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <CalendlyWidget/>
                </Offcanvas.Body>
            </Offcanvas>
</div>
)
}

export default FormValidation;