import {useParams} from 'react-router-dom';
import {Formik, FieldArray, ErrorMessage} from 'formik';
import {string} from 'yup' ;
import * as Yup from "yup";
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import PlacesAutocomplete from "../../component/PlacesAutocomplete";
import Form from 'react-bootstrap/Form';
import Downshift from 'downshift'
import ListGroup from "react-bootstrap/ListGroup";
import InputMask from 'react-input-mask';
import {familySituationOptions} from "../../constants/options";
import { onFamilySituationChange} from "../../utils/utils";

const ContactAddEdit = ({show, setShow, getAllContacts, getContact, isAddMode,user}) => {

    const [id, setId] = useState(useParams().id);
    const [isToggled, setIsToogled] = useState(false);
    const [countryList, setCountryList] = useState([])
    const handleClose = () => setShow(false);
    const initialValues = {
        id: '',
        civilite: {label: 'Monsieur', value: 1},
        prenom: '',
        societe: '',
        familySituation: {label: 'Célibataire', value: 0},
        society: {nom: ''},
        nom: '',
        email: '',
        adresse: '',
        level: '',
        profession:'',
        role: 'ROLE_PROSPECT',
        typeOfPerson: {label: 'Physique', value: 0},
        zip: '',
        roles: null,
        city: '',
        // mailchimpId: '',
        pays: {
            value: 'FR',
            label: 'France',
        },
        phones: [
            {
                number: '',
                type: {label: 'Portable', value: 1},
            },
        ],
    };

    const personOptions = [
        {value: 0, label: 'Physique'},
        {value: 1, label: 'Morale'},
    ]

    const [fieldsName, setFieldsName] = useState(
        {
            zip: `zip`,
            city: `city`,
            address: `adresse`,
        }
    );

    const handleFamilySituationChange = (selection,setFieldValue) => {

        onFamilySituationChange(selection, setFieldValue, null);
    };


    const handleTypeOfPersonChange = (newValue, setFieldValue) => {

        setFieldValue(`typeOfPerson`, newValue)

        if (newValue.value == 1) {

            setIsToogled(true)
        } else {
            setIsToogled(false)
        }
    };

    const ContactForm = ({
                             handleSubmit,
                             handleChange,
                             values,
                             errors,
                             isValid,
                             isSubmitting,
                             touched,
                             setFieldValue,
                             handleBlur
                         }) => {

            const [cityList, setCityList] = useState([])
            const zipToString = item => (item ? item.postal_code : '')
            const localityToString = item => (item ? item.locality : '')

            const getCities = (fieldName, place) => {
                axios.get(`/public/places/${place}?field=${fieldName}`)
                    .then(function (response) {
                        setCityList(response.data);
                    })
                    .catch(function (error) {

                        Swal.fire({
                            icon: 'error',
                            title: "Une erreur s'est produite!",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
            }


            return (
                <div>
                    <div className={"px-5 pt-4 pb-5 contact-wrapper-form"}>
                        <Form.Group className="mb-3">

                            <Form.Label>Type d'acquéreur</Form.Label>
                            <Select
                                id={'typeOfPerson'}
                                options={personOptions}
                                defaultValue={{label: "Physique", value: 0}}
                                name={`typeOfPerson`}
                                value={values.typeOfPerson}
                                onChange={newValue =>

                                    handleTypeOfPersonChange(newValue, setFieldValue)
                                }
                            />
                        </Form.Group>
                        {values.typeOfPerson && values.typeOfPerson.value == 1 ?
                            <div>
                                <Form.Group className="mb-3">

                                <Form.Label>AGISSANT EN QUALITE DE</Form.Label>
                                <Form.Control
                                    name={`society.actingAs`}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.society.actingAs}
                                />

                            </Form.Group>
                            <Form.Group className="mb-3">

                                <Form.Label>Société</Form.Label>
                                <Form.Control
                                    name={`society.nom`}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.society.nom}
                                />

                            </Form.Group>
                                <Form.Group className="mb-3">

                                    <Form.Label>Numéro de siret</Form.Label>
                                    <InputMask
                                        mask="999 999 999 99999" // Ajoutez le masque de saisie pour le SIRET
                                        maskChar="" // Supprimez le caractère de masque par défaut '_'
                                        className="form-control" // Ajoutez les classes Bootstrap si nécessaire
                                        placeholder="Entrez votre SIRET"
                                        name={`society.siretNumber`}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.society.siretNumber}
                                    />


                                </Form.Group>
                            <Form.Group className="mb-3">

                                <Form.Label>Ville d'immatriculation RCS</Form.Label>
                                <Form.Control
                                    name={`society.cityRCSRegistration`}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.society.cityRCSRegistration}
                                />

                            </Form.Group>


                            </div>
                            : null}

                        <Form.Group className="mb-3">

                            <Form.Label>Civilité</Form.Label>
                            <Select
                                id={"civilite"}
                                options={options}
                                onChange={value =>
                                    setFieldValue(`civilite`, value)}
                                defaultValue={{label: "Monsieur", value: 1}}
                                name="civilite"
                                value={values.civilite}
                            />

                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Prénom</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                name="prenom"
                                value={values.prenom}
                                type="text"/>
                            <ErrorMessage name={`prenom`} component="div" className="error"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                value={values.nom}
                                name="nom"
                                type="text"/>
                            <ErrorMessage name={`nom`} component="div" className="error"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                className={errors.email ? "error" : null}
                                name="email"
                                value={values.email}
                                onBlur={handleBlur} // This apparently updates `touched`?
                                type="text"/>
                            {
                                touched.email && errors.email ? (
                                    <div className="error">{errors.email}</div>
                                ) : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Adresse</Form.Label>
                            <PlacesAutocomplete
                                fieldValueChanger={setFieldValue}
                                initialAddress={values.adresse}
                                fieldsName={fieldsName}
                            ></PlacesAutocomplete>
                            <ErrorMessage name={`adresse`} component="div" className="error"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Code Postal</Form.Label>

                            <div>
                                <Downshift
                                    itemToString={zipToString}
                                    onInputValueChange={(inputValue) => {
                                        if (inputValue.length >= 2) {
                                            getCities('codePostal', inputValue);
                                        }
                                    }}
                                    inputValue={values.zip}
                                    onChange={(selection) =>
                                        onLocalitySelection(selection, setFieldValue)
                                    }
                                >
                                    {({
                                          getInputProps,
                                          getItemProps,
                                          highlightedIndex,
                                          isOpen,
                                      }) => (

                                        <div className="dropdown-zip">

                                            <Form.Control {...getInputProps(
                                                {
                                                    name: 'zip',
                                                    onChange: handleChange,
                                                    value: values.zip
                                                }
                                            )} />
                                            <div className={"downshift-menu-list"}>
                                                {isOpen
                                                    ? cityList.map((item, index) => (

                                                        <ListGroup.Item
                                                            className={"downshift-option"}
                                                            {...getItemProps(
                                                                {
                                                                    item,
                                                                    key: item.id,
                                                                    style: {
                                                                        backgroundColor:
                                                                            index === highlightedIndex ? '#DEEBFF' : null,
                                                                    },
                                                                }
                                                            )}
                                                        >
                                                            {item.postal_code} {item.locality}
                                                        </ListGroup.Item>
                                                    ))
                                                    : null}</div>
                                        </div>
                                    )}
                                </Downshift>
                            </div>
                            <ErrorMessage name={`zip`} component="div" className="error"/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Ville</Form.Label>
                            <Downshift
                                itemToString={localityToString}
                                onInputValueChange={(inputValue) => {
                                    if (inputValue.length >= 2) {
                                        getCities('nom', inputValue);
                                    }
                                }}
                                onChange={(selection) =>
                                    onLocalitySelection(selection, setFieldValue)
                                }
                            >
                                {({
                                      getInputProps,
                                      getItemProps,
                                      highlightedIndex,
                                      isOpen,
                                      inputValue,
                                  }) => (
                                    <div className="dropdown-zip">

                                        <Form.Control {...getInputProps(
                                            {
                                                name: 'city',
                                                onChange: handleChange,
                                                value: values.city
                                            }
                                        )} />
                                        <div className={"downshift-menu-list"}>
                                            {isOpen && cityList.length > 0 && inputValue != ''
                                                ? cityList.map((item, index) => (

                                                    <ListGroup.Item
                                                        className={"downshift-option"}
                                                        {...getItemProps({
                                                            item,
                                                            key: item.id,
                                                            style: {
                                                                backgroundColor:
                                                                    index === highlightedIndex ? '#DEEBFF' : null,
                                                            },
                                                        })}
                                                    >
                                                        {item.postal_code} {item.locality}
                                                    </ListGroup.Item>
                                                ))
                                                : null}</div>
                                    </div>
                                )}
                            </Downshift>
                            <ErrorMessage name={`city`} component="div" className="error"/>
                        </Form.Group>

                        {countryList.length > 0 ? (
                            <Form.Group className="mb-3">

                                <Form.Label>Pays</Form.Label>
                                <Select
                                    id={'pays'}
                                    options={countryList}
                                    onChange={newValue =>
                                        setFieldValue(`pays`, newValue)}
                                    name="pays"
                                    value={values.pays}
                                    defaultValue={{label: "France", value: 'FR'}}
                                />

                            </Form.Group>
                        ) : (<></>)}
                        <Form.Group className="mb-3">
                            <Form.Label>Statut</Form.Label>
                            <Select
                                options={levelOptions}
                                placeholder={''}
                                onChange={value =>
                                    setFieldValue(`level`, value)}
                                name="level"
                                value={values.level}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>TELEPHONE(S)</Form.Label>
                            <FieldArray name="phones">
                                {({insert, remove, push}) => (
                                    <div>
                                        {values.phones.length > 0 && phoneTypeOptions.length > 0 &&
                                            values.phones.map((phone, index) => (

                                                <Form.Group className="mb-2" key={`group[${index}]`}>
                                                    <div className="form-row" key={index}>
                                                        <div className="col-md-4 me-2"
                                                             key={`col[${index}]number`}>

                                                            <Form.Control
                                                                key={`phones[${index}]number`}
                                                                name={`phones[${index}]number`}
                                                                id={`phones_${index}_number`}
                                                                placeholder="Numéro"
                                                                onChange={handleChange}
                                                                type="text"
                                                                value={phone.number}
                                                            />
                                                        </div>

                                                        <div className="col-md-4 me-2"
                                                             key={`col[${index}]type`}>

                                                            <Select
                                                                key={`phones[${index}]type`}
                                                                options={phoneTypeOptions}
                                                                defaultValue={{label: "Portable", value: 1}}
                                                                name={`phones[${index}]type`}
                                                                id={`phones_${index}_type`}
                                                                value={phone.type}
                                                                onChange={newValue =>

                                                                    setFieldValue(`phones[${index}]type`, newValue)}
                                                            />

                                                        </div>
                                                        <div className="col-md-3"

                                                             key={`col[${index}]remove`}>
                                                                        <span onClick={() => remove(index)}>
                                                                        <button
                                                                            type="button"
                                                                            className="math_symbol me-2"

                                                                        >
                                                                            -
                                                                        </button></span>
                                                            <span onClick={() => push({
                                                                number: '',
                                                                type: {label: 'Portable', value: 1}
                                                            })}>
                                                                        <button
                                                                            type="button"
                                                                            className="math_symbol"
                                                                        >
                                                                            +
                                                                        </button></span>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            ))}

                                    </div>
                                )}
                            </FieldArray>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Profession</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                value={values.profession}
                                name="profession"
                                type="text"/>
                            <ErrorMessage name={`profession`} component="div" className="error"/>
                        </Form.Group>

                        <Form.Group className="mb-5">
                            <Form.Label>Situation matrimoniale</Form.Label>
                            <Select
                                options={familySituationOptions}
                                defaultValue={{label: "Célibataire", value: 0}}
                                name={`familySituation`}
                                value={values.familySituation}
                                onChange={selection =>
                                    handleFamilySituationChange(selection,setFieldValue)

                                }
                            />

                        </Form.Group>
                    </div>
                    <footer>
                        <div className={"d-flex justify-content-center py-3"}>
                            <button
                                variant="primary"
                                className="btn btn-success w-50"
                                disabled={isSubmitting}
                                type="submit">
                                {isSubmitting ? "S'il vous plaît, attendez..." : "Valider"}
                            </button>
                        </div>
                    </footer>
                </div>)
        }

    ;

    const defaultValue = (options, value) => {

        return phoneTypeOptions ? phoneTypeOptions.find(option => option.value === value) : "";
    };

    const validationSchema = Yup.object().shape({
        prenom: string()
            .required('Le prénom du contact est requis'),
        nom: string()
            .required('Le nom du contact est requis'),
        zip: string()
            .required('Le code postal est requis'),
        city: string()
            .required('Le nom de la ville est requise'),
        adresse: string().matches(/^[^,]*$/, "La virgule n'est pas autorisée dans ce champ").required('L\'adresse du contact est requis').nullable(),
        email: string()
            .email("Format de l'adresse email invalide")
            .required("L'adresse email est requise")

    });

    const validationSchemaPromoteur = Yup.object().shape({
        prenom: string()
            .required('Le prénom du contact est requis'),
        nom: string()
            .required('Le nom du contact est requis'),
        email: string()
            .email("Format de l'adresse email invalide")
            .required("L'adresse email est requise")

    });


    const onLocalitySelection = (selection, setFieldValue) => {

        setFieldValue(`zip`, selection.postal_code)
        setFieldValue(`city`, selection.locality)
    }


    function onSubmit(values, actions) {

        const fields = {...values};
        var pass = false;
        fields.phones.map((phone, index) => {

            if (phone.number == "") {
                pass = true;
            }
            phone.nrOrder = 1;
            phone.type = phone.type.value?phone.type.value:1;
        });

        if (!fields.level) {
            fields.level = {label: 'Autre', value: 99};
        }

        {
            pass ? delete fields.phones : ''
        }

        fields.pays = fields.pays.value;

        if (isAddMode) {

            createContact(fields);

        } else {
            updateContact(id, fields);
        }

        actions.setSubmitting(false);
    }

    function createContact(fields) {

        axios.get(`/admin/contacts/check-email?email=${fields.email}`)
            .then(function (response) {
               if(response.data.exists) {

                   Swal.fire({
                       icon: 'warning',
                       title: 'Un contact existe déjà sous cette adresse email',
                       showConfirmButton: false,
                       timer: 3000
                   })
               }
               else{

                   axios.post(`/admin/contacts`, fields)
                       .then(function (response) {

                           setShow(false);
                           getAllContacts();

                           Swal.fire({
                               icon: 'success',
                               title: 'Contact créé avec succès!',
                               showConfirmButton: false,
                               timer: 3000
                           })
                       })
                       .catch(function (error) {

                           if (error.response.status === 401) {
                               Swal.fire({
                                   icon: 'warning',
                                   title: error.response.data.message,
                                   showConfirmButton: false,
                                   timer: 3000
                               })

                           }
                           else{
                               Swal.fire({
                                   icon: 'error',
                                   title: 'Une erreur s\'est produite!',
                                   showConfirmButton: false,
                                   timer: 1500
                               })
                           }
                       });
               }
            }).catch(function (error) {


                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    text: error.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })

        });
    }


    function updateContact(id, fields) {
        delete fields.id;
        axios.patch(`/admin/contact/${id}`, fields)
            .then(function (response) {

                setShow(false);
                getContact();
                Swal.fire({
                    icon: 'success',
                    title: 'Contact mis à jour avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }

    const options = [
        {value: 1, label: 'Monsieur'},
        {value: 2, label: 'Madame'},
        {value: 3, label: 'Mademoiselle'},
        {value: 4, label: 'Monsieur et madame'},
        {value: 5, label: 'Société'},
        {value: 6, label: 'Messieurs'},
        {value: 7, label: 'Mesdames'},
        {value: 8, label: 'Mesdemoiselles'},
        {value: 9, label: 'Monsieur et mademoiselle'},
        {value: 10, label: 'Maître'}
    ]

    const levelOptions = [
        {value: 0, label: 'Sans suite'},
        {value: 5, label: 'Visiteur'},
        {value: 1, label: 'Lead'},
        {value: 2, label: 'Prospect'},
        {value: 3, label: 'Opportunité'},
        {value: 4, label: 'Client'},
        {value: 99, label: 'Autre'}
    ]

    const phoneTypeOptions = [
        {value: 1, label: 'Portable'},
        {value: 2, label: 'Fixe'},
        {value: 3, label: 'Professionnel'},

    ]

    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getAllCountries();

    }, [])


    return (

        <Offcanvas className={'offcanvas-size-xl'} id={"offcanvas-contact"} show={show} onHide={handleClose}
                   placement="end">
            <Offcanvas.Header closeButton className={"border-bottom"}>
                <Offcanvas.Title className={'py-2 ps-4'}>Ajouter un contact</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={user.role==='ROLE_ADMIN'||user.role==='ROLE_ADMIN'?validationSchemaPromoteur:validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {
                        useEffect(() => {

                        }, [values]);

                        useEffect(() => {

                            if (!isAddMode) {
                                axios.get(`/admin/contacts/${id}?form=1`)
                                    .then(function (response) {

                                        let contact = response.data;

                                        const fields = ['prenom', 'nom', 'email', 'adresse', 'zip', 'city', 'phones', 'pays', 'civilite', 'typeOfPerson', 'societe', 'level', 'society', 'roles','familySituation','profession'];
                                        fields.forEach(field =>

                                            setFieldValue(field, contact[field], false)
                                        );


                                    })
                                    .catch(function (error) {

                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Une erreur s\'est produite!',
                                            showConfirmButton: false,
                                            timer: 1500
                                        })
                                    })
                            }
                        }, [id]);


                        return (

                            <Form noValidate onSubmit={handleSubmit}>


                                <ContactForm
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    values={values}
                                    errors={errors}
                                    isValid={isValid}
                                    isSubmitting={isSubmitting}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}

                                />


                            </Form>)
                    }}
                </Formik>
            </Offcanvas.Body>
        </Offcanvas>

    );
}

export default ContactAddEdit;