import {useParams} from 'react-router-dom';
import {Formik, useFormikContext} from 'formik';
import * as Yup from "yup";
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import {InputGroup, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Icon} from "@iconify/react/dist/iconify";


const Filter = ({actionList, setActionList, optionsFilter}) => {


    const {setFieldValue, values, submitForm, handleChange} = useFormikContext();
    const [programList, setProgramList] = useState([])

    useEffect(() => {

    }, [actionList]);

    useEffect(() => {

        getProgrammeList();
        // Appel de la fonction de rafraîchissement automatique avec le délai de debounce
        setFilteredData(values);
        // setFiltered(true);

    }, [values, submitForm]);


    useEffect(() => {

    }, [optionsFilter]);
    // Fonction de rafraîchissement automatique du formulaire
    const optionsType = [
        {value: 1, label: 'Option'},
        {value: 2, label: 'Réservation'},
        {value: 3, label: 'Téléphone'},
        {value: 4, label: 'Email'},
        {value: 5, label: 'Courrier'},
        {value: 6, label: 'Rdv'},
    ]
    const typeOfProperty = [
        {
            label: "Maison",
            options: [
                {label: "Tout types", value: 100},
                {label: "Maison T1", value: 101},
                {label: "Maison T2", value: 102},
                {label: "Maison T3", value: 103},
                {label: "Maison T4", value: 104},
                {label: "Maison T5 et +", value: 105},
                {label: "Maison autre type", value: 199},
            ]
        },
        {
            label: "Appartement",
            options: [
                {label: "Tout types", value: 200},
                {label: "Appartement T1", value: 201},
                {label: "Appartement T2", value: 202},
                {label: "Appartement T3", value: 203},
                {label: "Appartement T4", value: 204},
                {label: "Appartement T5 et +", value: 205},
                {label: "Appartement autre type", value: 299},
            ]
        },
        {
            label: "Terrain",
            options: [
                {label: "Tout types", value: 300},
            ]
        },
        {
            label: "Locaux annexes",
            options: [
                {label: "Tout types", value: 200},
                {label: "Garage", value: 401},
                {label: "Parking", value: 402},
                {label: "Cave", value: 403},
                {label: "Cellier", value: 404},
                {label: "Local", value: 405},
                {label: "Autre type", value: 499},
            ]
        },
        {
            label: "Autres",
            options: [
                {label: "Tout types", value: 500},
            ]
        },
        {
            label: "Bureau",
            options: [
                {label: "Local commercial", value: 500},
            ]
        }
    ];

    function setFilteredData(fields) {

        // fields.type=fields.type.value
        var url = '/admin/filter/activities';
        axios.post(url, {'filterData': fields})
            .then(function (response) {

                setActionList(response.data);

            });
    }

    const getProgrammeList = () => {
        axios.get(`/admin/programmes`)
            .then(function (response) {

                setProgramList(response.data);


            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const customStyles = {

        menuPortal: base => ({
            ...base,
            zIndex: 9999, flex: 1,
            height: 35,
            minHeight: 35
        })
    };

    // Fonction appelée à chaque changement dans le champ de recherche
    const handleTermChange = (event) => {

        setFieldValue('contact', event.target.value)
    };


    return (
        <Row className={'d-flex justify-content-between'}>


            <Col lg={3} xl={3} xxl={2} className={'mt-2'}>
                <Select
                    isMulti
                    options={optionsType}
                    placeholder={<div>Type</div>}
                    className={'px-0'}
                    styles={customStyles}
                    onChange={(value) => {
                        setFieldValue('type', value, false);
                    }}
                    name="type"
                    value={values.type}
                    defaultValue={values.type}

                />
            </Col>
            <Col lg={3} xl={3} xxl={2} className={'mt-2'}>
                <Select
                    placeholder={<div>Programme</div>}
                    className={'px-0'}
                    options={programList}
                    styles={customStyles}
                    onChange={(value) => {
                        setFieldValue('programme', value, false);
                    }}
                    name="programme"
                    value={values.programme}
                    defaultValue={values.programme}
                />
            </Col>
            <Col lg={3} xl={3} xxl={2} className={'mt-2'}>
                <Form.Control
                    onChange={event =>

                        handleTermChange(event)
                    }

                    name="contact"
                    placeholder={'Filtrer par contact'}
                    value={values.contact}
                    type="text"
                />
            </Col>


            {/*<Col lg={3} xl={3} xxl={2} className={'mt-2'}>*/}

            {/*    <InputGroup>*/}
            {/*        <InputGroup.Text>Surface</InputGroup.Text>*/}
            {/*        <Form.Control*/}
            {/*            onChange={handleChange}*/}
            {/*            name="minSurface"*/}
            {/*            placeholder={'Min'}*/}
            {/*            value={values.minSurface}*/}
            {/*            type="text"*/}
            {/*        />*/}
            {/*        <Form.Control*/}
            {/*            onChange={handleChange}*/}
            {/*            name="maxSurface"*/}
            {/*            placeholder={'Max'}*/}
            {/*            value={values.maxSurface}*/}
            {/*            type="text"*/}
            {/*        />*/}
            {/*    </InputGroup> </Col>*/}


            <Col className="d-flex justify-content-end mt-2">
                {/* Colonne pour les boutons alignés à droite */}
                {/*<Button variant="success" type={'submit'} className="me-2">*/}
                {/*    Ajouter un filtre*/}
                {/*</Button>*/}
                <Button variant="secondary" onClick={() => {
                    setFilteredData(null)
                }}>
                    <Icon width={'20px'} icon="lucide:filter-x"/>
                </Button>
            </Col>

        </Row>

    )
}


const FilterActivity = ({actionList, setActionList, initialValues, setFiltered}) => {

    const [id, setId] = useState(useParams().id);
    const [optionsFilter, setOptionsFilter] = useState([])
    const validationSchema = Yup.object().shape({});

    useEffect(() => {

        getAllFilter();
    }, []);

    const getFilterList = () => {

        return axios.get(`/api/search-preferences`)
            .then(function (response) {

                return response.data;
            })
            .catch(function (error) {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    function getAllFilter() {

        getFilterList()
            .then((options) => {

                setOptionsFilter(options);
            })
            .catch((error) => {
                // Gérer les erreurs de récupération des options
            });
    }

    function onSubmit(values, actions) {

        const fields = {...values};
        fields.programme = fields.programme.value;
        fields.typeBien.length === 0 ? delete fields.typeBien : null;
        createSearchPreferences(fields);
        actions.setSubmitting(false);
    }

    return (
        <Card className={'mb-3'} id={'theft-card'}>

            <Card.Body>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {


                        return (

                            <Form noValidate onSubmit={handleSubmit}>
                                <Filter
                                    setFiltered={setFiltered}
                                    setActionList={setActionList}
                                    actionList={actionList}

                                />
                            </Form>)
                    }}
                </Formik>
            </Card.Body>
        </Card>


    );
}

export default FilterActivity;