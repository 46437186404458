import {Formik} from "formik";
import React, {useContext, useEffect} from "react";
import Form from "react-bootstrap/Form";
import {FormContext} from "../ReservationAddEdit";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from 'react-bootstrap/Spinner';
import {useNavigate} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {LocalizationProvider,MobileDateTimePicker} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import fr from "date-fns/locale/fr";
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import de la locale française
import {ErrorMessage} from "formik";
import { format } from 'date-fns';

// Configurez dayjs pour utiliser la locale française
dayjs.locale('fr');

function Validation() {

    let navigate = useNavigate();
    const {
        activeStepIndex,
        setActiveStepIndex,
        formData,
        setFormData,
        dataFiles,
        codeClient
    } = useContext(FormContext);

    useEffect(() => {

        setFormData(formData);
    }, []);

    const ValidationSchema = yup.object().shape({
        doneAt: yup.string().required("Veuillez indiquer le lieu"),
        lots: yup.array()
            .min(1, "Veuillez sélectionner au moins un lot")
    });

    const sendReservation = (data) => {
        console.log(data)
        if (data.medias && data.medias.length > 0) {
            var uploadedFiles = [...data.medias];
            // delete data.medias;
            postReservation(data, uploadedFiles);
        } else {

            postReservation(data);
        }
    }

    const postGallery = (medias, idReservation) => {

        axios.post(`/admin/upload-file?nrPromoteur=${codeClient}&&idReservation=${idReservation}`, {medias}, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
            .then(function (response) {

            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    text: error.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const postReservation = (data, medias) => {

        axios.post(`/admin/reservations?nrPromoteur=${codeClient}`, {data})
            .then(function (response) {
                const reservation = response.data;

                if (data.medias && medias.length > 0) {

                    postGallery(medias, reservation.id)
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Réservation créée avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                })

                navigate(`/admin/reservation/${reservation.id}/modifier?key=contract`)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    text: error.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
    }

    return (
        <div>
            <div className={'ms-2 mt-4 mb-1'}><h6>Validation</h6>
                <small>
                    Veuillez cliquer sur confirmer afin de valider la réservation</small>
            </div>
            <section>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12 mt-4">

                            <Formik
                                initialValues={{
                                    dateEnvoiRecommande: new Date(),
                                    dateReservation: new Date(),
                                    doneAt: ''
                                }}
                                validationSchema={ValidationSchema}
                                onSubmit={(values) => {

                                    const dateReservation = format(values.dateReservation, 'dd-MM-yyyy HH:mm', { locale: fr });
                                    const dateEnvoiRecommande = format(values.dateEnvoiRecommande, 'dd-MM-yyyy HH:mm', { locale: fr });
                                    values.dateReservation = dateReservation; // Exemple de formatage
                                    values.dateEnvoiRecommande = dateEnvoiRecommande;
                                    const data = {...formData, ...values};
                                    data.medias && data.medias.length > 0 ? '' : delete data.medias;
                                    sendReservation(data)
                                }}
                            >
                                {({
                                      handleSubmit,
                                      handleChange,
                                      values,
                                      errors,
                                      isValid,
                                      isSubmitting,
                                      touched,
                                      setFieldValue,
                                      handleBlur, // handler for onBlur event of form elements
                                  }) => {

                                    console.log(errors)
                                    return (
                                        <Form className="flex flex-col justify-center items-center" noValidate
                                              onSubmit={handleSubmit}>


                                            <Row className="mt-3">
                                                <Col xs={4}>
                                                    <Form.Label>Fait à</Form.Label>
                                                    <Form.Control
                                                        name={`doneAt`}
                                                        id={`doneAt`}
                                                        // placeholder="Veuillez indiquer votre email"
                                                        onChange={handleChange}
                                                        type="text"
                                                        value={values.doneAt}
                                                    />


                                                </Col>
                                                <ErrorMessage name={`doneAt`} component="div" className="error"/>
                                            </Row>
                                            <div style={{maxWidth: '100%'}}>
                                                <Row className="mt-4">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={{
                                                        shortDate: "d MMMM"
                                                    }} adapterLocale={fr}>
                                                        <Col xs={3}>

                                                            <MobileDateTimePicker
                                                                name="dateReservation"
                                                                format="d-MM-yyyy HH:mm"
                                                                label="Réservation"
                                                                clearText="vider"
                                                                cancelText="annuler"
                                                                onChange={(value) => {
                                                                    setFieldValue('dateReservation', value);
                                                                }}
                                                                value={values.dateReservation}
                                                                okText="ok"

                                                            />
                                                        </Col>
                                                        <Col xs={3}>
                                                            <MobileDateTimePicker
                                                                name="dateEnvoiRecommande"
                                                                format="d-MM-yyyy HH:mm"
                                                                label="Envoi du recommandé"
                                                                clearText="vider"
                                                                cancelText="annuler"
                                                                onChange={(value) => {
                                                                    setFieldValue('dateEnvoiRecommande', value);
                                                                }}
                                                                value={values.dateEnvoiRecommande}
                                                                okText="ok"
                                                            /></Col>


                                                    </LocalizationProvider>
                                                </Row>
                                            </div>

                                            <div className="actions clearfix">
                                                <button className="rounded-md font-medium text-white my-2 p-2 me-2"
                                                        onClick={() => {
                                                            setActiveStepIndex(activeStepIndex - 1)
                                                        }}>
                                                    Précédent
                                                </button>
                                                <button
                                                    disabled={!isValid || isSubmitting}
                                                    className="rounded-md font-medium text-white my-2 p-2"
                                                    type="submit"
                                                >
                                                    {isSubmitting ? <Button variant="primary" disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Button> : 'Confirmer'}
                                                </button>
                                            </div>
                                        </Form>)
                                }}
                            </Formik>


                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
}

export default Validation;