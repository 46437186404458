import {useDropzone} from "react-dropzone";
import React from 'react';
import {formatDate} from "@fullcalendar/core";
import {Icon} from "@iconify/react/dist/iconify";

export function switchValue(value) {

        return value ? <Icon style={{color: "#A8A9AD"}} width={'30px'}  icon="mdi:toggle-switch" /> :
            <Icon style={{color: "#A8A9AD"}} width={'30px'}  icon="mdi:toggle-switch-off"/>
}

