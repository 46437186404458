import React, {useState, useEffect} from 'react';
import {ErrorMessage, FieldArray, Formik} from "formik";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Button, Form, ProgressBar} from 'react-bootstrap';
import CityAndZipAutocomplete from "./CityAutocomplete";
import axios from "axios";
import Select from "react-select";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import de la locale française
import ReCAPTCHA from 'react-google-recaptcha';
import DefaultForm from "./DefaultForm";
import FormValidation from "./FormValidation";
import {useNavigate} from "react-router-dom";

const params = new URLSearchParams();


const FournisseurForm = ({values,setFieldValue,handleChange,handleSubmit,optSuggestions,handleRecaptchaChange,role,setInitialValues,isValid,
                             isSubmitting,
                             step,
                             setStep,
                             validateForm,
                             handleNextStep,
                             setTouched}) => {


    const [countryList, setCountryList] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    let navigate = useNavigate();
    const handlePrevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    useEffect(() => {
        if (!role) {
            navigate({
                search: `?role=artisan`
            });
            setInitialValues(initialFournisseurValues)
        }
    }, []);


    const initialFournisseurValues = {
        profession: '',
        username: '',
        nom: '',
        prenom: '',
        zip: '',
        city: '',
        email: '',
        promoCode: '',
        phones: '',
        password: '',
        confirmPassword: '',
        roles: [],
        role: 'ROLE_FOURNISSEUR',
        permissionContact: true
    };


    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getAllCountries();

    }, [])

    return (
        <div>
            <ProgressBar now={(step / 3) * 100} className="mb-4" style={{ height: '10px' }}/>
            {step === 2 && (
                <div>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.username}
                                    name="username"
                                    id= "username"
                                    aria-label= "username"
                                    placeholder={"Nom d'utilisateur"}
                                    type="text"/>
                                <ErrorMessage name={`username`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    aria-label= "email"
                                    placeholder={"Email"}
                                    type="text"/>
                                <ErrorMessage name={`email`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.nom}
                                    name="nom"
                                    aria-label= "nom"
                                    placeholder={"Nom"}
                                    type="text"/>
                                <ErrorMessage name={`nom`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    name="prenom"
                                    placeholder={"Prénom"}
                                    aria-label= "prenom"
                                    value={values.prenom}
                                    type="text"/>
                                <ErrorMessage name={`prenom`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>

                    <CityAndZipAutocomplete setFieldValue={setFieldValue} values={values}/>
                    <Row>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.phone}
                                    name="phones"
                                    aria-label= "phones"
                                    placeholder={"Téléphone"}
                                    type="text"/>
                                <ErrorMessage name={`phones`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    name="promoCode"
                                    placeholder={"Code promo"}
                                    value={values.promoCode}
                                    type="text"/>
                                <ErrorMessage name={`promoCode`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>

                                <Form.Control
                                    onChange={handleChange}
                                    name="password"
                                    aria-label="password"
                                    placeholder={"Mot de passe"}
                                    value={values.password}
                                    type="password"/>
                            </Form.Group>
                            <ErrorMessage name={`password`} component="div" className="error"/>
                        </Col>
                        <Col>


                            <Form.Control
                                onChange={handleChange}
                                name="confirmPassword"
                                value={values.confirmPassword}
                                type={showPassword ? 'text' : 'password'}
                                placeholder={"Confirmer le mot de passe"}
                                aria-label="confirmPassword"
                                aria-describedby="basic-addon2"
                            />

                            <ErrorMessage name={`confirmPassword`} component="div" className="error"/>
                        </Col>

                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}
            {step === 3 && (
                <div>
                    <h5 className={'pb-3 bold'}>Quel metiers et prestations exercez vous ?</h5>

                    <Col>
                        <Form.Group className="mb-3">

                            <Form.Control
                                onChange={handleChange}
                                name="profession"
                                placeholder={"Tapez votre réponse ici..."}
                                aria-label= "profession"
                                value={values.profession}
                                type="text"/>
                            <ErrorMessage name={`profession`} component="div" className="error mt-1"/>
                        </Form.Group>
                    </Col>


                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}


            {step === 4 && (
                <FormValidation handleRecaptchaChange={handleRecaptchaChange} optSuggestions={optSuggestions}
                                handleSubmit={handleSubmit} values={values} setFieldValue={setFieldValue}
                                handleChange={handleChange}/>
            )}


        </div>
    )
}

export default FournisseurForm;