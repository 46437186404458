import {useParams} from 'react-router-dom';
import {Formik, useFormikContext} from 'formik';
import * as Yup from "yup";
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import Select from "react-select";
import {InputGroup,Col,Row,Form,Card} from "react-bootstrap";



const Filter = ({fetchOptions, setInitialValues}) => {

    const {setFieldValue, values, submitForm, handleChange,dirty } = useFormikContext();
    const [optionsFilter, setOptionsFilter] = useState([])

    React.useEffect(() => {

        dirty?fetchOptions(values):null;

    }, [values, submitForm]);




    const getFilterList = () => {

        return axios.get(`/api/search-preferences`)
            .then(function (response) {

                const searchPreferences=response.data;
                return searchPreferences?searchPreferences:[];
            })
            .catch(function (error) {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }



    useEffect(() => {
        getFilterList()
            .then((options) => {
               setOptionsFilter(options);
            })
            .catch((error) => {
                // Gérer les erreurs de récupération des options
            });
    }, []);


    return (
        <Row className={'d-flex'}>
            <Col lg={3} xl={3} xxl={2} className={'mt-2'}>
                <Form.Control
                    onChange={handleChange}
                    style={{ padding: '.45rem' }}
                    className={'mx-0'}
                    name="q"
                    placeholder={'Nom'}
                    value={values.q}
                    type="text"
                />
            </Col>

        </Row>

    )
}


const FilterOption = ({fetchOptions}) => {

    const [id, setId] = useState(useParams().id);
    const isAddMode = !id;

    const [initialValues, setInitialValues] = useState({

        minPrice: '',
        maxPrice: '',
        role: '',
        programme: 'all',
    });

    const validationSchema = Yup.object().shape({});

    function onSubmit(values, actions) {

        const fields = {...values};
        fields.programme = fields.programme.value;
        fields.typeBien.length === 0 ? delete fields.typeBien : null;
        createSearchPreferences(fields);
        actions.setSubmitting(false);
    }

    return (
        <Card id={'theft-card'} className={'mb-3'}>

            <Card.Body className={'px-4'}>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {


                        return (

                            <Form noValidate onSubmit={handleSubmit}>

                                <Filter
                                    setInitialValues={setInitialValues}
                                    fetchOptions={fetchOptions}
                                />
                            </Form>)
                    }}
                </Formik>
            </Card.Body>
        </Card>


    );
}

export default FilterOption;