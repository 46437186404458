import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Formik} from 'formik';
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import Select from "react-select";
import Table from 'react-bootstrap/Table';
import {fetchUserData} from "../../component/fetchUserData";
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import DualTableSelection from "./component/DualTableSelection";
import axios from "axios";
import Swal from 'sweetalert2'

const ReservationHome = ({
                             formikRef, reservation, updateReservation
                         }) => {

    const [id, setId] = useState(useParams().id)
    const [isDisabled, setIsDisabled] = useState(false)
    const [lotList, setLotList] = useState(false)
    const initialValues = {
        typeOfProject: '',
        delaiLivraison: '',
        notaireFraisVente: '',
        notaireFraisPret: ''
    };

    const hasLeadFalse = reservation.acquereurs.some(acquereur => acquereur.lead === false);
    const validationSchema = Yup.object().shape({});
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [nrTranche, setNrTranche] = useState('')
    const [nrGrille, setNrGrille] = useState('')
    const [selectedItems, setSelectedItems] = useState(reservation.lots);

    useEffect(() => {
        console.log(selectedItems)
    }, [selectedItems]);



    const handleChange = (e) => {

        setSelectedOption(e.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        // Logique pour traiter le fichier et le type de média sélectionné
        selectedOption === null ? setSelectedOption("Annexes à signer") : null;
        handleClose();
    };

    useEffect(() => {
        getLotList();
    }, []);

    const getLotList = () => {
        axios.get(`/admin/users/programmes/${reservation.programmeId}/lots?tranches=${nrTranche}&grilles=${nrGrille}&reservationId=${reservation.id}`)
            .then(function (response) {


                setLotList(response.data.lotList);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    async function getUserData() {
        try {
            const userData = await fetchUserData();
            userData.role != 'ROLE_PRESCRIPTEUR' && userData.role != 'ROLE_PROMOTEUR' && userData.role != 'ROLE_ADMIN' ? setIsDisabled(true) : null;
            // Faites quelque chose avec les données utilisateur
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la récupération des données utilisateur :', error);
            // Gérez l'erreur de manière appropriée
        }
    }

    async function onSubmit(values, actions) {

        const fields = {...values};
        fields.typeOfProject = fields.typeOfProject.value;
        fields.lots = selectedItems;


        fields.lots  = selectedItems.map(item => ({
            txTVA: item.TVA,
            lot: item.id,
            mntDG: item.mntDG,
            pxVenteTtc: item.salePrice
        }));
        console.log(fields.lots)

        // if (!fields.pret) {
        //     delete fields.pret;
        // }
        //
        // updateReservation(id, fields);
    }


    const typeOfProjectOptions = [
        {value: 0, label: 'Résidence principale'},
        {value: 1, label: 'Résidence secondaire'},
        {value: 2, label: 'Investissement locatif'}
    ]

    useEffect(() => {

    }, [selectedItems]);

    return (
        <div>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      isValid,
                      isSubmitting,
                      touched,
                      setFieldValue,
                      handleBlur, // handler for onBlur event of form elements
                  }) => {

                    useEffect(() => {

                        getUserData();
                        const fields = [
                            {
                                name: "delaiLivraison",
                            },
                            {
                                name: "notaireFraisVente",

                            },
                            {
                                name: "notaireFraisPret",
                            },
                            {
                                name: "dateEnvoiRecommande",
                            },
                            {
                                name: "pret",
                                parameters: ["montant", "apportPersonnel", "delaiDepotDossier", 'delaiObtention']
                            },
                            {
                                name: "typeOfProject",
                            },
                            {
                                name: "galerie",
                            }
                        ];

                        fields.forEach(field => {

                            setFieldValue(field.name, reservation[field.name], false)
                        });
                    }, []);

                    return (

                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={5} xxl={4}>


                                    <Card>
                                        <Card.Body className={'px-4'}>
                                            <div className={'d-flex align-items-center flex-column mt-5'}>
                                                <h5 className={"mb-2"}> {reservation.acquereurs[0].nom} {reservation.acquereurs[0].prenom}</h5>
                                                <span>
                            <Badge className={"bg-label-secondary"} bg={'secondary'}>Acquereur principal</Badge>
                        </span>

                                            </div>
                                            <div className="d-flex justify-content-around flex-wrap my-4 py-3">
                                                <div className="d-flex align-items-start me-4 mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded">
                          <svg className="" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg"
                               viewBox="0 0 24 24"><path fill="#5a8dee"
                                                         d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"></path></svg>
                                </span>
                                                    <div>
                                                        <h5 className="mb-0">{reservation.nbrLots}</h5>
                                                        <span>Lots</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-start mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded">
                                 <svg className="" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"><path fill="#5a8dee"
                                                                d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39ZM13.54,9.59l-2.69,2.7-.89-.9a1,1,0,0,0-1.42,1.42l1.6,1.6a1,1,0,0,0,1.42,0L15,11a1,1,0,0,0-1.42-1.42Z"></path></svg>
                                </span>
                                                    <div>
                                                        <h5 className="mb-0">{reservation.nbrContacts}</h5>
                                                        <span>Coacquéreurs</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className={"pb-2 border-bottom mb-4"}>Détails</h5>
                                            <div className={"info-container mb-4"}>


                                                <Table responsive="xl" borderless>
                                                    <tbody>
                                                    <tr>
                                                        <td className={"font-weight-bold"}>Civilité</td>
                                                        <td>{reservation.acquereurs[0].civility}</td>

                                                    </tr>
                                                    <tr>
                                                        <td className={"font-weight-bold"}>Email</td>
                                                        <td>{reservation.acquereurs[0].email}</td>

                                                    </tr>
                                                    <tr>

                                                        <td className={"font-weight-bold"}>Adresse</td>
                                                        <td>{reservation.acquereurs[0].address}</td>

                                                    </tr>
                                                    <tr>

                                                        <td className={"font-weight-bold"}>Ville</td>
                                                        <td>{reservation.acquereurs[0].city}</td>

                                                    </tr>
                                                    <tr>

                                                        <td className={"font-weight-bold"}>Téléphone</td>
                                                        <td>{reservation.acquereurs[0].phone}</td>

                                                    </tr>
                                                    <tr>
                                                        <td className={"font-weight-bold"}>Pays</td>
                                                        <td>{reservation.acquereurs[0].country}</td>

                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={12} lg={12} xl={7} xxl={8}>

                                    <Card>
                                        <Card.Body>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="card-title align-items-start flex-column">
                                                    <h2 className={'mb-0'}>
                                                        Caractéristiques
                                                    </h2>

                                                </div>
                                                <div className="card-toolbar">


                                                </div>
                                            </div>


                                            <Row className="my-4">

                                                <Col xl={6} xxl={3}>
                                                    <Form.Label>Type de projet</Form.Label>
                                                    <Select
                                                        options={typeOfProjectOptions}
                                                        name={'typeOfProject'}
                                                        isDisabled={isDisabled}
                                                        type="text"
                                                        value={values.typeOfProject}
                                                        onChange={value =>
                                                            setFieldValue(`typeOfProject`, value)}
                                                    />
                                                </Col>
                                                <Col xl={6} xxl={3}>
                                                    <Form.Label>Délai de livraison</Form.Label>
                                                    <Form.Control
                                                        name={'delaiLivraison'}
                                                        type="text"
                                                        disabled={isDisabled}
                                                        onChange={handleChange}
                                                        value={values.delaiLivraison}
                                                    />
                                                </Col>
                                                <Col xl={6} xxl={3}>
                                                    <Form.Label>Frais notaire</Form.Label>
                                                    <Form.Control
                                                        name={'notaireFraisVente'}
                                                        type="text"
                                                        disabled={isDisabled}
                                                        onChange={handleChange}
                                                        value={values.notaireFraisVente}
                                                    />
                                                </Col>
                                                <Col xl={6} xxl={3}>
                                                    <Form.Label>Frais notariés du prêt</Form.Label>
                                                    <Form.Control
                                                        name={'notaireFraisPret'}
                                                        disabled={isDisabled}
                                                        type="text"
                                                        onChange={handleChange}
                                                        value={values.notaireFraisPret}
                                                    />
                                                </Col>
                                            </Row>
                                            {values.pret && (

                                                <div>
                                                    <h2 className={'pt-3'}>Prêt</h2>

                                                    <Row className="mt-4">

                                                        <Col>
                                                            <Form.Label>Montant du prêt</Form.Label>
                                                            <Form.Control
                                                                name={`pret.montant`}
                                                                type="text"
                                                                disabled={isDisabled}
                                                                onChange={handleChange}
                                                                value={values.pret.montant}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Label>Apport personnel</Form.Label>
                                                            <Form.Control
                                                                name={`pret.apportPersonnel`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                disabled={isDisabled}
                                                                value={values.pret.apportPersonnel}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Label>Délai du dépot du dossier</Form.Label>
                                                            <Form.Control
                                                                name={`pret.delaiDepotDossier`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                disabled={isDisabled}
                                                                value={values.pret.delaiDepotDossier}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Label>Délai d'obtention du du crédit</Form.Label>
                                                            <Form.Control
                                                                name={`pret.delaiObtention`}
                                                                type="text"
                                                                disabled={isDisabled}
                                                                onChange={handleChange}
                                                                value={values.pret.delaiObtention}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </Card.Body>
                                        <Card.Body>
                                        </Card.Body>
                                    </Card>
                                    <Row className="mt-4">
                                        {reservation && reservation.lots && reservation.lots.length > 0 && lotList.length > 0 && selectedItems.length > 0 ?
                                            <DualTableSelection selectedLots={selectedItems} setSelectedLots={setSelectedItems} availableLots={lotList}/> : null}
                                        {hasLeadFalse ?
                                            <Col>
                                                <Card>
                                                    <Card.Body>

                                                        <h2 className={'pt-1'}>Coacquéreurs</h2>


                                                        {reservation && reservation.acquereurs.length > 0 ? reservation.acquereurs.map((acquereur, index) => {

                                                            return (

                                                                acquereur.lead === false ?
                                                                    <Table responsive="xl" borderless id={'cnt-list'}
                                                                           key={index}>
                                                                        <tbody key={index}>
                                                                        <tr>
                                                                            <td className={"font-weight-medium"}>Nom
                                                                                complet
                                                                            </td>
                                                                            <td>{acquereur.nom} {acquereur.prenom}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td className={"font-weight-medium"}>Email</td>
                                                                            <td>{acquereur.email}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className={"font-weight-medium"}>Adresse</td>
                                                                            <td>{acquereur.address}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className={"font-weight-medium"}>Ville</td>
                                                                            <td>{acquereur.city}</td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </Table> : null

                                                            )
                                                        }) : null}


                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            : null}
                                    </Row>
                                </Col>

                            </Row>
                        </Form>)

                }}

            </Formik>

            <Modal id={'add-medias'} show={open} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className={'h5'}>Sélectionner le type de média</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Label className={'mb-4'}>Type de média</Form.Label>
                        <Form.Check
                            label="Annexes à signer"
                            type={'radio'}
                            value="Annexes à signer"
                            name="group1"
                            className={'mb-4'}
                            id={`reverse`}
                            onChange={handleChange}
                            defaultChecked
                        />
                        <Form.Check
                            name="group1"
                            label="Autres"
                            type={'radio'}
                            id={`reverse`}
                            value="Autres"
                            onChange={handleChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={handleConfirm} variant="primary">
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>


    )
}

export default ReservationHome;