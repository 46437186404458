import axios from "axios";
import {getSender} from "./GetSender";




export async function GetMail(id, setEmail, setName,setMail) {

    try {
        const messageUrl = 'http://www.t1.viteimmo.com:3000/apo/mail/user/admin@laei.fr/read/' + id;
        const response = await axios.get(messageUrl);
        const emailData = response.data;
        setEmail(emailData);
        const senderHeader = emailData.payload.headers.find((header) => header.name === 'From').value;

        if (senderHeader) {
            // Utilisez une expression régulière pour extraire le nom et l'adresse e-mail
            let [name, email] = getSender(senderHeader);

            setMail(email);
           setName(name);
        }
        return emailData;
    } catch (error) {
        console.error('Une erreur s\'est produite lors de la récupération des données utilisateur :', error);
        throw error;
    }

}