import React, {useState, useEffect} from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import Card from 'react-bootstrap/Card';
import {useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Formik} from 'formik';
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import {fetchUserData} from "../../../component/fetchUserData";
import {MultipleFileUpload} from "./MultipleFileUpload";
import MediaTab from "../../../component/MediaTab";
import {getMedias} from "../../../services/mediaService";

const params = new URLSearchParams();


const ReservationMediaTab = ({
                                 handleSelect,
                                 setIsContractAvailable,
                                 setIsPdfContractAvailable,
                                 setPreviousFiles,
                                 previousFiles,
                                 dataFiles,
                                 setDataFiles, user,formikRef,reservation,getStatusContract,setMediaList,mediaList
                             }) => {

    const [id, setId] = useState(useParams().id)
    const [isAddMode, setIsAddMode] = useState(!id)
    const initialValues = {
        typeOfProject: '',
        delaiLivraison: '',
        notaireFraisVente: '',
        notaireFraisPret: ''
    };

    const validationSchema = Yup.object().shape({});
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {

        getMedias(id,setMediaList);
    }, []);

    async function onSubmit(values, actions) {

        const fields = {...values};
        fields.typeOfProject = fields.typeOfProject.value;

        if (!fields.pret) {
            delete fields.pret;
        }

        var uploadedFiles = fields.medias;

        if (uploadedFiles) {
            const newFiles = uploadedFiles.filter((obj) => obj.uploaded === false);
            if (newFiles.length > 0) {

                await uploadFiles(newFiles, reservation, actions)
                // Fonction pour mettre à jour le produit
                await delete fields.medias;
                await updateReservation(id, fields, actions);

            }
        } else {
            updateReservation(id, fields, actions);
        }
    }

    async function uploadFiles(uploadedFiles, reservation, actions) {

        try {

            params.append('nrPromoteur', reservation.codeClient);
            params.append('idReservation', reservation.id);

            if (reservation.galerie !== null && reservation.galerie !== undefined) {
                params.append('galerie', reservation.galerie);
            }

            const url = `/admin/upload-file?${params.toString()}`;

            const response = await axios.post(url, {uploadedFiles}, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })

        } catch (error) {
            // Gérer les erreurs
            console.error('Une erreur s\'est produite lors de la mise à jour du produit', error);
            // Lancez une exception pour arrêter l'exécution du reste du code
            throw error;
        }
        return response;
    };


    async function updateReservation(id, fields, actions) {

        try {
            axios.patch(`/admin/reservation/${id}`, fields)
            Swal.fire({
                icon: 'success',
                title: 'Réservation mise à jour avec succès!',
                showConfirmButton: false,
                timer: 1500
            })

        } catch (error) {
            // Gérer les erreurs
            console.error('Une erreur s\'est produite lors du téléchargement des fichiers', error);
            // Lancez une exception pour arrêter l'exécution du reste du code
            throw error;
        }
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      isValid,
                      isSubmitting,
                      touched,
                      setFieldValue,
                      handleBlur, // handler for onBlur event of form elements
                  }) => {

                    return (
                        <div>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg="12">
                                        <Card id={'card-media-list'}>
                                            <Card.Header
                                                className="d-flex justify-content-between align-items-center border-bottom">
                                                <h2 className={'mb-0'}>Annexes</h2>
                                            </Card.Header>
                                            <Card.Body>
                                            {reservation ?

                                                <MultipleFileUpload
                                                    getMedias={getMedias}
                                                    reservation={reservation}
                                                    setMediaList={setMediaList}
                                                    formikRef={formikRef}
                                                    selectedOption={selectedOption}
                                                    previousFiles={previousFiles}
                                                    dataFiles={dataFiles}
                                                    setDataFiles={setDataFiles}
                                                    name="files"
                                                    setIsContractAvailable={setIsContractAvailable}
                                                    setIsPdfContractAvailable={setIsPdfContractAvailable}
                                                    isAddMode={isAddMode}
                                                    gallery={reservation.galerie}
                                                    setIsAddMode={setIsAddMode}
                                                    setFieldValue={setFieldValue}/>
                                                : null}
                                        </Card.Body>
                                        </Card>
                                    </Col>

                                </Row>
                            </Form>

                            <MediaTab medias={mediaList} setMediaList={setMediaList} reservation={reservation} getStatusContract={getStatusContract}/>
                        </div>)

                }}

            </Formik>
        </div>
    )
}

export default ReservationMediaTab;