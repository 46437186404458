import React, {useState, useEffect} from 'react';
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {useNavigate, useParams} from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import {Row} from "react-bootstrap";

const CardContactShare = ({user, setUser, isImpersonating, setIsImpersonating}) => {

    const [checkboxes, setCheckboxes] = useState([]);

    const getAllPromoteurs = () => {
        axios.get(`/admin/promoteurs`)
            .then(function (response) {

                setCheckboxes(response.data)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const handleCheckboxChange = (id) => {
        setCheckboxes((prevItems) =>
            prevItems.map((item) =>
                item.id === id ? { ...item, checked: !item.checked } : item
            )
        );
    };


    const postContactsShare = () => {



        axios.post(`/admin/promoteurs`, {promoteurs:checkboxes})
            .then(function (response) {

                // setShowAction(false);
                // if(getAllActions){
                //     getAllActions();
                // }


                Swal.fire({
                    icon: 'success',
                    title: 'Partage de contacts',
                    text: 'Les modifications ont été appliqués avec succès.',
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })

            });


    }

    useEffect(() => {

        getAllPromoteurs();
    }, []);


    return (
        <Card>
            <Card.Body className={'px-4'}>

                <h5 className={"pb-4 border-bottom mb-4"}>Partage de contacts</h5>

                <div className={"info-container mb-4"}>

                    <Row className="mt-4">
                        <Col xs={12} className={'mb-3'}>
                            <Form.Label>Promoteur(s)</Form.Label>

                            {checkboxes.length > 0 && checkboxes.map((promoteur,index) => {
                                return (
                                    <Form.Check
                                        label={promoteur.label}
                                        type={'checkbox'}
                                        checked={promoteur.checked}
                                        onChange={() => handleCheckboxChange(promoteur.id)}
                                    />

                                )
                            })}


                            {/*<Select*/}
                            {/*    options={optionsImpersonateUser}*/}
                            {/*    defaultValue={{label: "Choisir un utilisateur", value: 'all'}}*/}
                            {/*    onChange={newValue =>*/}

                            {/*        setUserId(newValue.value)*/}
                            {/*    }*/}
                            {/*/>*/}
                        </Col>

                        <Button variant="primary" onClick={postContactsShare}>
                            Valider
                        </Button>
                    </Row>


                </div>
                {/*                <div className={'d-flex pt-3'}>*/}
                {/*{isImpersonating ? <Button onClick={stopImpersonation} variant="primary">Quitter l'emprunt</Button>   : <Button onClick={startImpersonation} variant="primary">Valider</Button>}*/}

                {/*                </div>*/}
            </Card.Body>
        </Card>
    )
}

export default CardContactShare;