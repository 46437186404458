var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LinearProgress, Typography, } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { red } from '@mui/material/colors';
import { FileHeader } from './FileHeader';
var ErrorLinearProgress = styled(LinearProgress)(function (_a) {
    var theme = _a.theme;
    return ({
        '& .MuiLinearProgress-bar': {
            backgroundColor: red[500],
        },
    });
});
export function UploadError(_a) {
    var file = _a.file, onDelete = _a.onDelete, errors = _a.errors;
    return (_jsxs(React.Fragment, { children: [_jsx(FileHeader, { file: file, onDelete: onDelete }), _jsx(ErrorLinearProgress, { variant: "determinate", value: 100 }), errors.map(function (error) { return (_jsx("div", { children: _jsx(Typography, __assign({ color: "error" }, { children: error.message })) }, error.code)); })] }));
}
