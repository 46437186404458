import React, {createContext, useState, useEffect, useRef} from 'react';
import Layout from "../../component/Layout"
import {useNavigate, useParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Swal from 'sweetalert2'
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Icon} from "@iconify/react/dist/iconify";
import ReservationHome from "./ReservationHome";
import BuildContract from "./BuildContract";
import {fetchIsContractAvailable} from "../../component/FetchIsContractAvailable";
import Button from "react-bootstrap/Button";
import axios from 'axios';
import ReservationMediaTab from "./component/ReservationMediaTab";
import {refreshMediaList} from "../../component/RefreshMediaList";
import {getMedias} from "../../services/mediaService";

export const FormContext = createContext();

function ReservationEdit({user}) {

    let navigate = useNavigate();
    const [mediaList, setMediaList] = useState([])
    const [htmlContent, setHtmlContent] = useState('');
    const [id, setId] = useState(useParams().id)
    const [isAddMode, setIsAddMode] = useState(!id)
    const [loading, setLoading] = useState(false);
    const [isPdfContractAvailable, setIsPdfContractAvailable] = useState(false);
    var [key, setKey] = useState('home');
    const [isContractAvailable, setIsContractAvailable] = useState(false);
    const [dataFiles, setDataFiles] = useState([]);
    const [previousFiles, setPreviousFiles] = useState([]);
    const [contractPdfPath, setContractPdfPath] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [reservation, setReservation] = useState({acquereurs: [], lots: [], medias: []});
    const urlParams = new URLSearchParams(window.location.search);
    const formikRef = useRef(null);
    const [sideBarTab, setSideBarTab] = useState({
            program: '-',
            email: '-',
            nom: '-',
            prenom: '-',
            societe: null,
            cellphone: '-',
            lots: [{}]
        }
    );

    useEffect(() => {

    }, [htmlContent]);

    useEffect(() => {

        var keyParam = urlParams.get('key');
        keyParam && reservation.template ? setKey(keyParam) : null;
    }, [])

    const createPdf = async () => {

        setIsLoading(true);

        Swal.fire({
            title: 'Veuillez patienter',
            text: 'La génération du contrat est en cours...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        axios.post(`/admin/upload-reservation-contract?nrPromoteur=${reservation.codeClient}&&reservation=${id}`, {htmlContent: htmlContent}, {})
            .then((response) => {

                setIsLoading(false);
                refreshMediaList(id, setDataFiles, setPreviousFiles)
                handleSelect('media');
                getStatusContract();

                getMedias(id, setMediaList);

                Swal.fire({
                    icon: 'success',
                    title: 'Contrat archivé',
                    showConfirmButton: false,
                    timer: 3000
                })

                return response.data;
            })
            .catch((error) => {

                if (error.response.status === 404) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Contrat de réservation',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Erreur lors de l'envoi du contrat",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }


                setIsLoading(false);


            });
    }

    function handleSelect(key) {
        setKey(key)
    }


    async function reservationConfirm(id, fields) {
        if (fields.statut === 2) {

            Swal.fire({
                icon: 'warning',
                title: "Confirmation",
                text: "Souhaitez-vous confirmer la réservation ? Un mail de confirmation sera envoyé au contact.",
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                dangerMode: true,
            })
                .then(result => {

                        if (result.isConfirmed) {

                            updateReservation(id, fields);
                        }
                    }
                );
        }


    }

    async function updateReservation(id, fields) {

        axios.patch(`/admin/reservation/${id}`, fields).then(function (response) {

            Swal.fire({
                icon: 'success',
                title: 'Réservation mise à jour avec succès!',
                showConfirmButton: false,
                timer: 1500
            })

            let reservation = response.data;
            setReservation(reservation)

            viewContractPreview();
        })
            .catch(function (error) {

                if (error.response.status == 404) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Aucun modèle de contrat de réservation disponible',
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
                else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }


            });
    }

    useEffect(() => {

        const checkContractAvailability = async () => {
            try {
                const isAvailable = await getDocxContract();
                setIsContractAvailable(isAvailable);

                if (!isAvailable && reservation.template) {
                    viewContractPreview();
                }
            } catch (error) {
                console.error('Une erreur s\'est produite lors de la vérification de la disponibilité du contrat :', error);
                // Gérez l'erreur de manière appropriée
            }
        };

        checkContractAvailability();

    }, [reservation]);


    async function getDocxContract() {
        try {
            const result = await fetchIsContractAvailable(id, 'contrat');

            return result.data != '' ? true : false;
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la récupération des données utilisateur :', error);
            // Gérez l'erreur de manière appropriée
        }
    }


    useEffect(() => {

        fetchReservation();
    }, []);


    //Automatiquement déclencher si un modèle de réservation existe, permet l'affichage du contrat de réservation en HTML au démarrage
    const fetchReservation = () => {

        axios.get(`/admin/reservations/${id}`)
            .then(function (response) {
                let reservation = response.data;
                setReservation(reservation)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    };


//Automatiquement déclencher si un modèle de réservation existe, permet l'affichage du contrat de réservation en HTML au démarrage
    const viewContractPreview = () => {

        // Appel Axios ici pour créer le fichier docx
        axios.post(`/admin/docx-contrat/${id}`, null, {
            params: {
                nrPromoteur: reservation.codeClient,
                extension: 'docx'
            },
        })
            .then(response => {
                // refreshMediaList(id, setDataFiles, setPreviousFiles)
                setIsContractAvailable(true);
            })
            .catch(error => {
                // Gérer les erreurs en cas d'échec
                if (error.response.status == 404) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Aucun modèle de contrat de réservation disponible',
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
                console.error('Une erreur s\'est produite lors de la création du fichier.', error);
            });
    };


    const sendToDocuSign = async () => {
        // Définir l'état à "loading"
        setLoading(true);

        //Afficher le loader
        Swal.fire({
            title: 'Veuillez patienter',
            text: 'Le transfert vers DocuSign est en cours...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        axios.post(`/admin/send-envelope`, {idReservation: id, htmlContent: htmlContent}, {})
            .then((response) => {

                setIsLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Confirmation d\'Envoi',
                    text: 'Votre document a été envoyé pour signature avec succès. Vous recevrez une notification dès que le processus de signature sera complété. Merci pour votre confiance.',
                    showConfirmButton: false,
                    timer: 6000
                })
            })
            .catch((error) => {
                console.log("Erreur : " + error)
                setIsLoading(false);

                Swal.fire({
                    icon: 'error',
                    title: "Erreur lors de l'envoi du contrat",
                    text: error.message,
                    showConfirmButton: false,
                    timer: 1500
                })

            }).finally(function () {
                setLoading(false);
            }
        );
    };


    async function getStatusContract() {
        try {
            const result = await fetchIsContractAvailable(id, 'reservation_contract');

            if (result.status === 200) {
                setIsContractAvailable(true);
                setContractPdfPath(result.data);
                setIsPdfContractAvailable(true);
            } else if (result.status === 204) {
                // L'élément n'a pas été trouvé, traiter l'indisponibilité
                setIsPdfContractAvailable(false);
                console.log("Aucun contrat trouvé");
            }
            // Faites quelque chose avec les données utilisateur
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la récupération des données utilisateur :', error);
            // Gérez l'erreur de manière appropriée
        }
    }

    useEffect(() => {

        if (!isAddMode) {
            getStatusContract()
        }

    }, [sideBarTab])

    const renderTabsSwitch = () => {

        return <Tabs
            defaultActiveKey="home"
            activeKey={key}
            onSelect={handleSelect}
            id="uncontrolled-tab-example"
            className="mb-3"
        ><Tab eventKey="home" title={<span><Icon icon="cil:description"/>{" Caractéristiques"}</span>}>
            {reservation.id ?
                <ReservationHome reservation={reservation} formikRef={formikRef}
                                 setIsPdfContractAvailable={setIsPdfContractAvailable}
                                 setPreviousFiles={setPreviousFiles} previousFiles={previousFiles}
                                 handleSelect={handleSelect} setIsContractAvailable={setIsContractAvailable}
                                 dataFiles={dataFiles} setDataFiles={setDataFiles} id={id} user={user}
                                 updateReservation={updateReservation}/> : null}
        </Tab>
            <Tab eventKey="media" title={<span><Icon icon="material-symbols:perm-media-outline"/>{" Documents"}</span>}>
                {reservation.id ? <ReservationMediaTab
                    mediaList={mediaList}
                    setMediaList={setMediaList}
                    reservation={reservation} formikRef={formikRef}
                    getStatusContract={getStatusContract}
                    setIsPdfContractAvailable={setIsPdfContractAvailable}
                    setPreviousFiles={setPreviousFiles} previousFiles={previousFiles}
                    handleSelect={handleSelect}
                    setIsContractAvailable={setIsContractAvailable}
                    dataFiles={dataFiles} setDataFiles={setDataFiles} id={id}
                    user={user}/> : null}
            </Tab>
            {isContractAvailable ? <Tab eventKey="contract" title={<span><Icon icon="uil:file"/>{" Contrat"}</span>}>
                <BuildContract htmlContent={htmlContent} setHtmlContent={setHtmlContent}
                               getStatusContract={getStatusContract} isContractAvailable={isContractAvailable}
                               contractPdfPath={contractPdfPath}
                               handleSelect={handleSelect} setIsPdfContractAvailable={setIsPdfContractAvailable}
                               isPdfContractAvailable={isPdfContractAvailable} id={id} setDataFiles={setDataFiles}
                               reservation={reservation}
                               setPreviousFiles={setPreviousFiles} user={user}/>
            </Tab> : null}

        </Tabs>
            ;
    }

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1 d-flex">
                                <div>
                                    <h1>Réservation</h1>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
                                            navigate(`/admin/reservation/list`)
                                        }}>
                                            Liste des réservations
                                        </li>
                                        <li className="breadcrumb-item active ">{isAddMode ? "Ajouter une réservation" : "Editer une réservation"}</li>
                                    </ol>
                                </div>
                                {user.role === 'ROLE_PRESCRIPTEUR' || user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ?
                                    <div>
                                        {reservation.docuSign ?
                                            <Button
                                                variant="primary"
                                                border="success" className="me-1"
                                                disabled={reservation.statut === 5 || reservation.statut === 4 || reservation.statut === 1 ? true : false}
                                                onClick={(e) => sendToDocuSign()}
                                            >Envoyer pour signature
                                            </Button> :
                                            <Button
                                                type="button"
                                                variant="success"
                                                className={'btn-label-success me-2'}
                                                onClick={(e) => createPdf()}
                                                disabled={isPdfContractAvailable ? true : false}
                                            >

                                                <Icon width={'20px'} height={'20px'} className={'me-2'}
                                                      icon="material-symbols-light:file-export-outline-rounded"/>
                                                Générer le contrat
                                            </Button>}
                                        <Button
                                            variant={"success"}
                                            onClick={() => {
                                                if (formikRef.current) {
                                                    formikRef.current.handleSubmit();
                                                }
                                            }}
                                        >
                                            <Icon width={'17px'} height={'17px'} icon="radix-icons:update"
                                                  className={'me-2'}/>
                                            Mettre à jour
                                        </Button>
                                        {reservation.statut === 3 ? <Button
                                            variant={"primary"}
                                            className={'ms-2'}
                                            onClick={() => {
                                                reservationConfirm(id, {statut: 2})
                                            }}
                                        >
                                            <Icon width={'20px'} height={'20px'} icon="system-uicons:home-check"
                                                  className={'me-2'}/>
                                            Confirmer
                                        </Button> : null}

                                    </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    {
                        <Row>
                            {renderTabsSwitch()}
                        </Row>


                    }
                </div>
            </section>
            {/*</form>*/}

        </Layout>
    );
}

export default ReservationEdit;