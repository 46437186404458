import {useDropzone} from "react-dropzone";
import React from 'react';
import {formatDate} from "@fullcalendar/core";

//month{numeric,long}
export function convertFormatDate(date,month) {

    return formatDate(date, {

        day: "numeric",
        month: month,
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        separator:'-',
        locale: 'fr'
    });
}