
import React from 'react';

export function dateValueForRow(date) {

    var elem = '';

    if (date) {
        var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
            <time>{date}</time>
        </div>;
    }
    return elem;

}