import React, {useState, useEffect} from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import {ErrorMessage} from "formik";
import PlacesAutocomplete from "../../../component/PlacesAutocomplete";
import Downshift from 'downshift'
import ListGroup from 'react-bootstrap/ListGroup';
import InputMask from 'react-input-mask';
import {familySituationOptions} from "../../../constants/options";
import { onFamilySituationChange} from "../../../utils/utils";
const ContactForm = ({buyer, index, handleChange, errors, setFieldValue, values, touched, handleBlur, isValid}) => {

    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [nationalityList, setNationalityList] = useState([])
    const [isToggled, setIsToogled] = useState(false);
    const zipToString = item => (item ? item.postal_code : '')
    const localityToString = item => (item ? item.locality : '')
    const personOptions = [
        {value: 0, label: 'Physique'},
        {value: 1, label: 'Morale'},
    ]

    const options = [
        {value: 1, label: 'Monsieur'},
        {value: 2, label: 'Madame'},
        {value: 3, label: 'Mademoiselle'},
        {value: 4, label: 'Monsieur et madame'},
        {value: 5, label: 'Société'},
        {value: 6, label: 'Messieurs'},
        {value: 7, label: 'Mesdames'},
        {value: 8, label: 'Mesdemoiselles'},
        {value: 9, label: 'Monsieur et mademoiselle'},
        {value: 10, label: 'Maître'}
    ]

    const [contactList, setContactList] = useState([])

    // Utilisation de la fonction onFamilySituationChange
    const handleFamilySituationChange = (selection) => {

        onFamilySituationChange(selection, setFieldValue, index);
    };

    useEffect(() => {

        getAllContacts();
        getAllCountries();
        getAllNationalities();
    }, [errors, buyer])

    const [fieldsName, setFieldsName] = useState(
        {
            zip: `buyers[${index}]zip`,
            city: `buyers[${index}]city`,
            address: `buyers[${index}]address`,
            email: `buyers[${index}]email`,
        }
    );

    const handleTypeOfPersonChange = (newValue) => {

        setFieldValue(`buyers[${index}]typeOfPerson`, newValue)

        if (newValue.value == 1) {

            setIsToogled(true)
        } else {
            setIsToogled(false)
        }
    };

    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getCities = (fieldName, place) => {

        if(place){

            axios.get(`/public/places/${place}?field=${fieldName}`)
                .then(function (response) {

                    setCityList(response.data)
                })
                .catch(function (error) {

                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        }


    }

    const getAllNationalities = () => {
        axios.get(`/nationalities`)
            .then(function (response) {

                setNationalityList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getAllContacts = () => {
        axios.get(`/api/filter/contacts?from=booked`)
            .then(function (response) {

                setContactList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const fieldNames =['typeOfPerson','familySituation','nationality','society','id','civilite','nom','prenom','address','zip','city','role','pays','email','cellphone','phone','profession','birthDate']


    const onEmailSelection = (selection) => {

        Object.keys(selection).map((key, i) => {

            if (key != 'value' && fieldNames.includes(key)) {

                setFieldValue(`buyers[${index}]${key}`, selection[key])
            }
        });
    }

    const onLocalitySelection = (selection) => {

        setFieldValue(`buyers[${index}]zip`, selection.postal_code)
        setFieldValue(`buyers[${index}]city`, selection.locality)
    }

    return (
        <div key={index}>
            <Row className="mt-4">
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Type d'acquéreur</Form.Label>
                    <Select
                        key={`buyers[${index}]typeOfPerson`}
                        options={personOptions}
                        defaultValue={{label: "Physique", value: 0}}
                        name={`buyers[${index}]typeOfPerson`}
                        id={`buyers_${index}typeOfPerson`}
                        value={buyer.typeOfPerson}
                        onChange={newValue =>

                            handleTypeOfPersonChange(newValue)
                        }
                    />
                </Col>
            </Row>
            {buyer.typeOfPerson && buyer.typeOfPerson.value == 1 ?
                <Row className="mt-4">
                    <Col xs={12}>

                        <>
                            <Form.Label>AGISSANT EN QUALITE DE</Form.Label>
                            <Form.Control
                                key={`buyers[${index}]society.actingAs`}
                                name={`buyers[${index}]society.actingAs`}
                                id={`buyers_${index}society.actingAs`}
                                onChange={handleChange}
                                type="text"
                                value={buyer.society.actingAs}
                            />
                            <ErrorMessage name={`buyers[${index}]society.actingAs`} component="div" className="error"/>
                        </>


                    </Col>
                    <Col>

                        <>
                            <Form.Label>Société</Form.Label>
                            <Form.Control
                                key={`buyers[${index}]society.nom`}
                                name={`buyers[${index}]society.nom`}
                                id={`buyers_${index}society.nom`}
                                onChange={handleChange}
                                type="text"
                                value={buyer.society.nom}
                            />
                            <ErrorMessage name={`buyers[${index}]society.nom`} component="div" className="error"/>
                        </>


                    </Col>
                    <Col>

                        <>
                            <Form.Label>Ville d'immatriculation RCS</Form.Label>
                            <Form.Control
                                key={`buyers[${index}]society.cityRCSRegistration`}
                                name={`buyers[${index}]society.cityRCSRegistration`}
                                id={`buyers_${index}society.cityRCSRegistration`}
                                onChange={handleChange}
                                type="text"
                                value={buyer.society.cityRCSRegistration}
                            />
                            <ErrorMessage name={`buyers[${index}]society.cityRCSRegistration`} component="div" className="error"/>
                        </>

                    </Col>
                    <Col>

                        <>
                            <Form.Label>Numéro de siret</Form.Label>

                            <InputMask
                                mask="999 999 999 99999" // Ajoutez le masque de saisie pour le SIRET
                                maskChar="" // Supprimez le caractère de masque par défaut '_'
                                className="form-control" // Ajoutez les classes Bootstrap si nécessaire
                                placeholder="Entrez votre SIRET"
                                key={`buyers[${index}]society.siretNumber`}
                                name={`buyers[${index}]society.siretNumber`}
                                id={`buyers_${index}society.siretNumber`}
                                onChange={handleChange}
                                type="text"
                                value={buyer.society.siretNumber}
                            />
                            <ErrorMessage name={`buyers[${index}]society.siretNumber`} component="div" className="error"/>
                        </>

                    </Col>


                </Row>: null}
            <Row className="mt-4">

                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Control
                        key={`buyers[${index}]id`}
                        name={`buyers[${index}]id`}
                        id={`buyers_${index}id`}
                        type="hidden"
                        value={buyer.id}
                    />
                    <Form.Label>Email</Form.Label>
                    <Downshift
                        id={`email_${index}_autocompletion`}
                        onChange={(selection) =>

                            onEmailSelection(selection)
                        }
                        itemToString={(item) => (item ? item.value : '')}
                    >
                        {({
                              getInputProps,
                              getItemProps,
                              getLabelProps,
                              isOpen,
                              inputValue,
                              highlightedIndex,
                              selectedItem
                          }) => (
                            <div className="dropdown-email">
                                <Form.Control {...getInputProps({name: `buyers[${index}]email`,value:values.buyers[index].email,onChange: handleChange,})} />
                                {isOpen & contactList.length > 0
                                    ? contactList
                                        .filter((item) => !inputValue || item.email.includes(inputValue.toLowerCase()))
                                        .map((item, index) => (
                                            index < 10 ?
                                                <ListGroup.Item
                                                    {...getItemProps({
                                                        key: item.email,
                                                        index,
                                                        item,
                                                        style: {
                                                            backgroundColor:
                                                                highlightedIndex === index ? 'lightgray' : 'white',
                                                            fontWeight: selectedItem === item ? 'bold' : 'normal',
                                                        },
                                                    })}
                                                >
                                                    {item.email}
                                                </ListGroup.Item> : null
                                        ))
                                    : null}
                            </div>
                        )}
                    </Downshift>

                    <ErrorMessage name={`buyers[${index}]email`} component="div" className="error"/>


                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Civilité</Form.Label>
                    <Select
                        key={`buyers[${index}]civilite`}
                        options={options}
                        defaultValue={{label: "Monsieur", value: 1}}
                        styles={{menuPortal: base => ({...base, zIndex: 9999, flex: 1})}}
                        name={`buyers[${index}]civilite`}
                        id={`buyers_${index}_civilite`}
                        value={buyer.civilite}
                        onChange={newValue =>

                            setFieldValue(`buyers[${index}]civilite`, newValue)}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                        key={`buyers[${index}]nom`}
                        name={`buyers[${index}]nom`}
                        id={`buyers_${index}nom`}
                        // placeholder="Veuillez indiquer votre nom"
                        onChange={handleChange}
                        type="text"
                        value={buyer.nom}
                    />
                    <ErrorMessage name={`buyers[${index}]nom`} component="div" className="error"/>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control
                        key={`buyers[${index}]prenom`}
                        name={`buyers[${index}]prenom`}
                        id={`buyers_${index}prenom`}
                        onChange={handleChange}
                        type="text"
                        value={buyer.prenom}
                    />
                    <ErrorMessage name={`buyers[${index}]prenom`} component="div" className="error"/>
                </Col>

            </Row>
            <Row className="my-3">

                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Adresse</Form.Label>
                    <PlacesAutocomplete
                        fieldValueChanger={setFieldValue}
                        fieldsName={fieldsName}
                        initialAddress={buyer.address}
                    ></PlacesAutocomplete>
                    <ErrorMessage name={`buyers[${index}]address`} component="div" className="error"/>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>

                    <Form.Label>Code postal</Form.Label>

                    <div>
                        <Downshift itemToString={zipToString}
                                   onInputValueChange={(inputValue) => {
                                       getCities('codePostal', inputValue);
                                   }}

                                   onChange={(selection) =>
                                       onLocalitySelection(selection)
                                   }
                        >
                            {({
                                  getLabelProps,
                                  getInputProps,
                                  getMenuProps,
                                  getItemProps,
                                  getToggleButtonProps,
                                  clearSelection,
                                  highlightedIndex,
                                  selectedItem,
                                  isOpen,
                                  inputValue,
                              }) => (
                                <div className="dropdown-zip">

                                    <Form.Control {...getInputProps(       {
                                        name:`buyers[${index}]zip`,
                                        onChange: handleChange,
                                        value:buyer.zip
                                    })} />
                                    <div className={"downshift-menu-list"}>
                                        {isOpen && cityList.length > 0
                                            ? cityList.map((item, index) => (

                                                <ListGroup.Item
                                                    className={"downshift-option"}
                                                    {...getItemProps({
                                                        item,
                                                        key: item.id,
                                                        style: {
                                                            backgroundColor:
                                                                index === highlightedIndex ? '#DEEBFF' : null,
                                                        },
                                                    })}
                                                >
                                                    {item.postal_code} {item.locality}
                                                </ListGroup.Item>
                                            ))
                                            : null}</div>
                                </div>
                            )}
                        </Downshift>
                    </div>

                    <ErrorMessage name={`buyers[${index}]zip`} component="div" className="error"/>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Ville</Form.Label>
                    <Downshift
                        itemToString={localityToString}
                        onInputValueChange={(inputValue) => {
                            getCities('nom', inputValue);
                        }}
                        onChange={(selection) =>
                            onLocalitySelection(selection, setFieldValue)
                        }

                    >
                        {({
                              getLabelProps,
                              getInputProps,
                              getMenuProps,
                              getItemProps,
                              getToggleButtonProps,
                              clearSelection,
                              highlightedIndex,
                              selectedItem,
                              isOpen,
                              inputValue,
                          }) => (

                            <div className="dropdown-zip">

                                <Form.Control {...getInputProps(
                                    {
                                        name:`buyers[${index}]city`,
                                        onChange: handleChange,
                                        value:buyer.city
                                    }

                                )} />
                                <div className={"downshift-menu-list"}>
                                    {isOpen && cityList.length > 0 && inputValue != ''
                                        ? cityList.map((item, index) => (

                                            <ListGroup.Item
                                                className={"downshift-option"}
                                                {...getItemProps({
                                                    item,
                                                    key: item.id,
                                                    style: {
                                                        backgroundColor:
                                                            index === highlightedIndex ? '#DEEBFF' : null,
                                                    },
                                                })}
                                            >
                                                {item.postal_code} {item.locality}
                                            </ListGroup.Item>
                                        ))
                                        : null}</div>

                            </div>
                        )}
                    </Downshift>
                    <ErrorMessage name={`buyers[${index}]city`} component="div" className="error"/>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>

                    {countryList.length > 0 ? (
                        <Form.Group>

                            <Form.Label>Pays</Form.Label>
                            <Select
                                key={`buyers[${index}]pays`}
                                name={`buyers[${index}]pays`}
                                id={`buyers_${index}pays`}
                                options={countryList}
                                onChange={newValue =>

                                    setFieldValue(`buyers[${index}]pays`, newValue)}
                                name={`buyers[${index}]pays`}
                                value={buyer.pays}
                                defaultValue={{label: "France", value: 'FR'}}
                            />

                        </Form.Group>
                    ) : (<></>)}

                </Col>
            </Row>

            <Row className="mt-3">
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Téléphone portable</Form.Label>
                    <Form.Control
                        key={`buyers[${index}]cellphone`}
                        name={`buyers[${index}]cellphone`}
                        id={`buyers_${index}cellphone`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={buyer.cellphone}
                    />
                    <ErrorMessage name={`buyers[${index}]cellphone`} component="div" className="error"/>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Téléphone fixe</Form.Label>
                    <Form.Control
                        key={`buyers[${index}]phone`}
                        name={`buyers[${index}]phone`}
                        id={`buyers_${index}phone`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={buyer.phone}
                    />
                    <ErrorMessage name={`buyers[${index}]phone`} component="div" className="error"/>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Profession</Form.Label>
                    <Form.Control
                        key={`buyers[${index}]profession`}
                        name={`buyers[${index}]profession`}
                        id={`buyers_${index}profession`}
                        // placeholder="Veuillez indiquer votre email"
                        onChange={handleChange}
                        type="text"
                        value={buyer.profession}
                    />
                    <ErrorMessage name={`buyers[${index}]profession`} component="div" className="error"/>

                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Date de naissance</Form.Label>

                    <InputMask
                        mask="99-99-9999"
                        maskChar="" // Supprimez le caractère de masque par défaut '_'
                        key={`buyers[${index}]birthDate`}
                        name={`buyers[${index}]birthDate`}
                        className={'form-control'}
                        id={`buyers_${index}birthDate`}
                        placeholder="JJ-MM-AAAA"
                        onChange={handleChange}
                        type="text"
                        value={buyer.birthDate}
                    />
                    <ErrorMessage name={`buyers[${index}]birthDate`} component="div" className="error"/>
                </Col>
                <Row className="mt-3">
                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                    <Form.Label>Situation matrimoniale</Form.Label>
                    <Select
                        key={`buyers[${index}]familySituation`}
                        options={familySituationOptions}
                        defaultValue={{label: "Non renseignée", value: 0}}
                        name={`buyers[${index}]familySituation`}
                        id={`buyers_${index}familySituation`}
                        value={buyer.familySituation}
                        onChange={selection =>
                            handleFamilySituationChange(selection)

                        }
                    />

                </Col></Row>
            </Row>
        </div>
    )
}

export default ContactForm;