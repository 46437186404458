import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import MaterialTable, {MTableEditRow} from "@material-table/core";
import {Icon} from '@iconify/react';
import Button from 'react-bootstrap/Button';
import {dateValueForRow} from "../../component/DateValueForRow";
import {convertFormatDate} from "../../component/ConvertFormatDate";
import MarketTable from "./component/MarketTable";

function MarketList({user}) {

    let navigate = useNavigate();

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1">
                                <div className={'navbar-header'}>
                                    <h1>Marchés</h1>
                                </div>
                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des marchés</li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'content'}>
      <MarketTable user={user}/>
            </section>

        </Layout>
    );
}

export default MarketList;