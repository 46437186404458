import React, {useState, useEffect} from 'react';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";
import {Row} from "react-bootstrap";
import {Form, InputGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {Formik, ErrorMessage} from 'formik';
import * as Yup from "yup";
import * as yup from "yup";
import {Icon} from "@iconify/react/dist/iconify";

const CardPassword = ({user, setUser, isImpersonating, setIsImpersonating}) => {

    const [show, setShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    function createNewPassword(fields) {

        axios.post(`/admin/users/password`, fields)
            .then(function (response) {

                setShow(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Le mot de passe a été modifié avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {

                if(error.response.status='401'){

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data.title,
                        text: error.response.data.text,
                        showConfirmButton: false,
                        timer: 5000
                    })
                }
                else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            });
    }

    const initialValues = {
        password: '',
        newPassword:'',
    };

    const validationSchema = Yup.object().shape({
        password: yup.string()
            .required("Aucun mot de passe saisi"),
        newPassword:  yup
            .string()
            .min(8, 'Le nouveau mot de passe doit contenir au moins 8 caractères')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-])/,
                'Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial.'
            )
            .required('Le mot de passe est requis'),
    });

    function onSubmit(values, actions) {

        const fields = {...values};
        createNewPassword(fields);
    }

    const PasswordForm = ({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            isValid,
                            isSubmitting,
                            touched,
                            setFieldValue,
                            handleBlur
                        }) => {

        return (
                <div className={'px-5'}>
                    <Form.Group className="mb-3">
                        <Form.Label>Confirmer le mot de passe actuel</Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            name="password"
                          value={values.password}
                            type="password"/>
                        <ErrorMessage name={`password`} component="div" className="error"/>
                    </Form.Group>
                    <Form.Label>Nouveau mot de passe</Form.Label>
                    <InputGroup>

                        <Form.Control
                            onChange={handleChange}
                            name="newPassword"
                            value={values.newPassword}
                            type={showPassword ? 'text' : 'password'}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                        />
                        <InputGroup.Text id="basic-addon2" className={'border'}> <Icon width={'15px'} icon={showPassword ? 'mdi:eye-closed' : "mdi:eye-outline"} onClick={togglePasswordVisibility}/></InputGroup.Text>
                    </InputGroup>
                    <ErrorMessage name={`newPassword`} component="div" className="error"/>
                    <div className={'d-flex bd-highlight mt-5'}>
                    <Button variant="primary" type="submit" className={"flex-fill"}>
                        Réinitialiser le mot de passe
                    </Button>
                    <Button variant="outline-warning" className={"flex-fill ms-3"} onClick={handleClose}>
                        Annuler
                    </Button></div></div>
            )
        }
    ;

    return (
        <div>
        <Card>
            <Card.Body className={'px-4'}>

                <h5 className={"pb-4 border-bottom mb-4"}>Mot de passe</h5>
                <div className={"info-container mb-4"}>
                    <Row className="mt-4">
                        <Form.Label className={"link-primary fw-bolder"} style={{cursor: 'pointer'} } onClick={handleShow}>Réinitialiser le mot de passe</Form.Label>
                    </Row>
                </div>
                <Modal size="sm" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className={"h5 px-5"}>Réinitialiser le mot de passe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {


                        return (

                            <Form noValidate onSubmit={handleSubmit}>


                                <PasswordForm
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    values={values}
                                    errors={errors}
                                    isValid={isValid}
                                    isSubmitting={isSubmitting}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}

                                />


                            </Form>)
                    }}
                </Formik></Modal.Body>

                </Modal>
            </Card.Body>
        </Card>





        </div>
    )
}

export default CardPassword;