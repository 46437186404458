// Workspace.js
import {ErrorMessage, FieldArray, Formik} from "formik";
import Form from "react-bootstrap/Form";
import React, {useContext, useState, useEffect} from "react";
import {FormContext} from "../OptionAddEdit";
import * as yup from "yup";
import MaterialTable, {MTableEditRow, MTableBody} from "@material-table/core";
import {
    TableCell,
    TableRow,
    TableBody
} from '@mui/material'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Icon} from '@iconify/react';
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import de la locale française
function Step3({user}) {

    let navigate = useNavigate();
    const tableIcons = {
        Edit: () => <Icon icon="material-symbols:edit-outline" style={{ color: "#30a2e3" }} />,
        Check: () => <Icon style={{color: "#16D39A"}} width={'25px'} height={'25px'} icon="material-symbols:check"/>,
        Clear: () => <Icon style={{color: "#FFA015"}} width={'25px'} height={'25px'} icon="iconoir:cancel"/>,

    };

    const {
        activeStepIndex,
        setActiveStepIndex,
        formData,
        setFormData,
        sideBarTab,
        setSideBarTab,
        nbrJrVldOpt,
        programId
    } = useContext(FormContext);

    const [lotList, setLotList] = useState([])
    const [total, setTotal] = useState(0)
    const [totalGlobal, setTotalGlobal] = useState(0)
    const [dataTab, setDataTab] = useState([

        {label: 'Rappel du prix', amount: "", id: 1},
        {label: 'Frais de notaire de l’acte de vente', amount: formData.notaireFraisVente, id: 2, name: "notaireFraisVente"},
        {label: 'Frais de notaire de l\'acte de prêt', amount: formData.notaireFraisPret, id: 3, name: "notaireFraisPret"},
        {label: 'Quote-part', amount: formData.quotePart, id: 4, name: "quotePart"},

    ])
    const [totalMntDG, setTotalMntDG] = useState(0)
    useEffect(() => {
        console.log(programId)
    }, []);
    const addTotal = (data, byColumn) => {

        let total = data.reduce((acc, el) => {

            return acc += +convertToFloat(el[byColumn]);
        }, 0);


        if (byColumn === "salePrice") {
            setTotal(total)
            setTotalGlobal(numberWithCommas(total.toFixed(2)))
            setDataTab(
                dataTab.map((row) =>
                    // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                    row.id === 1
                        ? {...row, amount: numberWithCommas(total.toFixed(2))}
                        : {...row}
                )
            );
        }

        byColumn === "pxPropose" ? setTotalMntDG(total) : null;
        byColumn === "pxPropose" ? setTotalMntDG(total) : null;
    }

    const addTotalGlobal = (data) => {

        let total = data.reduce((acc, el) => {

            var amount = typeof el['amount'] === 'string' || el['amount'] instanceof String ? convertToFloat(el['amount']) : el['amount']
            return acc += +amount;
        }, 0);

        setTotalGlobal(numberWithCommas(total.toFixed(2)))
    }

    const ValidationSchema = yup.object().shape({
        // delaiLivraison: yup.string().required("Un délai de livraison prévisionnel est requis").nullable(),
        // notaireFraisVente: yup.number().min(1, 'L\'estimation des frais de notaire est requise').required("L'estimation des frais de notaire est requise").nullable(),
    });

    const typeOfProjectOptions = [
        {value: 0, label: 'Résidence principale'},
        {value: 1, label: 'Résidence secondaire'},
        {value: 2, label: 'Investissement locatif'}
    ]

    function convertToFloat(amount) {

        var pxVente = amount.replace(/\s/g, '');
        var px = parseFloat(pxVente);

        return px;
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    }

    useEffect(() => {

        addTotal(formData.lots, 'salePrice');
        addTotal(formData.lots, 'pxPropose');

        formData.lots.forEach(function (v) {
            delete v.tableData
        });

        setLotList(formData.lots);

    }, [])


    return (
        <Formik
            initialValues={{
                dteFinValidite: dayjs(new Date()).add(nbrJrVldOpt, 'day'),
                notes:'',
                programme:programId

            }}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {

                var dteFinValidite = values.dteFinValidite.format('DD-MM-YYYY HH:mm')
                values.dteFinValidite = dteFinValidite;

                const data = {...formData, ...values};
                setFormData(data);
                setActiveStepIndex(activeStepIndex + 1);
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  isValid,
                  isSubmitting,
                  touched,
                  setFieldValue,
                  handleBlur, // handler for onBlur event of form elements
              }) => {
                return (
                    <Form className="flex flex-col justify-center items-center" noValidate onSubmit={handleSubmit}>
                        <div className={'ms-2 my-4'}><h6>Lot</h6>
                            <small>
                                Lots sélectionnés</small>
                        </div>

                        <div id={"table-funding"}>
                            <MaterialTable
                                icons={tableIcons}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                        labelRowsSelect: 'lignes'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} lot(s) sélectionnée(s)',
                                        searchPlaceholder: 'Chercher'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Aucun programme à afficher',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                editable={{

                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                if (JSON.stringify(newData) === JSON.stringify(oldData)) {
                                                    // Aucune modification détectée
                                                    reject("Aucune modification détectée.");
                                                    return;
                                                }
                                                const dataUpdate = [...lotList];
                                                const index = oldData.tableData.index;

                                                if (newData.salePrice != oldData.salePrice) {

                                                    const dataSideBarUpdate = {...sideBarTab};
                                                    //recalcule le montant du dépot de garantie automatiquement

                                                    if (newData.pxPropose === oldData.pxPropose) {

                                                        var multiply = newData.pctDG * 0.01;
                                                        var newMntDG = convertToFloat(newData.salePrice) * multiply;
                                                        newData.pxPropose = numberWithCommas(newMntDG.toFixed(2));
                                                    }

                                                    var salePrice = convertToFloat(newData.salePrice);
                                                    salePrice = numberWithCommas(salePrice.toFixed(2))
                                                    newData.salePrice = salePrice;

                                                    dataSideBarUpdate.lots.map((lot, index) => {

                                                        lot.id == newData.id ? lot.salePrice = salePrice : null
                                                    })

                                                    setSideBarTab(dataSideBarUpdate)
                                                }

                                                //Si le montant du dépot de garantie a été modifié
                                                if (newData.pxPropose != oldData.pxPropose) {

                                                    var pxPropose = convertToFloat(newData.pxPropose);
                                                    newData.pxPropose = numberWithCommas(pxPropose.toFixed(2));

                                                }

                                                //Met à jour la rangé dans le tableau
                                                dataUpdate[index] = newData;
                                                var valuesUpdate = dataUpdate;
                                                valuesUpdate.forEach(function (v) {
                                                    delete v.tableData
                                                });
                                                setFieldValue('lots', valuesUpdate);

                                                setLotList([...dataUpdate]);
                                                addTotal(dataUpdate, 'salePrice')
                                                addTotal(dataUpdate, 'pxPropose')
                                                resolve();
                                            }, 1000)
                                        }),
                                }}
                                options={{

                                    pageSize: formData.lots.length > 5 ? formData.lots.length : 5,
                                    toolbar: false,
                                    paging: false,
                                    actionsColumnIndex: -1
                                }}

                                columns={[
                                    {
                                        title: 'N° Lot', field: 'nrLot', filtering: false, editable: false
                                    },
                                    {
                                        title: 'TVA',
                                        field: 'TVA',
                                        align: "right",
                                        filtering: false,
                                        editable: user.role==='ROLE_PROSPECT'?false:true
                                    },
                                    {
                                        title: 'Prix proposé',
                                        field: 'pxPropose',
                                        filtering: false,
                                        align: "right"
                                    },

                                    {
                                        title: 'Prix de vente',
                                        field: 'salePrice',
                                        filtering: false,
                                        align: "right",
                                        editable: false
                                    },
                                ]}

                                onSelectionChange={(rows) => onSelectionLotChange(rows, setFieldValue)}
                                onSearchChange={() => {

                                    setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                                }}
                                data={lotList}
                                components={{
                                    Body: (props) => (
                                        <>
                                            <MTableBody {...props} />
                                            <TableBody>
                                                <TableRow className={"bg-secondary"}>

                                                    <TableCell size={"medium"}>Total</TableCell>
                                                    <TableCell> </TableCell>
                                                    <TableCell
                                                        align={'right'}>{numberWithCommas(totalMntDG.toFixed(2))}</TableCell>

                                                    <TableCell align={'right'}
                                                               variant={"body"}>{numberWithCommas(total.toFixed(2))}</TableCell>
                                                    <TableCell> </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </>
                                    ),
                                    EditRow: (props) => <MTableEditRow {...props} onKeyDown={(e) => {

                                        if (e.keyCode === 13) {
                                            props.onEditingCanceled(props.mode, props.data)

                                        }
                                    }}/>
                                }}
                            />
                        </div>
                        {user.role!='ROLE_PROSPECT'?

                            <div>
                                <div className={'ms-2 my-4 mt-5'}><h6>Validité de l'option</h6>
                                </div>

                                <div style={{maxWidth: '100%'}}>
                                    <Row className="mt-4">
                                        <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{
                                            shortDate: "d MMMM"
                                        }} adapterLocale="fr">
                                            <Col xs={2}>

                                                <MobileDateTimePicker
                                                    clearable
                                                    ampm={false}
                                                    name="dteFinValidite"
                                                    format="DD-MM-YYYY"
                                                    label="Date de fin de validité"
                                                    clearText="vider"
                                                    cancelText="annuler"
                                                    onChange={(value) => {
                                                        setFieldValue('dteFinValidite', value);
                                                    }}
                                                    value={values.dteFinValidite}
                                                    okText="ok"
                                                />
                                            </Col>
                                        </LocalizationProvider>
                                    </Row></div>
                            </div>
                            : null}
                        <Row className={'mt-4'}>
                            <Col>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                    name={`notes`}
                                    id={`notes`}
                                    placeholder={'Ex: clauses suspensives'}
                                    onChange={handleChange}
                                    value={values.notes}
                                    as="textarea"
                                    rows={3} />
                                <ErrorMessage name={`notes`} component="div" className="error"/>
                            </Col></Row>
                        <ErrorMessage name={`notaireFraisVente`} component="div" className="error"/>
                        <FieldArray name="lots">
                        </FieldArray>
                        <div className="actions clearfix">
                            <Button variant="outline-secondary"  className={"my-2 p-2 me-2"} onClick={() => {
                                navigate(`/admin/lots/list`)
                            }}>Annuler</Button>
                            <Button variant={'warning'} className={"bg-orange-500 my-2 p-2 me-2 text-dallas"}  onClick={() => {
                                setActiveStepIndex(activeStepIndex - 1)
                            }}>Précédent</Button>
                            <Button variant={'warning'} className={"bg-warning my-2 p-2 me-2 text-white"} type={"submit"}>Suivant</Button>

                        </div>
                    </Form>)
            }}
        </Formik>
    );
}

export default Step3;