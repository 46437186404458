import {useDropzone} from "react-dropzone";
import React from 'react';
import {formatDate} from "@fullcalendar/core";
import axios from "axios";
import Swal from "sweetalert2";

export async function fetchUserData() {

    try {
        const response = await axios.get(`/user-info/`);
        return response.data;
    } catch (error) {
        console.error('Utilisateur non identifié :', error);
        throw error;
    }

}