import React, {useEffect} from 'react';
import ContactForm from "../Steps/ContactForm";


const TabContent =({buyer,index,options,handleChange,errors,setKey,setFieldValue,touched,handleBlur,isValid,values}) =>{

    useEffect(() => {

        setKey(index);
    }, [])

    return(
        <ContactForm values={values} isValid={isValid} buyer={buyer} index={index} options={options} handleChange={handleChange} setFieldValue={setFieldValue} errors={errors} touched={touched} handleBlur={handleBlur}/>

    )
}

export default TabContent;