import axios from 'axios';

let isRefreshing = false;
let refreshSubscribers = [];

// Fonction pour récupérer le token
const getToken = async () => {
    // Vérifier si une requête de rafraîchissement est déjà en cours
    if (isRefreshing) {
        // Attendre que la requête de rafraîchissement se termine
        await new Promise((resolve) => {
            refreshSubscribers.push(resolve);
        });
        // Une fois que la requête de rafraîchissement est terminée, récupérer le token
        return localStorage.getItem('token');
    }

    isRefreshing = true;
    try {

        // Effectuer une requête pour récupérer le nouveau token
        const response = await axios.post('/api/login_check',{'username':"LaeIngenierie",'password':">:cxD4S*Gw9S4D_v"});
        // const token = response.data.token;
       localStorage.setItem('token', response.data);
        return response.data.token;
    } catch (error) {
        console.error('Erreur lors de la récupération du token :', error);
        throw error;
    } finally {
        isRefreshing = false;
        // Résoudre toutes les promesses en attente pour les requêtes en cours
        refreshSubscribers.forEach((callback) => callback());
        refreshSubscribers = [];
    }
};


// Add a request interceptor
axios.interceptors.request.use(
    async (request) => {

        const token = localStorage.getItem('token');

        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        }
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);
