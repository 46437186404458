import React from 'react';
import { IconButton, Box } from '@mui/material';
// import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {Icon} from '@iconify/react';
const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="Première page"
            >
                {/*<FirstPage />*/}
                <Icon icon="mi:filter"/>
            </IconButton>

            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Précédent">
                {/*<KeyboardArrowLeft />*/}
                <span className={"keyboard-arrow"}>Précédent</span>
            </IconButton>

            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Suivant"
            >
                <span className={"keyboard-arrow"}>Suivant</span>
                {/*<KeyboardArrowRight />*/}
            </IconButton>

            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Dernière page"
            >
                {/*<LastPage />*/}
            </IconButton>
        </Box>
    );
};

export default TablePaginationActions;
