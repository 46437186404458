import React, {useState, useEffect} from 'react';
import Layout from "../../../component/Layout";
import Swal from 'sweetalert2'
import axios from 'axios';
import MaterialTable from "@material-table/core";

function TabAdf({idLot}) {


    const [role, setRole] = useState();
    const [adfData, setAdfData] = useState([]);

    useEffect(() => {

        getAllAdf();
    }, [])

    const getAllAdf = () => {
        axios.get(`/admin/adf?idLot=`+idLot)
            .then(function (response) {

                let data = response.data;
                setRole(data.role);
                setAdfData(data.adf)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const rowBackgroundColors = {

        "0": "#E9F0FC",
        "1": "#F3F7FE", // just for example, remove it if you don't need
        "2": "#FAFBFE",
    };

    const rowColors = {

        "false": "#333",
        "true": "#c5c4c4", // just for example, remove it if you don't need
    };

    const getPdfValueForRow = pdf => {

        if (pdf) {
            var elem = <a href={pdf} className={'btn btn-sm btn-default view_link'}  target="_blank"><i className="fa fa-download" aria-hidden="true"></i></a>;
        }

        return elem;
    };

    const getDateValueForRow = date => {
        var elem='';

        if (date) {
            var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
                <time>{date}</time>
            </div>;
        }
        return elem;
    };

    const handleLabelForRow = rowData => {

        var padding='';

        switch (rowData.level) {
            case 1:
                padding='ps-2';
                break
            case 2:
                padding='ps-3';
                break
            case 3:
                padding='ps-4';
                break
        }

            var elem = <div className={padding}>{rowData.name}</div>;

        return elem;
    };

    return (
        <Layout>
            <section className="content">
                <div className="container-fluid">

                    <div className="row">

                                {(role == 'ROLE_ACQUEREUR') ? (
                                    <div id={"adf-tab"} className="col-md-12">
                                        <div style={{maxWidth: '100%'}}>
                                            <MaterialTable
                                                title="Appel de fonds"
                                                data={adfData}
                                                options={{
                                                    toolbar:false,
                                                    rowStyle: (rowData) => {

                                                        return {
                                                            color:rowColors[rowData.echeancePrevue] ?? "#333",
                                                            backgroundColor: rowBackgroundColors[rowData.bg] ?? "#fff",
                                                        };
                                                    },
                                                    selectionProps: rowData => ({
                                                        color: 'primary',
                                                        checked: rowData.id === '1'
                                                    })
                                                }}

                                                columns={[
                                                    { title: 'Programme/Lot/Stade', field: 'name', width: "15%",render: rowData => handleLabelForRow(rowData) },
                                                    { title: '', field: 'pct', width: "5%" },
                                                    { title: 'Mnt vente', field: 'mntVente' },
                                                    { title: 'Date', field: 'date',  render: rowData => getDateValueForRow(rowData.date) },
                                                    { title: 'Echéance', field: 'echeance',  render: rowData => getDateValueForRow(rowData.echeance), removable: false },
                                                    { title: 'Mnt appelé', field: 'mntAppele', type: 'numeric' },
                                                    { title: 'Mnt réglé', field: 'mntRegle', type: 'numeric' },
                                                    { title: 'Solde', field: 'solde', type: 'numeric' },
                                                    { title: 'Solde du lot', field: 'soldeDuLot', type: 'numeric' },
                                                    {
                                                        title: "Pdf",
                                                        field: 'pdf',
                                                        render: rowData => getPdfValueForRow(rowData.pdf)
                                                        , width: "5%"
                                                    }
                                                ]}
                                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}

                                            />
                                        </div> </div> ) : (<></>)}
                    </div>

                </div>
            </section>
            {/*</form>*/}
        </Layout>
    );
}


export default TabAdf;