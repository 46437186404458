import React from 'react';
import axios from "axios";
import Swal from "sweetalert2";

export async function fetchMediaData(mediaArray,setDataFiles,setPreviousFiles) {

    const mediaData = [];
    const fileData = [];

    for (const media of mediaArray) {
        try {
            const response = await fetch(media.url);
            const blob = await response.blob();
            const file = new File([blob], media.name, {type: blob.type});
            const preview = URL.createObjectURL(blob);

            const mediaObject = {
                id: media.id,
                file: file,
                uploaded: true,
                preview: preview,
            };

            fileData.push(file);
            mediaData.push(mediaObject);
        } catch (error) {
            console.error(`Error fetching media ${media.id}`, error);
        }
    }

    setDataFiles(mediaData);
    setPreviousFiles(mediaData);
}