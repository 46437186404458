import React, {useState} from 'react';
import Slider from "react-slick";
import Swal from 'sweetalert2';

export default function SimpleSlider({medias}) {

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const openLightbox = (index) => {


        Swal.fire({
            imageUrl: medias['slider1'][index]['fullImage'],
            imageAlt: 'Image en grand format',
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {

                popup: 'my-custom-modal',

            },
        });

        setSelectedImageIndex(index);
        setLightboxIsOpen(true);
    };

    var settings1 = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    var settings2 = {
        //combien de diapositives afficher dans un cadre
        slidesToShow: medias.slider1.length > 3 ? 3 : medias.slider1.length,
        dots: true,
        slidesToScroll: 4,
        centerPadding: "10px",
        swipeToSlide: true,
        //Activer le glisser indépendamment de `slidesToScroll`
        focusOnSelect: true,
    };
    return (
        <div>
            <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...settings1}>

                {medias.slider1.map((url, index) => {

                    return (
                        <div key={index} onClick={() => openLightbox(index)}>
                            <img src={url.large} alt="" width={'100%'} style={{borderRadius:'10px',padding: '0 4px'}}/>
                        </div>
                    )
                })}
            </Slider>
            {medias.slider2.length > 1 ?

                <Slider
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}

                    {...settings2}
                >
                    {medias.slider2.map((url, index) => {
                        return (
                            <div key={index}>
                                <img src={url} alt="" width={'100%'} style={{borderRadius:'10px',padding: '0 4px'}}/>
                            </div>
                        )
                    })}
                </Slider>

                : null}


        </div>
    );
}