import React from 'react';

import { convertToFloat, numberWithCommas } from '../../../utils/formatters';

export default function handleLotRowUpdate({
                                               newData,
                                               oldData,
                                               lotList,
                                               setLotList,
                                               setFieldValue=null,
                                           }) {
    return new Promise((resolve) => {
        setTimeout(() => {

            const dataUpdate = [...lotList];
            const index = oldData.tableData.index;

            if (newData.salePrice != oldData.salePrice) {

                if (newData.mntDG === oldData.mntDG) {
                    const multiply = newData.pctDG * 0.01;
                    const newMntDG = convertToFloat(newData.salePrice) * multiply;
                    newData.mntDG = numberWithCommas(newMntDG.toFixed(2));
                }

                let salePrice = convertToFloat(newData.salePrice);
                salePrice = numberWithCommas(salePrice.toFixed(2));
                newData.salePrice = salePrice;
            }

            if (newData.mntDG != oldData.mntDG) {
                const mntDG = convertToFloat(newData.mntDG);
                newData.mntDG = numberWithCommas(mntDG.toFixed(2));
            }

            //Met à jour la rangé dans le tableau
            dataUpdate[index] = newData;
            var valuesUpdate = dataUpdate;
            //Suprime le champ tableData dans la liste des lots
            valuesUpdate.forEach(function (v) {
                delete v.tableData
            });

            setFieldValue ?setFieldValue('lots', valuesUpdate):null;
            setLotList([...dataUpdate]);

            resolve();
        }, 1000);
    });
}
