import React, {useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import usePlacesAutocomplete, {
    getGeocode,
    getZipCode,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import GoogleMapComponent from "./GoogleMapComponent";

const PlacesAutocomplete = ({fieldValueChanger, initialAddress, fieldsName, ...rest}) => {

    const isLoaded = GoogleMapComponent();
    const {
        init,
        // ready : ready est une variable booléenne qui indique si la fonction usePlacesAutocomplete est prête à être utilisée. Elle est généralement utilisée pour s'assurer que les dépendances nécessaires sont chargées avant de tenter de suggérer des emplacements.
        ready,
        value,
        suggestions: {status, data},
        setValue,
        clearSuggestions,

    } = usePlacesAutocomplete({
        initOnMount: false,
    });



    // Fonction pour initialiser le hook usePlacesAutocomplete lorsque le script est chargé


    useEffect(() => {
        return () => {
            clearSuggestions();
            setValue('');
        };
    }, []);
    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    const handleInput = (e) => {
        // Update the keyword of the input element
        fieldValueChanger(fieldsName.address, e.target.value);
        setValue(e.target.value);
    };

    const handleSelect =
        ({description}) =>
            () => {
                // When user selects a place, we can replace the keyword without request data from API
                // by setting the second parameter to "false"
                const address = description.substring(0, description.indexOf(","));
                fieldValueChanger(fieldsName.address, address);
                setValue(address, false);
                clearSuggestions();
                // Get latitude and longitude via utility functions
                getGeocode({address: description}).then((results) => {

                    const zipCode = getZipCode(results[0], false);
                    fieldValueChanger(fieldsName.zip, zipCode);
                    fieldValueChanger(fieldsName.city, description.substring(description.indexOf(",") + 2));
                });
            };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: {main_text, secondary_text},
            } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)}>
                    <small>{main_text}</small> <strong>{secondary_text}</strong>
                </li>
            );
        });

    useEffect(() => {


        if (isLoaded) {
            init();
        }

    }, [isLoaded]);


    return (
        <div>
            {isLoaded ? ( // Vérifiez si ready est vrai
                <div ref={ref}>
                    <Form.Control
                        value={initialAddress}
                        onChange={handleInput}
                        // disabled={!ready}
                        name={fieldsName.address}
                    />
                    {/* We can use the "status" to decide whether we should display the dropdown or not */}
                    {status === "OK" && <ul className={"suggestions"}>{renderSuggestions()}</ul>}
                </div>
            ) : (
                // Vous pouvez afficher un message d'attente ou un indicateur de chargement ici
                <div>Chargement en cours...</div>
            )}
        </div>

    );
};

export default PlacesAutocomplete;