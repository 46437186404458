import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import MaterialTable from "@material-table/core";
import {GetMail} from "./GetMail";
import {fetchUserData} from "../../../component/fetchUserData";
import {loadDynamicContent} from "./loadDynamicContent";


const MailTable = ({msgList,mbox,setModalShow,setInitialValues}) => {

    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [dynamicContent, setDynamicContent] = useState('');
    const [mail, setMail] = useState('');


    useEffect(() => {
        id?getEmailData(id):null;

    }, [id]);

    useEffect(() => {

        setInitialValues({message:dynamicContent,subject:'fdf'})
    }, [dynamicContent]);

    async function getEmailData(id) {
        try {

            const emailData = await GetMail(id,setEmail,setName,setMail);
            loadDynamicContent(emailData,setDynamicContent);

            // Faites quelque chose avec les données utilisateur
        } catch (error) {

            console.error('Une erreur s\'est produite lors de la récupération des données utilisateur :', error);
            // Gérez l'erreur de manière appropriée
        }
    }


    const getMailLinkValueForRow = (rowData,field) => {

        var elem = <span style={{cursor: 'pointer'}}>{field==='sender'?rowData.sender:rowData.subject}</span>;

        return elem;
    };
    async function getEmailContent(id) {

      return a;
    };


    const handleRowClick = (event, rowData) => {
        // rowData contient les données de la ligne sur laquelle vous avez cliqué

   if(mbox==="draft"){
   setId(rowData.id)

       setModalShow(true);

   }else {
       navigate(`/admin/courriel/${rowData.id}/voir`)
   }
        // Vous pouvez faire d'autres actions ici
    };

    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div style={{maxWidth: '100%'}}>

                        <MaterialTable
                            title={'Boite de réception'}
                            onRowClick={handleRowClick}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} of {count}',
                                    labelRowsSelect: 'lignes'
                                },
                                toolbar: {
                                    nRowsSelected: '{0} action(s) sélectionnée(s)',
                                    searchPlaceholder: 'Chercher'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Aucun marché à afficher',
                                    filterRow: {
                                        filterTooltip: 'Filter'
                                    }
                                }
                            }}
                            options={{
                                pageSize: 25,
                                selection: true,
                                rowStyle: (rowData) => {
                                    return {
                                        fontWeight: rowData.unread ? "500" : "300",
                                        backgroundColor: rowData.unread ? "#f8f9fa" : "#fff",
                                        color: rowData.unread ? "#495057" : "#74788d",
                                    };
                                },
                                // filtering: filter,
                                addRowPosition: 'last', // Ajouter les nouvelles lignes en bas du tableau
                            }}


                            columns={[
                                {
                                    title: 'Expéditeur',
                                    field: 'sender',
                                    align: "left",
                                    width: "30%",
                                    render: rowData => getMailLinkValueForRow(rowData,'sender'),
                                },
                                {
                                    title: 'Sujet',
                                    field: 'subject',
                                    align: "left",
                                    render: rowData => getMailLinkValueForRow(rowData,'subject'),
                                },
                                {
                                    title: 'Date',
                                    field: 'date',
                                    width: "10%",
                                    align: "right",
                                },


                            ]}

                            // tableRef={tableRef}
                            onSearchChange={() => {

                                setRowCount('Liste marché (' + tableRef.current.state.data.length + ')');
                            }}
                            data={msgList}

                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MailTable;