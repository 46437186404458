
import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import MaterialTable from "@material-table/core";
import {dateValueForRow} from "../../../component/DateValueForRow";
import axios from "axios";
import Swal from "sweetalert2";
import {GetLinkValueForRow} from "../../../component/GetLinkValueForRow";

const MarketTable  =({user,programmeId}) =>{

    const [marketList, setMarketList] = useState([])
    const [adminRole, setAdminRole] = useState('')
    const [hideColumn, setHideColumn] = useState(user.role==='ROLE_PROMOTEUR'?false:true)
    const [hideProgrammeColumn, setHideProgrammeColumn] = useState(programmeId?true:false)
    let navigate = useNavigate();
    const [filter, setFilter] = useState(false)
    const [rowCount, setRowCount] = useState('Consultation des marchés')

    useEffect(() => {

        getAllMarkets();
        getUser();
    }, [])

    const tableRef = React.useRef();

    const getUser = (setFieldValue) => {

        axios.get(`/user-info/`)
            .then(function (response) {

                var user = response.data;
                setAdminRole(user.role);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getPdfValueForRow = pdf => {

        if (pdf) {
            var elem = <a href={pdf} className={'btn btn-sm btn-default view_link'}  target="_blank"><i className="fa fa-download" aria-hidden="true"></i></a>;
        }

        return elem;
    };

    const getProgramLinkValueForRow = rowData => {

        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/programme/${rowData.programId}/voir`)}}>{rowData.programme}</span>;

        return elem;
    };


    const getAllMarkets = () => {

        var url=programmeId ? `/admin/markets?idprogramme=${programmeId}`  : `/admin/markets`;
        axios.get(url)
            .then(function (response) {

                if (response.data) {
                    setMarketList(response.data);

                }
            })
            .catch(function (error) {

                if(error.response.status='401'){

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }

    return(

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div style={{maxWidth: '100%'}}>

                            <MaterialTable
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                        labelRowsSelect: 'lignes'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} action(s) sélectionnée(s)',
                                        searchPlaceholder: 'Chercher'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Aucun marché à afficher',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                options={{
                                    pageSize: 25,
                                    selection:false,
                                    filtering: filter,
                                    addRowPosition: 'last', // Ajouter les nouvelles lignes en bas du tableau
                                    selectionProps: rowData => ({
                                        color: 'primary',
                                        checked: rowData.id === '1'
                                    })
                                }}
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}

                                columns={[
                                    {
                                        title: 'Date',
                                        field: 'dte',
                                        align: "center",
                                        width: "10%",
                                        render: rowData => dateValueForRow(rowData.dte)
                                    },
                                    {
                                        title: 'Libellé',
                                        field: 'libelle',
                                        align: "left",
                                    },
                                    {
                                        title: 'Budget',
                                        field: 'budget',
                                    },
                                    {
                                        title: 'Programme',
                                        field: 'programme',
                                        render: rowData => getProgramLinkValueForRow(rowData),
                                        hidden: hideProgrammeColumn
                                    },
                                    {
                                        title: 'N° avenant',
                                        field: 'nrAvenant',
                                        align: "left",
                                    },
                                    {
                                        title: 'N° tranche',
                                        field: 'nrTranche',
                                        align: "left",
                                        width: "10%",
                                    },

                                    {
                                        title: 'Montant TTC',
                                        field: 'montantTTC',
                                        align: "right",
                                    },
                                    {
                                        title: 'Caution',
                                        field: 'mntCaution',
                                        align: "right",
                                    },
                                    {
                                        title: 'Fournisseur',
                                        field: 'contact',
                                        render: rowData => GetLinkValueForRow(rowData,'contacts'),
                                        filtering: false,
                                        hidden: hideColumn
                                    },
                                    {
                                        title: 'Pdf',
                                        field: 'pdf',
                                        render: rowData => getPdfValueForRow(rowData.pdf)
                                        , width: "5%"
                                    },



                                ]}

                                tableRef={tableRef}
                                onSearchChange={() => {

                                    setRowCount('Liste marché (' + tableRef.current.state.data.length + ')');
                                }}
                                data={marketList}
                                title={rowCount}

                            />
                        </div>
                    </div>
                </div>
            </div>

    )
}

export default MarketTable;