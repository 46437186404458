import React, {useState, useEffect, useRef} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {ErrorMessage} from "formik";
import Select from "react-select";

const LoanForm = ({pret, index, handleChange, errors, setFieldValue, values, touched, handleBlur, isValid, setKey}) => {

    const [isToggled, setIsToogled] = useState(false);

    useEffect(() => {

        setKey(index);
    }, [])

    useEffect(() => {
        console.log(errors)
    }, [errors, pret])


    const optionsLoanType = [
        {value: 1, label: 'Prêt amortissable'},
        {value: 2, label: 'Prêt in fine'},
        {value: 3, label: 'Prêt relais'},
        {value: 4, label: 'Prêt accession sociale'},
        {value: 5, label: 'Prêt conventionné'},
        {value: 6, label: 'Prêt à taux zéro plus'},
        {value: 7, label: 'Prêt épargne logement'},
        {value: 8, label: 'Prêt action logement'},
    ]

    return (
        <div key={index}>

                <div>
                    <Row className={"mb-2"}>

                        <Col>
                            <Form.Label>Montant du prêt</Form.Label>
                            <Form.Control
                                key={`prets[${index}]montant`}
                                name={`prets[${index}]montant`}
                                id={`prets_${index}montant`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                value={pret.montant}

                            />
                            <ErrorMessage name={`prets[${index}]montant`} component="div"
                                          className="error"/>
                        </Col>
                        <Col>
                            <Form.Label>Montant maximal du prêt </Form.Label>
                            <Form.Control
                                name={`prets[${index}]montantMax`}
                                id={`prets[${index}]montantMax`}
                                onChange={handleChange}
                                type="text"
                                value={pret.montantMax}




                            />
                            <ErrorMessage name={`prets[${index}]montantMax`} component="div"
                                          className="error"/>
                        </Col>
                        <Col lg={'2'}>
                            <Form.Label>Taux</Form.Label>
                            <Form.Control
                                key={`prets[${index}]txInteretAnnuelMaxHorsAssurance`}
                                name={`prets[${index}]txInteretAnnuelMaxHorsAssurance`}
                                id={`prets_${index}txInteretAnnuelMaxHorsAssurance`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                value={pret.txInteretAnnuelMaxHorsAssurance}


                            />
                            <ErrorMessage name={`prets[${index}]txInteretAnnuelMaxHorsAssurance`}
                                          component="div" className="error"/>
                        </Col>
                        <Col lg={'2'}>
                            <Form.Label>Durée (ANNEE)</Form.Label>
                            <Form.Control
                                key={`prets[${index}]dureeMax`}
                                name={`prets[${index}]dureeMax`}
                                id={`prets_${index}dureeMax`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                value={pret.dureeMax}

                            />
                            <ErrorMessage name={`prets[${index}]dureeMax`} component="div"
                                          className="error"/>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Type</Form.Label>
                            <Select
                                key={`prets[${index}]type`}
                                name={`prets[${index}]type`}
                                id={`prets_${index}type`}
                                onBlur={handleBlur}
                                type="text"
                                value={pret.type}
                                options={optionsLoanType}
                                defaultValue={{label: "Prêt amortissable", value: 1}}
                                styles={{menuPortal: base => ({...base, zIndex: 9999, flex: 1})}}
                                onChange={newValue =>

                                    setFieldValue(`prets[${index}]type`, newValue)}
                            />
                        </Col>
                        <Col lg={'4'}>
                            <Form.Label>Délai dépôt dossier emprunt (mois)</Form.Label>
                            <Form.Control

                                key={`prets[${index}]delaiDepotDossier`}
                                name={`prets[${index}]delaiDepotDossier`}
                                id={`prets_${index}delaiDepotDossier`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                value={pret.delaiDepotDossier}


                            />
                            <ErrorMessage name={`prets[${index}]delaiDepotDossier`} component="div"
                                          className="error"/>
                        </Col>

                        <Col lg={'4'}>
                            <Form.Label>Délai d'obtention du prêt (mois)</Form.Label>
                            <Form.Control


                                key={`prets[${index}]delaiObtention`}
                                name={`prets[${index}]delaiObtention`}
                                id={`prets_${index}delaiObtention`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                value={pret.delaiObtention}


                            />
                            <ErrorMessage name={`prets[${index}]delaiObtention`} component="div"
                                          className="error"/>
                        </Col>


                    </Row></div>



        </div>


    )
}

export default LoanForm;