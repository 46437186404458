import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import MaterialTable from "@material-table/core";
// import AddBox from '@mui/icons-material/AddBox';
// import Delete from '@mui/icons-material/Delete';
import {Icon} from '@iconify/react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ScrollWrapper from "../utils/ScrollWrapper";
import Modal from 'react-bootstrap/Modal';

function CollaboratorList(isFullSize) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleEmail = (e) => {setEmail(e.target.value)}
    const [city, setCity] = useState('')
    const [email, setEmail] = useState('')
    const [contactList, setContactList] = useState([])
    const [filteredData, setFilteredData] = useState()
    const [rowCount, setRowCount] = useState('Liste des contacts')
    const [filter, setFilter] = useState(false)
    const [role, setRole] = useState('all')
    const [adminRole, setAdminRole] = useState('')
    let navigate = useNavigate();

    const handleChange = () => {

        setFilter(!filter)
    }

    const handleInvitation  = () => {

        setShow(false);

        axios.post(`/send-invitation`, { email: email })
            .then(function (response) {

                setShow(false);
                getAllContacts();

                Swal.fire({
                    icon: 'success',
                    title: 'Invitation envoyée!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }

    const handleDeleteRows = (event, rowData) => {

        let _data = [...contactList];

        rowData.forEach(rd => {

            _data = _data.filter(row => row.id !== rd.id);
        });

        rowData.forEach(rd => {

            _data = _data.filter(row => row.id !== rd.id);
        });

        setContactList(_data);
    };


    useEffect(() => {

        getAllContacts();
        getUser();
    }, [])

    useEffect(() => {

        setFilteredData(role === 'all' ? contactList : contactList.filter(dt => dt.role === role))

    }, [role])

    const getUser = (setFieldValue) => {

        axios.get(`/user-info/`)
            .then(function (response) {

                var user = response.data;
                setAdminRole(user.role);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const tableRef = React.useRef();

    const getAllContacts = () => {
        axios.get(`/admin/collaborators`)
            .then(function (response) {

                var contacts=response.data;

                if(contacts){
                setContactList(response.data);
                setFilteredData(response.data);

                setRowCount('Liste des collaborateurs (' + response.data.length + ')')}

            })
            .catch(function (error) {

                if(error.response.status='401'){

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }


    const getCreatedAtValueForRow = createdAt => {

        var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
            <time>{createdAt}</time>
        </div>;

        return elem;
    };

    const getLastLoginValueForRow = lastLogin => {

        if (lastLogin != 'inactif') {

            var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
                <time>{lastLogin}</time>
            </div>
        } else {
            var elem = <div className={'text-center'}><span className={"label label-default"}>inactif</span></div>;
        }

        return elem;
    };

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">
                            <div className="navbar navbar-default p-1">
                                <div>
                                    <h1>Collaborateur</h1>
                                </div>
                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/tableau-de-bord`)}}></span></li>
                                        <li className="breadcrumb-item active ">Liste de vos collaborateurs</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">
                            <ScrollWrapper  isFullSize={isFullSize}>

                                <MaterialTable
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} of {count}',
                                            labelRowsSelect: 'lignes'
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} collaborateur(s) sélectionnée(s)',
                                            searchPlaceholder: 'Chercher'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Aucun collaborateur à afficher',
                                            filterRow: {
                                                filterTooltip: 'Filter'
                                            }
                                        }
                                    }}

                                    options={{
                                        selection: true,
                                        pageSize: 10,
                                        filtering: filter
                                    }}
                                    columns={[
                                        {
                                            title: 'Nom', field: 'name', filtering: false,
                                        },
                                        {title: 'Ville', field: 'city', filtering: false},
                                        {title: "Courriel", field: 'mail', type: 'date', filtering: false},
                                        {
                                            title: 'Téléphone',
                                            field: 'phone',
                                            filtering: false
                                        },
                                        {
                                            title: 'Date de création',
                                            field: 'createdAt',
                                            filtering: false,
                                            render: rowData => getCreatedAtValueForRow(rowData.createdAt),

                                        },
                                        {
                                            title: 'Dernière connexion',
                                            field: 'lastLogin',
                                            render: rowData => getLastLoginValueForRow(rowData.lastLogin),
                                            filtering: true,
                                            width: "5%"
                                        }
                                    ]}
                                    tableRef={tableRef}
                                    onSearchChange={() => {

                                        setRowCount('Liste contact (' + tableRef.current.state.data.length + ')');
                                    }}
                                    data={contactList}
                                    title={rowCount}
                                    actions={[
                                        {
                                            icon: () => <Icon icon="mi:filter"/>,
                                            tooltip: 'Afficher le filtre',
                                            isFreeAction: true,
                                            onClick: (event) => {
                                                handleChange()
                                            }
                                        },

                                        {
                                            // icon: () => <AddBox onClick={handleShow} className={'fill-green navbar-toggler'}/>,
                                            icon: () => <Icon icon="material-symbols-light:add-box-outline" onClick={handleShow} className={'fill-green navbar-toggler'}/>,
                                            tooltip: 'Ajouter',
                                            isFreeAction: true
                                        }
                                        ,

                                        {
                                            // icon: () => <Delete/>,
                                            icon: () => <Icon icon="mi:filter" />,
                                            tooltip: "Supprimer toutes les collaborateurs sélectionnées",

                                            onClick: (event, rows) =>

                                                Swal.fire({
                                                    icon: 'warning',
                                                    title: 'Confirmer la suppression du ou des collaborateurs sélectionnés ?',
                                                    showConfirmButton: true,
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Annuler',
                                                    dangerMode: true,
                                                })
                                                    .then(result => {

                                                        if (result.isConfirmed) {

                                                            handleDeleteRows(event, rows);
                                                            var ids = rows.map(s => s.id);
                                                            let idsToString = ids.toString();

                                                            if (rows.length == 1) {
                                                                var message = "La collaborateur a bien été supprimée"
                                                            } else {
                                                                var message = "Les collaborateurs ont bien été supprimées"
                                                            }


                                                            axios.delete('/admin/collaborators?ids=' + idsToString)
                                                                .then(res => {

                                                                    Swal.fire({
                                                                        title: "Fait!",
                                                                        text: message,
                                                                        icon: "success",
                                                                        timer: 2000,
                                                                        button: false
                                                                    })


                                                                });
                                                        } else {
                                                            Swal.fire('Votre collaborateur est préservée', '', 'info')
                                                        }
                                                    })
                                        }
                                    ]
                                    }
                                />
                            </ScrollWrapper>

                        </div>
                    </div>

                </div>
            </section>
            {/*</form>*/}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Envoyer une invitation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group >

                        <Form.Control type="email"  onChange={handleEmail} value={email} placeholder="Saisissez l'email de votre collaborateur"/>
                    </Form.Group>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                    <Button variant="primary" onClick={handleInvitation}>
                        Envoyer
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
}

export default CollaboratorList;