import React from 'react';
import {useNavigate} from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import Swal from "sweetalert2";
// src/utils/familyUtils.js

// Fonction pour vérifier si tous les éléments d'un tableau sont identiques


export function toFrDateString(dateStr) {

    const [day, month, year] = dateStr.split("-");

// Réorganise en format ISO : YYYY-MM-DD
    const isoDateStr = `${year}-${month}-${day}`;
    const date  = new Date(isoDateStr);
// Options pour le format désiré
    const options = {day: '2-digit', month: 'short', year: 'numeric'};

// Formater la date en français
    const formattedDate = date.toLocaleDateString('fr-FR', options);


    return formattedDate;
};






