import React from 'react';
import {useNavigate} from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import Swal from "sweetalert2";
// src/utils/familyUtils.js

// Fonction pour vérifier si tous les éléments d'un tableau sont identiques


export const getStatusValueForRow = rowData => {

    var elem;

    switch (rowData.status) {
        case 0:
            elem = <Badge bg="danger">refusée</Badge>;
            break
        case 1:

            if (rowData.cancel == '1') {

                elem = <Badge bg="warning">annulée</Badge>;
            } else {
                elem = <Badge bg="success">acceptée</Badge>;
            }
            break
        case 2:

            elem = <Badge bg="warning">en attente</Badge>;

            break
        case 3:
            elem = <span className="label bg-label-secondary">brouillon</span>;

            break
        case 4:
            elem = <Badge bg="primary">en signature</Badge>;

            break
        case 5:
            elem = <Badge bg="info">signé</Badge>;

            break
    }


    return elem;
};


export const getReservationLinkValueForRow = rowData => {
    let navigate = useNavigate();
    //Si la réservation est annumée ou signé, elle n'est pas cliquable
    if (rowData.isOpen === false) {

        var elem = <span>{rowData.id}</span>;
    } else {

        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
            navigate(`/admin/reservation/${rowData.id}/modifier`)
        }}>{rowData.id}</span>;
    }

    return elem;
};





