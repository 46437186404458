import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import {useNavigate, useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SimpleSlider from "../../component/SimpleSlider";
import ListGroup from "react-bootstrap/ListGroup";
import Layout from "../../component/Layout"
import Container from 'react-bootstrap/Container';
import axios from "axios";
import Swal from "sweetalert2";
import {GetLinkValueForRow} from "../../component/GetLinkValueForRow";

const ReserveView =({lot,adminRole}) =>{

    const [id, setId] = useState(useParams().id)
    const [reserve, setReserve] = useState({ medias: {slider1: {}}})
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();

    const fetchReserveData = async () => {
        try {
            const response = await axios.get(`/admin/reserves/${id}`);
            var data = response.data;
            setReserve(data);
            setIsLoading(true); // Définir isLoading sur false lorsque les données sont chargées
        } catch (error) {
            setIsLoading(true);
            Swal.fire({
                icon: 'error',
                title: 'Une erreur s\'est produite!',
                showConfirmButton: false,
                timer: 1500
            })

        }
    };

    useEffect(() => {

        fetchReserveData()
    }, []);

    return(
        <Layout>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4 ">
                            <div className="navbar navbar-default" role="navigation">
                                <div className="navbar-header">
                                    <a href="#"><h1>
                                        Réserve {reserve.nrReserve}
                                    </h1></a></div>

                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span></li>
                                        <li className={"link-primary"} onClick={() => {
                                            navigate('/admin/reserve/liste')
                                        }}
                                        >
                                            <span className={"px-1 cursor-pointer"}>Liste des réserves</span>
                                        </li>
                                        <li>
                                            <span className={"ps-1"}>{reserve.libelle ? reserve.libelle : reserve.id}</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <Container fluid>
            <Row>

                <Col lg="4">
                    <Card>
                        <Card.Body>
                            { reserve && reserve.medias && reserve.medias.slider1 && reserve.medias.slider1.length>0 ? <SimpleSlider medias={reserve.medias}/>  : null}
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="8">
                    <Card>
                        <Card.Body>
                            <h2 className={'pt-3'}>Caractéristiques</h2>
                            <Row>
                                <Col lg="6">
                                    <ListGroup className={"borderless"} as="ul">

                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Libelle</div>
                                            <span>{reserve.libelle}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}> <div className={"text-muted"}>Date</div>
                                            <span>{ reserve.date  }</span>
                                        </ListGroup.Item>



                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Date prévue des travaux</div>
                                            <span>{reserve.dateEcheance}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Date de réalisation</div>
                                            <span>{reserve.dateRealisation}</span>
                                        </ListGroup.Item>

                                    </ListGroup>
                                </Col>
                                <Col lg="6">
                                    <ListGroup className={"borderless"} as="ul" id={"program-features"}>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Lot</div>
                                            <span>{GetLinkValueForRow(reserve,'lot')}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Localisation</div>
                                            <span>{reserve.localisation}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Promoteur</div>
                                            <span>{reserve.promoteur}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" className={"d-flex justify-content-between lh-condensed"}><div className={"text-muted"}>Acquereur</div>
                                            <span>{GetLinkValueForRow(reserve,'contacts')}</span>
                                        </ListGroup.Item>

                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Body>

                        </Card.Body>
                    </Card>
                </Col>

            </Row>
                </Container>
            </section>
        </Layout>

    )
}

export default ReserveView;