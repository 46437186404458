import React from 'react';
import {useNavigate} from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import Swal from "sweetalert2";
// src/utils/familyUtils.js

// Fonction pour vérifier si tous les éléments d'un tableau sont identiques


export const getProgramLinkValueForRow = rowData => {
    let navigate = useNavigate();
    var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
        navigate(`/admin/programme/${rowData.programId}/voir`)
    }}>{rowData.programme}</span>;

    return elem;
};





