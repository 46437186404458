import React from 'react';
import { Button } from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import {Icon} from '@iconify/react';
const AddOptionButton = ({ user }) => {

    let navigate = useNavigate();

    const shouldShowButton = () => {

        if(!user){
            return false;
        }
        const isRole = ["ROLE_PRESCRIPTEUR", "ROLE_PROMOTEUR", "ROLE_ADMIN", "ROLE_PROSPECT","ROLE_ACQUEREUR"].includes(user.role);
        // const isAcquererWhiteLabel = user.role === "ROLE_ACQUEREUR" && user.isWhiteLabel;

        return isRole;
    };
    const handleNavigate = () => {

        return user.role === "ROLE_PRESCRIPTEUR" || user.role === "ROLE_PROMOTEUR" || user.role === "ROLE_ADMIN"?    navigate('/admin/option/ajouter'):navigate('/admin/option/ajouter?prom='+user.whiteLabelId+'&user='+user.id);
    };

    return (
        <>
            {shouldShowButton() && (
                <Button
                    variant="warning"
                    border="success"
                    onClick={handleNavigate}
                    className="me-1 text-white bg-warning"
                    size="sm"
                ><Icon icon="ic:round-add"/> Poser une option</Button>
            )}
        </>
    );


};



export default AddOptionButton;
