import React, {useState, useEffect,useRef} from 'react';
import { useWindowSize,useMeasure  } from 'react-use';

// Créer le composant enveloppeur
const ScrollWrapper = ({ children,isFullSize,dependency,path='' }) => {

    const { height } = useWindowSize();
    const [ref, { width }] = useMeasure();
    const wrapperRef = useRef(null);

    useEffect(() => {

        if (wrapperRef.current) {
            wrapperRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [dependency]); // Déclenche le scroll lorsque `dependency` change

    const percentageToSubtract = path==='lot_list'?45:39; // Exemple : soustraire 20%
    // Calculer la nouvelle hauteur après soustraction du pourcentage
    const calculatedHeight = height - (height * (percentageToSubtract / 100));

    return (
        <div ref={wrapperRef} style={{ width: '100%', overflowX: 'scroll' }}>
            <div ref={ref} style={{ width: isFullSize?'1549px':'1726px', maxHeight: `${calculatedHeight}px` }}>
                {children}
            </div>
        </div>
    );
};

export default ScrollWrapper;