import React, { useState } from 'react';
import { useCombobox } from 'downshift';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import {Col, Row} from "react-bootstrap";
import {ErrorMessage} from "formik";

const CityAndZipAutocomplete = ({values,setFieldValue}) => {

    const [cityInputValue, setCityInputValue] = useState('');
    const [zipInputValue, setZipInputValue] = useState('');
    const [cityItems, setCityItems] = useState([]);
    const [zipItems, setZipItems] = useState([]);
    const localityToString = item => (item ? item.postal_code+'_'+item.locality : '')

    const {
        getMenuProps: getCityMenuProps,
        getInputProps: getCityInputProps,
        isOpen:isCityOpen,
        getItemProps: getCityItemProps,
        highlightedIndex: cityHighlightedIndex,
    } = useCombobox({
        items: cityItems,
        itemToString:localityToString,
        inputValue: cityInputValue,
        onInputValueChange: ({ inputValue }) => {

            const indexTiret = inputValue.indexOf('_');

            if (indexTiret !== -1) {
                const codePostal = inputValue.substring(0, indexTiret);
                const ville = inputValue.substring(indexTiret + 1);
                setCityInputValue(ville);
                setZipInputValue(codePostal)
                setFieldValue(`zip`, codePostal)
                setFieldValue(`city`, ville)
            } else {
                setCityInputValue(inputValue);
                setFieldValue(`city`, inputValue)
            }

            if (inputValue.length >= 2) {
                fetchCityList(inputValue,'nom');
            }
        },
    });

    const fetchCityList = async (searchTerm,fieldName) => {

        try {
            const response = await axios.get(`/public/places/${searchTerm}?field=${fieldName}`);

            if(fieldName==='nom'){
                setCityItems(response.data); // Mettre à jour la liste des villes
            }
            else{
                setZipItems(response.data)
            }

        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    const {
        getMenuProps: getZipMenuProps,
        getInputProps: getZipInputProps,
        isOpen:isZipOpen,
        getItemProps: getZipItemProps,
        highlightedIndex: zipHighlightedIndex,
    } = useCombobox({
        items: zipItems,
        inputValue: zipInputValue,
        itemToString:localityToString,
        onInputValueChange: ({ inputValue }) => {
            const indexTiret = inputValue.indexOf('_');
            if (indexTiret !== -1) {
                const codePostal = inputValue.substring(0, indexTiret);
                const ville = inputValue.substring(indexTiret + 1);
                setCityInputValue(ville);
                setZipInputValue(codePostal)
                setFieldValue(`zip`, codePostal)
                setFieldValue(`city`, ville)
            } else {
                setZipInputValue(inputValue);
                setFieldValue(`zip`, inputValue)
            }
            if (inputValue.length >= 2) {
                fetchCityList(inputValue,'codePostal');
            }
        },
    });

    return (
        <Row>         <Col >
            <Form.Control {...getZipInputProps()}  aria-label="zip" placeholder="Code postal" name={`zip`} value={values.zip} type="text"/>
            <ErrorMessage name={`zip`} component="div" className="error mt-1"/>
            <ul {...getZipMenuProps()}>
                {isZipOpen && zipItems.map((item, index) => (
                    <li
                        {...getZipItemProps({
                            key: item,
                            index,
                            item,
                            style: {
                                backgroundColor: zipHighlightedIndex === index ? 'lightgray' : 'white',
                            },
                        })}
                    >
                        {item.postal_code} {item.locality}
                    </li>
                ))}
            </ul>

        </Col>

                <Col>
                    <Form.Group className="mb-3">
                    <Form.Control {...getCityInputProps()} name={`city`}  aria-label="city" placeholder="Ville" value={values.city} type="text"/>
                    <ErrorMessage name={`city`} component="div" className="error mt-1"/>
                    </Form.Group>
                    <ul {...getCityMenuProps()}>
                        { isCityOpen && cityItems.map((item, index) => (
                            <li
                                {...getCityItemProps({
                                    key: item.id,
                                    index,
                                    item,
                                    style: {
                                        backgroundColor: cityHighlightedIndex === index ? 'lightgray' : 'white',
                                    },
                                })}
                            >
                                {item.postal_code} {item.locality}
                            </li>
                        ))}
                    </ul>
                </Col>



        </Row>
    );
};

export default CityAndZipAutocomplete;
