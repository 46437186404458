import React from 'react';
import Select from 'react-select';

export default ({ onChange, options, value, className }) => {

    const defaultValue = (options, value) => {
        return options ? options.find(option => option.value === value) : "";
    };

    return (
        <div className={className}>
            <Select
                placeholder={<div>Aucun</div>}
                value={defaultValue(options, value)}
                menuPortalTarget={document.body}
                styles={{menuPortal: base => ({...base, zIndex: 9999, flex: 1})}}
                onChange={value => {
                    onChange(value)

                }} options={options} />
        </div>

    )
}