
import React, {useState, useEffect} from 'react';
import MaterialTable from "@material-table/core";
import {dateValueForRow} from "../../../component/DateValueForRow";
import axios from "axios";
import Swal from "sweetalert2";
import {getPdfValueForRow} from "../../../component/getPdfValueForRow";
import {GetLinkValueForRow} from "../../../component/GetLinkValueForRow";

const InvoiceTable  =({user,programmeId}) =>{

    const [invoiceList, setInvoiceList] = useState([])
    const [hideColumn, setHideColumn] = useState(user.role==='ROLE_PROMOTEUR'?false:true)
    const [hideProgrammeColumn, setHideProgrammeColumn] = useState(programmeId?true:false)
    const [filter, setFilter] = useState(false)
    const [rowCount, setRowCount] = useState('Consultation des factures')

    useEffect(() => {

        getAllInvoices();
    }, [])


    const getAllInvoices = () => {
        var url=programmeId ? `/admin/invoices?idprogramme=${programmeId}`  : `/admin/invoices`;
        axios.get(url)
            .then(function (response) {

                if (response.data) {
                    setInvoiceList(response.data);

                }
            })
            .catch(function (error) {

                if(error.response.status='401'){

                    Swal.fire({
                        icon: 'warning',
                        title: 'Erreur d\'authentification',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }

    const tableRef = React.useRef();

    return(

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div style={{maxWidth: '100%'}}>

                            <MaterialTable
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                        labelRowsSelect: 'lignes'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} facture(s) sélectionnée(s)',
                                        searchPlaceholder: 'Chercher'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Aucune facture à afficher',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                options={{
                                    pageSize: 25,
                                    selection:false,
                                    filtering: filter,
                                    addRowPosition: 'last', // Ajouter les nouvelles lignes en bas du tableau
                                }}


                                columns={[
                                    {
                                        title: 'Date',
                                        field: 'dte',
                                        align: "right",
                                        width: "10%",
                                        render: rowData => dateValueForRow(rowData.dte)
                                    },

                                    {
                                        title: 'N° facture',
                                        field: 'nrFacture',
                                        align: "center",
                                    },
                                    {
                                        title: 'Libellé',
                                        field: 'libelle',
                                        align: "left",
                                    },
                                    {
                                        title: 'Programme',
                                        field: 'programme',
                                        align: "left",
                                        render: rowData => GetLinkValueForRow(rowData,'programme'),
                                        hidden: hideProgrammeColumn
                                    },
                                    {
                                        title: 'Montant TTC',
                                        field: 'mntFacture',
                                        align: "right",
                                    },
                                    {
                                        title: 'Règlement',
                                        field: 'mntRegle',
                                        align: "right",
                                    },
                                    {
                                        title: 'Solde',
                                        field: 'solde',
                                        align: "right",
                                    },
                                    {
                                        title: 'Fournisseur',
                                        field: 'contact',
                                        render: rowData => GetLinkValueForRow(rowData,'contacts'),
                                        filtering: false,
                                        hidden: hideColumn
                                    },
                                    {
                                        title: 'Pdf',
                                        field: 'pdf',
                                        align: "center",
                                        render: rowData => getPdfValueForRow(rowData.pdf,false)
                                        , width: "5%"
                                    }
                                ]}

                                tableRef={tableRef}
                                onSearchChange={() => {

                                    setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                                }}
                                data={invoiceList}
                                title={rowCount}

                            />
                        </div>
                    </div>
                </div>
            </div>

    )
}

export default InvoiceTable;