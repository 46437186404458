import React, {useState, useEffect, useRef} from 'react';
import MaterialTable from "@material-table/core";
import axios from "axios";
import Swal from "sweetalert2";
import {convertFormatDate} from "../../../component/ConvertFormatDate";
import {dateValueForRow} from "../../../component/DateValueForRow";
import {getPdfValueForRow} from "../../../component/getPdfValueForRow";

const TabTma = ({list,idLot}) => {

    const tableRef = React.useRef();
    const [tmaList, setTmaList] = useState([])
    const [rowCount, setRowCount] = useState('Consultation des Tma')

    const getAllTma = () => {

        var url=list ? `/admin/tma`  : `/admin/tma?idLot=${idLot}`
        axios.get(url)
            .then(function (response) {

                if (response.data) {
                    setTmaList(response.data);
                }
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {

        getAllTma();
        // getUser();
    }, [])



    return (
        <MaterialTable
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} of {count}',
                    labelRowsSelect: 'lignes'
                },
                toolbar: {
                    nRowsSelected: '{0} action(s) sélectionnée(s)',
                    searchPlaceholder: 'Chercher'
                },
                body: {
                    emptyDataSourceMessage: 'Aucun Tma à afficher',
                    filterRow: {
                        filterTooltip: 'Filter'
                    }
                }
            }}
            options={{
                pageSize: 20,
                addRowPosition: 'last', // Ajouter les nouvelles lignes en bas du tableau
                rowStyle: rowData => ({
                    color: rowData.tableData.childRows ? '#30a2e3' : 'normal', // Appliquer le style en gras uniquement aux lignes parentes
                    // fontWeight: rowData.tableData.childRows ? 'bold' : 'normal' // Appliquer le style en gras uniquement aux lignes parentes
                })
            }}


            columns={[
                {
                    title: 'Lot/Facture', field: 'nrFacture',
                    width: "10%",
                },
                {
                    title: 'Libellé',
                    field: 'libelle',
                    align: "left",
                },
                {
                    title: 'Date',
                    field: 'date',
                    initialEditValue: convertFormatDate(new Date(), 'numeric'),
                    align: "right",
                    width: "10%",
                    render: rowData => dateValueForRow(rowData.date)
                },
                {
                    title: 'Echéance',
                    field: 'dateEcheance',
                    initialEditValue: convertFormatDate(new Date(), 'numeric'),
                    align: "right",
                    width: "10%",
                    render: rowData => dateValueForRow(rowData.dateEcheance)
                },

                {
                    title: 'Montant à régler',
                    field: 'mntVente',
                    align: "left",
                },
                {
                    title: 'Montant réglé',
                    field: 'mntRegle',
                    align: "left",
                },
                {
                    title: 'Solde',
                    field: 'solde',
                    align: "left",
                },
                {
                    title: "Pdf",
                    field: 'pdf',
                    render: rowData => getPdfValueForRow(rowData.pdf)
                    , width: "5%"
                }
            ]}
            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
            tableRef={tableRef}
            onSearchChange={() => {

                setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
            }}
            data={tmaList}
            title={rowCount}
        />


    )
}

export default TabTma;