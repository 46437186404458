import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../../component/Layout"
import Button from 'react-bootstrap/Button';
import api from "../../src/utils/api"
import LotsTab from "../Programme/component/LotsTab";
import {handleAddOption} from "../../utils/handleAddOption"

function LotList({user,isFullSize}) {

    const [selectedRows, setSelectedRows] = useState([]);
    const [active, setActive] = useState(true);
    let navigate = useNavigate();

    const handleChange = () => {

        setFilter(!filter)
    }


    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">
                            <div className="navbar navbar-default p-1 d-flex">
                                <div>
                                    <h1>Lot</h1>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des lots</li>
                                    </ol>
                                </div>
                                <div>
                                    {user.role === 'ROLE_PROSPECT' || user.role === 'ROLE_ACQUEREUR' ?
                                        <Button variant="primary" border="success" className="me-1" size="sm"
                                                 onClick={() => {
                                            navigate(handleAddOption(selectedRows));
                                        }}>Poser une option</Button> : null}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            <section className="content mt-0 pt-0">
                <LotsTab setSelectedRows={setSelectedRows} setActive={setActive} user={user} isFullSize={isFullSize}/>
            </section>


        </Layout>
    );
}

export default LotList;