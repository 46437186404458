import React, {useState, useEffect, useRef} from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CardImpersonate from "../Card/CardImpersonate";
import CardMailbox from "../Card/CardMailbox";
import CardContactShare from "../Card/CardContactShare";
import ReservationContractCard from "../../../component/ReservationContractCard";


const ParametersTab = ({user,setUser,isImpersonating,setIsImpersonating}) => {

    const [templateReservation, setTemplateReservation] = useState(user.templateReservation)
    useEffect(() => {
        console.log(user)
    }, []);
    return (
        <div>
            <Row>
                <Col lg="3">
                    {isImpersonating || user.isAllowedToSwitch ?
                    <CardImpersonate user={user} setUser={setUser} isImpersonating={isImpersonating} setIsImpersonating={setIsImpersonating}/>:null}
                    { user.role==='ROLE_PRESCRIPTEUR' ? <CardContactShare user={user} setUser={setUser} isImpersonating={isImpersonating} setIsImpersonating={setIsImpersonating}/>:null}
                    <ReservationContractCard
                        codeClient={user.codeClient}
                        templateReservation={templateReservation}
                        setTemplateReservation={setTemplateReservation}
                    />

                    <CardMailbox user={user} setUser={setUser} isImpersonating={isImpersonating} setIsImpersonating={setIsImpersonating}/>
                </Col>
                <Col lg="8">
                    <Card>
                        <Card.Body>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>


    )
}

export default ParametersTab;