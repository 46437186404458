import React, {createContext, useState, useEffect} from 'react';
import Layout from "../../component/Layout"
import Card from 'react-bootstrap/Card';
import Stepper from "./component/Stepper";
import Step from "./component/Step";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import {getLotList} from "../../utils/lots";
import useUrlParams from "../../hook/useUrlParams";

export const FormContext = createContext();

function ReservationAddEdit({user}) {

    let navigate = useNavigate();
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [formData, setFormData] = useState({notaireFraisVente: 0, notaireFraisPret: 0, quotePart: 0,isNotary:false,isPrescripteur:false,mntDGChecked:true});
    const [id, setId] = useState(useParams().id)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const optionId = searchParams.get('option');
    const codeClientParam = searchParams.get('codeclient');
    const [isLoading, setIsLoading] = useState(false);
    const [isClause, setIsClause] = useState(false);
    const [option, setOption] = useState(null);
    const [isAddMode, setIsAddMode] = useState(!id)
    const [buyers, setBuyers] = useState([
        {
            id: '',
            civilite: {label: 'Monsieur', value: 1},
            nom: '',
            prenom: '',
            society: {siretNumber:'',cityRCSRegistration:'',nom:'',actingAs:''},
            address: '',
            societe: '',
            zip: '',
            city: '',
            pays: {label: 'France', value: 'FR'},
            email: '',
            birthDate: '',
            birthPlace: '',
            nationality: {label: 'Française', value: 'FR'},
            profession: '',
            cellphone: '',
            phone: '',
            resident: 1,
            familySituation: {label: 'non renseigné', value: '0'},
            typeOfPerson: {label: 'Physique', value: '0'},
            lienMatrimonial: false,
            dateContrat: '',
            regime: '',
            villeMariage: '',
            dateCelebration: '',
            nomNotaire: '',
            role: 'ROLE_ACQUEREUR',
            matrimonialSituationCheck: false,
            isFamilySituationToogled: false
        }
    ]);

    const [prets, setPrets] = useState([
        {
            type: {label: 'Prêt amortissable', value: '1'},
            montant: '',
            montantMax: '',
            dureeMax: '',
            delaiDepotDossier: '',
            delaiObtention: '',
            txInteretAnnuelMaxHorsAssurance: ''
        }
    ]);

    const [clauses, setClauses] = useState([
        {
            title: '',
            description: '',
        }
    ]);

    const [programId, setProgramId] = useState(0)
    const [codeClient, setCodeClient] = useState(codeClientParam?codeClientParam:'')
    const [filteredData, setFilteredData] = useState();
    const [lotList, setLotList] = useState([]);
    const [medias, setMedias] = useState([])
    const [selectedLots, setSelectedLots] = useState([])
    const [dataFiles, setDataFiles] = useState([]);
    const [sideBarTab, setSideBarTab] = useState({
            program: '-',
            email: '-',
            nom: '-',
            prenom: '-',
            societe: null,
            cellphone: '-',
            lots: [{}]
        }
    );

    const getOption = (id) => {
        axios.get(`/admin/options/` + id+'?type=confirmation')
            .then(function (response) {

                var option = response.data;
                setBuyers(option.contact)
                setOption(option);
                setIsLoading(true);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {

        if (optionId) {
            getOption(optionId)
        } else {
            setIsLoading(true);
        }


    }, [sideBarTab])

    // Utiliser le hook personnalisé
    useUrlParams({
        user,
        setProgramId,
        getLotList,
        setLotList,
        setFilteredData,
        setBuyers
    });

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1">
                                <div>
                                    <h1>Réservation</h1>
                                </div>

                                <div className="hidden-xs d-flex">
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
                                            navigate(`/admin/reservation/list`)
                                        }}>
                                            Liste des réservations
                                        </li>
                                        <li className="breadcrumb-item active ">{isAddMode ? "Ajouter une réservation" : "Editer une réservation"}</li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={'page-reservation'} className="content">
                <div className="container-fluid">
                    {isLoading ?
                        <Row>
                            <div className="row justify-content-center">
                            <Col xl={12}>
                                <Card className={"h-100"}>
                                    <Card.Body>
                                        <FormContext.Provider
                                            value={{
                                                activeStepIndex,
                                                setActiveStepIndex,
                                                formData,
                                                setFormData,
                                                buyers,
                                                prets,
                                                setPrets,
                                                clauses,
                                                isClause,
                                                setIsClause,
                                                setClauses,
                                                setBuyers,
                                                medias,
                                                setMedias,
                                                sideBarTab,
                                                setSideBarTab,
                                                programId,
                                                setProgramId,
                                                filteredData,
                                                setFilteredData,
                                                lotList,
                                                setLotList,
                                                selectedLots,
                                                setSelectedLots,
                                                dataFiles,
                                                setDataFiles,
                                                isAddMode,
                                                setIsAddMode,
                                                codeClient,
                                                setCodeClient,
                                                option

                                            }}>
                                            <div className="wizard">
                                                <Stepper/>
                                                <Step user={user}/>
                                            </div>
                                        </FormContext.Provider>
                                    </Card.Body>
                                </Card>
                            </Col>
                            </div>

                        </Row>
                        :
                        null
                    }
                </div>
            </section>
        </Layout>
    );
}

export default ReservationAddEdit;