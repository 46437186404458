import {useEffect} from 'react';
import {getLotList} from "../utils/lots";
import {getContactByPromoteur} from "../service/ContactService";

const useUrlParams = ({
                          user,
                          setProgramId,
                          setLotList,
                          setFilteredData,
                          getPromoteur,
                          setBuyers
                      }) => {
    useEffect(() => {

        // Récupérer la chaîne de requête de l'URL
        const queryString = window.location.search;
        // Analyser la chaîne de requête pour extraire les paramètres de requête
        const urlParams = new URLSearchParams(queryString);
        const idPromoteur = urlParams.get('prom');
        const userId = urlParams.get('user');
        const programId = urlParams.get('programId');
        const selectedIds = urlParams.get('selectedIds');
        // Si un programId est présent, mettre à jour l'état et appeler getLotList
        if (programId) {
            setProgramId(programId);
            getLotList(programId, selectedIds, setLotList, setFilteredData);
        }
        // Appeler getPromoteur si l'utilisateur a le rôle ROLE_PROSPECT
        user.role !== 'ROLE_FOURNISSEUR' &&  idPromoteur? getPromoteur(idPromoteur) : null;
        // Appeler getContactByPromoteur si l'utilisateur est un prospect ou un acquéreur
        if (user.role !== 'ROLE_FOURNISSEUR' && idPromoteur && userId) {

            getContactByPromoteur(idPromoteur, setBuyers,userId);
        }
    }, []);
};

export default useUrlParams;
