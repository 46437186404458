// Workspace.js
import {ErrorMessage, Field, FieldArray, Formik} from "formik";
import Form from "react-bootstrap/Form";
import React, {useContext, useState, useEffect} from "react";
import {FormContext} from "../ReservationAddEdit";
import * as yup from "yup";
import Select from "react-select";
import MaterialTable, {MTableEditRow, MTableBody, MTableEditField} from "@material-table/core";
// Importations de TableCell, TableRow, et TableBody depuis Material-UI v5
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Checkbox } from '@mui/material';
import handleLotRowUpdate from "../component/handleLotRowUpdate";
import { numberWithCommas,convertToFloat } from '../../../utils/formatters';
import { getCustomComponents } from '../../../component/table/customComponents';
import { addTotalToState } from "../../../utils/tableUtils";
function Funding() {

    const {
        activeStepIndex,
        setActiveStepIndex,
        formData,
        setFormData,
        sideBarTab,
        setSideBarTab
    } = useContext(FormContext);

    const [lotList, setLotList] = useState([])
    const [total, setTotal] = useState(0)
    const [totalGlobal, setTotalGlobal] = useState(0)
    const [checked, setChecked] = useState(formData.mntDGChecked);
    const [totalMntDG, setTotalMntDG] = useState(0)
    const handleHeaderCheckboxChange = (event) => {
        const newChecked = !checked;
        formData.mntDGChecked=newChecked;
        setChecked(newChecked)
        setLotList(lotList.map(row => ({ ...row, mntDG: 0 })));

        if(checked){

            lotList.forEach(function (e) {
                e.mntDG= 0
            });
        }

        setTotalMntDG(0);
    }
        //
    const [dataTab, setDataTab] = useState([

        {label: 'Rappel du prix', amount: "", id: 1},
        {label: 'Frais de notaire de l’acte de vente', amount: formData.notaireFraisVente, id: 2, name: "notaireFraisVente"},
        {label: 'Frais de notaire de l\'acte de prêt', amount: formData.notaireFraisPret, id: 3, name: "notaireFraisPret"},
        {label: 'Quote-part', amount: formData.quotePart, id: 4, name: "quotePart"},

    ])



    const addTotalGlobal = (data) => {

        console.log(data)
        let total = data.reduce((acc, el) => {

            var amount = typeof el['amount'] === 'string' || el['amount'] instanceof String ? convertToFloat(el['amount']) : el['amount']
            return acc += +amount;
        }, 0);

        setTotalGlobal(numberWithCommas(total.toFixed(2)))
    }

    const ValidationSchema = yup.object().shape({
        delaiLivraison: yup.string().required("Un délai de livraison prévisionnel est requis").nullable(),
        // notaireFraisVente: yup.number().min(1, 'L\'estimation des frais de notaire est requise').required("L'estimation des frais de notaire est requise").nullable(),
    });

    const typeOfProjectOptions = [
        {value: 0, label: 'Résidence principale'},
        {value: 1, label: 'Résidence secondaire'},
        {value: 2, label: 'Investissement locatif'}
    ]

    useEffect(() => {
        //supprime les collones inutiles
        formData.lots.forEach(function (v) {
            delete v.tableData
        });
        setLotList(formData.lots);
    }, []);




    return (
        <Formik
            initialValues={{
                typeOfProject: 0,
                delaiLivraison: formData.delaiLivraison,
                quotePart: formData.quotePart,
                notaireFraisPret: formData.notaireFraisPret,
                notaireFraisVente: formData.notaireFraisVente,
                lots:formData.lots
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {

                const data = {...formData, ...values};
                setFormData(data);
                setActiveStepIndex(activeStepIndex + 1);
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  isValid,
                  isSubmitting,
                  touched,
                  setFieldValue,
                  handleBlur, // handler for onBlur event of form elements
              }) => {

                useEffect(() => {
                    //gère l'affiche des montants totaux
                    addTotalToState({
                        data: values.lots,
                        byColumn: 'salePrice',
                        setTotal,
                        setTotalGlobal,
                        setTotalMntDG,
                        dataTab,
                        setDataTab,
                        values
                    });
                    addTotalToState({
                        data: values.lots,
                        byColumn: 'mntDG',
                        setTotal,
                        setTotalGlobal,
                        setTotalMntDG,
                        dataTab,
                        setDataTab,
                        values
                    });



                }, [values])


                return (
                    <Form className="flex flex-col justify-center items-center" noValidate onSubmit={handleSubmit}>
                        <div className={'ms-2 my-4'}><h6>Lot</h6>
                            <small>
                                Lots sélectionnés</small>
                        </div>
                        <Row className="mt-3 mb-5">
                            <Col>
                                <Form.Label>Type de projet</Form.Label>
                                <Select
                                    key={`typeOfProject`}
                                    options={typeOfProjectOptions}
                                    defaultValue={{label: "Résidence principale", value: 0}}
                                    name={`typeOfProject`}
                                    id={`typeOfProject`}
                                    onChange={newValue =>

                                        setFieldValue(`typeOfProject`, newValue)}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Le délai prévisionnel d’exécution des travaux (mois)</Form.Label>
                                <Form.Control
                                    key={`delaiLivraison`}
                                    name={`delaiLivraison`}
                                    id={`delaiLivraison`}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.delaiLivraison}
                                />
                                <ErrorMessage name={`delaiLivraison`} component="div" className="error"/>
                            </Col>

                        </Row>
                        <div id={"table-funding"}>
                            <MaterialTable

                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                        labelRowsSelect: 'lignes'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} lot(s) sélectionnée(s)',
                                        searchPlaceholder: 'Chercher'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Aucun programme à afficher',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                editable={{
                                    onRowUpdate: (newData, oldData) => {
                                        return handleLotRowUpdate({
                                            newData,
                                            oldData,
                                            lotList,
                                            setLotList,
                                            setFieldValue,
                                        }).then(() => {
                                            addTotalToState({
                                                data: values.lots,
                                                byColumn: 'salePrice',
                                                setTotal,
                                                setTotalGlobal,
                                                setTotalMntDG,
                                                dataTab,
                                                setDataTab,
                                                values
                                            });

                                            addTotalToState({
                                                data: values.lots,
                                                byColumn: 'mntDG',
                                                setTotal,
                                                setTotalGlobal,
                                                setTotalMntDG,
                                                dataTab,
                                                setDataTab,
                                                values
                                            });

                                        });
                                    }
                                }}
                                options={{
                                    pageSize: formData.lots.length > 5 ? formData.lots.length : 5,
                                    toolbar: false,
                                    paging: false,
                                    actionsColumnIndex: -1
                                }}

                                columns={[
                                    {
                                        title: 'N° Lot', field: 'nrLot', filtering: false, editable: false
                                    },
                                    {
                                        title: 'TVA',
                                        field: 'TVA',
                                        align: "right",
                                        filtering: false,
                                    },
                                    {
                                        title: (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginRight: 8 }}>Dépôt de garantie</span>
                                            <Checkbox
                                                checked={checked}
                                                onChange={handleHeaderCheckboxChange}
                                                inputProps={{ 'aria-label': 'select all' }}
                                            />
                                            </div>
                                        ),

                                        field: 'mntDG',
                                        filtering: false,
                                        align: "right"
                                    },

                                    {
                                        title: 'Prix de vente',
                                        field: 'salePrice',
                                        filtering: false,
                                        align: "right",
                                    },
                                ]}

                                onSelectionChange={(rows) => onSelectionLotChange(rows, setFieldValue)}
                                onSearchChange={() => {

                                    setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                                }}
                                data={lotList}
                                components={getCustomComponents(total, totalMntDG)}
                            />
                        </div>

                        <div className={'ms-2 my-4 mt-5'}><h6>Coût global de l'opération</h6>
                            <small>
                                Le coût global de l’opération est détaillé ci-dessous </small>
                        </div>
                        <MaterialTable
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} of {count}',
                                    labelRowsSelect: 'lignes'
                                },
                                toolbar: {
                                    nRowsSelected: '{0} lot(s) sélectionnée(s)',
                                    searchPlaceholder: 'Chercher'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Aucun programme à afficher',
                                    filterRow: {
                                        filterTooltip: 'Filter'
                                    }
                                }
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        setTimeout(() => {

                                            newData.name === 'quotePart' ? setFieldValue('quotePart', newData.amount) : null;
                                            newData.name === 'notaireFraisVente' ? setFieldValue('notaireFraisVente', newData.amount) : null;
                                            newData.name === 'notaireFraisPret' ? setFieldValue('notaireFraisPret', newData.amount) : null;
                                            const dataTabUpdate = [...dataTab];
                                            const index = oldData.tableData.index;
                                            dataTabUpdate[index] = newData;
                                            setDataTab([...dataTabUpdate]);
                                            addTotalGlobal(dataTabUpdate)
                                            resolve();
                                        }, 1000);
                                    }),
                            }}

                            options={{
                                pageSize: 5,
                                toolbar: false,
                                paging: false,
                                actionsColumnIndex: -1
                            }}

                            columns={[
                                {
                                    title: 'Libellé', field: 'label', filtering: false, editable: 'never'
                                },
                                {
                                    title: 'Montant',
                                    field: 'amount',
                                    filtering: false,
                                    align: "right",
                                    editable: 'onUpdate',
                                    editComponent: props => (
                                        <MTableEditField
                                            {...props}
                                            onChange={e => props.onChange(e)}
                                        />
                                    ),
                                },

                            ]}

                            onSelectionChange={(rows) => onSelectionLotChange(rows, setFieldValue)}
                            onSearchChange={() => {

                                setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                            }}
                            data={dataTab}
                            components={{
                                Body: (props) => (
                                    <>
                                        <MTableBody {...props} />
                                        <TableBody>
                                            <TableRow className={"bg-secondary"}>

                                                <TableCell size={"medium"}>Total</TableCell>


                                                <TableCell align={'right'}
                                                           variant={"body"}>{totalGlobal}</TableCell>
                                                <TableCell> </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </>
                                )
                            }}
                        />
                        <ErrorMessage name={`notaireFraisVente`} component="div" className="error"/>
                        <FieldArray name="lots">
                        </FieldArray>
                        <div className="actions clearfix">
                            <button className="rounded-md font-medium text-white my-2 p-2 me-2"
                                    onClick={() => {

                                        setActiveStepIndex(activeStepIndex - 1)
                                    }}>
                                Précédent
                            </button>
                            <button
                                className="rounded-md font-medium text-white my-2 p-2"
                                type="submit"
                            >
                                Suivant
                            </button>
                        </div>
                    </Form>)
            }}
        </Formik>
    );
}

export default Funding;