import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import MeetingAddEdit from "../../component/MeetingAddEdit";
import MeetingTable from "./component/MeetingTable";
import {Formik, useFormikContext} from 'formik';
import Form from 'react-bootstrap/Form';
import {Calendar, dateFnsLocalizer} from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import fr from 'date-fns/locale/fr'
import Modal from 'react-bootstrap/Modal';
import {convertDate} from "../../component/convertDate";
import {LocalizationProvider, MobileDateTimePicker} from "@mui/lab";
import DateFnsAdapter from "@mui/lab/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import TextField from "@mui/material/TextField";
import {Icon} from '@iconify/react';
import api from "../../src/utils/api"

const locales = {
    'fr': fr,
}

const MeetingList = ({user}) => {
    const [meetingList, setMeetingList] = useState([])
    const [isAddMode, setIsAddMode] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const closeModal = () => {
        setSelectedEvent(null);
    };


    const getUser = (id) => {
        axios.get(`/admin/users/${id}`)
            .then(function (response) {
                const user = response.data;

                return user;
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
    const getAllMeetings = () => {


        const url = user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ? `http://www.t1.viteimmo.com:3000/apo/calendar/list/events` : `http://www.t1.viteimmo.com:3000/apo/calendar/list/events?user=${user.id}`;
        axios.get(url)
            .then(async function (response) {

                const eventsList = response.data;
                // Créer un tableau de promesses pour les requêtes individuelles
                const promises = eventsList.map(async event => {
                    // Utiliser la valeur d'un champ de chaque élément pour construire l'URL de la requête
                    // const fieldValue = item.champ; // Remplacez 'champ' par le nom de votre champ
                  event.start = new Date(event.start);
                       event.end = new Date(event.end);
                    // Effectuer la requête et obtenir la réponse
                    const response = event.user ? await axios.get(`/admin/users/${event.user}`):null;
                    // Utiliser la réponse de la requête pour ajouter une propriété à l'élément actuel
                    const name =response? response.data.name:''; // Remplacez 'valeur' par le nom du champ que vous souhaitez utiliser
                    event.contact = name; // Ajout de la propriété au champ actuel

                    // Renvoyer l'élément avec la propriété ajoutée
                    return event;
                });

                // Attendre que toutes les promesses se résolvent
                const result = await Promise.all(promises);

                // console.log(eventsList)
                result ? setMeetingList(result) : setMeetingList([])
            })
            .catch(function (error) {
                console.log(error)
                if (error.response.status = '401') {

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })




    }

    function createMeetingFromPromoteur(fields) {

        fields.startTime = convertDate(fields.startTime);
        fields.endTime = convertDate(fields.endTime);

        axios.post('/admin/meetings', fields)
            .then(function (response) {
                closeModal();
                getAllMeetings();
                Swal.fire({
                    icon: 'success',
                    title: 'Le rendez-vous a été ajouté avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {
                console.log(error)
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            });

    }

    return (
        <Formik
            initialValues={{startTime: '', endTime: '', subject: ''}}
            onSubmit={values => {

                createMeetingFromPromoteur(values)
            }}
        >
            <InnerComponent closeModal={closeModal} selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent}
                            user={user} show={show} setShow={setShow} getAllMeetings={getAllMeetings}
                            meetingList={meetingList} setMeetingList={setMeetingList}/>
        </Formik>
    );
};


const InnerComponent = ({
                            user,
                            show,
                            setShow,
                            getAllMeetings,
                            setMeetingList,
                            meetingList,
                            setSelectedEvent,
                            selectedEvent,
                            closeModal
                        }) => {

    const {setFieldValue, values, submitForm, handleChange, handleSubmit, errors, isSubmitting} = useFormikContext();

    const messages = {
        allDay: "Tous les jours",
        previous: "Précédent",
        next: "Suivant",
        today: "Aujourd'hui",
        month: "Mois",
        week: "Semaine",
        day: "Jour",
        agenda: "Agenda",
        date: "Date",
        time: "Heure",
        event: "Evenement",
    };


    const handleShow = () => setShow(true);
    const [idAction, setIdAction] = useState(null)
    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales,
    })

    const [isAddMode, setIsAddMode] = useState(false);
    const handleEventClick = event => {

        setIsAddMode(false);
        setFieldValue('startTime', convertDate(event.start, 'invert'));
        setFieldValue('endTime', convertDate(event.end, 'invert'));
        setIsAddMode(false);
        setSelectedEvent(event);
    };


    let navigate = useNavigate();

    useEffect(() => {

        getAllMeetings();
    }, [])

    const handleEventAdd = ({start, end}) => {
        setIsAddMode(true);
        // Gérer la sélection de l'utilisateur ici, par exemple :
        const event = {startTime: convertDate(start), endTime: convertDate(end)};
        setFieldValue('startTime', new Date(start))
        setFieldValue('endTime', new Date(end))
        setIsAddMode(true);
        setSelectedEvent(event);

    };

    const handleDeleteEvent = () => {

        Swal.fire({
            icon: 'warning',
            title: "Confirmer la suppression du rendez-vous ?",
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            dangerMode: true,
        })
            .then(result => {

                if (result.isConfirmed) {


                    axios.delete(`/admin/meetings/${selectedEvent.id}`)
                        .then(res => {
                            closeModal();
                            getAllMeetings();

                            var message = "Le rendez-vous a bien été supprimé"

                            Swal.fire({
                                title: "Fait!",
                                text: message,
                                icon: "success",
                                timer: 2000,
                                button: false
                            })


                        })
                        .catch(function (error) {

                                if (error.response.status === 401) {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'Vous n\'êtes pas autorisé à supprimer ce rendez-vous',
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                }

                            }
                        );


                } else {
                    Swal.fire('Votre rendez-vous est maintenu', '', 'info')
                }
            })

    };

    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">
                            <div className="navbar navbar-default p-1 d-flex justify-content-between">
                                <div className={'navbar-header'}>
                                    <h1>Agenda</h1>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des rendez-vous</li>
                                    </ol>
                                </div>
                                <div>
                                    {user.role != 'ROLE_PROMOTEUR' && user.role != 'ROLE_ADMIN' ?
                                        <Button variant="primary" border="success" className="me-1" size="sm"
                                                onClick={() => {
                                                    handleShow();
                                                    setIsAddMode(true);
                                                }}>Prendre rendez-vous</Button> : <div></div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{maxWidth: '100%'}}>
                                {user.role === 'ROLE_PROMOTEUR' || user.role === 'ROLE_ADMIN' ? <div>
                                    <Calendar
                                        selectable
                                        localizer={localizer}
                                        culture="fr"
                                        defaultView={"week"}
                                        events={meetingList}
                                        step={15} // number of minutes per timeslot
                                        min={new Date(2024, 1, 8, 9, 0)} // Heure de début de la journée (9h)
                                        max={new Date(2024, 1, 8, 17, 0)} // Heure de fin de la journée (17h)
                                        timeslots={2} // Nombre de créneaux horaires par heure
                                        startAccessor="start"
                                        messages={messages}
                                        onSelectSlot={handleEventAdd} // Gestionnaire d'événements pour la sélection d'un créneau
                                        endAccessor="end"
                                        timeslots={2} // Nombre de créneaux horaires par jour (2 créneaux par heure)
                                        style={{height: "700px"}}
                                        onSelectEvent={handleEventClick} // Gérer le clic sur un événement// Afficher les heures pour les événements multi-jours
                                    />

                                    {selectedEvent && (
                                        <Modal show={!!selectedEvent} onHide={closeModal}>
                                            <Modal.Header className="bg-lavender">
                                                <Modal.Title className="text-white">Rendez-vous
                                                    <Icon className={'text-white float-right'}
                                                          icon="material-symbols:close" onClick={closeModal}/>
                                                    {!selectedEvent.contact ?
                                                        <Icon className={'text-secondary float-right me-4'}
                                                              icon="material-symbols:delete-outline"
                                                              onClick={handleDeleteEvent}/> : null}
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form noValidate onSubmit={handleSubmit}>

                                                    <div>
                                                        {isAddMode ? null : <Form.Group className="mb-2">
                                                            <Form.Label>Contact</Form.Label>
                                                            <Form.Control
                                                                readOnly={true}
                                                                name="contact"
                                                                value={selectedEvent.contact}
                                                                type="text"/>
                                                        </Form.Group>}

                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Titre</Form.Label>
                                                            <Form.Control
                                                                onChange={handleChange}
                                                                readOnly={isAddMode ? false : true}
                                                                name="subject"
                                                                value={selectedEvent.title}
                                                                type="text"/>
                                                        </Form.Group>
                                                        <Form.Group className="mb-4">
                                                            <LocalizationProvider dateAdapter={DateFnsAdapter}
                                                                                  locale={frLocale}>
                                                                <MobileDateTimePicker
                                                                    clearable
                                                                    ampm={false}
                                                                    name="startTime"
                                                                    views={['day', 'month', 'year']}
                                                                    readOnly={isAddMode ? false : true}
                                                                    inputFormat="dd-MM-yyyy HH:mm"
                                                                    label="Début"
                                                                    clearText="vider"
                                                                    cancelText="annuler"
                                                                    onChange={(value) => {
                                                                        setFieldValue('startTime', value)
                                                                    }}
                                                                    value={values.startTime}
                                                                    okText="ok"
                                                                    renderInput={props =>
                                                                        <TextField
                                                                            name="startTime"
                                                                            variant="outlined"
                                                                            id={"date"}
                                                                            {...props}
                                                                        />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Form.Group>
                                                        <Form.Group className="mb-4">
                                                            <LocalizationProvider dateFormats={{
                                                                shortDate: "d MMMM"
                                                            }} dateAdapter={DateFnsAdapter} locale={frLocale}>
                                                                <MobileDateTimePicker
                                                                    clearable
                                                                    readOnly={isAddMode ? false : true}
                                                                    ampm={false}
                                                                    name="endTime"
                                                                    inputFormat="dd-MM-yyyy HH:mm"
                                                                    label="Fin"

                                                                    clearText="vider"
                                                                    cancelText="annuler"
                                                                    onChange={(value) => {

                                                                        setFieldValue('endTime', value)
                                                                    }}
                                                                    value={values.endTime}
                                                                    okText="ok"
                                                                    renderInput={props =>
                                                                        <TextField
                                                                            name="endTime"
                                                                            variant="outlined"
                                                                            id={"date"}
                                                                            {...props}
                                                                        />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Form.Group>
                                                    </div>

                                                    {isAddMode ? <button

                                                        variant="primary"
                                                        className="float-right btn btn-success"
                                                        disabled={isSubmitting}
                                                        type="submit">
                                                        {isSubmitting ? "S'il vous plaît, attendez..." : "Valider"}


                                                    </button> : null}

                                                </Form>

                                            </Modal.Body>

                                        </Modal>
                                    )}
                                </div> : <MeetingTable meetingList={meetingList} getAllMeetings={getAllMeetings}
                                                       setIsAddMode={setIsAddMode} setShow={setShow}/>}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <MeetingAddEdit user={user} show={show} setShowAction={setShow} idAction={idAction} isAddMode={isAddMode}
                            getAllMeetings={getAllMeetings} user={user}/>
        </Layout>
    );
}

export default MeetingList;