import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import MaterialTable from "@material-table/core";
import {Icon} from '@iconify/react';
import Button from "react-bootstrap/Button";
import ScrollWrapper from "../../utils/ScrollWrapper";
import {renderLotList} from "../../utils/lots"
import { TablePagination,Box,Typography } from '@mui/material';
import TablePaginationActions from "../../component/TablePaginationActions";
import TablePaginationNullActions from "../../component/TablePaginationNullActions";
import Pagination from "../../component/Pagination";
import AddOptionButton from "../../component/AddOptionButton";
import {Col, Row} from "react-bootstrap";
import FilterOption from "./component/FilterOption";
function OptionList({user,isFullSize}) {

    const [data, setData] = useState([]); // Les données de l'API ou de la source

    const [filteredData, setFilteredData] = useState([])
    const [rowCount, setRowCount] = useState('Liste des lots')
    const [filter, setFilter] = useState(false)
    const [programId, setProgramId] = useState('all')
    const [filtered, setFiltered] = useState(false)
    let navigate = useNavigate();
    // Variables d'état pour la pagination
    const [dataCount, setDataCount] = useState(0); // Les données de l'API ou de la source
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const getStatusValueForRow = rowData => {

        var elem;

        switch (rowData.status) {
            case 0:
                elem = <span className="label label-danger">refusée</span>;
                break
            case 1:
                elem = <span className="label label-success">acceptée</span>;

                break
            case 2:

                elem = <span className="label label-warning">en cours</span>;
                break
            case 3:
                elem = <span className="label label-danger">expirée</span>;
                break

        }

        return elem;
    };

    const getOptionLinkValueForRow = rowData => {

        if (rowData.isOpen === false) {

            var elem = <span>{rowData.id}</span>;
        } else {

            var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
                navigate(`/admin/option/${rowData.id}/modifier`)
            }}>{rowData.id}</span>;
        }

        return elem;
    };


    const getProgramLinkValueForRow = rowData => {
        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
            navigate(`/admin/programme/${rowData.programId}/voir`)
        }}>{rowData.programme}</span>;

        return elem;
    };

    useEffect(() => {
        fetchOptions();
    }, [page, rowsPerPage]); // Exécuter à chaque changement de page ou de nombre de lignes

    useEffect(() => {
        // setFilteredData(programId === 'all' ? optionList : optionList.filter(dt => dt.programId === programId))
    }, [programId])

    useEffect(() => {

    }, [filteredData]);
    const tableRef = React.useRef();

    const fetchOptions = () => {
        axios.get(`/admin/options`)
            .then(function (response) {

                if (filtered == false) {

                    // response.data ? setFilteredData(response.data) : null;
                    if(response.data){
                        setDataCount(response.data.length)
                        const paginatedData = response.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
                        setData(paginatedData);
                    }

                    setRowCount('Liste des options (' + response.data.length + ')')
                }


            })
            .catch(function (error) {

                if (error.response.status = '401') {

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }


    const getCreatedAtValueForRow = createdAt => {

        var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
            <time>{createdAt}</time>
        </div>;

        return elem;
    };


    const getNameValueForRow = (rowData, role) => {

        var contact;
        var contactId
        if (role === 'ROLE_ACQUEREUR') {
            contact = rowData.acquereur;
            contactId = rowData.acquereurId;
        } else if (role === 'ROLE_PRESCRIPTEUR') {
            contact = rowData.prescripteur;
            contactId = rowData.prescripteurId;
        }


        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
            navigate(`/admin/contacts/${contactId}/voir`)
        }}>{rowData.societe ? rowData.societe : contact}</span>;

        return elem;
    };


    return (
        <Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1 d-flex">
                                <div>
                                    <h1>Options</h1>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                        <li className="breadcrumb-item active ">Liste des options</li>
                                    </ol>
                                </div>
                                <div>
                                    <AddOptionButton user={user}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">
                <div className="container-fluid">

                    <div className="row">

                        <div className="col-md-12">
                            <ScrollWrapper isFullSize={isFullSize}>
                                <MaterialTable
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} of {count}',
                                            labelRowsSelect: 'lignes'
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} option(s) sélectionnée(s)',
                                            searchPlaceholder: 'Chercher'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Aucune option à afficher',
                                            filterRow: {
                                                filterTooltip: 'Filter'
                                            }
                                        }
                                    }}

                                    options={{
                                        selection: true,
                                        pageSize: 10,
                                        filtering: filter,
                                        paging:false,
                                        selectionProps: rowData => ({
                                            disabled: !rowData.isSelectable, // Désactiver la sélection si isSelectable est faux
                                        }),
                                    }}
                                    parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                    columns={[
                                        {
                                            title: 'Id', field: 'id',
                                            filtering: false,
                                            render: rowData => getOptionLinkValueForRow(rowData),
                                            width: "5%",
                                        },
                                        {title: "Lot", field: 'lots', filtering: false,render: rowData => renderLotList(rowData)},
                                        {title: "N° Copro", field: 'nrCoproList', filtering: false,render: rowData => rowData.nrCoproList.join(', '),},
                                        {
                                            title: 'Programme',
                                            field: 'programme',
                                            filtering: false,
                                            render: rowData => getProgramLinkValueForRow(rowData)
                                        },
                                        {
                                            title: 'Etat',
                                            field: 'status',
                                            filtering: false,
                                            align: "center",
                                            render: rowData => getStatusValueForRow(rowData),
                                        },
                                        {
                                            title: 'Prix proposé', field: 'pxTotalProposeTTC',
                                            filtering: false,
                                            align: "center",
                                            render: rowData => rowData.pxTotalProposeTTC,

                                        },
                                        {
                                            title: 'Prix de vente', field: 'pxPromoteur',
                                            filtering: false,
                                            align: "center",
                                            render: rowData => rowData.pxPromoteur,

                                        },
                                        {
                                            title: 'Contact',
                                            field: 'contact',
                                            filtering: false,
                                            hidden: user.role==='ROLE_PROSPECT'?true:false,
                                            render: rowData => getNameValueForRow(rowData, 'ROLE_ACQUEREUR'),
                                            customFilterAndSearch: (searchTerm, rowData) => {

                                                const contact = rowData.role != 'ROLE_PRESCRIPTEUR'
                                                    ? rowData.acquereur
                                                    : rowData.prescripteur;
                                                const nameOrCompany = rowData.societe ? rowData.societe : contact;

                                                return nameOrCompany.toLowerCase().includes(searchTerm.toLowerCase());
                                            },
                                        },

                                        {
                                            title: 'Prescripteur',
                                            field: 'prescripteur',
                                            filtering: false,
                                            render: rowData => getNameValueForRow(rowData, 'ROLE_PRESCRIPTEUR'),
                                        },        {
                                            title: 'Date début validité', field: 'dteDebutValidite',
                                            filtering: false,
                                            align: "center",
                                            render: rowData => getCreatedAtValueForRow(rowData.dteDebutValidite),

                                        },
                                        {
                                            title: 'Date fin validité', field: 'dteFinValidite',
                                            filtering: false,
                                            align: "center",
                                            render: rowData => getCreatedAtValueForRow(rowData.dteFinValidite),
                                        },

                                    ]}
                                    actions={[
                                        {
                                            icon: () => <Icon id='delete-outline' icon="material-symbols:delete-outline"/>,
                                            tooltip: "Supprimer toutes les options sélectionnées",
                                            onClick: (event, rows) =>

                                                Swal.fire({
                                                    icon: 'warning',
                                                    title:"Confirmation",
                                                    text: rows.length === 1?"Confirmer la suppression de l'option sélectionnée ?":"Confirmer la suppression des options sélectionnées ?",
                                                    showConfirmButton: true,
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Annuler',
                                                    dangerMode: true,
                                                })
                                                    .then(result => {

                                                        if (result.isConfirmed) {

                                                            var ids = rows.map(s => s.id);
                                                            let idsToString = ids.toString();

                                                            axios.delete('/admin/options?ids=' + idsToString)
                                                                .then(res => {

                                                                    fetchOptions();
                                                                    if (rows.length == 1) {
                                                                        var message = "L'option a bien été annulée"
                                                                    } else {
                                                                        var message = "Les options ont bien été supprimées"
                                                                    }
                                                                    Swal.fire({
                                                                        title: "Fait!",
                                                                        text: message,
                                                                        icon: "success",
                                                                        timer: 2000,
                                                                        button: false
                                                                    })
                                                                })
                                                                .catch(function (error) {

                                                                        if (error.response.status === 401) {
                                                                            Swal.fire({
                                                                                icon: 'warning',
                                                                                title: 'Vous n\'êtes pas autorisé à supprimer cette option',
                                                                                showConfirmButton: false,
                                                                                timer: 1500
                                                                            })
                                                                        }
                                                                    }
                                                                );
                                                        }
                                                    })
                                        }
                                        ,
                                        {
                                            icon: () => <Icon icon="material-symbols:in-home-mode-outline"/>,
                                            tooltip: "Confirmer l'option",
                                            hidden: user.role==='ROLE_PROMOTEUR' || user.role==='ROLE_PRESCRIPTEUR' || user.role==='ROLE_ADMIN'?false:true,
                                            onClick: (event, rows) =>

                                                Swal.fire({
                                                    icon: 'warning',
                                                    title: "Confirmation",
                                                    text: "Souhaitez-vous confirmer cette option en tant que réservation ?",
                                                    showConfirmButton: true,
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Annuler',
                                                    dangerMode: true,
                                                })
                                                    .then(result => {

                                                        if (result.isConfirmed) {

                                                            var ids = rows.map(s => s.id);
                                                            var codeClients = rows.map(s => s.codeClient);

                                                            axios.get(`/admin/options/` + ids[0])
                                                                .then(function (response) {

                                                                    var option = response.data;

                                                                    if (!option.permission) {
                                                                        Swal.fire({
                                                                            icon: 'warning',
                                                                            title: "Désolé, le lot n'est plus disponible à la vente.",
                                                                            showConfirmButton: false,
                                                                            timer: 5000
                                                                        })
                                                                    } else {
                                                                        navigate({
                                                                            pathname: '/admin/reservation/ajouter',
                                                                            search: '?option=' + ids[0]+'&&codeclient='+codeClients[0],
                                                                        });
                                                                    }

                                                                })
                                                                .catch(function (error) {

                                                                    Swal.fire({
                                                                        icon: 'error',
                                                                        title: "Une erreur s'est produite!",
                                                                        showConfirmButton: false,
                                                                        timer: 5000
                                                                    })
                                                                })


                                                        } else {
                                                            // Swal.fire('Votre contact est préservée', '', 'info')
                                                        }
                                                    })
                                        }


                                    ]}
                                    tableRef={tableRef}
                                    onSearchChange={() => {

                                        setRowCount('Liste option (' + tableRef.current.state.data.length + ')');
                                    }}
                                    data={data}
                                    title={rowCount}

                                />
                        </ScrollWrapper>
                            {data.length > 0 && (
                                <Pagination
                                    dataCount={dataCount}  // Total des données
                                    page={page}  // Page actuelle
                                    rowsPerPage={rowsPerPage}  // Nombre de lignes par page
                                    onPageChange={(newPage) => setPage(newPage)}  // Changer la page
                                    onRowsPerPageChange={(newRowsPerPage) => {
                                        setRowsPerPage(newRowsPerPage);
                                        setPage(0);  // Réinitialiser à la première page quand le nombre de lignes change
                                    }}
                                    ActionsComponent={TablePaginationActions}  // Composant personnalisé de pagination
                                    TablePaginationNullActions={TablePaginationNullActions}  // Composant sans actions
                                />
                            )}



                        </div>
                    </div>
                </div>
            </section>


        </Layout>
    );
}

export default OptionList;