import React, {useState, useEffect,useRef} from 'react';
import { TablePagination, Box } from '@mui/material';

const Pagination = ({ dataCount, page, rowsPerPage, onPageChange, onRowsPerPageChange, rowsPerPageOptions = [10, 25,50], ActionsComponent, TablePaginationNullActions }) => {

    const handlePageChange = (event, newPage) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        onPageChange(newPage);  // Notifier le parent que la page a changé
    };


    const handleRowsPerPageChange = (event) => {

        const newRowsPerPage = parseInt(event.target.value);
        onRowsPerPageChange(newRowsPerPage);  // Notifier le parent du changement du nombre de lignes
    };


    return (
        <div style={{display: 'flex', justifyContent: 'center' }}>
            <TablePagination
                component="div"
                count={dataCount}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage=""
                labelDisplayedRows={() => ''}
                rowsPerPageOptions={rowsPerPageOptions} // Options de lignes par page
                ActionsComponent={ActionsComponent}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TablePagination
                    component="div"
                    count={dataCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    labelRowsPerPage="Lignes par page"
                    rowsPerPageOptions={rowsPerPageOptions}
                    showFirstButton={false}
                    showLastButton={false}
                    ActionsComponent={TablePaginationNullActions}
                />
            </Box>
        </div>
    );
};

export default Pagination;
