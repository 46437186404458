import {FieldArray, Formik, Form} from "formik";
import {FormContext} from "../OptionAddEdit";
import * as yup from "yup";
import React, {useState, useEffect, useContext} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from "../component/TabContent";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {checkEmailExists} from "../../../service/ContactService";

function Buyer({user}) {
    let navigate = useNavigate();
    const {
        activeStepIndex,
        setActiveStepIndex,
        formData,
        setFormData,
        buyers,
        setBuyers,
        sideBarTab,
        setSideBarTab
    } = useContext(FormContext);
    var [key, setKey] = useState('0');

    useEffect(() => {

    }, [key])

    const title = (index) => {

        var title = index == 0 ? 'Prospect' : 'Coacquéreur ' + index;

        return title;
    }

    const ValidationSchema = yup.object().shape({

        buyers: yup.array()
            .of(
                yup.object().shape({
                    // address: yup.string().required("L\'adresse est requise").nullable(),
                    prenom: yup.string().required("Le prénom est requis").nullable(),
                    nom: yup.string().required("Le nom est requis").nullable(),
                    // cellphone: yup.string().required("Un numéro de téléphone portable est requis").nullable(),
                    // phone: yup.string().required("Un numéro de téléphone fixe est requis").nullable(),
                    // zip: yup.string().required("Un code postal est requis").nullable(),
                    // city: yup.string().required("Une ville est requise").nullable(),
                    email: yup.string()
                        .email("Adresse email invalide")
                        .required("L'adresse email est requise")
                    .test('is-linked', "Un contact existe déjà sous cette adresse email, veuillez le sélectionner dans la liste suggérée", async function (value) {
                        if (!value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return true; // Ne pas valider si le champ est vide

                        const { id } = this.parent;
                        const exists = await checkEmailExists(value) && !id;

                        return !exists; // Retourne `true` si l'email n'existe pas, sinon `false`
                        }
                    )
                })
            )
    });


    // async function checkEmailExists(email) {
    //     try {
    //         const response = await axios.get(`/admin/contacts/check-email?email=${encodeURIComponent(email)}`);
    //
    //         return response.data.exists; // suppose que l'API renvoie `{ exists: true }` si l'email existe
    //     } catch (error) {
    //         // Gérez les erreurs ici si nécessaire
    //         console.error("Erreur lors de la vérification de l'email:", error);
    //         return false; // on retourne false en cas d'erreur pour éviter de bloquer la validation
    //     }
    // }

    const ValidationSchemaClient = yup.object().shape({

        buyers: yup.array()
            .of(
                yup.object().shape({
                    address: yup.string().required("L\'adresse est requise").nullable(),
                    prenom: yup.string().required("Le prénom est requis").nullable(),
                    nom: yup.string().required("Le nom est requis").nullable(),
                    cellphone: yup.string().required("Un numéro de téléphone portable est requis").nullable(),
                    profession: yup.string().required("Une profession est requise").nullable(),
                    // phone: yup.string().required("Un numéro de téléphone fixe est requis").nullable(),
                    zip: yup.string().required("Un code postal est requis").nullable(),
                    city: yup.string().required("Une ville est requise").nullable(),
                    birthDate: yup.string().required("Une date de naissance est requise").nullable(),
                    email: yup.string()
                        .email("Adresse email invalide")
                        .required("L'adresse email est requise")
                        .test('is-linked', "Un contact existe déjà sous cette adresse email, veuillez le sélectionner dans la liste suggérée", async function (value) {
                                if (!value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return true; // Ne pas valider si le champ est vide

                                const { id } = this.parent;
                                const exists = await checkEmailExists(value) && !id;

                                return !exists; // Retourne `true` si l'email n'existe pas, sinon `false`
                            }
                        )
                    ,
                })
            )
    });

    function handleSelect(key) {
        setKey(key)
    }

    return (
        <Formik
            initialValues={{
                buyers: buyers,
                lots: []
            }}
            validateOnChange={false} // Disable validation every field change
            validateOnBlur={false} // Disable validation every field blur
            validationSchema={user.role==='ROLE_ACQUEREUR' || user.role==='ROLE_PROSPECT' ?ValidationSchemaClient :ValidationSchema}
            onSubmit={(values) => {

                setBuyers(values.buyers);
                const data = {...formData, ...values};
                const new_obj = {
                    ...sideBarTab,
                    email: data.buyers[0].email,
                    nom: data.buyers[0].nom,
                    prenom: data.buyers[0].prenom,
                    cellphone: data.buyers[0].cellphone,
                    societe: data.buyers[0].societe
                }
                setSideBarTab(new_obj)
                setFormData(data);
                setActiveStepIndex(activeStepIndex + 1);
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  isValid,
                  isSubmitting,
                  touched,
                  setFieldValue,
                  handleBlur, // handler for onBlur event of form elements
              }) => {

                useEffect(() => {
                    setFieldValue('buyers',buyers)
                }, [buyers])


                return (

                    <Form className="flex flex-col justify-center items-center mt-4" noValidate onSubmit={handleSubmit}>

                        <FieldArray name="buyers">
                            {({insert, remove, push}) => (
                                <div>
                                    <div className={"d-flex align-items-start justify-content-between my-4"}>
                                        <div className={'ms-2'}><h6>Détails du prospect</h6>
                                            <small>
                                                Entrez les détails de votre prospect.</small>
                                        </div>
                                        <div>


                                        </div>
                                    </div>
                                    <Tabs
                                        defaultActiveKey="0"
                                        activeKey={key}
                                        className="mb-3"
                                        onSelect={handleSelect}
                                    >
                                        {Array.isArray(values.buyers) && values.buyers.length > 0 ?
                                            values.buyers.map((buyer, index) => (

                                                <Tab
                                                    key={index}
                                                    eventKey={index}
                                                    title={title(index)}>

                                                    {
                                                        <TabContent isValid={isValid} buyer={buyer} index={index}
                                                                    handleChange={handleChange} title={title(index)}
                                                                    setKey={setKey} setFieldValue={setFieldValue}
                                                                    values={values} errors={errors} touched={touched}
                                                                    handleBlur={handleBlur}/>
                                                    }
                                                </Tab>
                                            ))
                                            : null}

                                    </Tabs>
                                </div>
                            )}
                        </FieldArray>

                        <div className="actions clearfix">
                            <Button variant="outline-secondary"  className={"my-2 p-2 me-2"} onClick={() => {
                                navigate(`/admin/lots/list`)
                            }}>Annuler</Button>
                            <Button  type="submit" variant={'warning'} className={"bg-warning my-2 p-2 me-2 text-white"}>Suivant</Button>

                        </div>
                    </Form>

                )
            }}

        </Formik>

    );
}

export default Buyer;