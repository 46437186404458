import {Formik} from "formik";
import React, {useContext,useEffect} from "react";
import Form from "react-bootstrap/Form";
import {FormContext} from "../OptionAddEdit";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from 'react-bootstrap/Spinner';
import {useNavigate} from "react-router-dom";
import Button from 'react-bootstrap/Button';

function Validation() {

    let navigate = useNavigate();
    const {
        activeStepIndex,
        setActiveStepIndex,
        formData,
        setFormData,
        dataFiles,
        codeClient
    } = useContext(FormContext);

    useEffect(() => {
        setFormData(formData);
    }, []);
    const ValidationSchema = yup.object().shape({
        lots: yup.array()
            .min(1, "Veuillez sélectionner au moins un lot")
    });

    const postOption = (data) => {
        console.log(data)
        axios.post(`/admin/options?nrPromoteur=${codeClient}`, {data})
            .then(function (response) {

                Swal.fire({
                    icon: 'success',
                    title: 'Option créée avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                })

                navigate('/admin/option/liste')
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    return (

        <div>


            <div className={'ms-2 mt-4 mb-1'}><h6>Validation</h6>
                <small>
                    Veuillez cliquer sur confirmer afin de valider l'option</small>
            </div>


            <section>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12 mt-4">


                            <Formik
                                initialValues={{}}
                                validationSchema={ValidationSchema}
                                onSubmit={(values) => {

                                    const data = {...formData, ...values};
                                    delete data.nbrJrVldOpt;
                                    postOption(data)
                                }}
                            >
                                {({
                                      handleSubmit,
                                      handleChange,
                                      values,
                                      errors,
                                      isValid,
                                      isSubmitting,
                                      touched,
                                      setFieldValue,
                                      handleBlur, // handler for onBlur event of form elements
                                  }) => {
                                    return (
                                        <Form className="flex flex-col justify-center items-center" noValidate
                                              onSubmit={handleSubmit}>
                                            
                                            <div className="actions clearfix">
                                                <Button variant="outline-secondary"  className={"my-2 p-2 me-2"} onClick={() => {
                                                    navigate(`/admin/lots/list`)
                                                }}>Annuler</Button>
                                                <Button variant={'warning'} className={"bg-orange-500 my-2 p-2 me-2 text-dallas"}  onClick={() => {
                                                    setActiveStepIndex(activeStepIndex - 1)
                                                }}>Précédent</Button>
                                                <Button  disabled={!isValid || isSubmitting} variant={'warning'} className={"bg-warning my-2 p-2 me-2 text-white"} type={"submit"}>      {isSubmitting ?  <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="visually-hidden">Loading...</span>
                                                </Button> : 'Confirmer'}</Button>

                                            </div>





                                        </Form>)
                                }}
                            </Formik>


                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
}

export default Validation;