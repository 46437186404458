import axios from 'axios';

class MailchimpService {
    // Fonction pour effectuer une requête POST
    static async postData() {
        try {
            const response = await axios.post('URL_DE_VOTRE_API', { /* Données à envoyer */ });
            return response.data;
        } catch (error) {
            throw new Error('Erreur lors de la requête POST');
        }
    }

    // Fonction pour effectuer une requête PATCH
    static async patchContact(parameters) {
        try {

           const response = await axios.patch(`/mailchimp/update-contact/${parameters.id}`, parameters);
         return response.data;
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors de la requête PATCH');
        }
    }


    // Fonction pour effectuer une requête PATCH
    static async listTemplate() {
        try {

            const response = await axios.get(`/mailchimp/list/template`);

            return response.data;
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors de la requête');
        }
    }


    static async createCampaign(parameters) {
        try {

             const response = await axios.post(`/mailchimp/campaign`,parameters);

            return response.data;
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors de la requête');
        }
    }


    static async updateCampaign(parameters,id) {
        try {

            const response = await axios.patch(`/mailchimp/campaign/${id}`,parameters);

            return response.data;
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors de la requête');
        }
    }


    // Fonction pour effectuer une requête PATCH
    static async getCampaign(id) {
        try {

            const response = await axios.get(`/mailchimp/campaigns/${id}`);
            return response.data;
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors de la requête');
        }
    }


    // Fonction pour effectuer une requête PATCH
    static async sendCampaign(id) {
        try {

            const response = await axios.post(`/mailchimp/campaigns/${id}/send`);

            return response;
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors de la requête');
        }
    }


    // Fonction pour effectuer une requête DELETE
    static async deleteContact(parameters) {
        try {

            const response = await axios.patch(`/mailchimp/delete-contact/${parameters.email}`);
            return response.data;
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors de la requête PATCH');
        }
    }
}

export default MailchimpService;
