import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import Layout from "../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import MaterialTable, {Column} from "@material-table/core";
import {Icon} from '@iconify/react';
function TaskList() {

    const [taskList, setTaskList] = useState([])
    const [rowCount, setRowCount] = useState('Liste des tâches')
    const [filter, setFilter] = useState(false)
    let navigate = useNavigate();

    const handleChange = () => {

        setFilter(!filter)
    }

    const handleDeleteRows = (event, rowData) => {

        let _data = [...taskList];

        rowData.forEach(rd => {

            _data = _data.filter(row => row.id !== rd.id);
        });

        rowData.forEach(rd => {

            _data = _data.filter(row => row.id !== rd.id);
        });

        setTaskList(_data);
    };

    useEffect(() => {
        getAllTasks();
    }, [])

    const tableRef = React.useRef();

    const getAllTasks = () => {
        axios.get(`/task`)
            .then(function (response) {
                const responseData = response.data;
                if(responseData){
                    setTaskList(response.data);
                    setRowCount('Liste des tâches (' + response.data.length + ')')
                }
            })
            .catch(function (error) {

                if(error.response.status='401'){

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }

    const getDateValueForRow = date => {
        var elem='';

        if (date) {
            var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
                <time>{date}</time>
            </div>;
        }
        return elem;
    };

    return (
        <Layout>
            <section className="content-header p-3">
                <div className="container-fluid">
                    <div className="sticky-wrapper mt-4">
                        <div className="navbar navbar-default p-1" role="navigation">
                            <div className="navbar-header">
                                <a href="#"><h1>
                                    Tâche
                                </h1></a></div>
                            <div className="hidden-xs d-flex">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item">
                                    <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/tableau-de-bord`)}}></span></li>
                                    <li>Liste des tâches</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">

                    <div className="row">
            <div className="col-md-12">
                <div style={{maxWidth: '100%'}}>
                    <MaterialTable
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} of {count}',
                                labelRowsSelect: 'lignes'
                            },
                            toolbar: {
                                nRowsSelected: '{0} tâche(s) sélectionnée(s)',
                                searchPlaceholder: 'Chercher'
                            },
                            body: {
                                emptyDataSourceMessage: 'Aucune tâche à afficher',
                                filterRow: {
                                    filterTooltip: 'Filter'
                                }
                            }
                        }}
                        options={{
                            selection: true,
                            pageSize: 10,
                            filtering: filter
                        }}
                        columns={[
                            {title: 'Libellé', field: 'name', filtering: false},
                            {title: 'Date de début', field: 'startDate', filtering: false,render: rowData => getDateValueForRow(rowData.startDate)},
                            {title: "Date d'échéance", field: 'dueDate', type: 'date', filtering: false,render: rowData => getDateValueForRow(rowData.dueDate)},
                            {
                                title: 'Priorité',
                                field: 'priority',
                                lookup: {'Faible': 'Faible', 'Moyenne': 'Moyenne', 'Haute': 'Haute'}
                            },
                            {
                                title: 'Statut',
                                field: 'status',
                                lookup: {
                                    'Ouvert': 'Ouvert',
                                    'En cours': 'En cours',
                                    'En attente': 'En attente',
                                    'Terminé': 'Terminé'
                                }
                            },
                            {title: 'Type', field: 'type', filtering: false}

                        ]}
                        onRowClick={(event, rowData) => {
                            // Get your id from rowData and use with link.
                            navigate(`/admin/tache/${rowData.id}/modifier`)
                            event.stopPropagation();
                        }}
                        tableRef={tableRef}
                        onSearchChange={() => {

                            setRowCount('Liste tâches (' + tableRef.current.state.data.length + ')');
                        }}
                        data={taskList}
                        title={rowCount}
                        actions={[
                            {
                                icon: () => <Icon icon="material-symbols:filter-list"/>,
                                tooltip: 'Afficher le filtre',
                                isFreeAction: true,
                                onClick: (event) => {
                                    handleChange()
                                }
                            },

                            {
                                icon: () => <Icon icon="material-symbols-light:add-box-outline"/>,
                                tooltip: 'Ajouter',
                                onClick: (event, rowData) => navigate("/admin/tache/ajouter"),
                                isFreeAction: true
                            },
                            {
                                icon: () => <Icon icon="material-symbols:delete-outline"/>,
                                tooltip: "Supprimer toutes les tâches sélectionnées",
                                onClick: (event, rows) =>

                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'Confirmer la suppression des tâches sélectionnées ?',
                                        showConfirmButton: true,
                                        showCancelButton: true,
                                        cancelButtonText: 'Annuler',
                                        dangerMode: true,
                                    })
                                        .then(result => {

                                            if (result.isConfirmed) {

                                                handleDeleteRows(event, rows);
                                                var ids = rows.map(s => s.id);
                                                let idsToString = ids.toString();

                                                if (rows.length == 1) {
                                                    var message = "La tâche a bien été supprimée"
                                                } else {
                                                    var message = "Les tâches ont bien été supprimées"
                                                }

                                                axios.delete('/tasks?ids=' + idsToString)
                                                    .then(res => {

                                                        Swal.fire({
                                                            title: "Fait!",
                                                            text: message,
                                                            icon: "success",
                                                            timer: 2000,
                                                            button: false
                                                        })
                                                    })
                                                    .catch(function (error) {

                                                            if (error.response.status === 401) {
                                                                Swal.fire({
                                                                    icon: 'warning',
                                                                    title: 'Vous n\'êtes pas autorisé à supprimer cette tâche',
                                                                    showConfirmButton: false,
                                                                    timer: 1500
                                                                })
                                                            }
                                                    }
                                                    );
                                            } else {
                                                Swal.fire('Votre tâche est préservée', '', 'info')
                                            }
                                        })
                            }
                        ]
                        }
                    />
                </div>
            </div>
                    </div>

                </div>
            </section>
        </Layout>
    );
}

export default TaskList;