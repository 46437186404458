import {useParams, useNavigate} from 'react-router-dom';
import {Formik, Form} from 'formik';
import {array, object, date, string, number} from 'yup' ;
import * as Yup from "yup";
import {MultipleFileUploadField} from "../component/MultipleFileUploadField";
import React, {useState, useEffect, useRef} from 'react';
import Layout from "../component/Layout"
import Swal from 'sweetalert2'
import axios from 'axios';
import {LocalizationProvider, MobileDateTimePicker} from "@mui/lab";
import frLocale from "date-fns/locale/fr";
import TextField from "@mui/material/TextField";
import {formatDate} from '@fullcalendar/core'
import DateFnsAdapter from "@mui/lab/AdapterDateFns";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import {Editor} from '@tinymce/tinymce-react';
import CustomSelect from '../component/CustomSelect'
import Button from 'react-bootstrap/Button';


function convertFormatDate(date) {

    return formatDate(date, {

        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: '2-digit',
        minute: '2-digit',
        locale: 'fr'
    });
}


function TaskAddEdit({history, match}) {

    let navigate = useNavigate();
    const [id, setId] = useState(useParams().id)
    const [files, setFiles] = useState([])
    const [medias, setMedias] = useState('')
    const [dueDate, setDueDate] = useState(new Date());
    const [lastDueDate, setLastDueDate] = useState(new Date())
    const [statusChoice, setStatusChoice] = useState('')
    const [typeChoice, setTypeChoice] = useState('')
    const [name, setName] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const [isToggled, setIsToogled] = useState(false);
    const isAddMode = !id;
    const editorRef = useRef(null);
    var tmpDate = new Date();

    const validationSchema = Yup.object().shape({
        name: string()
            .required('Le nom de la tâche est requis'),
        startDate: date('Start Date is Required')
            .typeError("Start date is required")
            .required('Start Date is required'),
        dueDate: date('')
            .typeError("")
            .when("startDate",
                (started, yup) => started && yup.min(started, "La date d'échéance ne peut pas être antérieure à la date de début"))
            .required('End Date is required'),
    });

    const initialReminderOptions = [
        {value: 0, label: '30 minutes avant',isDisabled:false},
        {value: 1, label: '1 heure avant',isDisabled:false},
        {value: 2, label: '1 jour avant',isDisabled:false},
        {value: 3, label: '1 semaine avant',isDisabled:false},
        {value: 4, label: 'Date personnalisée',isDisabled:false}
    ];

    const [data, setData] = useState(initialReminderOptions);


    const updateReminderOptions = (diff) => {

        setData(initialReminderOptions);
        var disabledElems=[];

        switch (true) {
            case (diff<1800000):
                disabledElems.push(0,1,2,3)
                break;
            case (diff<3600000):
                disabledElems.push(1,2,3)
                break;
            case  (diff<86400000):
                disabledElems.push(2,3)
                break;
            case (diff<604800000 ):
                disabledElems.push(3)
                break;
        }

        const newReminderOptions = data.map(obj => {

            // 👇️ if id equals 2 replace object
            if (disabledElems.indexOf(obj.value)!== -1) {
                return  {value: obj.value, label: obj.label,isDisabled:true};
            }
            else{
                return  {value: obj.value, label: obj.label,isDisabled:false};
            }

            // 👇️ otherwise return object as is
            return obj;
        });

        setData(newReminderOptions);
    };

    const priorityOptions = [
        {value: 0, label: 'Faible'},
        {value: 1, label: 'Moyenne'},
        {value: 2, label: 'Haute'},
    ]

    const statusOptions = [
        {value: 0, label: 'Ouvert'},
        {value: 1, label: 'En cours'},
        {value: 2, label: 'En attente'},
        {value: 3, label: 'Terminé'},
    ]
    const [assignedOptions, setAssignedOptions] = useState([]);

    const typeOptions = [
        {value: 0, label: 'Appel'},
        {value: 1, label: 'Email'},
        {value: 2, label: 'A faire'},
        {value: 3, label: 'Rencontre'},
    ]

    const ref = useRef(null);


    const getAssignables = () => {
        axios.get(`/assignables/`)
            .then(function (response) {
                var assignables = response.data;
                setAssignedOptions(assignables);

            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }


    function handleTimeChange(setFieldValue, value, dueDate,startDate) {

        setFieldValue('reminder', value, false);
        var date = new Date(dueDate);
        var startDate = new Date(startDate);
        var inputValue = value;
        var diff=date.getTime()-startDate.getTime();

        updateReminderOptions(diff);

        switch (inputValue) {
            case 0:
                date.setMinutes(date.getMinutes() - 30)
                setLastDueDate(date)
                break;
            case 1:
                date.setHours(date.getHours() - 1)
                setLastDueDate(date)
                break;
            case 2:
                date.setDate(date.getDate() - 1)
                setLastDueDate(date)
                break;
            case 3:
                date.setDate(date.getDate() - 7)
                setLastDueDate(date)
                break;
        }


        if (value == 4) {

            if (lastDueDate) {
                setFieldValue('timeTrigger', lastDueDate);
            } else {
                setFieldValue('timeTrigger', date);
            }

            setIsToogled(!isToggled)
        } else {
            setIsToogled(false)
        }
    };

    const dateReset = () => {
        setIsToogled(false)
    }

    function onSubmit(values, actions) {

        const fields = {...values};
        fields.startDate = convertFormatDate(fields.startDate);
        fields.dueDate = convertFormatDate(fields.dueDate);
        fields.timeTrigger = convertFormatDate(fields.timeTrigger);

        if (isAddMode) {

            createTask(fields);

        } else {
            updateTask(id, fields);
        }
        actions.setSubmitting(false);
    }

    function createTask(fields) {

        axios.post(`/task`, fields)
            .then(function (response) {

                let task = response.data;
                setId(task.id);
                navigate("/admin/tache/" + task.id + "/modifier")
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
                setIsSaving(true)
            });

    }

    function updateTask(id, fields) {

        axios.patch(`/admin/task/${id}`, fields)
            .then(function (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Tâche mise à jour avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                })

                navigate('/admin/tache/liste')

            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }


    var dDate=new Date(tmpDate.setDate(tmpDate.getDate() + 7));
    const initialValues = {
        startDate: new Date(),
        dueDate: dDate,
        timeTrigger: '',
        status: 0,
        priority: 0,
        name: '',
        type: '',
        assigned: '',
        reminder: ''
    };

    const getUserInfo = (setFieldValue) => {

        axios.get(`/user-info/`)
            .then(function (response) {

                var user = response.data;
                setFieldValue('assigned', user.id, false)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    return (
        <Layout>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="sticky-wrapper mt-4 ">
                        <nav className="navbar navbar-default" role="navigation">
                            <div className="navbar-header">
                                <a href="#"><h1>
                                    Tâche
                                </h1></a></div>
                            <div className="hidden-xs d-flex">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item">
                                    <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/tableau-de-bord`)}}></span></li>
                                    <li className={"link-primary cursor-pointer"} onClick={() => {
                                        navigate('/admin/tache/liste')
                                    }}
                                    >Liste des tâches
                                    </li>
                                    {isAddMode ? '' : <li className="active"><span>{name}</span></li>}
                                </ol>
                            </div>

                        </nav>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}
                        >
                            {({
                                  errors, touched, isSubmitting, setFieldValue, values, handleChange, isValid,
                                  dirty
                              }) => {

                                useEffect(() => {

                                    if (!isAddMode) {
                                        axios.get(`/admin/task/${id}`)
                                            .then(function (response) {

                                                let task = response.data;
                                                const allMedias = task.attachments;
                                                setMedias(allMedias);
                                                setAssignedOptions(task.contacts);
                                                const fields = ['startDate', 'dueDate', 'status', 'priority', 'name', 'assigned', 'timeTrigger', 'type', 'reminder','message'];
                                                fields.forEach(field =>
                                                    setFieldValue(field, task[field], false)
                                                );

                                                setName(task.name);

                                                if (task.type) {
                                                    setTypeChoice(task.type)
                                                }

                                                if (task.reminder) {
                                                    setTypeChoice(task.reminder)
                                                }

                                                setStatusChoice(task.status)

                                                if (task.reminder==4) {

                                                    setIsToogled(true);
                                                }

                                            })
                                            .catch(function (error) {

                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Une erreur s\'est produite!',
                                                    showConfirmButton: false,
                                                    timer: 1500
                                                })
                                            })
                                    }

                                    getAssignables();

                                    if (isAddMode) {
                                        getUserInfo(setFieldValue);
                                    }

                                    // getAllMedias();



                                }, [id]);


                                return (
                                    <div className="box box-primary col-md-8 px-4">
                                    <Form>

                                            <div>
                                                <div className="my-4 d-flex justify-content-between">
                                                    <div className="card-title align-items-start flex-column">
                                                        <h3 className="card-label font-weight-bolder text-dark">
                                                            <strong>  {isAddMode ? 'Ajouter une tâche' : 'Modifier une tâche'}</strong>
                                                        </h3>
                                                        <span
                                                            className="text-muted font-weight-bold font-size-sm mt-1">Organisez vos tâches</span>
                                                    </div>
                                                    <div className="card-toolbar">

                                                        <div className="well well-small form-actions">
                                                            <button
                                                                disabled={!isValid || isSubmitting}
                                                                type="submit"
                                                                className="btn btn-success">
                                                                <i className="fa fa-save me-2" aria-hidden="true"></i>
                                                                {isSubmitting ?
                                                                    "S'il vous plaît, attendez..."  : "Valider"}

                                                            </button>


                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="row mt-4">
                                                        <div className={'col-md-12'}>
                                                            <div className={'col-md-6'}>
                                                                <label className={"control-label required"}>
                                                                    Libellé</label>
                                                                <TextField
                                                                    helperText={errors?.name ? errors.name : null}
                                                                    type="text"
                                                                    className={'form-control' + (errors.name ? ' is-invalid' : '')}
                                                                    name={"name"}
                                                                    onChange={handleChange}
                                                                    value={values.name}
                                                                    ref={ref}
                                                                    variant="outlined"
                                                                    error={
                                                                        !!errors?.name
                                                                    }
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3 mt-4"
                                                         style={{display: 'flex', alignItems: 'center'}}>

                                                            <LocalizationProvider dateFormats={{
                                                                shortDate: "d MMMM"
                                                            }} dateAdapter={DateFnsAdapter} locale={frLocale}>

                                                                <div className="col-md-3 w10-elem">
                                                                    <MobileDateTimePicker
                                                                        clearable
                                                                        // helperText="Date de début"
                                                                        ampm={false}
                                                                        name="startDate"
                                                                        format="d/MM/yyyy HH:mm"
                                                                        onChange={(value) => {
                                                                            setFieldValue('startDate', value);
                                                                        }}
                                                                        value={values.startDate}
                                                                        label="Date de début"
                                                                        clearText="vider"
                                                                        cancelText="annuler"
                                                                        okText="ok"
                                                                        renderInput={props =>
                                                                            <TextField
                                                                                name="startDate"
                                                                                variant="outlined"
                                                                                id={"startDate"}
                                                                                {...props}
                                                                            />}
                                                                    />
                                                                </div>
                                                                <div className="col-md-3 w10-elem">
                                                                    <MobileDateTimePicker
                                                                        clearable
                                                                        helperText="Date d'échéance"
                                                                        ampm={false}
                                                                        format="d/MM/yyyy HH:mm"
                                                                        value={values.dueDate}
                                                                        onChange={(value) => {
                                                                            setFieldValue('dueDate', value);
                                                                            setDueDate(value);
                                                                            handleTimeChange(setFieldValue, values.reminder, value,values.startDate);
                                                                        }}
                                                                        label="Date d'échéance"
                                                                        clearText="vider"
                                                                        cancelText="annuler"
                                                                        okText="ok"
                                                                        renderInput={props =>

                                                                            <TextField
                                                                                helperText={errors?.dueDate ? errors.dueDate : null}
                                                                                variant="outlined"
                                                                                name="dueDate"
                                                                                id={"dueDate"}
                                                                                {...props}
                                                                                error={
                                                                                    touched?.dueDate &&
                                                                                    !!errors?.dueDate
                                                                                }
                                                                            />}
                                                                    />
                                                                </div>

                                                                {isToggled ? '' : <div className="col-md-3">
                                                                    <CustomSelect
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({
                                                                                ...base,
                                                                                zIndex: 9999,
                                                                                flex: 1
                                                                            })
                                                                        }}
                                                                        placeholder={<div>pas de rappel</div>}
                                                                        isOptionDisabled={(option) =>
                                                                            option.value === "3"
                                                                        }
                                                                        options={data}
                                                                        value={values.reminder}
                                                                        onChange={value => handleTimeChange(setFieldValue, value.value, values.dueDate,values.startDate)}

                                                                    />
                                                                    {errors.reminder ? (
                                                                        <div className="error">{errors.reminder}</div>
                                                                    ) : null}
                                                                </div>}

                                                                {isToggled ? <div className="col-md-3 w10-elem"
                                                                                  style={{display: "flex"}}>
                                                                    <MobileDateTimePicker

                                                                        clearable
                                                                        helperText="Rappel"
                                                                        ampm={false}
                                                                        format="d/MM/yyyy HH:mm"
                                                                        value={values.timeTrigger}
                                                                        onChange={(value) => {
                                                                            setFieldValue('timeTrigger', value);
                                                                        }}
                                                                        name={"timeTrigger"}
                                                                        label="Rappel"
                                                                        clearText="vider"
                                                                        cancelText="annuler"
                                                                        okText="ok"
                                                                        error={
                                                                            touched?.timeTrigger &&
                                                                            !!errors?.timeTrigger
                                                                        }
                                                                        renderInput={props =>
                                                                            <TextField
                                                                                helperText={errors?.timeTrigger ? errors.timeTrigger : null}
                                                                                name="timeTrigger"
                                                                                variant="outlined"
                                                                                id={"timeTrigger"}
                                                                                {...props}
                                                                                error={
                                                                                    touched?.timeTrigger &&
                                                                                    !!errors?.timeTrigger
                                                                                }

                                                                            />}
                                                                    />
                                                                    <Button
                                                                        onClick={dateReset}
                                                                        style={{marginLeft: "auto"}}>x</Button>
                                                                </div> : ''}
                                                            </LocalizationProvider>

                                                    </div>
                                                    <div className="row mt-4">

                                                            <div className="col-md-3 ">
                                                                <label id="aria-label" htmlFor="aria-example-input">
                                                                    Priorité
                                                                </label>
                                                                <CustomSelect
                                                                    name={"priority"}
                                                                    onChange={value => setFieldValue('priority', value.value)}
                                                                    value={values.priority}
                                                                    options={priorityOptions}
                                                                    placeholder={<div>Aucune</div>}
                                                                />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label id="aria-label" htmlFor="aria-example-input">
                                                                    Statut
                                                                </label>
                                                                <CustomSelect
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({
                                                                            ...base,
                                                                            zIndex: 9999,
                                                                            flex: 1
                                                                        })
                                                                    }}
                                                                    name={"status"}
                                                                    placeholder={<div>Aucun</div>}
                                                                    onChange={value => setFieldValue('status', value.value)}
                                                                    value={values.status}
                                                                    options={statusOptions}
                                                                />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label id="aria-label" htmlFor="aria-example-input">
                                                                    Affecté à
                                                                </label>
                                                                <CustomSelect
                                                                    className='input'
                                                                    onChange={value => setFieldValue('assigned', value.value)}
                                                                    value={values.assigned}
                                                                    options={assignedOptions}
                                                                />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label id="aria-label" htmlFor="aria-example-input">
                                                                    Type
                                                                </label>
                                                                <CustomSelect
                                                                    onChange={value => setFieldValue('type', value.value)}
                                                                    value={values.type}
                                                                    options={typeOptions}
                                                                />
                                                            </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 my-5">
                                                            <Editor
                                                                apiKey='b4gtx3qcm9yj0zi2xvm7ysp9l3xhba3vh9b5gy4f5l1qi5ka'
                                                                onInit={(evt, editor) => editorRef.current = editor}
                                                                value={values.message}
                                                                onEditorChange={(newValue, editor) => {
                                                                    // setMessage(newValue);
                                                                    setFieldValue('message', newValue,false)
                                                                    // setText(editor.getContent({format: 'text'}));
                                                                }}
                                                                init={{
                                                                    height: 500,
                                                                    menubar: false,
                                                                    toolbar: 'undo redo | formatselect | ' +
                                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                        'removeformat | help',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    </Form>  </div>
                                );
                            }}
                        </Formik>
                        <div className="col-md-4">
                        <div id="attachments-sidebar">
                            <div className="box box-primary">
                                <Card>
                                    <CardContent>
                                        <Formik

                                            initialValues={{files}}
                                            validationSchema={object({
                                                files: array(
                                                    object({
                                                        url: string().required(),
                                                    })
                                                ),
                                            })}
                                            onSubmit={(values) => {

                                                return new Promise((res) => setTimeout(res, 2000));
                                            }}
                                        >
                                            {({values, errors, isValid, isSubmitting}) => (
                                                <Form>
                                                    <Grid container spacing={2} direction="column">
                                                        <MultipleFileUploadField isAddMode={isAddMode} idTask={id} medias={medias}
                                                                                 name="files"/>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
            </section>
        </Layout>

    );
}

export default TaskAddEdit;