import React, {useState, useEffect} from "react";
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Dashboard from "./Dashboard"
import AdfList from "./Adf/Adf";
import ContactList from "./Contact/ContactList";
import ContactView from "./Contact/ContactView";
import CollaboratorList from "./CollaboratorList";
import LotList from "./Lot/LotList";
import ProgramList from "./Programme/ProgramList";
import ReservationAddEdit from "./Reservation/ReservationAddEdit";
import ReservationList from "./Reservation/ReservationList";
import UserView from "./User/UserView";
import ProgramView from "./Programme/ProgramView";
import BuildContract from "./Reservation/BuildContract";
import TaskAddEdit from "./TaskAddEdit";
import TaskList from "./TaskList";
import ActionList from "./Action/ActionList";
import InvoiceList from "./Invoice/InvoiceList";
import MarketList from "./Market/MarketList";
import MenuSidebar from "../component/MenuSidebar";
import OptionList from "./Option/OptionList";
import MediaList from "./Media/MediaList";
import LotView from "./Lot/LotView";
import TmaList from "./Tma/TmaList";
import TopBar from "../component/TopBar";
import {fetchUserData} from "../component/fetchUserData";
import MediaView from "./Media/MediaView";
import ReserveList from "./Reserve/ReserveList";
import LogApiList from "./Log/LogApiList";
import LogView from "./Log/LogView";
import ReserveView from "./Reserve/ReserveView";
import OptionAddEdit from "./Option/OptionAddEdit";
import ReservationEdit from "./Reservation/ReservationEdit";
import MailList from "./Mail/MailList";
import MailView from "./Mail/MailView";
import Registration from "../frontend/Registration";
import {getProjectDir} from "../component/getPublicDir";
import CampaignList from "./Campaigns/CampaignList";
import MeetingList from "./Meeting/MeetingList";
import Confirmation from "../frontend/Confirmation";
import OptionEdit from "./Option/OptionEdit";


function App() {

    const toggleSize = () => {
        setVerticalCollapsed(!isFullSize?'':'vertical-collapsed sidebar-enable')
        setIsFullSize(!isFullSize);
    };
    const [isFullSize, setIsFullSize] = useState(true); // État local pour gérer l'affichage en taille pleine ou réduite
    const [user, setUser] = useState('')
    const [activeTab, setActiveTab] = useState('home');
    const [verticalCollapsed, setVerticalCollapsed] = useState('');

    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };
    const [isLoading, setIsLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);


    async function getUserData() {
        try {
            const userData = await fetchUserData();

            if(userData.authenticated){
                localStorage.setItem('token', userData.token);
                setUser(userData);
                setIsLoading(true);
                setIsAdmin(true);
            }
            else{
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
            // console.error('Utilisateur non identifié :', error);
            // Gérez l'erreur de manière appropriée
        }
    }


    useEffect(() => {
        getProjectDir();
        getUserData();

    }, []);

// Créez un composant qui enveloppera votre application
    const AppWrapper = ({children}) => {
        // Vous pouvez ajouter du code ici pour des fonctionnalités globales, par exemple la navigation, l'en-tête, le pied de page, etc.
        return (
            <div  className={verticalCollapsed} >
                <TopBar toggleSize={toggleSize} user={user} activeTab={activeTab} handleTabSelect={handleTabSelect} setVerticalCollapsed={setVerticalCollapsed}/>


                    {/* Autres composants globaux */}
                    <MenuSidebar isFullSize={isFullSize} user={user} />
                    <div className={"main-content"}>
                        <div className={"page-content"}>
                        {/* Routes sont également incluses ici */}
                        {children}
                        </div>
                    </div>

            </div>
        );
    };

    return (

//Si l'utilisateur est connecté ou non
        !isAdmin ? (
                <Router>
                    <Routes>
                        <Route path="/inscription" element={<Registration/>}/>
                        <Route path="/confirmation" element={<Confirmation/>}/>
                    </Routes>
                </Router>) :
            isLoading ? (
                <Router>

                    <AppWrapper>
                        <Routes>
                            <Route path="/admin/tableau-de-bord" element={<Dashboard user={user}/>}/>
                            <Route path="/admin/adf/list" element={<AdfList/>}/>
                            <Route path="/admin/tma/liste" element={<TmaList/>}/>
                            <Route path="/admin/contact/list" element={<ContactList user={user} isFullSize={isFullSize}/>}/>
                            <Route path="/inscription" element={<Registration/>}/>
                            <Route key={'id'} path="/admin/contacts/:id/voir" element={<ContactView user={user}/>}/>
                            <Route path="/admin/lots/list" element={<LotList user={user} isFullSize={isFullSize}/>}/>
                            <Route path="/admin/programmes/list" element={<ProgramList user={user} isFullSize={isFullSize}/>}/>
                            <Route key="program_view" path="/admin/programme/:id/voir" element={<ProgramView user={user} isFullSize={isFullSize}/>}/>
                            <Route path="/admin/lot/:id/voir" element={<LotView user={user}/>}/>
                            <Route path="/admin/reservation/list" element={<ReservationList user={user} isFullSize={isFullSize}/>}/>
                            <Route key={'all'} path="/admin/reservation/ajouter" element={<ReservationAddEdit user={user}/>}/>
                            <Route key={'single'} path="/admin/reservation/:id/modifier" element={<ReservationEdit user={user}/>}/>
                            <Route key={'single'} path="/admin/option/:id/modifier" element={<OptionEdit user={user}/>}/>
                            <Route path="/admin/reservation/contrat" element={<BuildContract/>}/>
                            <Route path="/admin/collaborateur/list" element={<CollaboratorList isFullSize={isFullSize}/>}/>
                            <Route path="/admin/profil/:id/voir"
                                   element={<UserView user={user} setUser={getUserData} activeTab={activeTab} handleTabSelect={handleTabSelect}/>}/>
                            <Route key={'single'} path="/admin/tache/:id/modifier" element={<TaskAddEdit/>}/>
                            <Route key={'all'} path="/admin/tache/ajouter" element={<TaskAddEdit/>}/>
                            <Route path="/admin/tache/liste" element={<TaskList/>}/>
                            <Route path="/admin/suivis/liste" element={<ActionList/>}/>
                            <Route path="/admin/facture/liste" element={<InvoiceList user={user}/>}/>
                            <Route path="/admin/reserve/liste" element={<ReserveList user={user}/>}/>
                            <Route path="/admin/reserve/:id/voir" element={<ReserveView/>}/>
                            <Route path="/admin/marche/liste" element={<MarketList user={user}/>}/>
                            <Route path="/admin/courriel/liste" element={<MailList user={user}/>}/>
                            <Route path="/admin/courriel/:id/voir" element={<MailView/>}/>
                            <Route path="/admin/option/liste" element={<OptionList user={user} isFullSize={isFullSize}/>}/>
                            <Route path="/admin/option/ajouter" element={<OptionAddEdit user={user}/>}/>
                            <Route path="/admin/log/api/liste" element={<LogApiList/>}/>
                            <Route path="/admin/log/api/:id/voir" element={<LogView/>}/>
                            <Route path="/admin/media/liste" element={<MediaList/>}/>
                            <Route path="/admin/reunion/liste" element={<MeetingList user={user}/>}/>
                            <Route path="/admin/campagnes/liste" element={<CampaignList user={user} isFullSize={isFullSize}/>}/>
                            <Route key={'single'} path="/admin/media/:id/voir" element={<MediaView/>}/>
                        </Routes>
                    </AppWrapper>
                </Router>) : null
    )
        ;
}

export default App;
const container = document.getElementById('mui-date-time-picker');
if (container) {
    const root = createRoot(container);
    root.render(<App/>);
}







