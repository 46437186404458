import React from "react";
import {MTableEditRow, MTableBody} from "@material-table/core";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { numberWithCommas } from "../../utils/formatters"; // adapte le chemin

export const getCustomComponents = (total, totalMntDG, reservationEdit=false) => ({
    Body: (props) => (
        <>
            <MTableBody {...props} />
            <TableBody>
           {reservationEdit ?     <TableRow className="bg-secondary">
               <TableCell size="medium">Total</TableCell>
               <TableCell> </TableCell>
               <TableCell >
               </TableCell>
               <TableCell align="right">

               </TableCell> <TableCell >
           </TableCell>
               <TableCell align="right">      {numberWithCommas(totalMntDG.toFixed(2))} </TableCell>
               <TableCell align="right">  {numberWithCommas(total.toFixed(2))}</TableCell>
           </TableRow>  :     <TableRow className="bg-secondary">
               <TableCell size="medium">Total</TableCell>
               <TableCell> </TableCell>
               <TableCell align="right">
                   {numberWithCommas(totalMntDG.toFixed(2))}
               </TableCell>
               <TableCell align="right">
                   {numberWithCommas(total.toFixed(2))}
               </TableCell>
               <TableCell> </TableCell>
           </TableRow>}
            </TableBody>
        </>
    ),
    EditRow: (props) => (
        <MTableEditRow
            {...props}
            onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    props.onEditingCanceled(props.mode, props.data);
                }
            }}
        />
    ),
});
