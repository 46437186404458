import React, {useEffect} from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import {Formik, ErrorMessage} from 'formik';
import * as yup from "yup";
import Button from "react-bootstrap/Button";

const ReservationContractCard = ({codeClient, idProgramme, templateReservation,setTemplateReservation,fetchProgramme}) => {

    const ValidationSchema = yup.object().shape({
        template: yup.mixed().required(''),
        // workspaceURL: yup.string().url().required(),
    });

    useEffect(() => {

    }, [templateReservation]);

    const handleDelete = (event) => {
        event.stopPropagation();
        console.log(templateReservation.id)
        const url = `/admin/medias/${templateReservation.id}`;
        axios.delete(url, {   params: {
                idProgramme: idProgramme,
            }})
            .then(function (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Votre modèle a bien été supprimé !',
                    showConfirmButton: false,
                    timer: 1500
                })

                setTemplateReservation('');
            })
            .catch(function (error) {

            });
    }

        return (

            <Card>
                <Card.Body className={"pb-5"}>
                    <h6 className={'MuiTypography-root MuiTypography-h6 css-1miy0lu-MuiTypography-root mb-4'}>Contrat de réservation</h6>
                    <Formik
                        initialValues={{
                            template: '',
                        }}
                        validationSchema={ValidationSchema}

                        onSubmit={(values, props) => {

                            let data = new FormData();
                            data.append(`template`, values.template);

                            axios
                                .post(`/admin/upload-template`, data, {
                                    params: {
                                        idProgramme: idProgramme,
                                        nrPromoteur: codeClient
                                    },
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                    },
                                })
                                .then((response) => {
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Votre modèle a été ajouté avec succès!',
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                    fetchProgramme()
                                })
                                .catch((err) => {

                                });
                        }}
                    >
                        {
                            (
                                {
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    errors,
                                    isValid,
                                    isSubmitting,
                                    touched,
                                    setFieldValue,
                                    handleBlur, // handler for onBlur event of form elements
                                }
                            ) => {

                                return (

                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Label>Fichier utilisé par défaut</Form.Label>

                                        {templateReservation ?
                                            <div className={'wrapper-input-file'}>
                                                <Form.Control
                                                    id="file"
                                                    name="template"
                                                    type="text"
                                                    value={templateReservation.name}
                                                    onChange={(event) => {

                                                        const file = event.target.files;
                                                        setFieldValue("template", file[0]);
                                                    }}
                                                />
                                                <Button onClick={handleDelete}
                                                        className="remove-media-button">Supprimer
                                                </Button>
                                            </div>
                                            :

                                            <div className={'wrapper-input-file'}>
                                                <Form.Control
                                                    id="file"
                                                    name="template"
                                                    type="file"
                                                    onChange={(event) => {
                                                        console.log(event.target.files[0])
                                                        const file = event.target.files;

                                                        setFieldValue("template", file[0]);
                                                    }}
                                                />
                                                <Button disabled={isSubmitting} type="submit"
                                                        className="btn remove-media-button">Valider
                                                </Button>
                                            </div>
                                        }


                                        <ErrorMessage name="template" component="div" className="error"/>
                                        {/*<Button disabled={isSubmitting} type="submit"  className="rounded-md font-medium text-white my-2 p-2 mt-4 bg-primary">Valider</Button>*/}
                                    </Form>

                                );
                            }}
                    </Formik>


                </Card.Body>
            </Card>


        )
    }

    export default ReservationContractCard;