// src/utils/familyUtils.js

//Le formulaire de réservation peut recevoir plusieurs cpntacts, index correspond à l'index du contact
export const onFamilySituationChange = (selection, setFieldValue, index) => {

    index!=null?setFieldValue(`buyers[${index}]familySituation`, selection):setFieldValue(`familySituation`, selection);

    if(index!=null){
        if (selection.value === 1 || selection.value === 2) {
            setFieldValue(`buyers[${index}]isFamilySituationToogled`, true);
        } else {
            setFieldValue(`buyers[${index}]isFamilySituationToogled`, false);
        }
    }

};
