import React, {useState, useEffect} from 'react';
import {ErrorMessage, FieldArray, Formik} from "formik";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Button, Form, ProgressBar} from 'react-bootstrap';
import CityAndZipAutocomplete from "./CityAutocomplete";
import axios from "axios";
import Select from "react-select";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import de la locale française
import ReCAPTCHA from 'react-google-recaptcha';
import SellerForm from "./SellerForm";
import ProspectForm from "./ProspectForm";
import InvestorForm from "./InvestorForm";
import FournisseurForm from "./FournisseurForm";
import DefaultForm from "./DefaultForm";
import PartnerForm from "./PartnerForm";

const params = new URLSearchParams();


const FormRedistributor = ({
                               values,
                               setFieldValue,
                               handleChange,
                               optSuggestions,
                               handleRecaptchaChange,
                               role,
                               handleSubmit,
                               setInitialValues,
                               setValidationSchema,isValid,isSubmitting,handleNextStep,validateForm,step,setStep,setTouched,setSelectedRole,selectedRole
                           }) => {


    const [countryList, setCountryList] = useState([])
    const [idsSelectionnes, setIdsSelectionnes] = useState([2, 3, 4]);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {

    }, [values]);

    const handlePrevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };


    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }


    useEffect(() => {
        getAllCountries();

    }, [])

    
    const handleOptionChange = (event) => {
        setFieldValue('role',event.target.value)
        setSelectedRole(event.target.value);
    };

    return (

        <div>

            {step === 1 && (

                <div>
                    <ProgressBar now={0} className="mb-4" style={{height: '10px'}}/>
                    <h5 className={'pb-4 bold'}>Quel est votre profil ?</h5>

                    <div className="mb-2">
                        <Form.Check
                            label="Je souhaite faire un investissement locatif"
                            value="ROLE_PROSPECT"
                            type="radio"
                            name="role"
                            checked={selectedRole === 'ROLE_PROSPECT'}
                            onChange={handleOptionChange}
                        /></div>
                    <div className="mb-2">
                        <Form.Check
                            label="Je souhaite être partenaire prescripteur"
                            value="ROLE_PRESCRIPTEUR"
                            type="radio"
                            name="role"
                            checked={selectedRole === 'ROLE_PRESCRIPTEUR'}
                            onChange={handleOptionChange}
                        />
                    </div>
                    <div className="mb-2">
                        <Form.Check
                            label="Je vends un bien immobilier"
                            value="ROLE_VENDEUR"
                            type="radio"
                            name="role"
                            checked={selectedRole === 'ROLE_VENDEUR'}
                            onChange={handleOptionChange}
                        />
                    </div>
                    <div className="mb-2">
                        <Form.Check
                            label="Je souhaite faire un devis pour mes prestations"
                            value="ROLE_CONSOMMATEUR"
                            type="radio"
                            name="role"
                            checked={selectedRole === 'ROLE_CONSOMMATEUR'}
                            onChange={handleOptionChange}
                        />
                    </div>
                    <div className="mb-2">
                        <Form.Check
                            label="Je souhaite devenir associé"
                            value="ROLE_PARTENAIRE"
                            type="radio"
                            name="role"
                            checked={selectedRole === 'ROLE_PARTENAIRE'}
                            onChange={handleOptionChange}
                        />
                    </div>
                    <div className="mb-2">
                        <Form.Check
                            label="Je souhaite investir dans le groupe"
                            value="ROLE_INVESTISSEUR"
                            type="radio"
                            name="role"
                            checked={selectedRole === 'ROLE_INVESTISSEUR'}
                            onChange={handleOptionChange}
                        />
                    </div>
                    <div className="mb-2">
                        <Form.Check
                            label="Je veux devenir partenaire artisan"
                            value="ROLE_FOURNISSEUR"
                            type="radio"
                            name="role"
                            checked={selectedRole === 'ROLE_FOURNISSEUR'}
                            onChange={handleOptionChange}
                        />
                    </div>
                    <div className={'d-flex justify-content-between'}>
                        <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1 w-100">
                            Précédent
                        </Button>
                        <Button variant="primary"
                                onClick={() => {

                                    handleNextStep(validateForm)
                                }}
                            // disabled={!mediaType}
                                className="mt-4 w-100">
                            Suivant
                        </Button>
                    </div>
                </div>
            )}

            {step === 2 && (
                <div>
                    {values.role === 'ROLE_VENDEUR' &&

                        <SellerForm
                            isValid={isValid}
                            setTouched={setTouched}
                            validateForm={validateForm}
                            handleNextStep={handleNextStep}
                            isSubmitting={isSubmitting}
                            setInitialValues={setInitialValues}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                        />
                    }

                    {values.role === 'ROLE_CONSOMMATEUR' && (

                        <ProspectForm
                            isValid={isValid}
                            setTouched={setTouched}
                            validateForm={validateForm}
                            handleNextStep={handleNextStep}
                            isSubmitting={isSubmitting}
                            setInitialValues={setInitialValues}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                        />
                    )}
                    {values.role === 'ROLE_INVESTISSEUR' && (

                        <InvestorForm
                            isValid={isValid}
                            setTouched={setTouched}
                            validateForm={validateForm}
                            handleNextStep={handleNextStep}
                            isSubmitting={isSubmitting}
                            setInitialValues={setInitialValues}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                        />
                    )}
                    {values.role === 'ROLE_PARTENAIRE' && (

                        <PartnerForm
                            isValid={isValid}
                            setTouched={setTouched}
                            validateForm={validateForm}
                            handleNextStep={handleNextStep}
                            isSubmitting={isSubmitting}
                            setInitialValues={setInitialValues}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                        />
                    )}
                    {values.role === 'ROLE_FOURNISSEUR' && (

                        <FournisseurForm
                            isValid={isValid}
                            setTouched={setTouched}
                            validateForm={validateForm}
                            handleNextStep={handleNextStep}
                            isSubmitting={isSubmitting}
                            setInitialValues={setInitialValues}
                            handleRecaptchaChange={handleRecaptchaChange}
                            optSuggestions={optSuggestions}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                        />
                    )}
                    {values.role === 'ROLE_PROSPECT' && (
                        <div>
                            <DefaultForm handleRecaptchaChange={handleRecaptchaChange} role={null}
                                         values={values} setFieldValue={setFieldValue}
                                         handleChange={handleChange}/>
                            <div className={'d-flex justify-content-between'}>
                                <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1 w-100">
                                    Précédent
                                </Button>
                                <Button variant="primary"
                                        type="submit"
                                    // onClick={handleNextStep}
                                    // disabled={!mediaType}
                                        className="mt-4 w-100">
                                    Envoyer
                                </Button></div>
                        </div>
                    )}

                    {values.role === 'ROLE_PRESCRIPTEUR' && (
                        <div>
                            <DefaultForm handleRecaptchaChange={handleRecaptchaChange} optSuggestions={optSuggestions}
                                         handleSubmit={handleSubmit} values={values} setFieldValue={setFieldValue}
                                         handleChange={handleChange}/>
                            <div className={'d-flex justify-content-between'}>
                                <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1 w-100">
                                    Précédent
                                </Button>
                                <Button variant="primary"
                                        type="submit"
                                    // disabled={!mediaType}
                                        className="mt-4 w-100">
                                    Envoyer
                                </Button></div>
                        </div>
                    )}

                </div>
            )}


        </div>
    )
}

export default FormRedistributor;