import React, {useState, useEffect} from 'react';
import {ErrorMessage} from "formik";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Button, Form, ProgressBar} from 'react-bootstrap';
import axios from "axios";
import 'dayjs/locale/fr'; // Import de la locale française
import {useNavigate} from "react-router-dom";
import FormValidation from "./FormValidation";

const PartnerForm = ({values,setFieldValue,handleChange,handleSubmit,optSuggestions,handleRecaptchaChange,role,setInitialValues,isValid,
                         isSubmitting,
                         step,
                         setStep,
                         validateForm,
                         handleNextStep,
                         setTouched}) => {


    const [countryList, setCountryList] = useState([])
    let navigate = useNavigate();

    const initialPartnerValues = {
        nom: '',
        prenom: '',
        email: '',
        phones: '',
        societyName: '',
        profilePartner: {
            motivation: '',
            businessIdea: '',
            contribution: '',
        },
        role: 'ROLE_PARTENAIRE',
    };


    useEffect(() => {
        if (!role) {
            navigate({
                search: `?role=partner`
            });
            setInitialValues(initialPartnerValues)
        }
    }, []);

    const handlePrevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };


    const getAllCountries = () => {
        axios.get(`/list-country`)
            .then(function (response) {

                setCountryList(response.data);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    useEffect(() => {
        getAllCountries();

    }, [])

    return (
        <div>
            <ProgressBar now={(step / 5) * 100} className="mb-4" style={{ height: '10px' }}/>
            {step === 2 && (
                <div className={'mb-3'}>
                    <h5 className={'pb-3 bold'}>Veuillez saisir vos coordonnés</h5>

                    <Row>
                        <Col>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.nom}
                                    name="nom"
                                    aria-label= "nom"
                                    placeholder={"Nom"}
                                    type="text"/>
                                <ErrorMessage name={`nom`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>
                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    name="prenom"
                                    placeholder={"Prénom"}
                                    aria-label= "prenom"
                                    value={values.prenom}
                                    type="text"/>
                                <ErrorMessage name={`prenom`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    aria-label= "email"
                                    placeholder={"Email"}
                                    type="text"/>
                                <ErrorMessage name={`email`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>
                        <Col xs={3}>

                            <Form.Group className="mb-3">

                                <Form.Control
                                    onChange={handleChange}
                                    value={values.phone}
                                    name="phones"
                                    aria-label= "phones"
                                    placeholder={"Téléphone"}
                                    type="text"/>
                                <ErrorMessage name={`phones`} component="div" className="error mt-1"/>
                            </Form.Group>


                        </Col>

                        <Col xs={3}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    onChange={handleChange}
                                    value={values.societyName}
                                    name="societyName"
                                    aria-label= "societyName"
                                    placeholder={"Entreprise"}
                                    type="text"/>
                                <ErrorMessage name={`societyName`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}
            {step === 3 && (
                <div>
                    <h5 className={'pb-3 bold'}>Pourquoi voulez-vous vous associer  ?</h5>

                    <Col>
                        <Form.Group className="mb-3">

                            <Form.Control
                                as="textarea"
                                rows={3}
                                onChange={handleChange}
                                name="profilePartner.motivation"
                                placeholder={"Tapez votre réponse ici..."}
                                aria-label= "profilePartner.motivation"
                                value={values.profilePartner.motivation}
                                type="text"/>
                            <ErrorMessage name={`profilePartner.motivation`} component="div" className="error mt-1"/>
                        </Form.Group>
                    </Col>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}

            {step === 4 && (
                <div>
                    <h5 className={'pb-3 bold'}>Qu’avez-vous à apporter dans cette association ?</h5>

                    <Row>

                        <Col>
                            <Form.Group className="mb-3">

                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={handleChange}
                                    name="profilePartner.contribution"
                                    placeholder={"Tapez votre réponse ici..."}
                                    aria-label= "profilePartner.contribution"
                                    value={values.profilePartner.contribution}
                                    type="text"/>
                                <ErrorMessage name={`profilePartner.contribution`} component="div" className="error mt-1"/>
                            </Form.Group>
                        </Col>

                    </Row>

                    <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                        Précédent
                    </Button>
                    <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                        Suivant
                    </Button>
                </div>
            )}
            {step === 5 && (
            <div>
                <h5 className={'pb-3 bold'}>Quelle est votre idée à faire fructifier ?</h5>

                <Row>
                    <Col>
                        <Form.Group className="mb-3">

                            <Form.Control
                                onChange={handleChange}
                                as="textarea"
                                rows={3}
                                name="profilePartner.businessIdea"
                                placeholder={"Tapez votre réponse ici..."}
                                aria-label= "profilePartner.businessIdea"
                                value={values.profilePartner.businessIdea}
                                type="text"/>
                            <ErrorMessage name={`profilePartner.businessIdea`} component="div" className="error mt-1"/>
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="secondary" onClick={handlePrevStep} className="mt-4 me-1">
                    Précédent
                </Button>
                <Button  disabled={!isValid || isSubmitting} variant="primary" onClick={() => {handleNextStep(validateForm,setTouched)}} className="mt-4">
                    Suivant
                </Button>
            </div>
        )}

            {step === 6 && (
                <FormValidation handleRecaptchaChange={handleRecaptchaChange} optSuggestions={optSuggestions}
                                handleSubmit={handleSubmit} values={values} setFieldValue={setFieldValue}
                                handleChange={handleChange}/>
            )}
        </div>
    )
}

export default PartnerForm;