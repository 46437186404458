import React, {useState, useEffect} from 'react';
import MaterialTable from "@material-table/core";
import {Typography} from '@mui/material';
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import handleLotRowUpdate from "./handleLotRowUpdate";
import { getCustomComponents } from '../../../component/table/customComponents';
import {addTotalToState} from "../../../utils/tableUtils"; // adapte le chemin

export default function DualTableSelection({setSelectedItems,selectedItems, lotList}) {

    const [show, setShow] = useState(false);
    const [total, setTotal] = useState(0)
    const [totalMntDG, setTotalMntDG] = useState(0)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [availableItems, setAvailableItems] = useState(lotList);
    const [leftSelection, setLeftSelection] = useState([]);
    const [rightSelection, setRightSelection] = useState([]);



    // Ajouter les éléments sélectionnés du tableau de gauche
    const handleAddSelected = () => {

        const newItems = leftSelection;

        setSelectedItems( newItems);
        setLeftSelection([]); // clear selection
        setShow(false)
    };

    useEffect(() => {
        addTotalToState({
            data: selectedItems,
            byColumn: 'salePrice',
            setTotal,
            setTotalMntDG,
        });
    }, []);

    return (
        <Row>
            <div >
                <Modal id={'available-lots-tab'} size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Lots disponibles</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                            <div className={'px-0'}>
                                <MaterialTable
                                    title=""
                                    columns={[
                                        {title: 'N° lot', field: 'nrLot'},
                                        {title: 'N° copropriété', field: 'nrCopro'},
                                        {title: 'Type de bien', field: 'typeProperty'},
                                        {title: 'TVA', field: 'TVA'},
                                        {title: 'Prix de vente TTC', field: 'salePrice'},
                                    ]}
                                    data={availableItems}
                                    options={{
                                        selection: true,
                                        search: false,
                                        toolbar: false,
                                        pageSize: 8,
                                        rowStyle: (rowData) => {
                                            const isSelected = rowData.tableData.checked ?? false;
                                            return isSelected
                                                ? {
                                                    color: '#0c0c0c',
                                                }
                                                : {
                                                    backgroundColor: 'white',
                                                    color: '#5b5656',
                                                };
                                        },
                                    }}
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: 'Aucune donnée à afficher',
                                            addTooltip: 'Ajouter',
                                            deleteTooltip: 'Supprimer',
                                            editTooltip: 'Modifier',
                                            filterRow: {
                                                filterTooltip: 'Filtrer',
                                            },
                                            editRow: {
                                                deleteText: 'Voulez-vous supprimer cette ligne ?',
                                                cancelTooltip: 'Annuler',
                                                saveTooltip: 'Enregistrer',
                                            },
                                            bulkEditTooltip: 'Modifier plusieurs lignes',
                                        },
                                        pagination: {
                                            labelRowsPerPage: 'Lignes par page',
                                            labelDisplayedRows: '{from}-{to} sur {count}',
                                            firstTooltip: 'Première page',
                                            previousTooltip: 'Page précédente',
                                            nextTooltip: 'Page suivante',
                                            lastTooltip: 'Dernière page',
                                        },
                                        toolbar: {
                                            searchTooltip: 'Rechercher',
                                            searchPlaceholder: 'Recherche',
                                            nRowsSelected: '{0} ligne(s) sélectionnée(s)', // C’est ici qu’on traduit "rows"
                                        },
                                    }}
                                    onSelectionChange={(rows) => setLeftSelection(rows)}
                                />

                            </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Fermer
                        </Button>
                        <Button variant="primary" onClick={handleAddSelected}>
                            Valider
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            {/* Tableau de gauche - éléments disponibles */}
            <Col md={12}>
                <Card id={'lots-card'}>
                    {/* Tableau de droite - éléments sélectionnés */}
                    <Card.Body className={'px-0'}>


                        <div className="d-flex justify-content-between align-items-center">
                            <Typography variant="h6" gutterBottom className={'ps-3'}>
                                Lots sélectionnés
                            </Typography>
                            <Button
                                className={'me-2 mb-2'}
                                variant="primary"
                                onClick={handleShow}

                            >
                                Modifier les lots sélectionnés
                            </Button></div>

                        <MaterialTable
                            title=""
                            columns={[
                                {title: 'N° lot', field: 'nrLot',editable:false},
                                {title: 'N° copropriété', field: 'nrCopro',editable:false},
                                {title: 'Type de bien', field: 'typeProperty',editable:false},
                                {title: 'TVA', field: 'TVA'},
                                {title: 'Prix de vente TTC', field: 'salePrice'},
                            ]}
                            data={selectedItems}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'Aucune donnée à afficher',
                                    addTooltip: 'Ajouter',
                                    deleteTooltip: 'Supprimer',
                                    editTooltip: 'Modifier',
                                    filterRow: {
                                        filterTooltip: 'Filtrer',
                                    },
                                    editRow: {
                                        deleteText: 'Voulez-vous supprimer cette ligne ?',
                                        cancelTooltip: 'Annuler',
                                        saveTooltip: 'Enregistrer',
                                    },
                                    bulkEditTooltip: 'Modifier plusieurs lignes',
                                },
                                pagination: {
                                    labelRowsPerPage: 'Lignes par page',
                                    labelDisplayedRows: '{from}-{to} sur {count}',
                                    firstTooltip: 'Première page',
                                    previousTooltip: 'Page précédente',
                                    nextTooltip: 'Page suivante',
                                    lastTooltip: 'Dernière page',
                                },
                                toolbar: {
                                    searchTooltip: 'Rechercher',
                                    searchPlaceholder: 'Recherche',
                                    nRowsSelected: '{0} ligne(s) sélectionnée(s)', // C’est ici qu’on traduit "rows"
                                },
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) => {
                                    return handleLotRowUpdate({
                                        newData,
                                        oldData,
                                        lotList:selectedItems,
                                        setLotList:setSelectedItems,
                                    }).then(() => {
                                        addTotalToState({
                                            data: values.lots,
                                            byColumn: 'salePrice',
                                            setTotal,
                                            setTotalMntDG,
                                        });

                                        addTotalToState({
                                            data: values.lots,
                                            byColumn: 'mntDG',
                                            setTotal,
                                            setTotalGlobal,
                                            setTotalMntDG,
                                            dataTab,
                                            setDataTab,
                                            values
                                        });

                                    });
                                }


                            }}
                            components={getCustomComponents(total, totalMntDG,true)}
                            options={{
                                search: false,
                                toolbar: false,
                                paging: false,
                            }}
                            onSelectionChange={(rows) => setRightSelection(rows)}
                        />

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
