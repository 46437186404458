import React, {useState, useEffect} from 'react';
import MaterialTable from "@material-table/core";
import {Typography} from '@mui/material';
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';



export default function handleLotRowUpdate({
                                               newData,
                                               oldData,
                                               lotList,
                                               sideBarTab,
                                               setLotList,
                                               setSideBarTab,
                                               setFieldValue,
                                               addTotal
                                           }) {
    return new Promise((resolve) => {
        setTimeout(() => {
            const dataUpdate = [...lotList];
            const index = oldData.tableData.index;

            if (newData.salePrice != oldData.salePrice) {
                const dataSideBarUpdate = { ...sideBarTab };

                if (newData.mntDG === oldData.mntDG) {
                    const multiply = newData.pctDG * 0.01;
                    const newMntDG = convertToFloat(newData.salePrice) * multiply;
                    newData.mntDG = numberWithCommas(newMntDG.toFixed(2));
                }

                let salePrice = convertToFloat(newData.salePrice);
                salePrice = numberWithCommas(salePrice.toFixed(2));
                newData.salePrice = salePrice;

                dataSideBarUpdate.lots = dataSideBarUpdate.lots.map(lot =>
                    lot.id === newData.id ? { ...lot, salePrice } : lot
                );

                setSideBarTab(dataSideBarUpdate);
            }

            if (newData.mntDG != oldData.mntDG) {
                const mntDG = convertToFloat(newData.mntDG);
                newData.mntDG = numberWithCommas(mntDG.toFixed(2));
            }

            dataUpdate[index] = newData;
            const valuesUpdate = dataUpdate.map(({ tableData, ...rest }) => rest);

            setFieldValue('lots', valuesUpdate);
            setLotList([...dataUpdate]);
            addTotal(dataUpdate, 'salePrice');
            addTotal(dataUpdate, 'mntDG');

            resolve();
        }, 1000);
    });
}
