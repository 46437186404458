
import React, {useState, useEffect} from 'react';
import MaterialTable from "@material-table/core";
import {dateValueForRow} from "../../../component/DateValueForRow";
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import {GetLinkValueForRow} from "../../../component/GetLinkValueForRow";

const ReserveTable  =({user,programmeId}) =>{

    const [reserveList, setReserveList] = useState([])
    let navigate = useNavigate();
    const [filter, setFilter] = useState(false)
    const [hideColumn, setHideColumn] = useState(user.role==='ROLE_PROMOTEUR'?false:true)
    const [hideProgrammeColumn, setHideProgrammeColumn] = useState(programmeId?true:false)
    const [rowCount, setRowCount] = useState('Consultation des réserves')

    useEffect(() => {

        getAllReserves();
    }, [])

    const getReserveLinkValueForRow = id => {
        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {navigate(`/admin/reserve/${id}/voir`)}}>{id}</span>;

        return elem;

    };

    const getAllReserves = () => {
        var url=programmeId ? `/admin/reserves?idprogramme=${programmeId}`  : `/admin/reserves`;
        axios.get(url)
            .then(function (response) {

                if (response.data) {
                    setReserveList(response.data);

                }
            })
            .catch(function (error) {

                if(error.response.status='401'){

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data,
                        showConfirmButton: false,
                        timer: 1500
                    })

                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: "Une erreur s'est produite!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
    }

    const tableRef = React.useRef();



    return(

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div style={{maxWidth: '100%'}}>

                            <MaterialTable
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                        labelRowsSelect: 'lignes'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} réserve(s) sélectionnée(s)',
                                        searchPlaceholder: 'Chercher'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Aucune réserve à afficher',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                options={{
                                    pageSize: 25,
                                    selection:false,
                                    filtering: filter,
                                    addRowPosition: 'last', // Ajouter les nouvelles lignes en bas du tableau
                                }}


                                columns={[
                                    {
                                        title: 'Id',
                                        field: 'id',
                                        align: "center",
                                        width: "5%",
                                       render: rowData => getReserveLinkValueForRow(rowData.id)
                                    },
                                    {
                                        title: 'Date',
                                        field: 'date',
                                        align: "right",
                                        width: "10%",
                                        render: rowData => dateValueForRow(rowData.date)
                                    },

                                    {
                                        title: 'N° réserve',
                                        field: 'nrReserve',
                                        align: "center",
                                    },
                                    {
                                        title: 'Libellé',
                                        field: 'libelle',
                                        align: "left",
                                    },
                                    {
                                        title: 'Programme',
                                        field: 'programme',
                                        align: "left",
                                        render: rowData => GetLinkValueForRow(rowData,'programme'),
                                        hidden: hideProgrammeColumn
                                    },
                                    {
                                        title: 'Localisation',
                                        field: 'localisation',
                                        align: "left",
                                    },
                                    {
                                        title: 'Lot',
                                        field: 'lot',
                                        align: "left",
                                        render: rowData => GetLinkValueForRow(rowData,'lot')
                                    },

                                    {
                                        title: 'Fournisseur',
                                        field: 'contact',
                                        render: rowData => GetLinkValueForRow(rowData,'contacts'),
                                        filtering: false,
                                        hidden: hideColumn
                                    },


                                ]}

                                tableRef={tableRef}
                                onSearchChange={() => {

                                    setRowCount('Liste lot (' + tableRef.current.state.data.length + ')');
                                }}
                                data={reserveList}
                                title={rowCount}

                            />
                        </div>
                    </div>
                </div>
            </div>

    )
}

export default ReserveTable;