// LotTable.js
import React, {useContext, useEffect, useState} from "react";
import {indentChildren} from "../../../component/indentChildren";
import {handleLotSelectChange} from "../../../component/handleLotSelectChange";
import MaterialTable from "@material-table/core";

export default function LotTable({
                                     columns = [],
                                     title = '',
                                     pageSize = 5,
                                     setFilteredData = null,
                                     filteredData,
                                     setSelectedLots = null,
                                     setFieldValue = null,

                                 }) {
    useEffect(() => {
        console.log(filteredData)
    }, []);

    return (
        <MaterialTable
            title={title}
            data={filteredData}
            columns={
                columns.length > 0
                    ? columns
                    : [
                        {
                            title: 'N° Lot',
                            field: 'nrLot',
                            filtering: false,
                        },
                        {title: 'Tranche', field: 'tranche'},
                        {title: 'Grille', field: 'grille'},
                        {
                            title: 'Prix de vente',
                            field: 'salePrice',
                            align: 'right',
                            filtering: false,
                        },
                    ]
            }
            options={{
                showSelectAllCheckbox: false,
                selection: true,
                paging: false,
                toolbar: true,
            }}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} sur {count}',
                    labelRowsSelect: 'lignes',
                },
                toolbar: {
                    nRowsSelected: '{0} lot(s) sélectionné(s)',
                    searchPlaceholder: 'Chercher',
                },
                body: {
                    emptyDataSourceMessage: 'Aucun lot à afficher',
                    filterRow: {
                        filterTooltip: 'Filtrer',
                    },
                },
            }}
            parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
            //Permet de sélectionner / déselectionner les lots en paquet
            onSelectionChange={(rows, row) => handleLotSelectChange(rows, row, setSelectedLots, setFieldValue, setFilteredData, filteredData)}
        />
    );
}

