import React, {useState, useEffect} from 'react';
import Layout from "../../component/Layout";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TimelineTab from "../../component/TimelineTab";
import MediaTab from "../../component/MediaTab";
import Table from 'react-bootstrap/Table';
import ContactAddEdit from "./ContactAddEdit";
import ReservationTab from "../../component/ReservationTab";
import OptionTab from "../../component/OptionTab";
import ActionAddEdit from "../Action/ActionAddEdit";
import AddOptionButton from "../../component/AddOptionButton";

function ContactView({user,history, match}) {

    const [id, setId] = useState(useParams().id)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [society, setSociety] = useState('');
    const [typeOfPerson, setTypeOfPerson] = useState('');
    const [civility, setCivility] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [contactUser, setContactUser] = useState('');
    const [country, setCountry] = useState('');
    const [role, setRole] = useState('');
    const [nbrBooked, setNbrBooked] = useState('');
    const [nbrOptions, setNbrOptions] = useState('');
    const [show, setShow] = useState(false);
    const [showAction, setShowAction] = useState(false);
    const handleShow = () => setShow(true);
    const [mediaList, setMediaList] = useState([])
    let navigate = useNavigate();
    const [timeline, setTimeline] = useState([])

    useEffect(() => {
        getContact();
        getMedias();
        getActivity();
    }, [])


    useEffect(() => {

    }, [showAction])


    const getContact = () => {

        axios.get(`/admin/contacts/${id}`)
            .then(function (response) {

                var contact = response.data;
                setTypeOfPerson(contact.typeOfPerson)
                setName(contact.name);
                setEmail(contact.email);
                setAddress(contact.address);
                setSociety(contact.society);
                setCivility(contact.civilite.label);
                setCity(contact.city);
                setPhone(contact.phone);
                setCountry(contact.country);
                setRole(contact.role)
                setContactUser(contact.user)
                setNbrBooked(contact.nbrBooked)
                setNbrOptions(contact.nbrOptions)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getMedias = () => {

        axios.get(`/admin/contacts/${id}/medias`)
            .then(function (response) {

                setMediaList(response.data)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }




    const getActivity = () => {

        axios.get(`/admin/contacts/${id}/timeline`)
            .then(function (response) {

                var timeline = response.data;
                setTimeline(timeline)

            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }





    const renderTabsSwitch = (role) => {
        switch (role) {
            case 'Prescripteur':
                return <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                ><Tab eventKey="home" title="Réservation">
                    <ReservationTab/>
                </Tab>
                    <Tab eventKey="option" title="Option">
                        <OptionTab/>
                    </Tab>
                    <Tab eventKey="media" title="Média">
                        <MediaTab medias={mediaList}/>
                    </Tab>
                </Tabs>
                    ;
            default:
                return <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Activité">
                        <TimelineTab setShowAction={setShowAction} timeline={timeline}/>
                    </Tab>
                    <Tab eventKey="media" title="Média">
                        <MediaTab medias={mediaList}/>
                    </Tab>
                </Tabs>;
        }
    }

    return (<Layout>
            <section className={'content-header'}>
                <div className="container-fluid">
                    <div className="p-3">
                        <div className="sticky-wrapper mt-4">

                            <div className="navbar navbar-default p-1 d-flex">
                                <div>
                                    <h1>Contact</h1>
                                    <ol className="breadcrumb justify-content-end">
                                        <li className="breadcrumb-item">
                                            <span className={"link-primary fas fa-home"} style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      navigate(`/admin/tableau-de-bord`)
                                                  }}></span>
                                        </li>
                                                                    <li className={"link-primary"} onClick={() => {
                                                                        navigate('/admin/contact/list')
                                                                    }}
                                                                    >
                                                                        <span className={"px-1 cursor-pointer"}>Liste des contacts</span>
                                                                    </li>
                                        <li className="breadcrumb-item active ">Contact</li>
                                    </ol>
                                </div>
                                <div>
                                    <AddOptionButton user={contactUser}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <Container fluid>
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={5}>
                            <Card>
                                <Card.Body className={'px-4'}>
                                    <div className={'d-flex align-items-center flex-column'}>
                                        <Image src='/assets/img/blank.png' fluid width={'110'} className="my-4"/>
                                        <h5 className={"mb-2"}> {society && society.nom ? society.nom  : name}</h5>
                                        <span>
                            <Badge className={"bg-label-secondary"} bg={'secondary'}>{role}</Badge>
                        </span>

                                    </div>
                                    <div className="d-flex justify-content-around flex-wrap my-4 py-3">
                                        <div className="d-flex align-items-start me-4 mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded">
                          <svg className="" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg"
                               viewBox="0 0 24 24"><path fill="#5a8dee"
                                                         d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"></path></svg>
                                </span>
                                            <div>
                                                <h5 className="mb-0">{nbrOptions}</h5>
                                                <span>Options</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-start mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded">
                                 <svg className="" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"><path fill="#5a8dee"
                                                                d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39ZM13.54,9.59l-2.69,2.7-.89-.9a1,1,0,0,0-1.42,1.42l1.6,1.6a1,1,0,0,0,1.42,0L15,11a1,1,0,0,0-1.42-1.42Z"></path></svg>
                                </span>
                                            <div>
                                                <h5 className="mb-0">{nbrBooked}</h5>
                                                <span>Réservations</span>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className={"pb-2 border-bottom mb-4"}>Détails</h5>
                                    <div className={"info-container mb-4"}>


                                        <Table responsive="xl" borderless>
                                            <tbody>
                                            <tr>
                                                <td className={"font-weight-bold"}>Civilité</td>
                                                <td>{civility}</td>

                                            </tr>
                                            <tr>
                                                <td className={"font-weight-bold"}>Email</td>
                                                <td>{email}</td>

                                            </tr>
                                            <tr>

                                                <td className={"font-weight-bold"}>Adresse</td>
                                                <td>{address}</td>

                                            </tr>
                                            <tr>

                                                <td className={"font-weight-bold"}>Ville</td>
                                                <td>{city}</td>

                                            </tr>
                                            <tr>

                                                <td className={"font-weight-bold"}>Téléphone</td>
                                                <td>{phone}</td>

                                            </tr>
                                            <tr>
                                                <td className={"font-weight-bold"}>Pays</td>
                                                <td>{country}</td>

                                            </tr>
                                            </tbody>
                                        </Table>


                                    </div>
                                    <div className={'d-flex justify-content-center pt-3'}>
                                        <Button onClick={handleShow} variant="primary" disabled={user.role==='ROLE_FOURNISSEUR'?true:false}>Modifier</Button></div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12" xl="7">
                            <Card>
                                <Card.Body>
                                    <h2 className={'pt-2'}>Transactions</h2>
                                    {renderTabsSwitch(role)}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            <ContactAddEdit show={show} setShow={setShow} getContact={getContact} user={user}/>
            <ActionAddEdit showAction={showAction} setShowAction={setShowAction} isAddMode={true} contact={{label:name,value:id}} getActivity={getActivity} getAllActions={''}/>
        </Layout>
    )

}

export default ContactView;