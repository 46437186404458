import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import ListGroup from "react-bootstrap/ListGroup";
import Downshift from 'downshift'
import {Formik} from "formik";
import * as Yup from "yup";
import * as yup from "yup";
import {Editor} from '@tinymce/tinymce-react';
import {MultipleFileUpload} from "../../../component/MultipleFileUpload";


function EmailComposer({show, setShow, mailSuggestions,initialValues}) {

    // const [show, setShow] = useState(false);
    const zipToString = item => (item ? item : '')
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dataFiles, setDataFiles] = useState([]);
    const [previousFiles, setPreviousFiles] = useState([]);
    const [isAddMode, setIsAddMode] = useState([]);
    const [medias, setMedias] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [message, setMessage] = useState('');
    const formikRef = React.useRef(null); // Créez une référence au composant Formik

    useEffect(() => {
        setMessage(initialValues.message)
        if (formikRef.current) {

            formikRef.current.resetForm(); // Utilisez resetForm pour réinitialiser le formulaire
        }
    }, [initialValues]);

    // Créez une fonction qui lit un fichier avec FileReader et renvoie une promesse
    function readFileAsync(file) {
        const filename = file.name;
        return new Promise((resolve, reject) => {

            const reader = new FileReader();
            reader.onload = (event) => {

                const base64String = event.target.result.split(',')[1]; // Extrait la partie Base64 du résultat

                const file = {
                    content: base64String,
                    filename: filename,
                    encoding: 'base64'
                }
                resolve(file); // Renvoie le résultat de la lecture du fichier
            };
            reader.readAsDataURL(file); // Commence la lecture du fichier en tant que données URL (Base64)
        });
    }

    const sendMail = (values, actions) => {

        const fields = {...values};
        const attachmentList = [];

        if (fields.medias.length > 0) {
            const medias = fields.medias;

            medias.map((media, index) => {
                const reader = new FileReader();

                reader.onload = (event) => {
                    const base64String = event.target.result.split(',')[1]; // Extrait la partie Base64 du résultat
                    attachmentList.push({
                        content: base64String,
                        filename: media.file.name,
                        encoding: 'base64'

                    });
                    setAttachments(attachmentList)
                };

                reader.readAsDataURL(media.file); // Commence la lecture du fichier en tant que données URL (Base64)
            })

// Créez un tableau de promesses pour lire les fichiers
            const fileReadPromises = medias.map((media) => readFileAsync(media.file));

// Utilisez Promise.all pour attendre la fin de toutes les lectures de fichiers
            Promise.all(fileReadPromises)
                .then((results) => {
                    // Code pour envoyer l'e-mail ici
                    // Une fois que l'e-mail est envoyé, fermez la fenêtre modale
                    const messageUrl = 'http://www.t1.viteimmo.com:3000/apo/mail/send'
                    fields.attachments=results;
                    delete fields.medias;

                    axios.post(messageUrl, {params: fields})
                        .then((response) => {

                            const data = response.data;
                        })
                        .catch((error) => {
                            console.error('Erreur lors de la récupération des détails du message :', error);
                        });
                    handleClose();


                    // Vous pouvez maintenant traiter les données, par exemple, les afficher ou les manipuler.
                })
                .catch((error) => {
                    console.error('Erreur lors de la lecture des fichiers :', error);
                });

        }



    };

    const validationSchema = Yup.object().shape({
        receiver: yup.string()
            .required("Un destinataire est requis"),

    });





    return (
        <Modal

            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Nouveau message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={sendMail}
                    innerRef={formikRef} // Associez la référence au composant Formik
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          isSubmitting,
                          touched,
                          setFieldValue,
                          handleBlur, // handler for onBlur event of form elements
                      }) => {

                        return (
                            <Form noValidate onSubmit={handleSubmit}>


                                <Downshift itemToString={zipToString}
                                           onInputValueChange={(inputValue) => {
                                               mailSuggestions.filter(element => element.includes(inputValue));
                                           }}
                                           onChange={(selection) =>
                                               setFieldValue('receiver', selection)
                                           }>
                                    {({
                                          getLabelProps,
                                          getInputProps,
                                          getMenuProps,
                                          getItemProps,
                                          getToggleButtonProps,
                                          clearSelection,
                                          highlightedIndex,
                                          selectedItem,
                                          isOpen,
                                          inputValue,
                                      }) => (
                                        <div className="dropdown-zip">

                                            <Form.Control {...getInputProps({
                                                name: `receiver`,
                                                value: values.receiver,
                                                className: 'mb-3',
                                                onChange: handleChange,
                                            })} />
                                            <div className={"downshift-menu-list"}>
                                                {isOpen && mailSuggestions.length > 0
                                                    ? mailSuggestions.map((item, index) => (

                                                        <ListGroup.Item
                                                            className={"downshift-option"}
                                                            {...getItemProps({
                                                                item,
                                                                key: item,
                                                                style: {
                                                                    backgroundColor:
                                                                        index === highlightedIndex ? '#DEEBFF' : null,
                                                                },
                                                            })}
                                                        >
                                                            {item}
                                                        </ListGroup.Item>
                                                    ))
                                                    : null}</div>
                                        </div>
                                    )}
                                </Downshift>
                                <Form.Control className={'mb-3'}
                                              name={'subject'}
                                              placeholder="Sujet"
                                              onChange={handleChange}
                                              type="text"

                                />
                                <Editor
                                    onEditorChange={(content) => setFieldValue('message', content)}
                                    onChange={handleChange}
                                    apiKey='b4gtx3qcm9yj0zi2xvm7ysp9l3xhba3vh9b5gy4f5l1qi5ka'
                                    init={{
                                        menubar: false, // Désactiver la barre de menus
                                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                        toolbar: 'undo redo bold italic | blocks fontfamily fontsize | underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                    }}
                                    initialValue=""
                                    value={message}
                                />
                                <MultipleFileUpload dataFiles={dataFiles} setDataFiles={setDataFiles}
                                                    name="files" isAddMode={isAddMode}
                                                    setIsAddMode={setIsAddMode} medias={medias}
                                                    previousFiles={previousFiles}
                                                    setFieldValue={setFieldValue}/>
                                <Modal.Footer>
                                    <Button onClick={handleClose} variant="secondary">Fermer</Button>
                                    <Button disabled={isSubmitting} type="submit">Envoyer</Button> </Modal.Footer>
                            </Form>
                        )
                    }}

                </Formik> </Modal.Body>


        </Modal>
    );
}

export default EmailComposer;