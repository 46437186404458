import * as Yup from 'yup';
import axios from "axios";

class ValidationManager {
    constructor() {
        this.schemas = {
            ROLE_VENDEUR: {
                size: 8,
                1: Yup.object().shape({}),
                2: Yup.object().shape({
                    nom: Yup.string()
                        .required('Un nom est requis'),
                    prenom: Yup.string()
                        .required('Un prénom est requis'),
                    phones: Yup.string().required('Ce champ est requis, nous vous appellerons pour organiser une visite'),
                    email: Yup.string().email('Email invalide').required('Ce champ est requis'),
                }),
                3: Yup.object().shape({

                    property: Yup.object().shape({
                        type: Yup.array().min(1, 'Please select at least one option').required('Oops! Veuillez faire une sélection'),
                    })

                }),
                4: Yup.object().shape({
                    city: Yup.string()
                        .required('Veuillez remplir ce champ'),
                    zip: Yup.string()
                        .required('Veuillez remplir ce champ'),
                    property: Yup.object().shape({
                        address: Yup.string()
                            .required('Veuillez remplir ce champ'),

                    }),
                }),
                5: Yup.object().shape({}),
                6: Yup.object().shape({}),
                7: Yup.object().shape({
                    property: Yup.object().shape({
                        totalSurface: Yup.number()
                            .required('Ce champ est obligatoire')
                            .typeError('La valeur doit être un nombre'),
                    }),
                }),
                8: Yup.object().shape({}),
            },
            ROLE_PROSPECT: {
                size: 2,
                1: Yup.object().shape({}),
                2: Yup.object().shape({
                    nom: Yup.string()
                        .required('Un nom est requis'),
                    prenom: Yup.string()
                        .required('Un prénom est requis'),
                    phones: Yup.string()
                        .required('Un numéro de téléphone est requis'),
                    city: Yup.string()
                        .required('Un nom de ville est requis'),
                    zip: Yup.string()
                        .required('Un code postal est requis'),
                    email: Yup.string()
                        .email('Format de l\'adresse email invalide')
                        .test('uniqueEmail', 'Cette adresse e-mail est déjà utilisée', async function (value) {
                            try {
                                const response = await axios.get(`/check-email-exists?email=${value}`);
                                return !response.data.exists;
                            } catch (error) {
                                console.error('Un utilisateur existe sous cette adresse mail');
                                return false; // Gérer l'erreur de manière appropriée
                            }
                        })
                        .required('L\'adresse e-mail est requise'),
                    username: Yup.string()
                        .test('uniqueUsername', "Ce nom d'utilisateur est déjà utilisée", async function (value) {
                            try {
                                const response = await axios.get(`/check-username-exists?username=${value}`);

                                return !response.data.exists;
                            } catch (error) {
                                console.error('Ce nom d\'utilisateur est déjà utilisée');
                                return false; // Gérer l'erreur de manière appropriée
                            }
                        })
                        .required('Nom d\'utilisateur requis'),

                    password: Yup.string().required('Un mot de passe est requis').min(8, 'Le mot de passe doit contenir au moins 8 caractères')
                        .matches(/[a-z]/, 'Le mot de passe doit contenir au moins une lettre minuscule')
                        .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une lettre majuscule')
                        .matches(/\d/, 'Le mot de passe doit contenir au moins un chiffre')
                        .matches(/[!@#$%^&*()\-_=+[\]{}|;:,.<>?]/, 'Le mot de passe doit contenir au moins un caractère spécial suivant (!@#$%^&*()\\-_=+[\\]{}|;:,.<>?)'),
                    confirmPassword: Yup.string().required('Confirmation de mot de passe requis').oneOf([Yup.ref('password'), null], "Les mots de passe ne correspondent pas"),

                }),
                3: Yup.object().shape({}),
            },
            ROLE_COLLEAGUE: {
                size: 2,
                1: Yup.object().shape({}),
                2: Yup.object().shape({
                    email: Yup.string()
                        .email('Format de l\'adresse email invalide')
                        .test('uniqueEmail', 'Cette adresse e-mail est déjà utilisé', async function (value) {
                            try {
                                const response = await axios.get(`/check-email-exists?email=${value}`);
                                return !response.data.exists;
                            } catch (error) {
                                console.error('Un utilisateur existe sous cette adresse mail');
                                return false; // Gérer l'erreur de manière appropriée
                            }
                        })
                        .required('L\'adresse e-mail est requise'),
                    username: Yup.string()
                        .test('uniqueUsername', "Ce nom d'utilisateur est déjà utilisée", async function (value) {
                            try {
                                const response = await axios.get(`/check-username-exists?username=${value}`);

                                return !response.data.exists;
                            } catch (error) {
                                console.error('Ce nom d\'utilisateur est déjà utilisée');
                                return false; // Gérer l'erreur de manière appropriée
                            }
                        })
                        .required('Nom d\'utilisateur requis'),

                    password: Yup.string().required('Un mot de passe est requis').min(8, 'Le mot de passe doit contenir au moins 8 caractères')
                        .matches(/[a-z]/, 'Le mot de passe doit contenir au moins une lettre minuscule')
                        .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une lettre majuscule')
                        .matches(/\d/, 'Le mot de passe doit contenir au moins un chiffre')
                        .matches(/[!@#$%^&*()\-_=+[\]{}|;:,.<>?]/, 'Le mot de passe doit contenir au moins un caractère spécial suivant (!@#$%^&*()\\-_=+[\\]{}|;:,.<>?)'),
                    confirmPassword: Yup.string().required('Confirmation de mot de passe requis').oneOf([Yup.ref('password'), null], "Les mots de passe ne correspondent pas"),

                }),
                3: Yup.object().shape({}),
            },
            ROLE_PRESCRIPTEUR: {
                size: 7,
                1: Yup.object().shape({}),
                2: Yup.object().shape({
                    nom: Yup.string()
                        .required('Un nom est requis'),
                    prenom: Yup.string()
                        .required('Un prénom est requis'),
                    phones: Yup.string()
                        .required('Un numéro de téléphone est requis'),
                    city: Yup.string()
                        .required('Un nom de ville est requis'),
                    zip: Yup.string()
                        .required('Un code postal est requis'),
                    email: Yup.string()
                        .email('Format de l\'adresse email invalide')
                        .test('uniqueEmail', 'Cette adresse e-mail est déjà utilisée', async function (value) {
                            try {
                                const response = await axios.get(`/check-email-exists?email=${value}`);
                                return !response.data.exists;
                            } catch (error) {
                                console.error('Un utilisateur existe sous cette adresse mail');
                                return false; // Gérer l'erreur de manière appropriée
                            }
                        })
                        .required('L\'adresse e-mail est requise'),
                    username: Yup.string()
                        .test('uniqueUsername', "Ce nom d'utilisateur est déjà utilisée", async function (value) {
                            try {
                                const response = await axios.get(`/check-username-exists?username=${value}`);

                                return !response.data.exists;
                            } catch (error) {
                                console.error('Ce nom d\'utilisateur est déjà utilisée');
                                return false; // Gérer l'erreur de manière appropriée
                            }
                        })
                        .required('Nom d\'utilisateur requis'),

                    password: Yup.string().required('Un mot de passe est requis').min(8, 'Le mot de passe doit contenir au moins 8 caractères')
                        .matches(/[a-z]/, 'Le mot de passe doit contenir au moins une lettre minuscule')
                        .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une lettre majuscule')
                        .matches(/\d/, 'Le mot de passe doit contenir au moins un chiffre')
                        .matches(/[!@#$%^&*()\-_=+[\]{}|;:,.<>?]/, 'Le mot de passe doit contenir au moins un caractère spécial suivant (!@#$%^&*()\\-_=+[\\]{}|;:,.<>?)'),
                    confirmPassword: Yup.string().required('Confirmation de mot de passe requis').oneOf([Yup.ref('password'), null], "Les mots de passe ne correspondent pas"),

                }),
                3: Yup.object().shape({}),
            },
            ROLE_PARTENAIRE: {
                size: 6,
                1: Yup.object().shape({}),
                2: Yup.object().shape({
                    nom: Yup.string()
                        .required('Un nom est requis'),
                    prenom: Yup.string()
                        .required('Un prénom est requis'),
                    phones: Yup.string()
                        .required('Un numéro de téléphone est requis'),
                    email: Yup.string()
                        .email('Format de l\'adresse email invalide')
                        .test('uniqueEmail', 'Cette adresse e-mail est déjà utilisée', async function (value) {
                            try {
                                const response = await axios.get(`/check-email-exists?email=${value}`);
                                return !response.data.exists;
                            } catch (error) {
                                console.error('Un utilisateur existe sous cette adresse mail');
                                return false; // Gérer l'erreur de manière appropriée
                            }
                        })
                        .required('L\'adresse e-mail est requise'),

                }),
                3: Yup.object().shape({
                    profilePartner: Yup.object().shape({
                        motivation: Yup.string()
                            .required('Veuillez remplir ce champ'),
                    }),
                }),
                4: Yup.object().shape({
                    profilePartner: Yup.object().shape({
                        contribution: Yup.string()
                            .required('Veuillez remplir ce champ'),
                    }),
                }),
                5: Yup.object().shape({
                    profilePartner: Yup.object().shape({
                        businessIdea: Yup.string()
                            .required('Veuillez remplir ce champ'),
                    }),
                }),
            },
            ROLE_INVESTISSEUR: {
                size: 9,
                1: Yup.object().shape({}),
                2: Yup.object().shape({
                    email: Yup.string().email('Email invalide').required('Ce champ est requis'),
                }),
                3: Yup.object().shape({
                    age: Yup.number().required('Veuillez remplir ce champ').typeError('La valeur doit être un nombre'),
                }),
            },
            ROLE_CONSOMMATEUR: {
                size: 6,
                1: Yup.object().shape({}),
                2: Yup.object().shape({
                    nom: Yup.string()
                        .required('Un nom est requis'),
                    prenom: Yup.string()
                        .required('Un prénom est requis'),
                    phones: Yup.string()
                        .required('Un numéro de téléphone est requis'),
                    societyName: Yup.string()
                        .required('Veuillez remplir ce champ'),
                    email: Yup.string()
                        .email('Format de l\'adresse email invalide')
                        .test('uniqueEmail', 'Cette adresse e-mail est déjà utilisé', async function (value) {
                            try {
                                const response = await axios.get(`/check-email-exists?email=${value}`);
                                return !response.data.exists;
                            } catch (error) {
                                console.error('Un utilisateur existe sous cette adresse mail');
                                return false; // Gérer l'erreur de manière appropriée
                            }
                        })
                        .required('L\'adresse e-mail est requise'),
                }),
                3: Yup.object().shape({
                    property: Yup.object().shape({
                        works: Yup.array().min(1, 'Please select at least one option').required('Oops! Veuillez faire une sélection'),
                    }),
                }),
                4: Yup.object().shape({
                    city: Yup.string()
                        .required('Veuillez remplir ce champ'),
                    zip: Yup.string()
                        .required('Veuillez remplir ce champ'),
                    property: Yup.object().shape({
                        address: Yup.string()
                            .required('Veuillez remplir ce champ'),

                    }),
                }),
                5: Yup.object().shape({
                    property: Yup.object().shape({
                        totalSurface: Yup.number().required('Veuillez remplir ce champ').typeError('La valeur doit être un nombre')
                    }),
                }),
            },
            ROLE_FOURNISSEUR: {
                size: 4,
                1: Yup.object().shape({}),
                2: Yup.object().shape({
                    nom: Yup.string()
                        .required('Un nom est requis'),
                    prenom: Yup.string()
                        .required('Un prénom est requis'),
                    phones: Yup.string()
                        .required('Un numéro de téléphone est requis'),
                    city: Yup.string()
                        .required('Un nom de ville est requis'),
                    zip: Yup.string()
                        .required('Un code postal est requis'),
                    email: Yup.string()
                        .email('Format de l\'adresse email invalide')
                        .test('uniqueEmail', 'Cette adresse e-mail est déjà utilisé', async function (value) {
                            try {
                                const response = await axios.get(`/check-email-exists?email=${value}`);
                                return !response.data.exists;
                            } catch (error) {
                                console.error('Un utilisateur existe sous cette adresse mail');
                                return false; // Gérer l'erreur de manière appropriée
                            }
                        })
                        .required('L\'adresse e-mail est requise'),
                    username: Yup.string()
                        .test('uniqueUsername', "Ce nom d'utilisateur est déjà utilisée", async function (value) {
                            try {
                                const response = await axios.get(`/check-username-exists?username=${value}`);

                                return !response.data.exists;
                            } catch (error) {
                                console.error('Ce nom d\'utilisateur est déjà utilisée');
                                return false; // Gérer l'erreur de manière appropriée
                            }
                        })
                        .required('Nom d\'utilisateur requis'),

                    password: Yup.string().required('Un mot de passe est requis').min(8, 'Le mot de passe doit contenir au moins 8 caractères')
                        .matches(/[a-z]/, 'Le mot de passe doit contenir au moins une lettre minuscule')
                        .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une lettre majuscule')
                        .matches(/\d/, 'Le mot de passe doit contenir au moins un chiffre')
                        .matches(/[!@#$%^&*()\-_=+[\]{}|;:,.<>?]/, 'Le mot de passe doit contenir au moins un caractère spécial suivant (!@#$%^&*()\\-_=+[\\]{}|;:,.<>?)'),
                    confirmPassword: Yup.string().required('Confirmation de mot de passe requis').oneOf([Yup.ref('password'), null], "Les mots de passe ne correspondent pas"),

                }),
                3: Yup.object().shape({
                    profession: Yup.string().required('Ce champ est requis'),
                }),
            }
            // Ajoutez d'autres rôles et étapes ici
        };
    }

    getValidationSchema(role, step) {
        return this.schemas[role] ? this.schemas[role][step] : null;
    }

    getNumberOfStepsPerRole(role) {
        return this.schemas[role] ? this.schemas[role].size : null;
    }


}

export default ValidationManager;
