import React, {useState, useEffect, useRef} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {ErrorMessage} from "formik";
import {Editor} from '@tinymce/tinymce-react';

const ClauseForm = ({clause, index, handleChange, errors, setFieldValue, values, touched, handleBlur, isValid, setKey}) => {

    const [isToggled, setIsToogled] = useState(false);
    const editorRef = useRef(null);
    useEffect(() => {

        setKey(index);
    }, [])

    useEffect(() => {
        console.log(errors)
    }, [errors, clause])


    return (



        <div key={index}>

                <div>
                    <Row className={"mb-2"}>

                        <Col>
                            <Form.Label>Titre</Form.Label>
                            <Form.Control
                                key={`clauses[${index}]title`}
                                name={`clauses[${index}]title`}
                                id={`clauses_${index}title`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                value={clause.title}
                            />
                            <ErrorMessage name={`clauses[${index}]title`} component="div"
                                          className="error"/>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col>
                            <Form.Label>Description</Form.Label>
                            {/*<Form.Control*/}
                            {/*    name={`clauses[${index}]description`}*/}
                            {/*    id={`clauses[${index}]description`}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    type="text"*/}
                            {/*    value={clause.description}*/}
                            {/*/>*/}

                                <Editor
                                    apiKey='b4gtx3qcm9yj0zi2xvm7ysp9l3xhba3vh9b5gy4f5l1qi5ka'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    value={clause.description}
                                    onEditorChange={(newValue, editor) => {
                                        // setMessage(newValue);
                                        setFieldValue(`clauses[${index}]description`, newValue,false)
                                        // setText(editor.getContent({format: 'text'}));
                                    }}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        toolbar: 'formatselect | ' +
                                            'bold italic | alignleft aligncenter ' +
                                            'alignright |' ,
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />

                            <ErrorMessage name={`clauses[${index}]description`} component="div"
                                          className="error"/>
                        </Col>

                    </Row></div>
        </div>


    )
}

export default ClauseForm;