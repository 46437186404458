import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MaterialTable from "@material-table/core";
import {lotLinkValue} from "../../../component/LotLinkValue";
import {pxVenteValue} from "../../../component/PxVenteValue";
import axios from "axios";
import Swal from "sweetalert2";
import FilterLot from "../../Lot/component/FilterLot";
import ScrollWrapper from "../../../utils/ScrollWrapper";
import {handleLotSelectChange} from "../../../component/handleLotSelectChange";
import Pagination from "../../../component/Pagination";
import TablePaginationActions from "../../../component/TablePaginationActions";
import TablePaginationNullActions from "../../../component/TablePaginationNullActions";
import LogApiList from "../../Log/LogApiList";
import {renderLotList} from "../../../utils/lots";
import {Icon} from '@iconify/react';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import {getStatusValueForRow,getReservationLinkValueForRow} from "../../../service/ReservationService";


const ReservationTab = ({ user,filteredData,fetchReservations}) => {

    const [filter, setFilter] = useState(false);
    const tableRef = React.useRef();
    let navigate = useNavigate();
    const [rowCount, setRowCount] = useState('Liste des réservations')


    useEffect(() => {

    }, [filteredData]);

    const getCreatedAtValueForRow = createdAt => {

        var elem = <div className={'sonata-ba-list-field sonata-ba-list-field-datetime text-center'}>
            <time>{createdAt}</time>
        </div>;

        return elem;
    };


    const getNameValueForRow = rowData => {

        var elem = user.role != 'ROLE_ACQUEREUR' ?
            <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
                navigate(`/admin/contacts/${rowData.acquereurId}/voir`)
            }}>{rowData.societe ? rowData.societe : rowData.acquereur}</span> : rowData.societe ? rowData.societe : rowData.acquereur;

        return elem;
    };

    const getHiddenStatus = rowData => {

        const codeClients = ['57004'];

        var hidden;
//Un prescripteur d'Immotep57 ne peut pas passer d'option
        if (user.role === "ROLE_PRESCRIPTEUR" && codeClients.includes(user.codeClient)) {
            hidden = true;
        } else if (user.role === "ROLE_PROMOTEUR" || user.role === "ROLE_ADMIN") {
            hidden = false;
        } else if (user.role === "ROLE_PRESCRIPTEUR") {
            hidden = false;
        } else {
            hidden = true;
        }

        return hidden;
    };

    const getProgramLinkValueForRow = rowData => {
        var elem = <span className={"link-primary"} style={{cursor: 'pointer'}} onClick={() => {
            navigate(`/admin/programme/${rowData.programId}/voir`)
        }}>{rowData.programme}</span>;

        return elem;

    };

    return (

        <MaterialTable
            title={rowCount}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} of {count}',
                    labelRowsSelect: 'lignes'
                },
                toolbar: {
                    nRowsSelected: '{0} réservation(s) sélectionnée(s)',
                    searchPlaceholder: 'Chercher'
                },
                body: {
                    emptyDataSourceMessage: 'Aucune réservation à afficher',
                    filterRow: {
                        filterTooltip: 'Filter'
                    }
                }
            }}

            options={{

                search:false,
                selection: true,
                pageSize: 10,
                filtering: filter
            }}
            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
            columns={[
                {
                    title: 'Id', field: 'id',
                    filtering: false, render: rowData => getReservationLinkValueForRow(rowData),
                    width: "5%",
                },
                {
                    title: 'Date', field: 'date',
                    filtering: false,
                    align: "center",
                    render: rowData => getCreatedAtValueForRow(rowData.date),

                },
                {
                    title: 'Acquéreur',
                    field: 'acquereur',
                    filtering: false,
                    render: rowData => getNameValueForRow(rowData),
                },
                {title: "Lot", field: 'lots', filtering: false,render: rowData => renderLotList(rowData)},
                // {title: "Lot", field: 'lots', filtering: false,render: rowData => renderLotList(rowData)},
                {
                    title: 'Programme',
                    field: 'programme',
                    filtering: false,
                    render: rowData => getProgramLinkValueForRow(rowData)
                },

                {
                    title: 'Etat',
                    field: 'status',
                    filtering: false,
                    align: "center",
                    render: rowData => getStatusValueForRow(rowData),
                }


            ]}

            tableRef={tableRef}
            onSearchChange={() => {

                setRowCount('Liste réservation (' + tableRef.current.state.data.length + ')');
            }}
            data={filteredData}
            actions={[

                {
                    icon: () => <Icon icon="material-symbols:delete-outline"/>,
                    tooltip: "Supprimer toutes les réservations sélectionnées",
                    onClick: (event, rows) =>

                        Swal.fire({
                            icon: 'warning',
                            title: "Confirmer l'annulation des réservations sélectionnées ?",
                            showConfirmButton: true,
                            showCancelButton: true,
                            cancelButtonText: 'Annuler',
                            dangerMode: true,
                        })
                            .then(result => {

                                if (result.isConfirmed) {

                                    var ids = rows.map(s => s.id);
                                    let idsToString = ids.toString();

                                    axios.delete('/admin/reservations?ids=' + idsToString)
                                        .then(res => {

                                            fetchReservations();
                                            if (rows.length == 1) {
                                                var message = "La réservation a bien été annulée"
                                            } else {
                                                var message = "Les réservations ont bien été supprimées"
                                            }
                                            Swal.fire({
                                                title: "Fait!",
                                                text: message,
                                                icon: "success",
                                                timer: 2000,
                                                button: false
                                            })


                                        })
                                        .catch(function (error) {

                                                if (error.response && error.response.status === 401) {
                                                    Swal.fire({
                                                        icon: 'warning',
                                                        title: 'Vous n\'êtes pas autorisé à supprimer cette réservation',
                                                        showConfirmButton: false,
                                                        timer: 1500
                                                    })
                                                }

                                            }
                                        );


                                } else {
                                    Swal.fire('Votre contact est préservée', '', 'info')
                                }
                            })
                }

            ]
            }
        />


    )
}

export default ReservationTab;